import styled from 'styled-components';

export const SectionContainer = styled.div`
  padding: 55px 50px;
  border-radius: 3px;
  background-color: ${props => props.theme.white};
`;

export const Image = styled.img`
  object-fit: cover;
  height: 200px;
  width: 100%;
`;

export const Section = styled.section`
  margin-top: 60px;
  padding-left: 22%;
  padding-right: 22%;
  scroll-margin-top: 8rem;
`;

export const Title = styled.h1`
  font-family: Avenir-Medium;
  height: 24px;
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${props => props.theme.primaryDark};
  margin-bottom: 40px;
`;

export const Content = styled.div`
  opacity: 0.9;
  text-align: justify;
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 27px;
  color: ${props => props.theme.slateGrey};

  * {
    font-size: inherit;
    margin: 0;
  }
`;

export const Footer = styled.p`
  text-align: center;
  opacity: 0.9;
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.04px;
  color: ${props => props.theme.slateGrey};
  margin-top: 30px;
`;

export const ContentOutline = styled.div`
  margin-left: 22%;
  margin-right: 22%;
  border-radius: 3px;
  font-family: Avenir-Medium;
  border: 1px solid ${props => props.theme.slateGrey};
  margin-top: 30px;
`;
