// @flow

import {
  resetPasswordAPI,
  login as loginAPI,
  requestPasswordReset as requestPasswordResetAPI,
  changePasswordAPI,
} from '../../api';
import {
  REQUEST_LOGIN,
  SUCCESS_LOGIN,
  REQUEST_REQUEST_PASSWORD_RESET,
  REQUEST_RESET_PASSWORD,
  REQUEST_CHANGE_PASSWORD,
} from '../constants/types';

const requestLogin = () => ({ type: REQUEST_LOGIN });
const requestResetPassword = () => ({ type: REQUEST_RESET_PASSWORD });
const requestChangePassword = () => ({ type: REQUEST_CHANGE_PASSWORD });
const requestRequestPasswordReset = () => ({
  type: REQUEST_REQUEST_PASSWORD_RESET,
});

const successLogin = account => ({
  type: SUCCESS_LOGIN,
  payload: { account },
});

const login = (formdata: {
  username: string,
  password: string,
}) => async dispatch => {
  dispatch(requestLogin());

  const { data } = await loginAPI(formdata);

  dispatch(successLogin(data));

  if (data.jwt) {
    localStorage.setItem('token', data.jwt);
  }

  return data;
};

export const resetPassword = (formdata: {
  username: string,
  password: string,
  otp: string,
}) => async dispatch => {
  dispatch(requestResetPassword());

  const { data } = await resetPasswordAPI(formdata);

  return data;
};

export const changePassword = (formdata: {
  username: string,
  oldPassword: string,
  newPassword: string,
}) => async dispatch => {
  dispatch(requestChangePassword());

  const { data } = await changePasswordAPI(formdata);
  return data;
};

export const requestPasswordReset = (email: string) => async dispatch => {
  dispatch(requestRequestPasswordReset());

  const { data } = await requestPasswordResetAPI(email);

  return data;
};

export default login;
