// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getStrategyTransfers = (policyId: string) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/policies/${policyId}/funds/transfers`;

  return axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });
};

export default getStrategyTransfers;

// @flow

// const getStrategyRedirects = (policyId: string) => ({
//   data: [
//     { assetAllocation: '1', investmentAllocation: '2', effectiveDate: new Date().toISOString() },
//     { assetAllocation: '3', investmentAllocation: '4', effectiveDate: new Date().toISOString() },
//     { assetAllocation: '5', investmentAllocation: '6', effectiveDate: new Date().toISOString() },
//   ]
// });

// export default getStrategyRedirects;
