import colors from '../../config/colors';

export const axisTitle = {
  fontColor: colors.slateGrey,
  fontFamily: 'Avenir-Medium',
  fontSize: 14,
  lineHeight: 1.71,
};

export const chartLabelFontStyle = {
  fontColor: colors.white,
  fontFamily: 'Avenir-Medium',
  fontSize: 14.7,
};

export const chartLegendFontStyle = {
  fontColor: colors.primaryDark,
  fontFamily: 'Avenir-Medium',
  fontSize: 12,
};

export const chartLegendStyle = {
  padding: 22,
  usePointStyle: true,
  boxWidth: 9,
};

export const hiddenPieChartTicks = {
  fontColor: 'transparent',
  lineHeight: '20px',
};

export const basicTooltip = {
  backgroundColor: colors.white,
  titleFontFamily: 'Avenir-Medium',
  titleFontSize: 14,
  titleFontStyle: 'normal',
  titleFontColor: colors.slateGrey,
  titleAlign: 'left',
  titleMarginBottom: 10,
  titleSpacing: 15,
  bodyFontFamily: 'Avenir-Heavy',
  bodyAlign: 'left',
  bodyFontColor: colors.primaryDark,
  bodyFontSize: 16,
  bodyFontStyle: 'normal',
  bodySpacing: 15,
  xPadding: 15,
  yPadding: 15,
  cornerRadius: 3,
  displayColors: false,
  caretSize: 0,
};

export const tooltipStyle = {
  ...basicTooltip,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowBlur: 6,
  shadowColor: colors.paleGreyDark,
};

export const tooltipStyleNoTitle = {
  ...tooltipStyle,
  bodyFontFamily: 'Avenir-Medium',
  bodyAlign: 'left',
  bodyFontColor: colors.primaryDark,
  bodyFontSize: 14,
  bodyFontStyle: 'normal',
  bodySpacing: 15,
};

export const chartGridLinesStyle = {
  drawBorder: false,
  color: 'rgba(217, 217, 217, 0.6)',
  zeroLineColor: 'rgba(217, 217, 217, 0.6)',
};

export const xAxisTickStyle = {
  fontColor: colors.slateGrey,
  fontSize: 14,
  fontFamily: 'Avenir-Roman',
  padding: 15,
  z: 10,
};

export const yAxisTickStyle = {
  fontColor: colors.slateGrey,
  fontSize: 12,
  fontFamily: 'Avenir-Roman',
  padding: 22,
  z: 10,
};

export const barStyle = {
  barThickness: 10,
  backgroundColor: colors.lightBlueGrey80,
};

export const lineHeightAnnotationStyle = {
  color: colors.paleGreyDark,
  lineWeight: 1,
  shadow: {
    color: colors.paleGreyDark,
    blur: 6,
    offset: {
      x: 0,
      y: 0,
    },
  },
  noDash: true,
};
