// @flow

import * as qs from 'query-string';

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';

import Tabs from '../../components/tabs';
import globals from '../../config/globals';
import Documents from '../../containers/documents';
import Performance from '../../containers/performance';
import Contribution from '../../containers/contribution';
import PolicyDetailsTab from '../../components/policies/details';
import EncashmentTab from '../../components/policies/encashment';
import BeneficiaryTab from '../../components/policies/beneficiaries';
import Strategy from '../../containers/strategy';

const PolicyDetails = () => {
  const location = useLocation();

  const [policyId, setPolicyId] = useState();
  const [policyNumber, setPolicyNumber] = useState();

  const [currentTab, setCurrentTab] = useState();
  const [customerId, setCustomerId] = useState('...');

  const { id: ownerParam } = useParams();
  const { policyNumber: policyParam } = qs.parse(location.search) || {};
  const { policyNumber: policyState } = location.state || {};

  useEffect(() => {
    setCustomerId(ownerParam || null);

    if (policyParam || policyState) {
      setPolicyNumber(policyParam || policyState);
    }
  }, [ownerParam, policyParam]);

  function renderCurrentTab() {
    switch (currentTab) {
      case globals.POLICY_TABS.DETAILS.key:
        return <PolicyDetailsTab {...{ policyId, customerId }} />;

      case globals.POLICY_TABS.BENEFICIARIES.key:
        return <BeneficiaryTab {...{ policyId, customerId }} />;

      case globals.POLICY_TABS.CONTRIBUTION.key:
        return <Contribution {...{ policyId, customerId }} />;

      case globals.POLICY_TABS.ENCASHMENT.key:
        return <EncashmentTab {...{ policyId, customerId }} />;

      case globals.POLICY_TABS.PERFORMANCE.key:
        return <Performance {...{ policyId, customerId }} />;

      case globals.POLICY_TABS.STRATEGY.key:
        return <Strategy {...{ policyId, customerId }} />;

      case globals.POLICY_TABS.DOCUMENTS.key:
        return <Documents {...{ policyId, customerId }} />;

      default:
        return null;
    }
  }

  return (
    <>
      <Tabs
        customerId={customerId}
        onChangeTab={setCurrentTab}
        onChangePolicyId={setPolicyId}
        policyNumber={policyNumber}
      />
      {renderCurrentTab()}
    </>
  );
};

export default PolicyDetails;
