// @flow

import React, { useState, useEffect } from 'react';
import { isEmpty, isNull } from 'lodash';
import Spinner from '../../shared/spinner';

import {
  usePolicyBenefits,
  usePolicyBasicInfo,
  usePremiumAllocation,
  usePolicyPaymentDetails,
} from '../../../hooks/policies.hooks';

import { isInvestmentTypePolicy } from '../../../utils/policy';

import PolicyPaymentDetails from './payment-details';
import PolicyBrokerInfo from './broker-info';
import PolicyBasicInfo from './basic-info';
import PolicyBenefits from './benefits';
import PremiumAllocation from './premium-allocation';

type Props = {
  policyId: number,
  customerId: number,
};

const PolicyDetailsTab = ({ policyId, customerId }: Props) => {
  const [isCalendarOpen, setIsCalenderOpen] = useState(false);
  const [tempDateAsAt, setTempDateAsAt] = useState(null);
  const [policyType, setPolicyType] = useState(null);
  const [dateChange, setDateChange] = useState();
  const [additionalCurrency, setAdditionalCurrency] = useState({
    id: null,
    name: '',
  });

  // loader field "loading" present for details - rest are loaded dynamically
  const { loading, data: basicInfo } = usePolicyBasicInfo(policyId, customerId);
  const { data: paymentDetails } = usePolicyPaymentDetails(
    policyId,
    customerId,
  );
  const { data: benefits } = usePolicyBenefits(policyId, customerId);

  const { data: premiumAllocation } = usePremiumAllocation(
    policyId,
    dateChange,
    policyType,
    true,
    additionalCurrency.id,
  );

  const handleDateChange = (date: Moment) => {
    setIsCalenderOpen(false);
    setDateChange(date);
  };

  useEffect(() => {
    if (!isEmpty(premiumAllocation) && isNull(tempDateAsAt)) {
      setDateChange(premiumAllocation.info.lastDate);
      setTempDateAsAt(premiumAllocation.info.lastDate);
    }
  }, [JSON.stringify(premiumAllocation)]);

  useEffect(() => {
    const { policyType: type, currencies } = basicInfo;

    if (type) {
      setPolicyType(type);
    }

    if (!isEmpty(currencies) && isNull(additionalCurrency.id)) {
      const defaultPolicyCurrency = currencies.find(curr => curr.isDefault);
      setAdditionalCurrency(defaultPolicyCurrency);
    }
  }, [basicInfo]);

  const handleCurrencyChange = (currencyId: number) => {
    const { currencies: policyCurrencies } = basicInfo;
    const selectedCurrency = policyCurrencies.find(
      curr => curr.id === currencyId,
    );

    setAdditionalCurrency(selectedCurrency);
  };

  function renderContent() {
    const {
      currencies: policyAvailableCurrencies,
      currency: defaultCurrency,
    } = basicInfo;

    return (
      <>
        <PolicyBasicInfo data={basicInfo} />
        <PolicyBrokerInfo data={basicInfo} />

        <PolicyPaymentDetails
          data={{
            ...paymentDetails,
            policyType,
            currency: defaultCurrency,
            policyNumber: basicInfo.policyNumber,
            frequency: basicInfo.frequency,
            hasExtraPremium: basicInfo.hasExtraPremium,
          }}
        />

        <PolicyBenefits
          data={benefits}
          currency={defaultCurrency}
          policyType={policyType}
        />

        {isInvestmentTypePolicy(policyType) && (
          <PremiumAllocation
            availableCurrencies={policyAvailableCurrencies}
            data={(premiumAllocation.allocations || []).slice(1)}
            currency={additionalCurrency}
            effectiveDate={basicInfo.startDate}
            dateChange={dateChange}
            isCalendarOpen={isCalendarOpen}
            setCalendarOpenState={setIsCalenderOpen}
            handleCurrencyChange={handleCurrencyChange}
            handleDateChange={handleDateChange}
            maxDate={
              !isEmpty(premiumAllocation) && premiumAllocation.info.lastDate
            }
          />
        )}
      </>
    );
  }

  return loading ? <Spinner /> : renderContent();
};

export default PolicyDetailsTab;
