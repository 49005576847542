// @flow

import React from 'react';
import Table from '../shared/table';
import { tableCol } from './styles';
import strings from '../../config/strings';

const AllocationsListView = ({ funds, policyInfo = {} }: Props) => (
  <Table
    contentData={funds}
    headerData={strings.PORTFOLIO_ALLOCATIONS_TABLE_HEADERS}
    customStyle={tableCol}
    customRow={{
      fundTicker: strings.TOTAL,
      cost: +(policyInfo.netInvestedAmount || 0),
      totalUnits: +(policyInfo.policyValue || 0),
      profitLoss: +(policyInfo.totalProfitLoss || 0),
      profitLossPerc: +(policyInfo.totalReturn || 0),
      interestDividend: +(policyInfo.totalInterestDividend || 0),
      interestDividendPercentage: +(policyInfo.totalInterestDividendPerc || 0),
      totalGain: +(policyInfo.totalGainTotal || 0),
      gainPercentage: +(policyInfo.totalPercentage || 0),
    }}
    shouldScrollInside
  />
);

export default AllocationsListView;
