import styled from 'styled-components';
import { size } from '../../../config/device';
import { ConvertableRowToColContainer } from '../../shared/container';

export const policyDetailsListTitle = { marginBottom: 22 };

export const ListRowContainer = styled.div`
  background: ${props => props.theme.white};
  border-radius: 3px;
`;

export const ListRow = styled(ConvertableRowToColContainer)`
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 36px;
  background-color: ${props => props.theme.white};
  border-bottom: 1px solid #e1e1e1;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${size.tablet}) {
    align-items: flex-start;
    padding: 15px 0;
    min-height: 51px;
  }
`;

export const SectionContainer = styled.div`
  margin-bottom: 60px;
  position: relative;

  @media (max-width: ${size.tablet}) {
    margin-bottom: 30px;
  }
`;

export const PieChartWrapper = styled.div`
  position: relative;
  width: 75%;
  margin: auto;
  margin-bottom: 50px;
`;
