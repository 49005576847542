// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getServiceForms = () => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL || ''}/service-forms`;

  return axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });
};

// const getServiceForms = () => ({
//   data: [
//     {
//       documentName: 'Latest info',
//       documentType: 'pdf',
//       dateAdded: 'June 23, 2020',
//       id: 1,
//     },
//     {
//       documentName: 'Latest info',
//       documentType: 'pdf',
//       dateAdded: 'June 23, 2020',
//       id: 2,
//     },
//     {
//       documentName: 'Latest info',
//       documentType: 'pdf',
//       dateAdded: 'June 23, 2020',
//       id: 3,
//     },
//   ],
// });

export default getServiceForms;
