import styled from 'styled-components';
import { RowContainer } from '../shared/container';
import device, { size } from '../../config/device';

export const MainContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  top: 94px;
  z-index: 8000;
  position: sticky;
  background: ${props => props.theme.paleGrey};

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media (max-width: ${size.tablet}) {
    flex-wrap: nowrap;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;

  @media (max-width: ${size.tablet}) {
    display: block;
    overflow: scroll;
    margin: 0 calc(-1 * (100% - 85.76% + 1.76%) / 2);
    margin-bottom: 2rem;

    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TabsScrollable = styled(RowContainer)`
  border-bottom: solid 2px ${props => props.theme.whiteDarker};

  @media (max-width: ${size.tablet}) {
    width: fit-content;
    overflow: hidden;
    padding: 0 calc((100% - 85.76%) / 2);
  }
`;

export const TabsIndicator = styled.div`
  height: ${props => (props.active ? '5px' : '0')};
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: height 0.2s;
  background-color: ${props => props.theme.primary};
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const TabItem = styled.a`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-top: 18px;
  min-width: 100px;
  width: auto;

  margin-left: 10px;
  margin-right: 10px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  span {
    opacity: 0.8;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    font-family: Avenir-Medium;
    font-weight: ${props => (props.active ? 900 : 500)};
    letter-spacing: ${props => (props.active ? '0.13px' : '-0.04px')};
    color: ${props =>
      props.active ? props.theme.primary : props.theme.slateGrey};
    line-height: 24px;
    padding-top: 12px;
  }
`;
