import styled from 'styled-components';

const Button = styled.a`
  background-color: ${props => props.theme.cobaltBlue07};
  transition: background-color .1s;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  padding: 10px;

  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.primary};

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;

  :hover {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.white};
  }
`;

export default Button;
