import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { roundDecimalToInteger } from '../utils/numbers';
import { getProducerFilters } from '../redux/actions/producer';

const parseValues = arrayValues => {
  const isRange = arrayValues[0].planTypeCode === 'ALL';

  const displayValues = arrayValues.map(value =>
    isRange
      ? `${roundDecimalToInteger(
          value.rangeFromValue,
        )} - ${roundDecimalToInteger(value.rangeToValue)}`
      : value.planType,
  );

  const realValues = arrayValues.map(value =>
    isRange
      ? `${value.rangeFromValue}-${value.rangeToValue}`
      : value.planTypeCode,
  );

  return { displayValues, realValues };
};

const reduceFilters = filters =>
  filters.reduce((acc, curr) => {
    const currentAccumulator = { ...acc };
    const { filterDescription } = curr;

    if (Array.isArray(acc[filterDescription])) {
      currentAccumulator[filterDescription].push(curr);
    } else {
      currentAccumulator[filterDescription] = [curr];
    }

    return currentAccumulator;
  }, {});

const formatFilters = filters => {
  const filterObject = reduceFilters(filters);

  return Object.entries(filterObject).map(([key, value]) => ({
    key,
    name: key,
    ...parseValues(value),
  }));
};

const useFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.producer.filters);

  useEffect(() => {
    if (!filters.length) {
      dispatch(getProducerFilters());
    }
  }, [filters]);

  return formatFilters(filters);
};

export default useFilters;
