import styled from 'styled-components';
import colors from '../../config/colors';

const Textbox = styled.input`
  width: 100%;
  border-radius: 3px;
  border: solid 1px rgba(100, 101, 109, 0.65);
  background-color: transparent;
  padding: 13px 0;
  text-indent: 16px;

  font-family: Avenir-Book;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${colors.primaryDark};

  ${({ hasError }) =>
    hasError &&
    `
    border: solid 2px ${colors.danger};
    `};

  :focus {
    border-width: 2px;
    border-style: solid;
    border-color: ${({ hasError }) =>
      hasError ? colors.danger : colors.primary};
  }

  :disabled {
    background-color: ${colors.slateGrey10};
    border: 0px;
    width: calc(100% + 2px);
    padding: 14px 0;
    color: ${colors.battleshipGrey};
  }
`;

export const TextBoxWrapper = styled.div`
  margin-top: 10px;
`;

export default Textbox;
