// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../../utils/error';

const { REACT_APP_CMS_URL } = process.env;

const getInbox = (searchQuery, page) => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_CMS_URL}/support-chat/conversations?page=${page}`;

  return axios.get(url + (searchQuery ? `&_q=${searchQuery}` : ''));
};

export default getInbox;
