import styled from 'styled-components';
import colors from '../../../config/colors';
import { size } from '../../../config/device';

const BorderlessLink = styled.p`
  font-family: Avenir-Heavy;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: ${props => props.theme.primary};

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
  }
`;

export const BorderlessLinkLight = styled(BorderlessLink)`
  color: ${props => props.theme.slateGrey};
`;

export const DocumentLink = styled.p`
  font-family: Avenir-Medium;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.14px;
  color: ${props => props.theme.primaryDark};
`;

export const DocumentLinkRef = styled.a`
  opacity: 0.9;
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  letter-spacing: -0.04px;
  color: ${colors.slateGrey65};
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: ${size.tablet}) {
    font-size: 12px;
    letter-spacing: 0.11px;
  }
`;

export default BorderlessLink;
