import { useIdleTimer } from 'react-idle-timer';

import strings from '../config/strings';

const { REACT_APP_IDLE_TIMEOUT_MINS: IDLE_TIMEOUT } = process.env;

const IdleTimeout = ({ onIdle: handleOnIdle }) => {
  const events = ['keydown', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown'];

  useIdleTimer({
    timeout: 1000 * 60 * IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 1000,
    events,
  });

  const alertUserLogout = () => {
    if (window.location.pathname === '/login') {
      return false;
    }

    // eslint-disable-next-line no-alert
    alert(strings.WARNING_INACTIVITY);
    return true;
  };

  useIdleTimer({
    timeout: 1000 * 60 * (IDLE_TIMEOUT - 3),
    onIdle: alertUserLogout,
    debounce: 1000,
    events,
  });

  return null;
};

export default IdleTimeout;
