// @flow
import React from 'react';
import Select from 'react-select';
import {
  arrowContainer,
  container,
  controlStyle,
  dropDownMenuListDesign,
  indicatorSeparator,
  indicatorsContainer,
  inputStyle,
  placeholderStyle,
  singleValue,
  valueContainer,
} from './styles';
import colors from '../../../config/colors';

type Props = {
  placeholder: string,
  value: string,
};

const InputDropDown = ({ placeholder, value }: Props) => (
  <Select
    isDisabled
    maxMenuHeight={200}
    placeholder={placeholder}
    theme={theme => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: colors.slateGrey10,
        primary: colors.slateGrey10,
      },
    })}
    inputValue={value}
    styles={{
      container: () => container,
      menuList: provided => ({ ...provided, ...dropDownMenuListDesign }),
      valueContainer: () => valueContainer,
      control: () => controlStyle,
      input: provided => ({ ...provided, ...inputStyle }),
      dropdownIndicator: () => arrowContainer,
      indicatorSeparator: () => indicatorSeparator,
      indicatorsContainer: () => indicatorsContainer,
      placeholder: provided => ({
        ...provided,
        ...placeholderStyle,
      }),
      singleValue: defaultStyles => ({
        ...defaultStyles,
        ...singleValue,
      }),
    }}
  />
);

export default InputDropDown;
