import axios from 'axios';
import { isEmpty } from 'lodash';

import colors from '../config/colors';
import strings from '../config/strings';

const FileDownloader = (urlPath, headers, params) =>
  new Promise(resolve => {
    axios({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
      headers,
      params,
    }).then(response => {
      let filename = 'file.pdf';

      if (response.headers['content-disposition']) {
        [, filename] = response.headers['content-disposition'].split(
          'filename=',
        );
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.replace(/(^")|("$)/g, ''));
      document.body.appendChild(link);
      link.click();

      resolve();
    });
  });

export const FileViewer = (urlPath, headers, params) => {
  const newWindow = window.open();
  newWindow.document.write(
    `<html>
      <style>
        #bar {
          width: 100%;
          height: 20px;
          border-radius: 8px;
          background-color: ${colors.whitishGrey};
        }

        #progressBar {
          width: 0%;
          height: 20px;
          border-radius: 8px;
          background-color: ${colors.primary};
        }

        p {
          font-family: sans-serif;
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.19px;
          color: ${colors.primaryDark};
        }
      </style>

      <body>
        <p>${strings.DOCUMENT_LOADER_TEXT}</p>

        <div id="bar">
          <div id="progressBar" />
        </div>

        <br />

      </body>
    </html>`,
  );

  return new Promise(resolve => {
    axios({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
      headers,
      params,
      onDownloadProgress: config => {
        const percentCompleted = Math.round(
          (config.loaded * 100) / config.total,
        );

        newWindow.document.getElementById(
          'progressBar',
        ).style.width = `${percentCompleted}%`;
      },
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type }),
      );

      newWindow.location = url;

      resolve();
    });
  });
};

export const ImageExtractor = (blob, imgId) => {
  const src = window.URL.createObjectURL(
    new Blob([blob], { type: 'image/png' }),
  );

  const img = document.getElementById(imgId);
  if (!isEmpty(img)) img.src = src;

  return src;
};

export default FileDownloader;
