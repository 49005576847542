// @flow

import React from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';

import MainPage from '../components/skeleton';

type ProfileInfoObj = {
  firstName: string,
  lastName: string,
  fullname: string,
  dob: string,
  maritalStatus: string,
  address: string,
  type: 'Male' | 'Female' | 'Company',
  nationality: number,
  email: string,
  phoneNumbers: Array<string>,
  profilePic: ?string,
};

type Props = { children: Node };

const Main = ({ children }: Props) => {
  const { logo = {} } = useSelector(state => state.media.data);
  const userProfile: ProfileInfoObj = useSelector(state => state.user.data);

  return (
    <MainPage userProfile={userProfile} logoUrl={logo.url}>
      {children}
    </MainPage>
  );
};

export default Main;
