import {
  REQUEST_GET_DOCUMENTS_CONTRACTS,
  SUCCESS_GET_DOCUMENTS_CONTRACTS,
  REQUEST_GET_DOCUMENTS_NOTICES,
  SUCCESS_GET_DOCUMENTS_NOTICES,
  SET_CURRENT_POLICY_ID,
} from '../constants/types';

const defaultData = {
  requested: false,
  loading: false,
  data: [],
};

const loadingData = {
  requested: false,
  loading: true,
  data: [],
};

const initialState = {
  currentPolicyId: '',
  contracts: { ...defaultData },
  notices: { ...defaultData },
};

const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_POLICY_ID:
      if (state.currentPolicyId === action.payload.currentPolicyId) {
        return { ...state };
      }

      return { ...initialState, currentPolicyId: action.payload.currentPolicyId };

    case REQUEST_GET_DOCUMENTS_CONTRACTS:
      return { ...state, contracts: { ...loadingData } };

    case SUCCESS_GET_DOCUMENTS_CONTRACTS:
      return { ...state, contracts: { loading: false, requested: true, data: action.payload.contracts } };

    case REQUEST_GET_DOCUMENTS_NOTICES:
      return { ...state, notices: { ...loadingData } };

    case SUCCESS_GET_DOCUMENTS_NOTICES:
      return { ...state, notices: { loading: false, requested: true, data: action.payload.notices } };

    default:
      return { ...state };
  }
};

export default DocumentsReducer;
