// @flow

import React, { useState, useEffect } from 'react';
import { isEmpty, omit, pick } from 'lodash';

import strings from '../../config/strings';
import { useAccountValue } from '../../hooks';
import { isEmptyNumber } from '../../utils/numbers';
import AccountValueSection from '../../components/strategy/account-value';

type Props = {
  currency: { id: number, name: string },
  policyId: number,
};

const AccountValue = ({ policyId, currency }: Props) => {
  const defaultFundsOptions = [
    { id: -1, name: strings.STRATEGY_ACCOUNT_VALUE_DROPDOWN_TITLE },
  ];

  const [period, setPeriod] = useState(4);
  const [selectedFundId, setFundId] = useState(-1);
  const [fundsOptions, setFundsOptions] = useState(defaultFundsOptions);

  const { data, loading } = useAccountValue(
    policyId,
    null,
    period,
    currency.id,
  );

  const fundsGroupedById = data.funds.reduce((accumulator, elt) => {
    const fund = Object.assign({}, elt);
    const fundInfoHeaders = ['fundId', 'fundDescription'];

    fund.policyValue = isEmptyNumber(fund.policyValue) ? 0 : fund.policyValue;
    fund.profitLoss = isEmptyNumber(fund.profitLoss) ? 0 : fund.profitLoss;

    if (isEmpty(accumulator[fund.fundId])) {
      accumulator[fund.fundId] = {
        info: { ...pick(fund, ...fundInfoHeaders) },
        stats: [omit(fund, ...fundInfoHeaders)],
      };
    } else {
      accumulator[fund.fundId].stats.push(omit(fund, ...fundInfoHeaders));
    }

    return accumulator;
  }, {});

  const handleFundsDropdownClick = (fundId: string | number) => {
    setFundId(fundId);
  };

  const handlePeriodPickerClick = (periodIndex: number) => {
    setPeriod(periodIndex);
  };

  useEffect(() => {
    const options = defaultFundsOptions;
    Object.keys(fundsGroupedById).forEach(fundId => {
      options.push({
        id: fundId,
        name: fundsGroupedById[fundId].info.fundDescription,
      });
    });

    const fundIds = Object.keys(fundsGroupedById);
    setFundId(fundIds[fundIds.length - 1] || -1);
    setFundsOptions(options);
  }, [JSON.stringify(fundsGroupedById)]);

  return (
    <AccountValueSection
      chartInfo={data.chartInfo}
      currency={currency}
      funds={fundsGroupedById}
      fundsOptions={fundsOptions}
      handleFundsDropdownClick={handleFundsDropdownClick}
      handlePeriodPickerClick={handlePeriodPickerClick}
      isLoading={loading}
      selectedFundId={selectedFundId}
      selectedPeriod={period}
    />
  );
};

export default AccountValue;
