// @flow

import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';

import FundsChart from './chart';
import strings from '../../../config/strings';
import ChartDescription from './chart-description';
import { SectionTitle2 } from '../../shared/texts/title';
import { strategyListTitle, SectionBlock } from '../container';

import type {
  ChartDescription as ChartDescriptionType,
  RiskReturnFundPerformance,
} from '../../../models/funds';

type Props = {
  chartInfo: ChartDescriptionType,
  funds: Array<RiskReturnFundPerformance>,
  policyCurrency: string,
};

const RiskReturn = ({ chartInfo, funds, policyCurrency }: Props) => (
  <Fragment>
    <SectionTitle2 style={strategyListTitle}>
      {strings.SECTION_TITLE_STRATEGY_RISK_RETURN}
    </SectionTitle2>

    <SectionBlock>
      {!isEmpty(funds) && (
        <FundsChart funds={funds} policyCurrency={policyCurrency} />
      )}
      <ChartDescription chartInfo={chartInfo} />
    </SectionBlock>
  </Fragment>
);

export default RiskReturn;
