// @flow

import React from 'react';
import globals from '../../config/globals';
import { Outline, TabContainer } from './container';
import ListViewIcon from '../shared/hamburger-menu/tab-switch';
import {
  chartsViewActive,
  chartsViewInactive,
  chartsViewInactive2x,
  chartsViewActive3x,
  chartsViewActive2x,
  chartsViewInactive3x,
} from '../../assets/images';

type Props = { activeTabKey: string, handleTabClick: Function };

const Switch = ({ activeTabKey, handleTabClick }: Props) => (
  <Outline>
    <TabContainer
      tabIndex={0}
      role="button"
      onClick={() =>
        handleTabClick(globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.LIST_VIEW)
      }
    >
      <ListViewIcon
        active={
          activeTabKey === globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.LIST_VIEW
        }
      />
    </TabContainer>

    <TabContainer
      tabIndex={0}
      role="button"
      onClick={() =>
        handleTabClick(globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.CHART_VIEW)
      }
    >
      <img
        src={
          activeTabKey === globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.CHART_VIEW
            ? chartsViewActive
            : chartsViewInactive
        }
        alt="chart_view"
        srcSet={
          activeTabKey === globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.CHART_VIEW
            ? `${chartsViewActive2x} 2x, ${chartsViewActive3x} 3x`
            : `${chartsViewInactive2x} 2x, ${chartsViewInactive3x} 3x`
        }
      />
    </TabContainer>
  </Outline>
);

export default Switch;
