// @flow

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import axios from 'axios';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import Main from '../containers/main';
import ScrollToTop from './scroll-top';
import Library from '../containers/library';
import UserLogin from '../containers/login';
import Overview from '../containers/overview';
import Producer from '../containers/producer';
import ProducerPolicies from '../containers/producer-policies';
import ServiceForms from '../containers/service-forms';
import CustomerInformation from '../containers/customer-information';
import PrivacyPolicy from '../containers/privacy-policy';
import Inbox from '../containers/inbox';
import FAQs from '../containers/faqs';
import ResetPassword from '../containers/reset-password';
import ChangePassword from '../containers/change-password';
import ForgetPassword from '../containers/forget-password';

import {
  PolicyDetails,
  ArticleDetails,
  ProfilePage,
  ResetPasswordSuccess,
} from '../pages';

import {
  FAQS,
  INDEX,
  INBOX,
  LIBRARY,
  LOGIN,
  PRODUCER,
  POLICY_DETAILS,
  ARTICLE_DETAILS,
  SERVICE_FORMS,
  PROFILE_DETAILS,
  CUSTOMER_INFORMATION,
  PRIVACY_POLICY,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  FORGET_PASSWORD,
  PRODUCER_POLICIES,
  PRODUCER_CUSTOMER_INFORMATION,
  PRODUCER_POLICY_DETAILS,
} from './routes';

import globals from '../config/globals';
import IdleTimeout from './idle-timeout';
import { getNotifications } from '../redux/actions';
import { getUserInfo } from '../redux/actions/user';

import TermsSignature from '../containers/terms-signature';
import useMediaImages from '../hooks/useMediaImages';
import { hasProducerAccess } from '../utils/user';

function useAnalyticsPageViews() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(globals.GA_TRACKER);
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.hash);
  }, [location]);
}

type Props = {
  onIdle: Function,
};

const Routes = ({ onIdle }: Props) => {
  useMediaImages();
  useAnalyticsPageViews();

  const dispatch = useDispatch();
  const location = useLocation();

  const [authed, setAuthed] = useState(!!localStorage.getItem('token'));

  const { account = {}, data: userInfo } = useSelector(state => state.user);

  const isProducer = account.data && account.data.user
    && hasProducerAccess(account.data.user.typeLinkCode);

  useEffect(() => {
    delete axios.defaults.headers.common.Authorization;

    const isAuthenticated = !!(account && account.data && account.data.jwt);

    if (isAuthenticated) {
      axios.defaults.headers.common.Authorization = `Bearer ${account.data.jwt}`;

      // wait 0 to 1 second to fetch userInfo
      // userInfo may get earlier (userTypeLink will be defined)
      if (userInfo.userTypeLink === undefined) {
        setTimeout(() => setAuthed(isAuthenticated), 1000);
      } else {
        setAuthed(isAuthenticated);
      }
    } else {
      setAuthed(isAuthenticated);
    }
  }, [account, isProducer]);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem('token')) {
        dispatch(getNotifications());
      }
    }, 500);
  }, [location]);

  useEffect(() => {
    if (authed && account && account.data) {
      const { user = {} } = account.data;
      dispatch(getUserInfo(user.username));
    }
  }, [authed]);

  return (
    <>
      {!authed && (
        <Switch>
          <Route exact path={LOGIN} component={UserLogin} />
          <Route exact path={RESET_PASSWORD} component={ResetPassword} />
          <Route
            exact
            path={RESET_PASSWORD_SUCCESS}
            component={ResetPasswordSuccess}
          />
          <Route exact path={FORGET_PASSWORD} component={ForgetPassword} />
          <Redirect from="*" to={LOGIN} />
        </Switch>
      )}

      {authed && (
        <Main>
          <Switch>
            <Route
              exact
              path={PRODUCER}
              component={isProducer ? Producer : Overview}
            />
            <Route
              exact
              path={INDEX}
              component={isProducer ? Producer : Overview}
            />
            <Route exact path={CHANGE_PASSWORD} component={ChangePassword} />

            {isProducer && (
              <Route
                exact
                path={PRODUCER_POLICIES}
                component={ProducerPolicies}
              />
            )}

            {isProducer && (
              <Route
                exact
                path={PRODUCER_CUSTOMER_INFORMATION}
                component={CustomerInformation}
              />
            )}

            {isProducer && (
              <Route
                exact
                path={CUSTOMER_INFORMATION}
                component={CustomerInformation}
              />
            )}

            {isProducer && (
              <Route
                exact
                path={PRODUCER_POLICY_DETAILS}
                component={PolicyDetails}
              />
            )}

            {!isProducer && <Route exact path={INBOX} component={Inbox} />}

            <Route exact path={SERVICE_FORMS} component={ServiceForms} />
            <Route exact path={ARTICLE_DETAILS} component={ArticleDetails} />
            <Route exact path={POLICY_DETAILS} component={PolicyDetails} />
            <Route exact path={LIBRARY} component={Library} />
            <Route exact path={FAQS} component={FAQs} />
            <Route exact path={PROFILE_DETAILS} component={ProfilePage} />
            <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />

            <Redirect from="*" to={INDEX} />
          </Switch>

          <IdleTimeout onIdle={onIdle} />
          <TermsSignature />
        </Main>
      )}
    </>
  );
};

export default ({ onIdle }: Props) => (
  <Router>
    <ScrollToTop />
    <Routes onIdle={onIdle} />
  </Router>
);
