// @flow

import React from 'react';

import {
  Button,
  ActionsWrapper,
  CheckboxWrapper,
} from './styles';

import Checkbox from '../../shared/checkbox';
import strings from '../../../config/strings';

type Props = {
  onClickClear: Function;
  onClickApply: Function;

  checkboxValue: Boolean;
  onChangeCheckboxValue: (value: boolean) => void;
};

const Actions = ({ onClickClear, onClickApply, checkboxValue, onChangeCheckboxValue }: Props) => (
  <ActionsWrapper>
    <CheckboxWrapper>
      <Checkbox
        checkboxValue={checkboxValue}
        onChangeCheckboxValue={onChangeCheckboxValue}
        label={strings.FILTER_SHOW_ACTIVE}
      />
    </CheckboxWrapper>
    <div>
      <Button type="negative" onClick={onClickClear}>
        {strings.FILTER_CLEAR_ALL}
      </Button>
      <Button type="positive" onClick={onClickApply}>
        {strings.FILTER_APPLY}
      </Button>
    </div>
  </ActionsWrapper>
);

export default Actions;
