import styled from 'styled-components';
import { size } from '../../config/device';
import { RowContainer } from '../shared/container';

export const Container = styled.div`
  height: 423px;
  border-radius: 3px;
  padding: 30px 32px;
  margin-bottom: 60px;
  background-color: ${props => props.theme.white};
  box-sizing: border-box;
  position: relative;
  display: none;

  @media (max-width: ${size.tablet}) {
    margin-right: calc(-1 * (100% - 85.76%) / 2);
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  overflow: hidden;
  min-width: 650px;
  width: 100%;
  height: 100%;
`;

export const ScrollableContainer = styled.div`
  overflow-x: scroll;
  width: 100%;
  height: 100%;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardsContainer = styled(RowContainer)`
  justify-content: space-between;
  margin-bottom: 30px;
  overflow-x: auto;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${size.tablet}) {
    margin-right: calc(-1 * (100% - 85.76%) / 2);
  }
`;

export const TitleContainer = styled(RowContainer)`
  align-items: flex-start;
`;

export const ContentContainer = styled(RowContainer)`
  width: 100%;
  position: relative;
  justify-content: flex-start;

  ${({ isEditable }) =>
    isEditable &&
    `
  cursor:pointer;
  `}
`;
