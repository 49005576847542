import styled from 'styled-components';
import device from '../../config/device';

export const InboxContent = styled.div`
  display: flex;
`;

export const sectionTitleStyle = { marginBottom: 32 };

export const ActionsContainer = styled.div`
  display: flex;
`;

export const ConversationsContainer = styled.div`
  ${({ shrink }) => {
    if (shrink) {
      return `
        display: none;

        max-height: 810px;
        overflow-y: auto;
        overflow-x: hidden;

        @media ${device.laptop} {
          display: block;
          max-width: 33%;
          padding-right: 20px;
          max-height: calc(193px + 45vh);
        }
      `;
    }

    return '';
  }}

  flex-grow: 1;

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const ConversationItemContainer = styled.div`
  min-height: 66px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px 30px 10px 25px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  background-color: ${props => props.theme.white};
  box-sizing: border-box;
  cursor: pointer;

  & > div {
    display: flex;
  }

  ${({ active, shrink }) => {
    if (shrink) {
      return `opacity: ${active ? 1 : 0.6};`;
    }

    return '';
  }}

  ${({ active }) => {
    if (active) {
      return `
        position: relative;
        box-shadow: 0 3px 16px 0 #dedede;

        &:after, &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(0, 0, 0, 0);
          border-left-color: #fff;
          border-width: 15px;
          margin-top: -15px;
        }
      `;
    }

    return '';
  }}
`;

export const SenderItem = styled.div`
  width: 300px;
  display: flex;

  @media ${device.mobileL} {
    border-right: 1px solid ${props => props.theme.white4};
  }

  ${({ shrink }) => {
    if (shrink) {
      return `
        border: none !important;
      `;
    }

    return '';
  }}
`;

export const SenderName = styled.span`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 900;
  line-height: 46px;
  padding-left: 15px;
  letter-spacing: -0.1px;
  color: ${props => props.theme.primaryDark};
`;

export const ContentContainer = styled.p`
  padding: 0 24px;
  line-height: 46px;

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;

  height: 46px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  display: none;

  @media ${device.mobileL} {
    display: -webkit-box;
  }

  ${({ shrink }) => {
    if (shrink) {
      return `
        display: none !important;
      `;
    }

    return '';
  }}
`;

export const Content = styled.span`
  letter-spacing: -0.1px;
  color: ${props => props.theme.slateGrey};
  font-family: Avenir-Roman;
  font-size: 14px;
`;

export const Subject = styled.span`
  font-family: Avenir-Heavy;
  font-size: 14px;
  letter-spacing: -0.1px;
  content: '${props => props.subject} - ';
  color: ${props => props.theme.primaryDark};
`;

export const MobileContent = styled(Content)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  padding-left: 62px;

  @media ${device.mobileL} {
    display: none;
  }

  ${({ shrink }) => {
    if (shrink) {
      return `
        display: -webkit-box !important;
      `;
    }

    return '';
  }}
`;

export const DateHint = styled.p`
  width: 250px;
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: oblique;
  line-height: 46px;
  letter-spacing: -0.1px;
  text-align: right;
  color: ${props => props.theme.slateGrey};
  display: none;

  @media ${device.mobileL} {
    display: inline;
  }

  ${({ shrink }) => {
    if (shrink) {
      return `
        display: none !important;
      `;
    }

    return '';
  }}
`;

export const MobileDateHint = styled(DateHint)`
  display: inline;
  position: absolute;
  right: 25px;
  top: 0;

  @media ${device.mobileL} {
    display: none;
  }

  ${({ shrink }) => {
    if (shrink) {
      return `
        display: inline !important;
      `;
    }

    return '';
  }}
`;
