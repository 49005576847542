import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  background-color: #ffffff;
  width: 48%;
  margin-bottom: 2rem;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  height: 252px;
`;

export const ArticleOverview = styled.div`
  padding: 40px 30px;
`;

export const ArticleContent = styled.p`
  height: 7rem;
  overflow: hidden;
  margin: 24px 0 38px 0;
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  font-family: Avenir-Roman;
  color: #505055;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: 1000px) {
    height: 7rem;
  }
`;

export const ArticleTitle = styled.h5`
  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.17px;
  color: #020b41;
  margin: 0;
`;
