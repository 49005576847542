import styled from 'styled-components';
import colors from '../../../config/colors';
import { size } from '../../../config/device';

export const CardTitle2 = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.13px;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    line-height: 19.2px;
    letter-spacing: 0.1px;
  }
`;

export const ProductType = styled.p`
  font-family: Avenir-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.63px;
  color: ${props => props.theme.primary};

  @media (max-width: ${size.tablet}) {
    font-size: 16px;
    letter-spacing: -0.5px;
  }
`;

export const SectionTitle = styled.p`
  font-family: Avenir-Roman;
  font-size: 26px;
  line-height: 0.92;
  letter-spacing: 0.24px;
  color: ${props => props.theme.primaryDark};
`;

export const SectionTitle2 = styled.p`
  font-family: Avenir-Roman;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.21px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 17.6px;
    line-height: 19.2px;
    letter-spacing: 0.16px;
  }
`;

export const SectionTitle3 = styled.p`
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: ${colors.nightBlue};
`;

export const SectionTitle4 = styled.p`
  color: ${props => props.theme.primaryDark};
  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.06px;

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
    line-height: 26px;
    letter-spacing: -0.05px;
  }
`;

export const SubTitle = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.13px;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: 0.1px;
  }
`;

export const SubTitle2 = styled.p`
  font-family: Avenir-Roman;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.19px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 16px;
    letter-spacing: 0.15px;
  }
`;

export const SubTitle3 = styled.p`
  font-family: Avenir-Heavy;
  font-size: 14px;
  line-height: 19.2px;
  letter-spacing: 0;
  color: ${props => props.theme.primary};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    line-height: 15.4px;
  }
`;

export const Subtitle4 = styled.p`
  font-family: Avenir-Medium;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: ${props => props.theme.primaryDark};
`;

export const Subtitle5 = styled.p`
  font-family: Avenir-Medium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.06px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-family: Avenir-Roman;
    font-size: 14.4px;
    line-height: 19.2px;
    letter-spacing: 0.14px;
  }
`;

export const Subtitle6 = styled.p`
  font-family: Avenir-Medium;
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.15px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-family: Avenir-Medium;
    font-size: 14.4px;
    letter-spacing: 0.14px;
  }
`;

export const CardTitle = styled.p`
  font-family: Avenir-Medium;
  font-size: 22px;
  line-height: 1.09;
  letter-spacing: 0.21px;
  color: ${props => props.theme.nightBlue};
`;

export const InformationTitle = styled.p`
  font-family: Avenir-Medium;
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.17px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
    letter-spacing: 0.14px;
  }
`;

export const TooltipTitle = styled.p`
  opacity: 0.9;
  font-family: Avenir-Heavy;
  font-size: 13px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${props => props.theme.primary};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: 0.1px;
  }
`;

export const ProfileDetails = styled.p`
  font-family: Avenir-Roman;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.23px;
  color: ${props => props.theme.nightBlue};

  @media (max-width: ${size.tablet}) {
    font-size: 19.2px;
    letter-spacing: 0.18px;
  }
`;

export const ProfileInformation = styled.p`
  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.17px;
  color: ${props => props.theme.primaryDark};
  margin-top: 40px;

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
    letter-spacing: 0.14px;
  }
`;

export const Notification = styled.p`
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.56;
  color: ${props => props.theme.slateGrey};
`;

export const DocumentTitle = styled.p`
  font-family: Avenir-Heavy;
  font-size: 14px;
  color: ${props => props.theme.nightBlue};
  font-weight: 900;
  line-height: 1.43;
  letter-spacing: -0.05px;

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: -0.04px;
  }
`;

export const DateTitle = styled.p`
  font-family: Avenir-Medium;
  color: ${props => props.theme.slateGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  font-style: oblique;
  letter-spacing: -0.05px;

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: -0.04px;
  }
`;

export const ViewTitle = styled.a`
  font-family: Avenir-Heavy;
  font-size: 14px;
  cursor: pointer;
  font-weight: 900;
  line-height: 1.37;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: 0;
  }
`;

export const DownloadTitle = styled.a`
  font-family: Avenir-Heavy;
  font-size: 14px;
  cursor: pointer;
  font-weight: 900;
  line-height: 1.37;
  color: ${props => props.theme.cobaltBlue};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: 0;
  }
`;

export const PopupTitle = styled.p`
  font-family: Avenir-Medium;
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.26px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 22.4px;
    line-height: 19.2px;
    letter-spacing: 0.21px;
  }
`;

export const NoDocumentTitle = styled.p`
  font-size: 16px;
  font-family: Avenir-Medium;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: -0.06px;
  color: ${props => props.theme.slateGrey65};
  text-align: center;
  width: 100%;

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
    letter-spacing: -0.05px;
  }
`;

export const EndList = styled.p`
  font-style: oblique;
  font-family: Avenir-Medium;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.04px;
  color: ${props => props.theme.slateGrey};
`;
