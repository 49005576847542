import styled from 'styled-components';
import { size } from '../../config/device';
import { RowContainer } from '../shared/container';

export const policyContractListTitle = { marginBottom: 30 };
export const policyNoticesListTitle = { marginBottom: 30 };

export const SectionContainer = styled.div`
  padding-bottom: 60px;
`;

export const BadgeContainer = styled(RowContainer)`
  width: 48px;
  height: 48px;
  border-radius: 3.6px;
  background-color: ${props => props.theme.cobaltBlue10};
  object-fit: contain;

  @media (max-width: ${size.tablet}) {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 2.4px;
  }

  @media (max-width: ${size.mobileS}) {
    display: ${({ isHiddenSm }) => isHiddenSm && 'none'};
  }
`;

export const OpenLinkImg = styled.img`
  @media (max-width: ${size.mobileM}) {
    display: ${({ isHiddenSm }) => isHiddenSm && 'none'};
  }
`;
