// @flow

import React from 'react';
import strings from '../../../config/strings';
import { searchIcon } from '../../../assets/images';

import {
  SearchBox,
  SearchIcon,
  SearchBoxContainer,
} from './styles';

type Props = {
  onSearch: (input: string) => void,
};

const Search = ({ onSearch }: Props) => (
  <SearchBoxContainer>
    <SearchBox
      name="search"
      autoComplete="off"
      onChange={(e) => onSearch(e.target.value)}
      placeholder={strings.FILTER_SEARCH_PLACEHOLDER}
    />
    <SearchIcon src={searchIcon} height="16" width="17" />
  </SearchBoxContainer>
);

export default Search;
