import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  flex: 1;
  position: relative;
  margin-bottom: 20px;
`;

export const SearchBox = styled.input`
  margin: 0;
  padding: 0;
  height: 55px;
  border: none;
  display: block;
  padding-left: 60px;
  border-radius: 3px;
  background-color: ${props => props.theme.white};

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 24px;
  top: 19px;
`;
