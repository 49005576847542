// @flow

import React from 'react';
import moment from 'moment';

import type { Moment } from 'moment';

import DatePicker from '../form/date';
import DatePickerWrapper from './styles';
import strings from '../../config/strings';
import { formatDate } from '../../utils/date';

type Props = {
  dateAsAt: Moment,
  handleDateChange: Function,
  isCalendarOpen: boolean,
  maxDate?: Moment,
  setCalendarOpenState: Function,
};

const GlobalFilters = ({
  dateAsAt,
  handleDateChange,
  isCalendarOpen,
  maxDate,
  setCalendarOpenState,
}: Props) => (
  <DatePickerWrapper>
    <DatePicker
      maxDate={maxDate}
      value={formatDate(dateAsAt)}
      handleDateChange={handleDateChange}
      isCalendarOpen={isCalendarOpen}
      isInline={false}
      key="strategyDate"
      setCalendarOpenState={setCalendarOpenState}
      title={strings.PERFORMANCE_POLICY_DATE_LABEL}
    />
  </DatePickerWrapper>
);

GlobalFilters.defaultProps = {
  maxDate: moment(),
};

export default GlobalFilters;
