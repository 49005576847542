// @flow

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { Moment } from 'moment';

import {
  getAccountValueByPolicyId,
  getFundsAnalysis,
  getFundsAveraging,
  getBenchmarking,
  getStrategyTransfers,
  getFundsPrices,
} from '../redux/actions';
import { isEmptyNumber } from '../utils/numbers';

export const useStrategyBenchmarking = (
  policyId: number,
  dateAsAt: Moment,
  shouldUpdate: boolean,
) => {
  const dispatch = useDispatch();

  const polId = useSelector(state => state.policies.currentPolicyId);
  const strategyBenchmarking = useSelector(
    state => state.strategy.benchmarking,
  );

  useEffect(() => {
    if (
      (!isEmptyNumber(policyId) &&
        !isEmptyNumber(polId) &&
        policyId !== polId) ||
      (!isEmptyNumber(policyId) && !isEmptyNumber(polId) && shouldUpdate)
    ) {
      dispatch(getBenchmarking(policyId, dateAsAt));
    }
  }, [policyId, dateAsAt, shouldUpdate]);

  return strategyBenchmarking;
};

export const useStrategyTransfers = policyId => {
  const dispatch = useDispatch();

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);

  const { loading, requested, data } = useSelector(
    state => state.strategy.transfers,
  );

  useEffect(() => {
    if (policyId && (policyId !== previousPolicyId || !requested)) {
      dispatch(getStrategyTransfers(policyId));
    }
  }, [policyId]);

  return { loading, data };
};

export const useStrategyAnalysis = (
  policyId: number,
  dateAsAt: Moment,
  shouldUpdate: boolean,
) => {
  const dispatch = useDispatch();

  const analysis = useSelector(state => state.strategy.analysis);
  const polId = useSelector(state => state.policies.currentPolicyId);

  useEffect(() => {
    if (
      (!isEmptyNumber(policyId) &&
        !isEmptyNumber(polId) &&
        policyId !== polId) ||
      (!isEmptyNumber(policyId) && !isEmptyNumber(polId) && shouldUpdate)
    ) {
      dispatch(getFundsAnalysis(policyId, dateAsAt));
    }
  }, [policyId, dateAsAt, shouldUpdate]);

  return analysis;
};

export const useFundsAveraging = (policyId: number) => {
  const dispatch = useDispatch();

  const averaging = useSelector(state => state.strategy.averaging);

  useEffect(() => {
    if (policyId && averaging && !averaging.requested) {
      dispatch(getFundsAveraging(policyId));
    }
  }, [policyId]);
  return averaging;
};

export const useFundsPrices = (
  policyId: number,
  period: number,
  fundId: number,
) => {
  const dispatch = useDispatch();

  const prices = useSelector(state => state.strategy.prices);

  useEffect(() => {
    if (!fundId || +fundId < 1) {
      return;
    }

    if (policyId || (prices && !prices.requested)) {
      dispatch(getFundsPrices(policyId, period, fundId));
    }
  }, [policyId, period, fundId]);

  return prices;
};

export const useAccountValue = (
  policyId: number,
  fundId: number,
  period: number,
  currencyId: number,
) => {
  const dispatch = useDispatch();

  const accountValue = useSelector(state => state.strategy.accountValue);

  useEffect(() => {
    if ((policyId && currencyId) || (accountValue && !accountValue.requested)) {
      dispatch(getAccountValueByPolicyId(policyId, fundId, period, currencyId));
    }
  }, [policyId, fundId, period, currencyId]);

  return accountValue;
};
