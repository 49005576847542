import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FAQsPage } from '../pages';
import { getFAQs } from '../redux/actions';

import Spinner from '../components/shared/spinner';

const FAQs = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(state => state.faqs);

  useEffect(() => {
    dispatch(getFAQs());
  }, []);

  function renderContent() {
    return <FAQsPage {...{ data }} />;
  }

  return loading ? <Spinner /> : renderContent();
};

export default FAQs;
