import styled from 'styled-components';
import { RowContainer } from '../container';
import device from '../../../config/device';

const Overlay = styled(RowContainer)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 23, 145, 0.3);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  z-index: 9200;

  @media ${device.mobileL} {
    background: rgba(0, 23, 145, 0.15);
  }
`;

export default Overlay;
