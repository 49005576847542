// @flow

import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  openLinkIcon,
  openLinkIcon2x,
  openLinkIcon3x,
} from '../../../assets/images';
import { RowContainer } from '../container';
import strings from '../../../config/strings';
import DocumentBadge from '../../documents/badge';
import { OpenLinkImg } from '../../documents/styles';
import { PopupTitle, Subtitle6 } from '../texts/title';
import { ScrollableLinksContainer } from './container';
import { PopupLinksListContainer, PopupLinkOutline, Block } from './links';

type Links = { label: string, name: string, path: string };

type Props = {
  dataArray: Array<Links>,
  handleLinkClick: Function,
};

const PopupContent = ({ dataArray, handleLinkClick }: Props) => {
  const getDocType = (label: 'string') => {
    const str = label.split('.');
    const docType = str[str.length - 1];
    return docType;
  };

  return (
    <Fragment>
      <PopupTitle>{strings.MORE_DOCUMENTS_POPUP_TITLE}</PopupTitle>

      <ScrollableLinksContainer>
        <PopupLinksListContainer>
          {!isEmpty(dataArray) &&
            dataArray.map(link => (
              <PopupLinkOutline
                key={link.label}
                onClick={() =>
                  handleLinkClick(link.name.split(',')[0], link.path)
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <RowContainer>
                  <DocumentBadge docType={getDocType(link.label)} isHiddenSm />

                  <Block>
                    <Subtitle6>
                      {link.label.includes(',')
                        ? link.label.split(',')[1]
                        : link.label}
                    </Subtitle6>
                  </Block>
                </RowContainer>

                <OpenLinkImg
                  src={openLinkIcon}
                  srcSet={`${openLinkIcon2x} 2x, ${openLinkIcon3x} 3x`}
                  alt="open_link"
                  isHiddenSm
                />
              </PopupLinkOutline>
            ))}
        </PopupLinksListContainer>
      </ScrollableLinksContainer>
    </Fragment>
  );
};

export default PopupContent;
