// @flow

import React, { Fragment } from 'react';

import type Fund from '../../models/policies';

import { ChartsRowContainer } from './container';
import PortfolioPieChart from './portfolio-pie-chart';
import ProfitLossBarChart from './profit-loss-bar-chart';
import AccountValue from '../../containers/strategy/account-value';

type Props = {
  currency: { id: number, name: string },
  funds: Array<Fund>,
  policyId: number,
};

const ChartsView = ({ currency, funds, policyId }: Props) => (
  <Fragment>
    <ChartsRowContainer>
      <PortfolioPieChart currency={currency} funds={funds} />
      <ProfitLossBarChart funds={funds.sort((a, b) => a.fundId - b.fundId)} />
    </ChartsRowContainer>

    <AccountValue currency={currency} policyId={policyId} />
  </Fragment>
);

export default ChartsView;
