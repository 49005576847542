// @flow

import React from 'react';
import FAQs from '../components/faqs';

type Props = {
  data: any[];
};

const FAQsScreen = ({ data }: Props) => (
  <>
    <FAQs data={data} />
  </>
);

export default FAQsScreen;
