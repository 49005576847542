import styled from 'styled-components';
import { size } from '../../config/device';
import { Header } from '../shared/texts/content';

export const HamburgerDiv = {
  marginLeft: '8px',
  display: 'flex',
  width: '43px',
  height: '43px',
  alignSelf: 'center',
};

export const FixedDiv = styled.div`
  background-color: ${props => props.theme.paleGrey};
  z-index: 9000;
  width: 100%;

  position: sticky;
  top: 0;
`;

export const UpperDiv = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.paleGrey};
`;

export const MenuWrapper = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  paddingLeft: '20px',
  alignSelf: 'center',
};

export const RightHeaderWrapper = {
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '88px',
  justifyContent: 'space-between',
  width: '100%',
};

export const LogoWrapper = {
  width: '50%',
};

export const DateWrapper = {
  alignSelf: 'center',
};

export const ProfileWrapper = {
  flexDirection: 'column',
  paddingTop: '10px',
};

export const profilePicture = { marginLeft: 15 };

export const ItemWrapper = {
  display: 'flex',
  alignSelf: 'center',
  cursor: 'pointer',
  outline: 'none',
};
export const NotificationWrapper = {
  display: 'flex',
  alignSelf: 'center',
  cursor: 'pointer',
  outline: 'none',
  flexDirection: 'column',
};

export const SectionDivider = styled.div`
  display: flex;
  margin: 7px 15px;
  align-items: center;

  @media (max-width: ${size.tablet}) {
    display: ${({ isHiddenSM = true }) => (!isHiddenSM ? 'none' : 'inherit')};
  }
`;

export const MarginRight = {
  marginRight: '20px',
};

export const PageNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;

export const ImageWrapper = {
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  alignSelf: 'center',
  outline: 'none',
};

export const UserDisplayName = styled(Header)`
  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;
