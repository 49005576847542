import styled from 'styled-components';
import colors from '../../config/colors';
import { RowContainer, ColumnContainer } from '../shared/container';

export const Container = styled(ColumnContainer)`
  align-items: flex-start;
`;

export const Content = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const Outline = styled(RowContainer)`
  width: 87px;
  height: 26px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  background-color: ${colors.white};
  padding: 8px 0;
  align-self: flex-end;

  > :nth-child(n) {
    display: flex;
    flex: 1;
    padding: 0 8px;
    border-right: 1px solid ${colors.white4};
    height: 100%;
  }

  > :last-child {
    border: 0;
  }
`;

export const TabContainer = styled(ColumnContainer)`
  cursor: pointer;

  :active,
  :focus {
    outline: none;
  }
`;
