import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getArticles } from '../../../redux/actions/articles.action';
import { SectionTitle } from '../../shared/texts/title';
import { Grid, articlesListTitle } from './container';

import strings from '../../../config/strings';
import ArticleCard from '../article-card';

const ArticlesList = () => {
  const dispatch = useDispatch();
  const articles = useSelector(state => state.articles.data);

  useEffect(() => {
    dispatch(getArticles());
  }, []);

  return (
    <>
      {!!articles.length && (
        <SectionTitle style={articlesListTitle}>
          {strings.SECTION_TITLE_ARTICLES_LIST}
        </SectionTitle>
      )}
      <Grid>
        {
          articles.map(article =>
            <ArticleCard key={article.id} article={article} />
          )
        }
      </Grid>
    </>
  );
};

export default ArticlesList;
