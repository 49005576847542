// @flow

/**
 * Reference
 * https://medium.com/@subalerts/implememting-a-simple-collapsible-component-in-react-js-67c796e64652
 * */

import React, { useState } from 'react';

import { SectionTitle4 } from '../texts/title';
import { collabsiblePlus, collabsibleMinus } from '../../../assets/images';
import { Container, Content, Wrapper, Header, CollapsibleIcon } from './styles';

type Props = {
  title: String,
  children: any,
  contentHeight: any,
};

const Collapsible = ({ title, children, contentHeight }: Props) => {
  const [open, setOpen] = useState(false);

  const togglePanel = () => {
    setOpen(wasOpen => !wasOpen);
  };

  return (
    <Container>
      <Header onClick={togglePanel}>
        <SectionTitle4>{title}</SectionTitle4>
        {!open ? (
          <CollapsibleIcon src={collabsiblePlus} alt="+" />
        ) : (
          <CollapsibleIcon src={collabsibleMinus} alt="-" />
        )}
      </Header>
      <Content height={contentHeight} open={open}>
        <Wrapper>{children}</Wrapper>
      </Content>
    </Container>
  );
};

export default Collapsible;
