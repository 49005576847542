// @flow

import React, { useState } from 'react';
import Spinner from '../components/shared/spinner';
import ContributionScreen from '../pages/policy-details/contribution';
import { useContributionChart, useContributionInfo } from '../hooks/contribution';
import { usePolicyBasicInfo } from '../hooks/policies.hooks';
import { dateToString } from '../utils/date';

type Props = {
  policyId: number,
  customerId: number,
};

const Contribution = ({ policyId, customerId }: Props) => {
  const [tablePage, setTablePage] = useState(1);
  const [contributionType, setContributionType] = useState('TOTAL');

  const { data: basicInfo } = usePolicyBasicInfo(policyId, customerId);
  const { policyType, currency = 'USD' } = basicInfo;

  const { loading, data: contributionsData } = useContributionInfo(policyId, tablePage);
  const { data: payments } = useContributionChart(policyId, contributionType);

  const chartPayments = payments.map(({ amount, premiumDueDate }) => ({
    label: dateToString(premiumDueDate),
    value: amount,
  })).reverse();

  function onLoadMorePayments() {
    setTablePage(page => page + 1);
  }

  function renderContent() {
    return (
      <ContributionScreen
        payments={chartPayments}
        contributionsPage={tablePage}
        onLoadMorePayments={onLoadMorePayments}
        contributions={contributionsData.contributions}
        contributionsInfo={contributionsData.contributionsInfo}
        onChangeContributionType={setContributionType}
        policyType={policyType}
        currency={currency}
      />
    );
  }

  return loading ? <Spinner /> : renderContent();
};

export default Contribution;
