// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  HeaderAction,
  HeaderActionGreyText,
  HeaderActionGreyIcon,
  SectionContainer,
  DetailsHeader,
  DetailsContainer,
  ArticleTitleContainer,
  ArticleLargeImage,
  ContentContainer,
  ArticleContent,
  ArticleTitle,
  ArticleNotes,
  ArticleDate,
  ArticleText,
  Footer,
  HeaderActionImage,
} from './container';

import strings from '../../../config/strings';
import getImageUrl from '../../../utils/image';
import { formatDate } from '../../../utils/date';

import type Article from '../../../models/article';
import { arrowBack } from '../../../assets/images';

type Props = {
  data: Article,
};

const ArticleDetailsComponent = ({ data = {} }: Props) => {
  const history = useHistory();

  return (
    <SectionContainer>
      <DetailsContainer>
        <DetailsHeader>
          <HeaderAction onClick={() => history.goBack()}>
            <HeaderActionGreyIcon>
              <HeaderActionImage src={arrowBack} alt="back" />
            </HeaderActionGreyIcon>
            <HeaderActionGreyText>
              {strings.ARTICLE_DETAILS_GO_BACK}
            </HeaderActionGreyText>
          </HeaderAction>
        </DetailsHeader>
        {!!data.image && (
          <ArticleLargeImage
            src={getImageUrl(data.image, 'large')}
          />
        )}
        <ContentContainer>
          <ArticleText>
            <ArticleTitleContainer>
              <ArticleTitle>{data.title}</ArticleTitle>
              <ArticleDate>
                {`${strings.PRE_ARTICLE_DATE} ${formatDate(
                  data.created_at,
                ).replace(/,/g, '')}${strings.POST_ARTICLE_DATE}`}
              </ArticleDate>
            </ArticleTitleContainer>
            <ArticleContent>{data.content}</ArticleContent>
          </ArticleText>
          <ArticleNotes />
        </ContentContainer>
        <Footer>{strings.ARTICLE_DETAILS_FOOTER}</Footer>
      </DetailsContainer>
    </SectionContainer>
  );
};

export default ArticleDetailsComponent;
