import {
  REQUEST_GET_ARTICLES,
  SUCCESS_GET_ARTICLES,
  FAILURE_GET_ARTICLES,
  SUCCESS_GET_ARTICLE_DETAILS,
} from '../constants/types';

const initialState = {
  currentArticle: {},
  errorMessage: '',
  loading: false,
  data: [],
};

const ArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_ARTICLES:
      return { ...state, errorMessage: '', loading: true };

    case SUCCESS_GET_ARTICLES:
      return { ...state, loading: false, data: action.payload.articles };

    case FAILURE_GET_ARTICLES:
      return { ...state, loading: false, errorMessage: action.payload.err };
    
    case SUCCESS_GET_ARTICLE_DETAILS:
      return { ...state, currentArticle: action.payload.currentArticle };
    
    default:
      return { ...state };
  }
};

export default ArticlesReducer;
