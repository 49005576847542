import styled from 'styled-components';

import colors from '../../config/colors';
import { size } from '../../config/device';
import { ConvertableBlock } from '../policies/overview/container';
import { ColumnContainer, RowContainer } from '../shared/container';

const ChartsViewContainer = styled(ColumnContainer)`
  align-items: flex-start;
  background-color: ${colors.white};
  box-shadow: 0 1px 3px 0 ${colors.paleGrey2};
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;
  /* height: fit-content; */

  @media (max-width: ${size.laptop}) {
    width: calc(100% - 40px);
  }
`;

export const PortfolioBlockContainer = styled(ChartsViewContainer)`
  width: calc(39% - 40px);
`;

export const ProfitLossBlockContainer = styled(ChartsViewContainer)`
  width: calc(59% - 40px);
  overflow-x: auto;
  /* height: 100%; */
`;

export const ProfitLossScrollableWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  @media (max-width: ${size.laptop}) {
    min-width: 650px;
  }
`;

export const ChartsRowContainer = styled(ConvertableBlock)`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 60px;
  /* height: fit-content; */
  align-items: stretch;
`;

export const ScrollableChartContainer = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 25px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: inherit;
`;

export const DoughnutChartWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const BarChartTicksContainer = styled(RowContainer)`
  width: calc(100% - 100px);
  margin: -20px;
  height: 58px;
  border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 -4px 14px 0 rgba(199, 203, 224, 0.4);
  background-color: ${colors.white};
  z-index: 10;
  padding: 0 70px;
  justify-content: space-between;
`;

export const TooltipContainer = styled.div`
  margin-left: 11px;
`;

export const AllocationsTitleContainer = styled(RowContainer)`
  position: absolute;
  top: 8px;
`;
