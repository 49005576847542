import styled from 'styled-components';
import { size } from '../../../config/device';

export const NavigationLink = styled.p`
  font-family: Avenir-Medium;
  font-size: 12.6px;
  font-weight: 500;
  text-align: center;
  line-height: 1.43;
  letter-spacing: -0.39px;
  padding-top: 9px;
  color: ${props => props.theme.white};
`;

export const LogoutLink = styled.p`
  font-family: Avenir-Roman;
  font-size: 16px;
  color: ${props => props.theme.nightBlue};
  align-self: center;
  padding-left: 12px;
  cursor: pointer;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;

export const PageName = styled.p`
  font-family: Avenir-Medium;
  font-size: 16px;
  color: ${props => props.theme.dusk};
  align-self: center;
`;

export const PasswordLink = styled.p`
  font-family: Avenir-Medium;
  cursor: pointer;
  font-style: oblique;
  color: ${props => props.theme.slateGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.13px;
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.slateGrey};
`;

export const BackToSearchLink = styled.p`
  font-family: Avenir-Roman;
  font-size: 14px;
  letter-spacing: -0.05px;
  color: ${props => props.theme.slateGrey90};
  text-align: center;
`;
