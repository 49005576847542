import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import getMedia from '../redux/actions/media';

const useMediaImages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedia());
  }, []);

  return null;
};

export default useMediaImages;
