import styled from 'styled-components';
import { size } from '../../config/device';
import { ColumnContainer } from '../shared/container';

const CardOutline = styled(ColumnContainer)`
  height: 68px;
  padding: 16px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  background-color: ${props => props.theme.white};
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 1.5%;
  flex: 1;

  :only-child {
    flex: 0.5;
  }

  :last-child {
    margin: 0;
  }

  @media (max-width: ${size.tablet}) {
    min-width: 224px;
  }
`;

export default CardOutline;
