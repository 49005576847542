import {
  REQUEST_GET_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS,
  FAILURE_GET_NOTIFICATIONS,
} from '../constants/types';

import getNotificationsAPI from '../../api/notifications/get-notification';

const requestGetNotifications = () => ({ type: REQUEST_GET_NOTIFICATIONS });

const successGetNotifications = notifications => ({
  type: SUCCESS_GET_NOTIFICATIONS,
  payload: { notifications },
});

const failureGetNotifications = err => ({
  type: FAILURE_GET_NOTIFICATIONS,
  payload: { err },
});

export const getNotifications = id => async dispatch => {
  dispatch(requestGetNotifications());

  try {
    const { data } = await getNotificationsAPI(id);

    dispatch(successGetNotifications(data));
  } catch (error) {
    dispatch(failureGetNotifications(error));
  }
};

export default getNotifications;
