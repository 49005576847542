// @flow

import moment from 'moment';

import React from 'react';
import ProfilePic from '../profile-pic';

import { MessageContainer, MessageText } from './chat-styles';

type Props = {
  data: any[],
};

const Message = ({ data }: Props) => (
  <MessageContainer userMessage={!data.fromSupport}>
    <ProfilePic containerStyle={{ width: 30, height: 30 }} />
    <MessageText userMessage={!data.fromSupport}>
      <span>{data.text}</span>
      <br /><i>{moment(data.created_at).fromNow()}</i>
    </MessageText>
  </MessageContainer>
);

export default Message;
