import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PopupOverlay from './popup';
import SignTermsAndConditions from '../components/terms-conditions-popup';

import { submitTerms } from '../redux/actions/privacy-policy';
import { getPrivacyPolicy } from '../redux/actions';

const TermsSignature = () => {
  const dispatch = useDispatch();

  const [popupOpen, setPopupOpen] = useState(false);

  const account = useSelector(state => state.user.account);
  const { disclaimer } = useSelector(state => state.privacyPolicy.data);

  useEffect(() => {
    dispatch(getPrivacyPolicy());

    if (account && account.data.user && !account.data.user.signDate) {
      setPopupOpen(true);
    }
  }, []);

  const onAccept = () => {
    dispatch(submitTerms());
    setPopupOpen(false);
  };

  return (
    <PopupOverlay
      isOpen={popupOpen && disclaimer}
      containerStyle={{ backgroundColor: '#eee' }}
    >
      <SignTermsAndConditions
        onAccept={onAccept}
        data={disclaimer}
      />
    </PopupOverlay>
  );
};

export default TermsSignature;
