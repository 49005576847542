// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import SwitchContainer from '../components/switch-container';

type State = { activeTabKey: string };
type Props = {
  activeTabKey: string,
  children: Node,
  handleTabSwitch: Function,
};

export default class Switch extends Component<Props, State> {
  state = { activeTabKey: '' };

  componentDidMount = () => {
    const { activeTabKey } = this.props;
    this.setState({ activeTabKey });
  };

  handleTabChange = (key: string) => {
    this.setState({ activeTabKey: key });

    const { handleTabSwitch } = this.props;
    handleTabSwitch(key);
  };

  render() {
    const { activeTabKey } = this.state;
    const { children } = this.props;

    return (
      <SwitchContainer
        activeTabKey={activeTabKey}
        handleTabClick={this.handleTabChange}
      >
        {children}
      </SwitchContainer>
    );
  }
}
