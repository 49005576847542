const CURRENCIES = {
  USD: { key: 'USD', value: '$' },
  LBP: { key: 'LBP', value: 'LBP' },
  GBP: { key: 'GBP', value: '£' },
  EUR: { key: 'EUR', value: '€' },
  AED: { key: 'AED', value: 'د.إ' },
  CHF: { key: 'CHF', value: 'fr.' },
  KWD: { key: 'KWD', value: 'KD' },
};

export default Object.freeze({
  CHART_ACCOUNT_VALUE_CONFIG: {
    PROFIT_LOSS: { TOOLTIP_LABEL: 'Profit/loss' },
    ACCOUNT_VALUE: { TOOLTIP_LABEL: 'Policy value' },
    PERIOD_PICKERS: ['1W', '1M', '3M', '1Y', '2Y'],
  },
  CHART_ANALYSIS_LABELS: {
    STYLE: { 1: 'Value', 2: 'Blend', 3: 'Growth', 4: 'Hedge' },
    SIZE: { 1: 'Small cap', 2: 'Mid cap', 3: 'Large cap' },
  },
  FUNDS_REDIRECT_PAGING: 8,
  INBOX_PAGING: 10,
  CONTRIBUTION_PAGING: 12,
  GA_TRACKER: 'UA-170080286-1',
  COOKIES_STORAGE_KEY: 'allowCookies',
  DOWNLOADS_STORAGE_KEY: 'allowDownloads',
  // any other currency (even empty) defaults to USD
  CURRENCIES: new Proxy(CURRENCIES, {
    get(receiver, name) {
      return name in receiver ? receiver[name] : receiver.USD;
    },
  }),
  POLICY_TYPE_INVESTMENT: ['L'],
  POLICY_TYPE_BENEFITS_HEADER: ['L', 'E'],
  POLICY_TYPES: {
    L: { label: 'Life insurance and savings', priority: 1 },
    E: { label: 'University Educational program', priority: 2 },
    T: { label: 'Term life insurance', priority: 3 },
    P: { label: 'Personal accident insurance', priority: 4 },
    D: { label: 'Cancer insurance', priority: 5 },
    null: { priority: 6 },
  },
  POLICY_TABS: {
    DETAILS: { key: 'policy-details', label: 'Policy details' },
    BENEFICIARIES: { key: 'beneficiaries', label: 'Beneficiaries' },
    CONTRIBUTION: { key: 'contribution', label: 'Contribution' },
    ENCASHMENT: { key: 'encashment', label: 'Encashment' },
    PERFORMANCE: { key: 'performance', label: 'Performance' },
    STRATEGY: { key: 'strategy', label: 'Strategy' },
    DOCUMENTS: { key: 'documents', label: 'Documents' },
  },
  PRODUCER_POLICIES_PAGING: 12,
  PRODUCER_TABS: {
    POLICIES: { key: 'policies', label: 'Policies' },
    CUSTOMER_INFORMATION: {
      key: 'customer-information',
      label: 'Customer information',
    },
  },
  STORAGE_USER_POLICIES: 'userPolicies',
  STORAGE_USER_PROFILE: 'userProfile',
  STORAGE_POLICY_ID: 'currentPolicyId',
  PORTFOLIO_ALLOCATIONS_TABS_KEYS: {
    CHART_VIEW: 'chartView',
    LIST_VIEW: 'listView',
  },
  STRATEGY_SUB_TABS: {
    ANALYTICS: { key: 'analytics', label: 'Portfolio analytics' },
    ALLOCATIONS: { key: 'allocations', label: 'Funds allocations' },
  },
});
