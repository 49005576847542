// @flow

import React from 'react';
import FilterBar from '../components/producer/filter-bar';
import FilterChips from '../components/producer/filter-chips';
import ProducerTable from '../components/producer/table';
import Spinner from '../components/shared/spinner';

import { ProducerStats } from '../models/producer';

type Props = {
  data: ProducerStats[],
  filterQuery: String,
  searchTerm: String,
  loading: Boolean,

  chips: any[],
  levels: any[],
  filters: any[],
  selectedFilters: any[],

  onClearChips: Function,
  onRemoveChip: (index: number) => void,
  onApply: filter => void,

  checkboxValue: Boolean,
  onChangeCheckboxValue: (value: boolean) => void,

  onSearch: Function,
  onExpandRow: Function,
  expandedRow: Function,
};

const Producer = ({
  checkboxValue,
  onChangeCheckboxValue,
  chips,
  onSearch,
  onRemoveChip,
  onClearChips,
  onApply,
  onExpandRow,
  filterQuery,
  searchTerm,
  data,
  filters,
  selectedFilters,
  loading,
  setOpen,
  open,
}: Props) => (
  <>
    <FilterBar
      onApply={onApply}
      onSearch={onSearch}
      onClearAll={onClearChips}
      checkboxValue={checkboxValue}
      onChangeCheckboxValue={onChangeCheckboxValue}
      selectedFilters={selectedFilters}
      filters={filters}
    />
      <FilterChips
        chips={chips}
        onRemoveChip={onRemoveChip}
        onClearChips={onClearChips}
      />
      {loading && <Spinner />}
      {!loading && (
        <ProducerTable
          isParent
          allPolicies={!checkboxValue}
          onExpandRow={onExpandRow}
          searchTerm={searchTerm}
          filter={filterQuery}
          setOpen={setOpen}
          open={open}
          data={data}
        />
      )}
    </>
  );

export default Producer;
