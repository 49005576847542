import styled from 'styled-components';
import { size } from '../../../config/device';
import { CardContent } from '../../shared/texts/content';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 52px;
  background-color: ${props => props.theme.white};
  border-radius: 3px;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 60px 60px;
  box-sizing: border-box;
  align-content: space-between;
  justify-content: space-between;

  @media (max-width: ${size.tablet}) {
    padding: 9% 3.8%;
  }
`;

export const BeneficiaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: solid 1px ${props => props.theme.blueBerry20};
  border-radius: 3px;
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 13px;
  background-color: ${props => props.theme.paleGrey};
  border-radius: 3px;
  height: 44px;
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.whitishGrey};
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
`;

export const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  margin-top: 20px;
  background-color: ${props => props.theme.paleGrey};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: ${size.tablet}) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const CardValue = styled(CardContent)`
  text-align: right;
  flex: 0.95;
`;
