// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';

import globals from '../../config/globals';
import { ChartBlockTitle } from './header';
import strings from '../../config/strings';
import type Fund from '../../models/policies';
import DoughnutChart from '../charts/doughnut';
import { PortfolioBlockContainer, DoughnutChartWrapper } from './container';

type Props = {
  currency: { id: number, name: string },
  funds: Array<Fund>,
};

const PortfolioPieChart = ({ currency, funds }: Props) => (
  <PortfolioBlockContainer>
    <ChartBlockTitle>
      {strings.PERFORMANCE_PORTFOLIO_CHART_TITLE}
    </ChartBlockTitle>

    {!isEmpty(funds) && (
      <DoughnutChartWrapper>
        <DoughnutChart
          data={funds.map(fund => fund.totalUnits)}
          labels={funds.map(
            fund => `${fund.fundDescription} (${fund.fundTicker})`,
          )}
          currency={globals.CURRENCIES[currency.name].value}
        />
      </DoughnutChartWrapper>
    )}
  </PortfolioBlockContainer>
);

export default PortfolioPieChart;
