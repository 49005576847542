/* eslint-disable import/prefer-default-export */

import {
  REQUEST_GET_LIBRARY,
  SUCCESS_GET_LIBRARY,
  FAILURE_GET_LIBRARY,
} from '../types/library';

import getLibraryAPI from '../../api/library';

const requestGetLibrary = () => ({ type: REQUEST_GET_LIBRARY });

const successGetLibrary = (library) => ({
  type: SUCCESS_GET_LIBRARY,
  payload: { library },
});

const failureGetLibrary = (err) => ({
  type: FAILURE_GET_LIBRARY,
  payload: { err },
});

export const getLibrary = () => async (dispatch) => {
  dispatch(requestGetLibrary());

  try {
    const { data } = await getLibraryAPI();

    dispatch(successGetLibrary(data));
  } catch (error) {
    dispatch(failureGetLibrary(error));
  }
};
