import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ProducerScreen from '../pages/producer';
import useDebounce from '../hooks/useDebounce';

import { useFilters } from '../hooks';
import { INDEX } from '../router/routes';
import { getProducerSubLevels } from '../api/producer';
import { getProducer, getProducerLevels } from '../redux/actions/producer';

const generateFilterQuery = (filters) => {
  let query = '';

  filters.forEach(({ key, realValue }) => {
    const [value1, value2] = realValue.split('-');

    switch (key) {
      case 'Policy Value':
        query += `&fromPolicyValue=${value1}&toPolicyValue=${value2}`;
        break;

      case 'Sum Insured':
        query += `&fromSumInsured=${value1}&toSumInsured=${value2}`;
        break;


      case 'Annual Premium':
        query += `&fromAnnualPremium=${value1}&toAnnualPremium=${value2}`;
        break;

      case 'Line of Business':
        query += `&planCategory=${value1}`;
        break;

      default:
        break;
    }
  })

  return query;
};

const Producer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(new Map());

  const filters = useFilters().map(f => ({ ...f, values: f.displayValues }));

  const userData = useSelector(state => state.user.data);

  const { data: producerData, loading } = useSelector(state => state.producer);

  const { userType, userTypeLink } = useSelector(state => state.user.data);

  const [chips, setChips] = useState([]);
  const [selectedFilters, setSelectedFilter] = useState([]);
  const [showActiveOnly, setShowActiveOnly] = useState(true);

  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const filterQuery = generateFilterQuery(selectedFilters);

  const dataLoading = loading && !producerData.length;

  const getValues = (key, selectedValue) => {
    const filter = filters.find(f => f.key === key);
    const index = filter.values.indexOf(selectedValue);

    return {
      value: selectedValue,
      realValue: filter.realValues[index]
    };
  };

  const onApplyFilter = (filter) => {
    const currentFilter = Array.from(filter.entries())
      .filter(([key, value]) => value.selected !== null)
      .map(([key, value]) => ({ key, name: value.name, ...getValues(key, value.selected) }));

    setSelectedFilter(currentFilter);
  };

  const onClearChips = () => {
    setShowActiveOnly(false);
    setSelectedFilter([])
    setChips([]);
  };

  const onRemoveChip = (index) => {
    const chip = chips[index];
    const filterIndex = selectedFilters.findIndex(f => f.key === chip.key);

    setSelectedFilter(currentFilters => [
      ...currentFilters.slice(0, filterIndex),
      ...currentFilters.slice(filterIndex + 1),
    ]);

    setChips(currentChips => [
      ...currentChips.slice(0, index),
      ...currentChips.slice(index + 1),
    ]);
  };

  const onExpandRow = useCallback((level, typeLink) => {
    const userInfo = {
      userType: level,
      userTypeLink: typeLink,
      searchKey: searchTerm,
      allPolicies: !showActiveOnly,
    };

    return getProducerSubLevels(userInfo, filterQuery);
  }, [searchTerm, showActiveOnly, filterQuery]);

  useEffect(() => {
    dispatch(getProducerLevels(userType));
  }, []);

  useEffect(() => {
    if (userData.userTypeLink === null) {
      history.replace({ pathname: INDEX });
    }
  }, [userData]);

  useEffect(() => {
    setChips(selectedFilters.map(f =>
      ({ key: f.key, text: `${f.name}: ${f.value}` })
    ));

    const userInfo = {
      userType,
      userTypeLink,
      searchKey: searchTerm,
      allPolicies: !showActiveOnly,
    };

    if (userInfo.userType) {
      dispatch(getProducer(userInfo, filterQuery));
      setOpen(new Map());
    }
  }, [selectedFilters, userType, debouncedSearchTerm]);

  return (
    <ProducerScreen
      chips={chips}
      loading={dataLoading}
      onApply={onApplyFilter}
      onSearch={setSearchTerm}
      searchTerm={debouncedSearchTerm}
      onRemoveChip={onRemoveChip}
      onClearChips={onClearChips}
      checkboxValue={showActiveOnly}
      onChangeCheckboxValue={setShowActiveOnly}
      selectedFilters={selectedFilters}
      onExpandRow={onExpandRow}
      filterQuery={filterQuery}
      data={producerData}
      filters={filters}
      setOpen={setOpen}
      open={open}
    />
  );
};

export default Producer;
