import {
  SUCCESS_GET_CONTRIBUTION_CHART,
  SUCCESS_GET_CONTRIBUTION_INFO,
  SET_CURRENT_POLICY_ID,
} from '../constants/types';

const defaultData = {
  requested: false,
  loading: false,
  data: [],
};

const initialState = {
  currentPolicyId: '',
  chart: { ...defaultData },
  info: { ...defaultData },
};

const ContributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_POLICY_ID:
      if (state.currentPolicyId === action.payload.currentPolicyId) {
        return { ...state };
      }

      return { ...initialState, currentPolicyId: action.payload.currentPolicyId };

    case SUCCESS_GET_CONTRIBUTION_CHART:
      return {
        ...state,
        chart: {
          loading: false,
          requested: true,
          data: action.payload.chart,
        }
      };

    case SUCCESS_GET_CONTRIBUTION_INFO:
      return {
        ...state,
        info: {
          loading: false,
          requested: true,
          data: {
            contributionsInfo: action.payload.info.contributionsInfo,
            // append data in case of load more paging
            contributions: !action.payload.append ? action.payload.info.contributions : [...state.info.data.contributions, ...action.payload.info.contributions],
          }
        }
      };

    default:
      return { ...state };
  }
};

export default ContributionReducer;
