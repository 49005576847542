import { isNull, isUndefined } from 'lodash';

export const hasProducerAccess = (typeLinkCode = '') => {
  const [userType, typeLink] = typeLinkCode.split('_');
  const userTypeLink = +typeLink || null;

  const isUserTypeLinkNUllOrUndef =
    isNull(userTypeLink) || isUndefined(userTypeLink);

  const isProducer =
    !isUserTypeLinkNUllOrUndef ||
    (isUserTypeLinkNUllOrUndef && ['A', 'E', 'P'].includes(userType));

  return isProducer;
};
