// @flow

import React from 'react';
import { Subtitle5 } from '../../shared/texts/title';
import { DescriptionBlockContainer } from '../container';
import { DescriptionText } from '../../shared/texts/content';

type Props = { title: string, value: string | number };

const StatsBlock = ({ title, value }: Props) => (
  <DescriptionBlockContainer>
    <Subtitle5>{title}</Subtitle5>
    <DescriptionText>{value}</DescriptionText>
  </DescriptionBlockContainer>
);

export default StatsBlock;
