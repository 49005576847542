// @flow

import React from 'react';
import PrivacyPolicy from '../components/privacy-policy';

type Props = {
  data: any[];
  image: any;
};

const PrivacyPolicyScreen = ({ data, image }: Props) => (
  <PrivacyPolicy image={image} data={data} />
);

export default PrivacyPolicyScreen;
