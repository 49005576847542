// @flow

import React from 'react';
import DocumentCard from '../document-card';
import Collapsible from '../shared/collapsible';
import { SectionTitle2 } from '../shared/texts/title';
import { serviceFormsListTitle, SectionContainer } from './styles';

type Props = {
  data: any[],
  handleDownloadDocumentClick: Function,
  handleViewDocumentClick: Function,
};

const ServiceFormsList = ({
  data = [],
  handleDownloadDocumentClick,
  handleViewDocumentClick,
}: Props) =>
  data.map(item => (
    <SectionContainer key={item.section}>
      <SectionTitle2 style={serviceFormsListTitle}>
        {item.section}
      </SectionTitle2>
      {item.data.map(doc => (
        <Collapsible title={doc.topic} key={doc.topic}>
          {doc.data.map(el => (
            <DocumentCard
              dateAdded={new Date(el.updatedDate).toISOString()}
              documentName={el.documentName}
              documentPath={el.documentPath}
              handleDownloadDocumentClick={handleDownloadDocumentClick}
              handleViewDocumentClick={handleViewDocumentClick}
              key={el.documentName}
            />
          ))}
        </Collapsible>
      ))}
    </SectionContainer>
  ));

export default ServiceFormsList;
