// @flow

import React from 'react';
import { Button, Icon, Text } from './styles';

type Props = {
  icon?: string,
  size: string,
  title: String,
  disabled?: boolean,
  onClick: Function,
  iconStyle?: any,
  inverted?: boolean,
  style: any,
  type?: 'square',
};

const ActionButton = ({
  disabled,
  style,
  iconStyle,
  size = '55',
  title,
  icon,
  onClick,
  inverted,
  type,
}: Props) => (
  <Button
    size={size}
    style={style}
    inverted={inverted}
    type={type}
    onClick={() => (!disabled ? onClick() : null)}
  >
    {icon && <Icon style={iconStyle} src={icon} />}

    <Text size={size} inverted={inverted}>
      {title}
    </Text>
  </Button>
);

ActionButton.defaultProps = {
  type: '',
  inverted: false,
  disabled: false,
  icon: '',
  iconStyle: {},
};

export default ActionButton;
