import styled from 'styled-components';
import { size } from '../../../config/device';
import { ColumnContainer, RowContainer } from '../container';

export const ContentContainer = styled(ColumnContainer)`
  background: ${props => props.theme.white};
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  padding: 50px;
  min-width: 200px;
  align-items: flex-start;
  max-height: 80vh;

  @media (max-width: ${size.tablet}) {
    padding: 40px;
    width: calc(90% - 80px);
  }
`;

export const DismissContainer = styled(RowContainer)`
  cursor: pointer;
  margin-top: -23px;
  margin-right: -23px;
  align-self: flex-end;
`;

export const DismissIcon = styled.img`
  @media (max-width: ${size.tablet}) {
    transform: scale(0.8);
  }
`;
