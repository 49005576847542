import styled from 'styled-components';

import colors from '../../../config/colors';
import { size } from '../../../config/device';
import { RowContainer, ColumnContainer } from '../../shared/container';

export const ConvertableBlock = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;

  @media (max-width: ${size.laptopM}) {
    flex-direction: column;
  }
`;

export const GroupBlock = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;

  p {
    text-align: left;
  }
`;

export const ContentBlock = styled(ColumnContainer)`
  width: 100%;
  align-items: flex-start;
  padding: 14px 3%;
  visibility: ${({ shouldHide }) => shouldHide && 'hidden'};

  > :nth-child(2) {
    margin-top: 8px;
  }

  ${({ hasBorder }) =>
    hasBorder &&
    `
    border: 0;
    border-right: 1px;
    border-style: solid;
    border-color: ${colors.white3};
    `}
`;
