// @flow

import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import strings from '../../config/strings';
import { formatShortDate } from '../../utils/date';
import { colorPalette } from '../../config/colors';
import AveragingSection from '../../components/strategy/averaging';

import type { ChartDescription, FundAveraging } from '../../models/funds';

type Props = {
  chartInfo: ChartDescription,
  funds: Array<FundAveraging>,
  policyCurrency: strings,
};

const Averaging = ({ chartInfo, funds, policyCurrency }: Props) => {
  const defaultFundsOptions = [
    { id: -1, name: strings.STRATEGY_AVERAGING_DROPDOWN_TITLE },
  ];

  const [selectedFundId, setSelectedFundId] = useState(-1);
  const [fundInfo, setFundInfo] = useState({});
  const [fundsOptions, setFundsOptions] = useState(defaultFundsOptions);
  const [chartPointsStyle, setChartPointsStyle] = useState([]);
  const [chartData, setChartData] = useState({
    chartDataStats: [],
    chartDataAvg: [],
    labels: [],
    tooltipLabels: [],
  });

  useEffect(() => {
    const options = fundsOptions;
    Object.keys(funds).forEach(fundId => {
      options.push({
        id: fundId,
        name: funds[fundId].info.fundDescription,
      });
    });

    setSelectedFundId(Object.keys(funds)[0] || -1);
    setFundsOptions(options);
  }, [funds]);

  useEffect(() => {
    const data = Object.assign({}, chartData);

    const stats =
      !isEmpty(funds) &&
      selectedFundId !== -1 &&
      funds[selectedFundId].stats.map(elt => ({
        fundPrice: elt.fundPrice,
        allocatedUnits: elt.allocatedUnits,
        fundValue: elt.fundValue,
      }));

    const average =
      !isEmpty(stats) &&
      new Array(stats.length).fill(
        funds[selectedFundId].info.purchaseAveragePrice,
      );
    const labels =
      !isEmpty(funds) &&
      selectedFundId !== -1 &&
      funds[selectedFundId].stats.map(elt =>
        formatShortDate(elt.effectiveDate),
      );

    const tooltipLabels =
      !isEmpty(funds) &&
      selectedFundId !== -1 &&
      funds[selectedFundId].stats.map(elt => elt.event);

    data.average = average;
    data.stats = stats;
    data.labels = labels;
    data.tooltipLabels = tooltipLabels;

    setChartData(data);

    const selectedFundInfo =
      !isEmpty(funds) && selectedFundId !== -1 && funds[selectedFundId].info;

    setFundInfo(selectedFundInfo);
  }, [funds, selectedFundId]);

  const handleFundsDropdownClick = (fundId: string | number) => {
    setSelectedFundId(fundId);
  };

  useEffect(() => {
    if (!isEmpty(funds) && selectedFundId !== -1) {
      const existingColors = [...colorPalette];
      const existingShapes = [
        'triangle',
        'star',
        'cross',
        'circle',
        'crossRot',
        'dash',
        'line',
        'rect',
        'rectRounded',
        'rectRot',
      ];
      const events = funds[selectedFundId].stats.map(fund => fund.event);

      const reduced = events.reduce((accumulator, event) => {
        const existingEvent = accumulator.find(elt => elt.event === event);
        if (accumulator.length === 0) {
          accumulator.push({
            event,
            style: { color: existingColors[0], shape: existingShapes[0] },
          });
          existingColors.shift();
          existingShapes.shift();
        } else if (existingEvent !== undefined) {
          accumulator.push({ event, style: existingEvent.style });
        } else {
          accumulator.push({
            event,
            style: { color: existingColors[0], shape: existingShapes[0] },
          });
          existingColors.shift();
          existingShapes.shift();
        }
        return accumulator;
      }, []);

      const points = reduced.map(elt => elt.style);

      setChartPointsStyle(points);
    }
  }, [JSON.stringify(funds), selectedFundId]);

  return (
    <AveragingSection
      {...{
        chartInfo,
        funds,
        policyCurrency,
        fundsOptions,
        fundInfo,
        selectedFundId,
        handleFundsDropdownClick,
        chartData,
        chartPointsStyle,
      }}
    />
  );
};

export default Averaging;
