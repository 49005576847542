import styled from 'styled-components';

export const dropdownStyle = {
  width: '100%',
  marginRight: 25,
  boxSizing: 'border-box',
};

const Row = styled.div`
  display: flex;
  border-radius: 3px;
  justify-content: space-between;

  & > div {
    padding: 0 5px;
    width: calc(100% / 6);
    box-sizing: border-box;

    font-family: Avenir-Roman;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.05px;
    color: ${props => props.theme.primaryDark};

    &:first-child {
      font-family: Avenir-Medium;
      font-weight: 900;
    }

    &:last-child {
      font-weight: 900;
      text-align: right;
      color: ${props => props.theme.primary};
    }
  }
`;

export const IconContainer = styled.a`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  left: 10px;
  top: 10px;
`;

export const Container = styled.div`
  overflow: auto;

  @media (max-width: 768px) {
    margin-right: calc(-1 * (100% - 85.76%) / 2);
  }

  & > div {
    min-width: 652px;
    overflow: hidden;
    box-sizing: border-box;
  }
`;

export const LevelsHeaderContainer = styled(Row)`
  padding: 10px 25px;
  padding-right: 45px;

  & > div:first-child {
    margin-right: 20px;
    line-height: 40px;
  }
`;

export const LevelContainer = styled.div`
  padding: 0 25px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  margin-bottom: 10px;
  background: ${props => props.theme.white};
`;

export const LevelHead = styled.div`
  padding: 25px 0;

  & > div {
    padding-right: 25px;
  }
`;

export const HeadContainer = styled(Row)`
  padding-left: 0;

  & > div:first-child {
    margin-right: 25px;
  }

  & > div {
    line-height: 50px;
  }
`;

export const LevelBody = styled.div`
  padding: 30px 0;
  border-top: 1px solid ${props => props.theme.paleGreyDark};
`;

export const RowContainer = styled(Row)`
  border: 1px solid ${props => props.theme.paleGreyDark};
  margin-bottom: 10px;
  padding: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  a:-webkit-any-link {
    color: ${props => props.theme.primary};
  }
`;

export const Anchor = styled.a`
  cursor: pointer;
`;

export const DownloadIcon = styled.img`
  top: 2px;
  margin-left: 10px;
  object-fit: contain;
  position: relative;
  margin-right: 5px;
`;

export const ViewPolicies = styled.img`
  filter: opacity(0.5) drop-shadow(0 0 0 blue);
  position: relative;
  margin-left: 5px;
  top: 1px;
`;

export const Header = styled.div`
  white-space: pre-line;
`;
