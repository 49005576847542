// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import strings from '../config/strings';
import { ForgetPasswordScreen } from '../pages';
import { requestPasswordReset } from '../redux/actions';
import { RESET_PASSWORD_SUCCESS } from '../router/routes';
import { getFooter, getTheme } from '../redux/actions/theme.action';

type Props = { history: { push: Function } };

const ForgetPassword = ({ history }: Props) => {
  const dispatch = useDispatch();
  const themeColors = useSelector(state => state.theme.colors);
  const { logo = {} } = useSelector(state => state.media.data);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(strings.VALIDATION_ERROR_MESSAGE_INVALID_EMAIL)
      .required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
  });

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getTheme());
  }, []);

  const handleFormSubmit = async data => {
    try {
      const { email } = data;
      const result = await dispatch(requestPasswordReset(email));

      if (!result.success) {
        setError('email', {
          type: 'manual',
          message: strings.SERVER_ERROR_NOT_FOUND_EMAIL,
        });
      } else {
        history.push({
          pathname: RESET_PASSWORD_SUCCESS,
          state: { media: { logoUrl: logo.url }, isReset: true },
        });
      }
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: strings.SERVER_ERROR,
      });
    }
  };

  return (
    <ForgetPasswordScreen
      logoUrl={logo.url}
      handleFormSubmit={handleFormSubmit}
      themeColors={themeColors}
      formUtils={{ register, handleSubmit, errors }}
    />
  );
};

export default ForgetPassword;
