import styled from 'styled-components';
import colors from '../../config/colors';
import { ColumnContainer } from '../shared/container';

export const Outline = styled(ColumnContainer)`
  width: 38px;
  height: 38px;
`;

export const Icon = styled.span`
  color: ${colors.white};
  transform: scale(1.5);
`;
