// @flow

import React from 'react';

import { HeadContainer } from './styles';

import {
  decimalWithCommas,
  roundDecimalToInteger,
} from '../../../utils/numbers';

import ExportButton from '../export-button';
import { ProducerStats } from '../../../models/producer';

type Props = {
  id: number,
  filter: string,
  stats: ProducerStats,

  onExport: Function,
  renderIcon: Function,
};

const areEqual = (prevProps, nextProps) =>
  prevProps.id === nextProps.id &&
  prevProps.searchTerm === nextProps.searchTerm &&
  prevProps.renderIcon === nextProps.renderIcon &&
  JSON.stringify(prevProps.stats) === JSON.stringify(nextProps.stats) &&
  JSON.stringify(prevProps.filter) === JSON.stringify(nextProps.filter);

const ProducerTableHead = ({
  id,
  stats,
  filter,
  onExport,
  renderIcon,
}: Props) => {
  const disabled = !stats.totalPoliciesNbr;

  return (
    <HeadContainer key={id}>
      <div>{`${stats.userDetails}`}</div>
      <div>{decimalWithCommas(stats.totalPoliciesNbr)}</div>
      <div>$ {decimalWithCommas(stats.totalGrossPremium)}</div>
      <div>{decimalWithCommas(stats.totalNewPolicies)}</div>
      <div>$ {roundDecimalToInteger(stats.totalAnnualPremium)}</div>
      <div style={{ opacity: disabled ? 0.5 : 1 }}>
        <ExportButton
          lowerPosition
          disabled={disabled}
          onExport={() =>
            !disabled &&
            onExport(
              {
                userType: stats.userTypeCode,
                userTypeLink: stats.levelId,
              },
              filter,
            )
          }
        />
        {renderIcon(stats.userTypeCode, stats.levelId, disabled)}
      </div>
    </HeadContainer>
  );
};

export default React.memo(ProducerTableHead, areEqual);
