// @flow

import React from 'react';
import {
  BeneficiaryContainer,
  InformationContainer,
  HorizontalDivider,
  DisclaimerContainer,
  CardContainer,
  CardValue,
} from './styles';
import strings from '../../../config/strings';
import { InformationTitle } from '../../shared/texts/title';
import { DisclaimerContent } from '../../shared/texts/content';

const BeneficiaryCard = ({ beneficiary }: Props) => {
  const { name, relationshipDesc, sharePerc, clause } = beneficiary;
  const share = `${sharePerc}%`;

  return (
    <CardContainer>
      <BeneficiaryContainer>
        <InformationContainer>
          <InformationTitle>{strings.FULL_NAME}</InformationTitle>
          <CardValue>{name}</CardValue>
        </InformationContainer>
        <HorizontalDivider />
        <InformationContainer>
          <InformationTitle>{strings.RELATIONSHIP}</InformationTitle>
          <CardValue>{relationshipDesc}</CardValue>
        </InformationContainer>
        <HorizontalDivider />
        <InformationContainer>
          <InformationTitle>{strings.SHARE}</InformationTitle>
          <CardValue>{share}</CardValue>
        </InformationContainer>
      </BeneficiaryContainer>
      {relationshipDesc === 'Bank' && (
        <DisclaimerContainer>
          <DisclaimerContent>{clause}</DisclaimerContent>
        </DisclaimerContainer>
      )}
    </CardContainer>
  );
};

export default BeneficiaryCard;
