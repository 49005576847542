// @flow

import React from 'react';

import Table from '../../shared/table';
import { Container } from '../container';
import strings from '../../../config/strings';
import DocumentCard from '../../document-card';
import { SectionTitle2 } from '../../shared/texts/title';

type Props = {
  data: any[],
};

const StrategyBenchmarking = ({ data }: Props) => (
  <>
    <Container>
      <SectionTitle2>Funds Benchmarking</SectionTitle2>
      <Table
        contentData={data.funds}
        headerData={strings.STRATEGY_BENCHMARKING_FUND_TABLE_HEADER}
        nomargin
      />

      {data.funds && !data.funds.length && (
        <DocumentCard empty noIcon centerClause emptyClause={strings.NO_DATA} />
      )}
    </Container>

    <Container>
      <SectionTitle2>Indices funds Benchmarking</SectionTitle2>
      <Table
        contentData={data.indicesFunds}
        headerData={strings.STRATEGY_BENCHMARKING_INDICES_TABLE_HEADER}
        nomargin
      />

      {data.indicesFunds && !data.indicesFunds.length && (
        <DocumentCard empty noIcon centerClause emptyClause={strings.NO_DATA} />
      )}
    </Container>
  </>
);

export default StrategyBenchmarking;
