// @flow

import React, { useState } from 'react';
import ConversationInput from './conversation-input';

import { SubjectP } from './chat-styles';
import {
  PopupContent,
  Subject,
  EmptySpace,
  HintSpace,
  SelectSpace,
} from './popup-styles';
import ToolPin from '../shared/toolpin/hint-toolpin';
import ConversationHeader from './conversation-header';
import SimpleDropdown from '../shared/dropdown/simple';
import strings from '../../config/strings';

type Props = {
  onClose: Function,
  onSendMessage: Function,
  sendingMessage: Boolean,
  currentconversation: any,
  userPolicies: any[],
};

const ConversationPopup = ({
  userPolicies = [],
  currentconversation,
  sendingMessage,
  onSendMessage,
  onClose,
}: Props) => {
  const [policy, setPolicy] = useState(strings.INBOX_DROPDOWN_NO_POLICY);
  const [subject, setSubject] = useState(strings.INBOX_DROPDOWN_NO_SUBJECT);

  const policiesOptions = [
    strings.INBOX_DROPDOWN_NO_POLICY,
    ...userPolicies.map(p => p.policyNumber),
  ];

  const onSendMessageWithSubject = message => {
    onSendMessage(message, subject, policy);

    setPolicy(strings.INBOX_DROPDOWN_NO_POLICY);
    setSubject(strings.INBOX_DROPDOWN_NO_SUBJECT);
  };

  return (
    <PopupContent>
      <ConversationHeader
        onClose={onClose}
        externalSubject={subject}
        currentconversation={currentconversation}
      />
      <Subject>
        <SubjectP>{strings.SUBJECTS_INBOX_SELECT}</SubjectP>
        <SimpleDropdown
          right={0}
          title={subject}
          onSelect={setSubject}
          options={strings.SUBJECTS_INBOX}
          width="auto"
          noMaxWidth
        />
      </Subject>
      <Subject>
        <SubjectP>{strings.POLICIES_INBOX_SELECT}</SubjectP>

        <SelectSpace>
          <HintSpace>
            <ToolPin hint={strings.INBOX_HINT} />
          </HintSpace>
          <SimpleDropdown
            right={0}
            title={policy}
            onSelect={setPolicy}
            options={policiesOptions}
            width="auto"
            noMaxWidth
          />
        </SelectSpace>
      </Subject>
      <EmptySpace />
      <ConversationInput
        loading={sendingMessage}
        onSendMessage={onSendMessageWithSubject}
        containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
      />
    </PopupContent>
  );
};

export default ConversationPopup;
