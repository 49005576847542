import React from 'react';
import { useSelector } from 'react-redux';
import NotificationHolder from '../components/notifications/notification-holder';

const Notifications = () => {
  const { data: notifications } = useSelector(state => state.notifications);

  return <NotificationHolder data={notifications} />;
};

export default Notifications;
