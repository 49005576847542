import {
  REQUEST_GET_LIBRARY,
  SUCCESS_GET_LIBRARY,
  FAILURE_GET_LIBRARY,
} from '../types/library';

const initialState = {
  errorMessage: '',
  loading: false,
  data: [],
};

const LibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_LIBRARY:
      return { ...state, loading: true, data: [] };

    case SUCCESS_GET_LIBRARY:
      return { ...state, loading: false, data: action.payload.library };

    case FAILURE_GET_LIBRARY:
      return { ...state, loading: false, errorMessage: action.payload.err };

    default:
      return { ...state };
  }
};

export default LibraryReducer;
