// @flow

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import strings from '../../config/strings';

import { PRIVACY_POLICY } from '../../router/routes';
import { Container, Title, Text, Button } from './styles';

type Props = {
  allowCookies: Function;
  cookiesAllowed: boolean;
};

const CookieDisclaimer = ({ cookiesAllowed, allowCookies }: Props) => {
  if (cookiesAllowed) {
    return null;
  }

  return (
    <Container>
      <Title>
        {strings.COOKIE_DISCLAIMER_TITLE}
      </Title>
      <Text>
        {strings.COOKIE_DISCLAIMER}
        <Link smooth to={`${PRIVACY_POLICY}#disclaimer`}>
          {strings.DATA_PRIVACY}
        </Link>
      </Text>
      <Button onClick={allowCookies}>
        {strings.ALLOW_COOKIES}
      </Button>
    </Container>
  );
};

export default CookieDisclaimer;
