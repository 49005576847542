// @flow

import {
  SET_CURRENT_POLICY_ID,
  REQUEST_CUSTOMER_POLICIES_LIST,
  SUCCESS_CUSTOMER_POLICIES_LIST,
  REQUEST_POLICY_BENEFITS,
  SUCCESS_POLICY_BENEFITS,
  REQUEST_POLICY_PAYMENT_DETAILS,
  SUCCESS_POLICY_PAYMENT_DETAILS,
  REQUEST_ENCASHMENT,
  SUCCESS_ENCASHMENT,
  REQUEST_PREMIUM_ALLOCATION,
  SUCCESS_PREMIUM_ALLOCATION,
  REQUEST_BENEFICIARIES,
  SUCCESS_BENEFICIARIES,
  SUCCESS_POLICIES_LIST,
  REQUEST_POLICIES_LIST,
  FAILURE_POLICIES_LIST,
} from '../constants/types';

// api endpoints
import getPolicyBenefitsAPI from '../../api/benefits';
import getPolicyPaymentDetailsAPI from '../../api/payments';
import getPremiumAllocationAPI from '../../api/premium-allocation';
import getBeneficiariesAPI from '../../api/beneficiaries';
import getEncashmentAPI from '../../api/encashment';
import { getUserPolicies } from '../../api';

import type { Policy } from '../../models/policies';
import globals from '../../config/globals';
import showToast from '../../utils/toast';

const setCurrentPolicyId = currentPolicyId => ({
  type: SET_CURRENT_POLICY_ID,
  payload: { currentPolicyId },
});

// #region Policies List
const requestPoliciesList = () => ({ type: REQUEST_POLICIES_LIST });
const failurePoliciesList = () => ({ type: FAILURE_POLICIES_LIST });

export const successPoliciesList = policies => ({
  type: SUCCESS_POLICIES_LIST,
  payload: { policies },
});

const requestCustomerPoliciesList = currentCustomerId => ({
  type: REQUEST_CUSTOMER_POLICIES_LIST,
  payload: { currentCustomerId },
});

export const successCustomerPoliciesList = policies => ({
  type: SUCCESS_CUSTOMER_POLICIES_LIST,
  payload: { policies },
});

const sortPolicies = (policies: Array<Policy>): Array<Policy> => {
  const sortedByTypeByDate = policies
    .sort(
      (polA: Policy, polB: Policy) =>
        globals.POLICY_TYPES[polA.policyType].priority -
        globals.POLICY_TYPES[polB.policyType].priority,
    )
    .sort((polA: Policy, polB: Policy) => {
      if (polA.policyType === polB.policyType) {
        return polA.startDate - polB.startDate;
      }

      return 0;
    });

  return sortedByTypeByDate;
};

export const getPoliciesList = (username: string) => async dispatch => {
  dispatch(requestPoliciesList());

  try {
    const { data } = await getUserPolicies(null, username);

    return dispatch(successPoliciesList(sortPolicies(data)));
  } catch (error) {
    dispatch(failurePoliciesList());
    showToast();
  }
};

export const getCustomerPoliciesList = (
  userId: string,
  username: string,
) => async dispatch => {
  dispatch(requestCustomerPoliciesList(userId));

  try {
    const { data } = await getUserPolicies(userId, username);
    return dispatch(successCustomerPoliciesList(sortPolicies(data)));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion

// #region Encashment
const requestEncashment = () => ({ type: REQUEST_ENCASHMENT });

const successEncashment = encashments => ({
  type: SUCCESS_ENCASHMENT,
  payload: { encashments },
});

export const getEncashment = (policyId: string) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestEncashment());

  try {
    const { data: encashments } = await getEncashmentAPI(policyId);
    dispatch(successEncashment(encashments));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion

// #region Beneficiaries
const requestBeneficiaries = () => ({ type: REQUEST_BENEFICIARIES });

const successBeneficiaries = beneficiaries => ({
  type: SUCCESS_BENEFICIARIES,
  payload: { beneficiaries },
});

export const getBeneficiaries = (policyId: string) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestBeneficiaries());

  try {
    const { data: beneficiaries } = await getBeneficiariesAPI(policyId);
    dispatch(successBeneficiaries(beneficiaries));
  } catch (error) {
    // TODO: handle error
  }
};

// #endregion

// #region Policy Benefits
const requestPolicyBenefits = () => ({ type: REQUEST_POLICY_BENEFITS });

const successPolicyBenefits = benefits => ({
  type: SUCCESS_POLICY_BENEFITS,
  payload: { benefits },
});

export const getPolicyBenefits = (policyId: string) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestPolicyBenefits());

  try {
    const { data: benefits } = await getPolicyBenefitsAPI(policyId);

    dispatch(successPolicyBenefits(benefits));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion

// #region Policy Payments
const requestPolicyPaymentDetails = () => ({
  type: REQUEST_POLICY_PAYMENT_DETAILS,
});

const successPolicyPaymentDetails = paymentDetails => ({
  type: SUCCESS_POLICY_PAYMENT_DETAILS,
  payload: { paymentDetails },
});

export const getPolicyPaymentDetails = (policyId: string) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestPolicyPaymentDetails());

  try {
    const { data: paymentDetails } = await getPolicyPaymentDetailsAPI(policyId);

    dispatch(successPolicyPaymentDetails(paymentDetails));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion

// #region Premium Allocation
const generateLabelKey = (key: string) =>
  key.replace(/^./g, `label${key.charAt(0).toUpperCase()}`);

const requestPremiumAllocation = () => ({ type: REQUEST_PREMIUM_ALLOCATION });

const successPremiumAllocation = premiumAllocation => ({
  type: SUCCESS_PREMIUM_ALLOCATION,
  payload: { premiumAllocation },
});

export const getPremiumAllocation = (
  policyId: string,
  dateChange: moment,
  currencyId: number,
  skipLoading: boolean,
) => async dispatch => {
  if (!skipLoading) {
    dispatch(setCurrentPolicyId(policyId));
    dispatch(requestPremiumAllocation());
  }

  try {
    const { data } = await getPremiumAllocationAPI(
      policyId,
      dateChange,
      currencyId,
    );

    const refinedData = Object.assign(data, {
      labelEffectiveDate: 'Effective date',
    });

    const premiumAllocation = Object.keys(refinedData)
      .filter(key => !key.startsWith('label') && key !== 'lastDate')
      .map(key => ({
        key: data[generateLabelKey(key)],
        value: data[key] || 0,
      }));

    const groupedData = {
      info: { lastDate: refinedData.lastDate },
      allocations: premiumAllocation.filter(el => el.value !== 0),
    };

    dispatch(successPremiumAllocation(groupedData));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion
