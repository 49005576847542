// @flow

import React, { Fragment } from 'react';

import FundsAveragingChart from './chart';
import strings from '../../../config/strings';
import { SectionTitle2 } from '../../shared/texts/title';
import { strategyListTitle, SectionBlock } from '../container';
import ChartDescription from '../risk-return/chart-description';

import type {
  ChartDescription as ChartDescriptionType,
  FundAveraging,
  FundInfo,
} from '../../../models/funds';

type Props = {
  chartInfo: ChartDescriptionType,
  fundInfo: FundInfo,
  funds: Array<FundAveraging>,
  policyCurrency: string,
  fundsOptions: Array<DropDownOption>,
  selectedFundId: number | string,
  handleFundsDropdownClick: Function,
  chartData: {
    stats: Array<number | string>,
    average: Array<number>,
    labels: Array<string>,
  },
};

const Averaging = ({
  chartInfo,
  fundInfo,
  policyCurrency,
  fundsOptions,
  selectedFundId,
  handleFundsDropdownClick,
  chartData,
  chartPointsStyle,
}: Props) => (
  <Fragment>
    <SectionTitle2 style={strategyListTitle}>
      {strings.SECTION_TITLE_STRATEGY_AVERAGING_CHART}
    </SectionTitle2>

    <SectionBlock>
      <FundsAveragingChart
        {...{
          fundsOptions,
          selectedFundId,
          handleFundsDropdownClick,
          chartData,
          fundInfo,
          policyCurrency,
          chartPointsStyle,
        }}
      />
      <ChartDescription chartInfo={chartInfo} />
    </SectionBlock>
  </Fragment>
);

export default Averaging;
