const scrollToBottom = (divRef, delay = 200) => {
  if (!divRef || !divRef.current) {
    return;
  }

  setTimeout(() => {
    const box = divRef.current;
    box.scrollTop = box.scrollHeight;
  }, delay);
};

export default scrollToBottom;
