import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasProducerAccess } from '../../utils/user';

import { Spacer, FooterLeft, FooterRight, FooterContainer } from './container';

const filterOutInbox = links =>
  links.filter(l => l.link.toLowerCase().replace(/[^a-z]/g, '') !== 'inbox');

const MainFooter = () => {
  const { account = {} } = useSelector(state => state.user);
  const footerData = useSelector(state => state.theme.footer);

  const authed = !!(account && account.data && account.data.jwt);

  const isProducer =
    account.data &&
    account.data.user &&
    hasProducerAccess(account.data.user.typeLinkCode);

  const footerDataLinks = !isProducer
    ? footerData.links || []
    : filterOutInbox(footerData.links || []);

  const renderLink = linkData => {
    if (
      linkData.link.startsWith('http://') ||
      linkData.link.startsWith('https://')
    ) {
      return (
        <a
          href={linkData.link}
          key={linkData.text}
          target="_blank"
          rel="noopener noreferrer"
        >
          <li>{linkData.text}</li>
        </a>
      );
    }

    return (
      <Link to={linkData.link} key={linkData.text}>
        <li>{linkData.text}</li>
      </Link>
    );
  };

  return (
    <>
      {authed && <Spacer />}
      <FooterContainer id="footer">
        <FooterLeft grey={!authed}>
          <span>&copy; {new Date().getFullYear()}</span>
          <span>{footerData.left_text}</span>
          <span>{footerData.right_text}</span>
        </FooterLeft>
        <FooterRight>{authed && footerDataLinks.map(renderLink)}</FooterRight>
      </FooterContainer>
    </>
  );
};

export default MainFooter;
