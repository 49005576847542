// @flow

import React from 'react';
import strings from '../../config/strings';

import { Container, Content, ButtonContainer, Button } from './styles';

type Props = {
  data: String;
  onAccept: Function;
};

const SignTermsAndConditions = ({ data = '', onAccept }: Props) => (
  <Container>
    <Content>{data}</Content>
    <ButtonContainer>
      <Button onClick={onAccept}>
        {strings.ACCEPT_TERMS_CONDITIONS_BUTTON}
      </Button>
    </ButtonContainer>
  </Container>
);

export default SignTermsAndConditions;
