export const REQUEST_GET_INBOX = 'REQUEST_GET_INBOX';
export const SUCCESS_GET_INBOX = 'SUCCESS_GET_INBOX';
export const FAILURE_GET_INBOX = 'FAILURE_GET_INBOX';

export const REQUEST_GET_INBOX_MESSAGES = 'REQUEST_GET_INBOX_MESSAGES';
export const SUCCESS_GET_INBOX_MESSAGES = 'SUCCESS_GET_INBOX_MESSAGES';

export const REQUEST_SEND_INBOX_MESSAGE = 'REQUEST_SEND_INBOX_MESSAGE';
export const SUCCESS_SEND_INBOX_MESSAGE = 'SUCCESS_SEND_INBOX_MESSAGE';
export const FAILURE_SEND_INBOX_MESSAGE = 'FAILURE_SEND_INBOX_MESSAGE';

export const SUCCESS_CLEAR_INBOX = 'SUCCESS_CLEAR_INBOX';
export const SUCCESS_READ_INBOX = 'SUCCESS_READ_INBOX';
