// @flow

import React from 'react';

import DailyFundsChart from './chart';
import strings from '../../../config/strings';

import { ButtonsContainer } from './styles';

import Dropdown from '../../shared/dropdown';
import ActionButton from '../../shared/action-button';
import { SectionTitle2 } from '../../shared/texts/title';
import { strategyListTitle, SectionBlock } from '../container';
import ChartDescription from '../risk-return/chart-description';

type Props = {
  chartInfo: ChartDescription,
  funds: Array<FundAveraging>,
  policyCurrency: string,
  selectedFundId: number,
  pricesPeriod: number,
  onPeriodChange: Function,
  onFundIdChange: Function,
  loading: boolean,
};

const actionBtnProps = {
  style: { width: 75 },
  size: '35',
};

const DailyFunds = ({
  chartInfo,
  funds,
  fundId = -1,
  pricesPeriod,
  policyCurrency,
  onPeriodChange,
  onFundIdChange,
  fundsOptions = [],
  loading,
}: Props) => {
  const renderChartControls = () => (
    <SectionBlock style={{ margin: 0, padding: 0 }}>
      {fundsOptions.length > 2 && (
        <Dropdown
          options={fundsOptions}
          title={fundsOptions.filter(opt => opt.id === fundId)[0].name}
          selected={fundsOptions.filter(opt => opt.id === fundId)[0]}
          onSelect={id => id !== -1 && onFundIdChange(id)}
          size="large"
        />
      )}

      <ButtonsContainer>
        {['1W', '1M', '3M', '1Y', '2Y'].map((el, i) => (
          <ActionButton
            key={el}
            title={el}
            onClick={() => onPeriodChange(i + 1)}
            inverted={pricesPeriod !== i + 1}
            {...actionBtnProps}
          />
        ))}
      </ButtonsContainer>
    </SectionBlock>
  );

  return (
    <>
      <SectionTitle2 style={strategyListTitle}>
        {strings.SECTION_TITLE_STRATEGY_DAILY_FUNDS_CHART}
      </SectionTitle2>

      <SectionBlock>
        <DailyFundsChart
          funds={funds}
          loading={loading}
          chartInfo={chartInfo}
          policyCurrency={policyCurrency}
          renderChartControls={renderChartControls}
        />
        <ChartDescription chartInfo={chartInfo} />
      </SectionBlock>
    </>
  );
};

export default DailyFunds;
