// @flow

import {
  REQUEST_GET_USER,
  SUCCESS_GET_USER,
  FAILURE_GET_USER,
  USER_LOGOUT,
} from '../constants/types';

import getUserInfoAPI from '../../api/user';

export const logoutUser = () => ({ type: USER_LOGOUT });

const requestGetUser = () => ({ type: REQUEST_GET_USER });

const successGetUser = info => ({
  type: SUCCESS_GET_USER,
  payload: { info },
});

const failureGetUser = err => ({
  type: FAILURE_GET_USER,
  payload: { err },
});

export const getUserInfo = (username: string) => async dispatch => {
  dispatch(requestGetUser());

  try {
    const { data } = await getUserInfoAPI(username);

    dispatch(successGetUser(data));

    return data;
  } catch (error) {
    dispatch(failureGetUser(error));
  }
};
