// @flow

import React from 'react';

import { BadgeContainer } from './styles';
import { GenericDocument, pdfShape } from '../../assets/images';

type Props = { docType: string, isHiddenSm: boolean };

const DocumentBadge = ({ docType, isHiddenSm }: Props) => (
  <BadgeContainer isHiddenSm={isHiddenSm}>
    <img
      src={docType.toUpperCase() === 'PDF' ? pdfShape : GenericDocument}
      alt="document"
    />
  </BadgeContainer>
);

export default DocumentBadge;
