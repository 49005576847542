import styled from 'styled-components';

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.paleGrey3};
  border-radius: 3px;
  border: dashed 1px ${props => props.theme.warmGrey};
  width: auto;
  height: 598px;
`;

export const ImageDiv = styled.div`
  width: 66px;
  height: 43px;
  margin-bottom: 30px;
`;
