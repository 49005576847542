import styled from 'styled-components';
import { size } from '../../../config/device';
import { ConvertableRowToColContainer } from '../container';

const TabsRowWrapper = styled(ConvertableRowToColContainer)`
  justify-content: space-between;
  position: sticky;
  top: 193px;
  background-color: ${props => props.theme.white};
  z-index: 100;

  @media (max-width: ${size.laptopM}) {
    top: 276px;
  }

  @media (max-width: ${size.tablet}) {
    align-items: flex-start;
    margin-bottom: 2rem;
    position: initial;
  }
`;

export default TabsRowWrapper;
