import styled from 'styled-components';
import { size } from '../../../config/device';

export const SectionContainer = styled.div`
  background-color: ${props => props.theme.paleGrey};
`;

export const DetailsContainer = styled.div`
  padding: 55px 50px;
  border-radius: 3px;
  background-color: ${props => props.theme.white};

  @media (max-width: ${size.tablet}) {
    padding: 20px;
  }
`;

export const ContentContainer = styled.div`
  padding-left: 23%;
  padding-right: 8%;
  display: flex;

  @media (max-width: ${size.tablet}) {
    padding: 0;
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const HeaderAction = styled.a`
  display: block;
  cursor: pointer;
`;

export const HeaderActionText = styled.span`
  font-family: Avenir-Medium;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.theme.primary};
  padding: 0 10px;
`;

export const HeaderActionGreyText = styled(HeaderActionText)`
  opacity: 0.9;
  font-family: Avenir-Roman;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: -0.04px;
  }
`;

export const HeaderActionImage = styled.img`
  width: 18px;

  @media (max-width: ${size.tablet}) {
    width: 15px;
  }
`;

export const HeaderActionIcon = styled.span`
  position: relative;
  top: 2px;
`;

export const HeaderActionGreyIcon = styled(HeaderActionIcon)`
  opacity: 0.9;
  color: ${props => props.theme.slateGrey};
`;

export const ArticleLargeImage = styled.img`
  width: 100%;
  height: 252px;
  object-fit: cover;

  @media (max-width: ${size.tablet}) {
    height: 143px;
  }
`;

export const ArticleTitle = styled.h3`
  font-family: Avenir-Medium;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: 0.26px;
  margin-bottom: 0;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 23px;
    letter-spacing: 0.22px;
  }
`;

export const ArticleTitleContainer = styled.div`
  padding-bottom: 40px;

  @media (max-width: ${size.tablet}) {
    padding-bottom: 32px;
  }
`;

export const ArticleDate = styled.p`
  opacity: 0.9;
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 20px 0 0 0;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: -0.03px;
    line-height: 16px;
    margin-top: 21px;
  }
`;

export const ArticleContent = styled.p`
  opacity: 0.9;
  font-family: Avenir-Roman;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
    letter-spacing: 0px;
  }
`;

export const ArticleText = styled.div`
  flex: 1;
`;

export const ArticleNotes = styled.div`
  width: 90px;
  margin-left: 90px;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;

export const Footer = styled.p`
  text-align: center;
  opacity: 0.9;
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.04px;
  color: ${props => props.theme.slateGrey};
  margin-top: 30px;

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: -0.03px;
  }
`;
