import styled from 'styled-components';

const Anchor = styled.a`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  text-decoration: none !important;

  font-family: Avenir-Heavy !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: ${props => props.theme.primary} !important;
`;

export { Anchor };
