import {
  REQUEST_GET_INBOX,
  SUCCESS_GET_INBOX,
  FAILURE_GET_INBOX,
  REQUEST_GET_INBOX_MESSAGES,
  SUCCESS_GET_INBOX_MESSAGES,
  REQUEST_SEND_INBOX_MESSAGE,
  SUCCESS_SEND_INBOX_MESSAGE,
  FAILURE_SEND_INBOX_MESSAGE,
  SUCCESS_CLEAR_INBOX,
  SUCCESS_READ_INBOX,
} from '../types/inbox';

const initialState = {
  // track selected conversation
  currentConversation: '',

  // thread messages list
  sendingMessage: false,
  messagesLoading: false,
  messagesList: [],

  // inbox conversations
  errorMessage: '',
  requested: false,
  loading: false,
  data: [],
};

const inboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_INBOX:
      return { ...state, loading: true };

    case SUCCESS_GET_INBOX:
      // eslint-disable-next-line no-case-declarations
      const inboxData = action.payload.inbox;

      if (action.payload.append) {
        inboxData.unshift(...state.data);
      }

      return { ...state, requested: true, loading: false, data: inboxData };

    case FAILURE_GET_INBOX:
      return { ...state, loading: false, errorMessage: action.payload.err };

    case REQUEST_GET_INBOX_MESSAGES:
      return {
        ...state,
        messagesList: [],
        messagesLoading: true,
        currentConversation: action.payload.conversationId,
      };

    case SUCCESS_GET_INBOX_MESSAGES:
      if (state.currentConversation !== action.payload.conversationId) {
        return { ...state };
      }

      return {
        ...state,
        messagesLoading: false,
        messagesList: action.payload.messages,
      };

    case REQUEST_SEND_INBOX_MESSAGE:
      return { ...state, sendingMessage: true };

    case SUCCESS_SEND_INBOX_MESSAGE:
      return { ...state, sendingMessage: false };

    case FAILURE_SEND_INBOX_MESSAGE:
      return { ...state, sendingMessage: false };

    case SUCCESS_CLEAR_INBOX:
      return { ...state, currentConversation: '', messagesList: [] };

    case SUCCESS_READ_INBOX:
      return {
        ...state,
        data: state.data.map(el => {
          if (el.id === action.payload.conversationId) {
            return { ...el, read: true };
          }

          return { ...el };
        }),
      }

    default:
      return { ...state };
  }
};

export default inboxReducer;
