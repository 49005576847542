import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getPrivacyPolicy } from '../redux/actions';

import PrivacyPolicyScreen from '../pages/privacy-policy';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.privacyPolicy);

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return <PrivacyPolicyScreen image={data.image} data={data.sections} />;
};

export default PrivacyPolicy;
