// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import strings from '../config/strings';
import { changePassword } from '../redux/actions';
import { RESET_PASSWORD_SUCCESS } from '../router/routes';
import { ResetPassword as ResetPasswordScreen } from '../pages';
import { getFooter, getTheme } from '../redux/actions/theme.action';

type Props = { history: { push: Function } };

const ChangePassword = ({ history }: Props) => {
  const dispatch = useDispatch();
  const themeColors = useSelector(state => state.theme.colors);
  const { logo = {} } = useSelector(state => state.media.data);
  const { data: userData } = useSelector(state => state.user.account);

  const userName = userData.user.username;

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
    password: yup.string().required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        strings.VALIDATION_ERROR_MESSAGE_EQUAL_FIELDS,
      )
      .required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
  });

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getTheme());
  }, []);

  const handleFormSubmit = async data => {
    try {
      const body = {
        username: userName,
        oldPassword: data.oldPassword,
        newPassword: data.passwordConfirmation,
      };
      const result = await dispatch(changePassword(body));

      if (!result.success) {
        if (result.message === 'NEW_PASSWORD_NOT_VALID') {
          return setError('passwordConfirmation', {
            type: 'manual',
            message: strings.SERVER_ERROR_INVALID_NEW_PASS,
          });
        }

        if (result.message === 'OLD_PASSWORD_NOT_VALID') {
          return setError('passwordConfirmation', {
            type: 'manual',
            message: strings.SERVER_ERROR_INVALID_OLD_PASS,
          });
        }

        throw new Error(strings.SERVER_ERROR);
      }

      history.push({
        pathname: RESET_PASSWORD_SUCCESS,
        state: { media: { logoUrl: logo.url } },
      });
    } catch (error) {
      setError('passwordConfirmation', {
        type: 'manual',
        message: strings.SERVER_ERROR,
      });
    }
  };

  return (
    <ResetPasswordScreen
      logoUrl={logo.url}
      handleFormSubmit={handleFormSubmit}
      themeColors={themeColors}
      formUtils={{ register, handleSubmit, errors }}
      changePassword
    />
  );
};

export default ChangePassword;
