import styled from 'styled-components';

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.paleGrey3};
  border-radius: 3px;
  border: dashed 1px ${props => props.theme.warmGrey};
  width: auto;
  height: 189px;
`;

export const ImageDiv = styled.div`
  align-self: center;
  width: 59px;
  height: 41px;
  margin-right: 20px;
`;

export const SubDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  width: auto;
`;

export const MessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
