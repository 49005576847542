import React, { useEffect, useState } from 'react';

import * as qs from 'query-string';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { InboxScreen } from '../pages';
import { usePolicies } from '../hooks';
import globals from '../config/globals';
import useDebounce from '../hooks/useDebounce';

import {
  getInbox,
  getInboxMessages,
  sendInboxMessage,
  clearCurrentInbox,
  readInboxConversation,
} from '../redux/actions/inbox';

const Inbox = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userPolicies = usePolicies();

  const {
    data,
    loading,
    requested,
    currentConversation,
    sendingMessage,
    messagesList,
  } = useSelector(state => state.inbox);

  const { data: user } = useSelector(state => state.user);
  const { email } = user;
  const { fullname } = user;

  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [composing, setComposing] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [queryConversation, setQueryConversation] = useState();

  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const onLoadMore = () => {
    dispatch(getInbox(null, page + 1));
    setPage(p => p + 1);
  };

  const onSendMessage = async (message, conversationId, subject, policy) => {
    const extra = `${policy},${email},${fullname}`;
    const msgObject = {
      id: Date.now(),
      updated_at: new Date().toISOString(),
      created_at: new Date().toISOString(),
      fromSupport: false,
      text: message,
    };

    if (conversationId) {
      setMessages(msgs => [...msgs, msgObject]);
    }

    await dispatch(sendInboxMessage(message, conversationId, subject, extra));

    if (composing) {
      dispatch(getInbox());
      setComposing(false);
    }
  };

  const onConversationClick = id => {
    if (currentConversation !== id) {
      dispatch(readInboxConversation(id));
      dispatch(getInboxMessages(id));
      setDetailsOpen(true);
    }
  };

  const toggleCompose = () => {
    setComposing(c => !c);
  };

  const toggleDetails = () => {
    if (detailsOpen) {
      dispatch(clearCurrentInbox());
    }

    setDetailsOpen(open => !open);
  };

  useEffect(() => {
    const { conversation } = qs.parse(location.search) || {};
    setQueryConversation(conversation);

    dispatch(getInbox());
  }, []);

  useEffect(() => {
    const shouldSearch = debouncedSearchTerm !== undefined &&
      (debouncedSearchTerm.length > 2 || debouncedSearchTerm.length === 0);

    if (shouldSearch) {
      setDetailsOpen(false);
      dispatch(clearCurrentInbox());
      dispatch(getInbox(debouncedSearchTerm));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setMessages(messagesList);
  }, [messagesList]);

  useEffect(() => {
    if (
      data &&
      queryConversation &&
      data.find(conv => +conv.id === +queryConversation)
    ) {
      onConversationClick(queryConversation);
      setQueryConversation(null);
    }
  }, [data]);

  const showLoadMore = page * globals.INBOX_PAGING <= data.length;

  return (
    <InboxScreen
      loading={loading && !data.length}
      messages={messages}
      conversations={data}
      onSearch={setSearchTerm}
      userPolicies={userPolicies}
      onSendMessage={onSendMessage}
      sendingMessage={sendingMessage}
      emptyConversations={requested && !data.length}
      onLoadMore={showLoadMore ? onLoadMore : null}
      currentConversation={currentConversation}
      onConversationClick={onConversationClick}
      toggleCompose={toggleCompose}
      toggleDetails={toggleDetails}
      detailsOpen={detailsOpen}
      composing={composing}
    />
  );
};

export default Inbox;
