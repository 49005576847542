// @flow

import React, { Fragment, useState } from 'react';
import { isNull } from 'lodash';

import type { Node } from 'react';

import TabsOutline from './outline';
import TabsRowWrapper from './styles';

type Props = {
  containerStyle: Object,
  content: { [user_id: number]: Node },
  sideContainer?: Node,
  sideContainerTabKey?: string,
  tabsArr: Array<TabObj>,
  tabsWrapperStyle: Object,
};

const Tabs = ({
  containerStyle,
  content,
  sideContainer,
  sideContainerTabKey,
  tabsArr,
  tabsWrapperStyle,
}: Props) => {
  const [activeTabKey, setActiveTabKey] = useState(tabsArr[0].key);

  const handleTabSwitch = key => {
    setActiveTabKey(key);
  };

  return (
    <Fragment>
      <TabsRowWrapper>
        <TabsOutline
          containerStyle={containerStyle}
          tabsArr={tabsArr}
          tabsWrapperStyle={tabsWrapperStyle}
          activeTabKey={activeTabKey}
          handleTabSwitch={handleTabSwitch}
        />
        {!isNull(sideContainer) &&
          sideContainerTabKey === activeTabKey &&
          sideContainer}
      </TabsRowWrapper>
      {content[activeTabKey]}
    </Fragment>
  );
};

Tabs.defaultProps = {
  sideContainer: null,
  sideContainerTabKey: '',
};

export default Tabs;
