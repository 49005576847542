// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getFundsAveraging = (
  policyId: string,
  fundId: number,
  period: number,
  currencyId: number,
) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/policies/${policyId}/funds/account-value`;
  const params = { period, fundId: fundId < 0 ? null : fundId, currencyId };
  const headers = {
    'Content-Type': 'application/json',
    Accept: REACT_APP_ACCEPT_HEADER_WB,
  };

  return axios.get(url, { headers, params });
};

export default getFundsAveraging;
