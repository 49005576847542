// @flow

import React from 'react';

import AreaChart from '../../charts/area';
import strings from '../../../config/strings';
import globals from '../../../config/globals';
import Spinner from '../../shared/spinner';

import { formatDate } from '../../../utils/date';
import { ChartContainer, SectionDescriptionBlock } from '../container';

type Props = {
  renderChartControls: () => JSX.Element;
  policyCurrency: strings,
  loading: Boolean,
  funds: any[],
};

const DailyFundsChart = ({ loading, funds, policyCurrency, renderChartControls }: Props) => (
  <SectionDescriptionBlock isChart>
    {renderChartControls()}
    <ChartContainer>
      {!!loading && <Spinner />}
      {!loading && (
        <AreaChart
          data={funds.map(f => f.price || 0)}
          labels={funds.map(f =>
            formatDate(
              f.effectiveDate ? new Date(f.effectiveDate).toISOString() : null,
            ),
          )}
          dataName="funds"
          symbol={globals.CURRENCIES[policyCurrency].value}
          errorMessage={strings.NO_DATA}
        />
      )}
    </ChartContainer>
  </SectionDescriptionBlock>
);

export default DailyFundsChart;
