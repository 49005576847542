// @flow

import React from 'react';
import Collapsible from '../shared/collapsible';

import {
  Content,
  Section,
  titleStyle,
} from './styles';

import { SectionTitle } from '../shared/texts/title';

type Props = {
  data: any[];
};

const FAQs = ({ data = [] }: Props) => (
  data.map(section => (
    <Section key={section.title}>
      <SectionTitle style={titleStyle}>
        {section.title}
      </SectionTitle>
      {
        section.faqs
          .filter(faq => faq.active)
          .map(faq => (
            <Collapsible contentHeight="initial" key={faq.id} title={faq.question}>
              {/* eslint-disable-next-line react/no-danger */}
              <Content dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </Collapsible>
          ))
      }
    </Section>
  ))
);

export default FAQs;
