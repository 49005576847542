// @flow

import axios, { CancelToken } from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;
let currentProducerPoliciesSource = null;

const getProducerPolicies = async (
  pageNbr,
  allPolicies,
  producerId,
  filtersQueryString,
) => {
  const { WB_API_CACHE = {} } = window;

  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');

  if (currentProducerPoliciesSource) {
    currentProducerPoliciesSource.cancel();
  }

  const source = CancelToken.source();
  currentProducerPoliciesSource = source;

  let query = `pageNbr=${pageNbr}&shouldRetrieveAllPolicies=${
    allPolicies ? 'Y' : 'N'
  }`;

  if (producerId) {
    query += `&producerId=${producerId}`;
  }

  if (filtersQueryString) {
    query += filtersQueryString;
  }

  const url = `${REACT_APP_WB_BACKEND_URL || ''}/producers/policies?${query}`;

  if (WB_API_CACHE[url]) {
    return { data: WB_API_CACHE[url] };
  }

  const response = await axios.get(url, {
    cancelToken: source.token,
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });

  window.WB_API_CACHE[url] = response.data;

  return response;
};

export default getProducerPolicies;
