// @flow

const isTruthy = (key: any = '') => {
  switch (key.toUpperCase()) {
    case 'Y':
    case 'YES':
    case true:
      return true;

    case 'N':
    case 'NO':
    case false:
      return false;

    default:
      return key;
  }
};

export default isTruthy;
