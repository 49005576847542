import {
  FAILURE_STRATEGY_ACCOUNT_VALUE,
  FAILURE_STRATEGY_FUNDS_AVERAGING,
  REQUEST_STRATEGY_ACCOUNT_VALUE,
  REQUEST_STRATEGY_BENCHMARKING,
  REQUEST_STRATEGY_FUND_ANALYSIS,
  REQUEST_STRATEGY_FUNDS_AVERAGING,
  REQUEST_STRATEGY_TRANSFERS,
  SET_CURRENT_POLICY_ID,
  SUCCESS_STRATEGY_ACCOUNT_VALUE,
  SUCCESS_STRATEGY_BENCHMARKING,
  SUCCESS_STRATEGY_FUND_ANALYSIS,
  SUCCESS_STRATEGY_FUNDS_AVERAGING,
  FAILURE_STRATEGY_FUNDS_PRICES,
  REQUEST_STRATEGY_FUNDS_PRICES,
  SUCCESS_STRATEGY_FUNDS_PRICES,
  SUCCESS_STRATEGY_TRANSFERS,
} from '../constants/types';

import { defaultData, loadingData } from '../constants/defaults';

const fundsDefaultData = Object.assign({}, defaultData);
fundsDefaultData.data = { funds: [], chartInfo: {} };

const fundsLoadingData = Object.assign({}, loadingData);
fundsLoadingData.data = { funds: [], chartInfo: {} };

const initialState = {
  currentPolicyId: '',
  transfers: { ...defaultData },
  analysis: { ...defaultData },
  benchmarking: { ...defaultData },
  prices: { ...fundsDefaultData },
  averaging: { ...fundsDefaultData },
  accountValue: { ...fundsDefaultData },
};

const StrategyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_POLICY_ID:
      if (state.currentPolicyId === action.payload.currentPolicyId) {
        return { ...state };
      }

      return {
        ...initialState,
        currentPolicyId: action.payload.currentPolicyId,
      };

    case REQUEST_STRATEGY_BENCHMARKING:
      return { ...state, benchmarking: { ...loadingData } };

    case REQUEST_STRATEGY_TRANSFERS:
      return { ...state, transfers: { ...loadingData } };

    case REQUEST_STRATEGY_FUND_ANALYSIS:
      return { ...state, analysis: { ...loadingData } };

    case REQUEST_STRATEGY_FUNDS_AVERAGING:
      return { ...state, averaging: { ...fundsLoadingData } };

    case REQUEST_STRATEGY_ACCOUNT_VALUE:
      return { ...state, accountValue: { ...fundsLoadingData } };

    case FAILURE_STRATEGY_FUNDS_AVERAGING:
      return {
        ...state,
        averaging: {
          loading: false,
          data: { funds: [], chartInfo: {} },
          errorMessage: action.payload.error,
        },
      };

    case FAILURE_STRATEGY_ACCOUNT_VALUE:
      return {
        ...state,
        accountValue: {
          loading: false,
          data: { funds: [], chartInfo: {} },
          errorMessage: action.payload.error,
        },
      };

    case SUCCESS_STRATEGY_BENCHMARKING:
      return {
        ...state,
        benchmarking: {
          loading: false,
          requested: true,
          data: action.payload.benchmarking,
        },
      };

    case SUCCESS_STRATEGY_TRANSFERS:
      return {
        ...state,
        transfers: {
          loading: false,
          requested: true,
          data: action.payload.transfers,
        },
      };

    case SUCCESS_STRATEGY_FUND_ANALYSIS:
      return {
        ...state,
        analysis: {
          loading: false,
          requested: true,
          data: action.payload.analysis,
        },
      };

    case SUCCESS_STRATEGY_FUNDS_AVERAGING:
      return {
        ...state,
        averaging: {
          loading: false,
          requested: true,
          data: action.payload.averaging,
        },
      };

    case REQUEST_STRATEGY_FUNDS_PRICES:
      return { ...state, prices: { ...fundsLoadingData } };

    case FAILURE_STRATEGY_FUNDS_PRICES:
      return {
        ...state,
        prices: {
          loading: false,
          data: { funds: [], chartInfo: {} },
          errorMessage: action.payload.error,
        },
      };

    case SUCCESS_STRATEGY_FUNDS_PRICES:
      return {
        ...state,
        prices: {
          loading: false,
          requested: true,
          data: action.payload.prices,
        },
      };

    case SUCCESS_STRATEGY_ACCOUNT_VALUE:
      return {
        ...state,
        accountValue: {
          loading: false,
          requested: true,
          data: action.payload.accountValue,
        },
      };

    default:
      return { ...state };
  }
};

export default StrategyReducer;
