// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SERVICE_FORMS, INBOX } from '../../router/routes';
import { arrowUp } from '../../assets/images';
import strings from '../../config/strings';
import {
  MainContent4,
  ProfileMenuItem,
} from '../../components/shared/texts/content';
import {
  MenuContainer,
  MenuItemContainer,
  ItemHighlighter,
  HorizontalDivider,
  CollapsableSpace,
  ArrowDiv,
  ArrowImage,
  HeaderContainer,
} from './styles';

const ProfileMenu = () => {
  const [animationState, setAnimationState] = useState();
  const [onInformation, setOnInformation] = useState(true);
  const [onContacts, setOnContacts] = useState(false);
  const [onAddress, setOnAddress] = useState(false);
  const [onSecurity, setOnSecurity] = useState(false);
  // const [onPreferences, setOnPreferences] = useState(false);

  const history = useHistory();

  const resetStates = () => {
    setOnInformation(false);
    setOnContacts(false);
    setOnAddress(false);
    // setOnPreferences(false);
    setOnSecurity(false);
  };

  const scrollToItem = item => {
    const element = document.getElementsByClassName(item)[0];

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  };

  const handleMenuAnimation = () => {
    setAnimationState(!animationState);
  };

  return (
    <MenuContainer>
      <HeaderContainer onClick={handleMenuAnimation}>
        <MainContent4>{strings.PROFILE_DETAILS}</MainContent4>
        <ArrowDiv>
          <ArrowImage
            animationState={animationState}
            src={arrowUp}
            alt="arrow"
          />
        </ArrowDiv>
      </HeaderContainer>
      <CollapsableSpace animationState={animationState}>
        <MenuItemContainer
          onClick={() => {
            resetStates();
            setOnInformation(true);
            scrollToItem('personal-information');
          }}
        >
          <ProfileMenuItem isHighlighted={onInformation}>
            {strings.PERSONAL_INFORMATION}
          </ProfileMenuItem>
          {onInformation && <ItemHighlighter />}
        </MenuItemContainer>
        <MenuItemContainer
          onClick={() => {
            resetStates();
            setOnContacts(true);
            scrollToItem('contacts');
          }}
        >
          <ProfileMenuItem isHighlighted={onContacts}>
            {strings.CONTACTS}
          </ProfileMenuItem>
          {onContacts && <ItemHighlighter />}
        </MenuItemContainer>
        <MenuItemContainer
          onClick={() => {
            resetStates();
            setOnAddress(true);
            scrollToItem('address');
          }}
        >
          <ProfileMenuItem isHighlighted={onAddress}>
            {strings.ADDRESS}
          </ProfileMenuItem>
          {onAddress && <ItemHighlighter />}
        </MenuItemContainer>
        <MenuItemContainer
          onClick={() => {
            resetStates();
            setOnSecurity(true);
            scrollToItem('security');
          }}
        >
          <ProfileMenuItem isHighlighted={onSecurity}>
            {strings.SECURITY}
          </ProfileMenuItem>
          {onSecurity && <ItemHighlighter />}
        </MenuItemContainer>
        {/* <MenuItemContainer
          onClick={() => {
            resetStates();
            setOnPreferences(true);
            scrollToItem('preferences');
          }}
        >
          <ProfileMenuItem isHighlighted={onPreferences}>
            {strings.PREFERENCES}
          </ProfileMenuItem>
          {onPreferences && <ItemHighlighter />}
        </MenuItemContainer> */}
      </CollapsableSpace>
      <HorizontalDivider />
      <MenuItemContainer pageMenuItem onClick={() => history.push(INBOX)}>
        <ProfileMenuItem>{strings.INBOX}</ProfileMenuItem>
      </MenuItemContainer>
      <HorizontalDivider />
      <MenuItemContainer
        pageMenuItem
        onClick={() => history.push(SERVICE_FORMS)}
      >
        <ProfileMenuItem>{strings.SERVICE_FORMS}</ProfileMenuItem>
      </MenuItemContainer>
    </MenuContainer>
  );
};

export default ProfileMenu;
