import axios from 'axios';

import getBenchmarking from './benchmarking';
import getUserPolicies from './policies';
import getFundsAnalysis from './analysis';
import getFundsAveraging from './averaging';
import getFundsPrices from './funds-prices';
import getAccountValue from './account-value';
import getStrategyTransfers from './strategy-transfers-redirects';
import { getPerformance, getRiskReturnPerformance } from './performance';
import login, {
  requestPasswordReset,
  resetPasswordAPI,
  changePasswordAPI,
} from './account';

const JWT = localStorage.getItem('token');

if (JWT) {
  axios.defaults.headers.common.Authorization = `Bearer ${JWT}`;
}

export {
  getAccountValue,
  getBenchmarking,
  getFundsAnalysis,
  getFundsAveraging,
  getFundsPrices,
  getUserPolicies,
  getPerformance,
  getRiskReturnPerformance,
  getStrategyTransfers,
  login,
  requestPasswordReset,
  resetPasswordAPI,
  changePasswordAPI,
};
