import styled from 'styled-components';
import { size } from '../../config/device';
import { RowContainer } from '../shared/container';

export const blockSeparator = { marginRight: 10 };

export const currency = { fontSize: 11 };

export const displayIcon = {
  height: 72,
  width: 'unset',
  marginRight: 5,
  marginBottom: 4,
  boxSizing: 'border-box',
  padding: '14px 20px',
  maxWidth: 85,
};

export const FooterClickableRow = styled(RowContainer)`
  cursor: pointer;
  justify-content: flex-end;
  width: 100%;

  :active,
  :visited,: {
    outline: 0;
  }

  @media (max-width: ${size.tablet}) {
    justify-content: flex-start;
  }
`;

export const horizontalDivider = { marginTop: 22 };

export const inlineText = { display: 'inline-block' };

export const justifyBetween = { justifyContent: 'space-between' };

export const policiesOverviewListTitle = { marginBottom: 32 };

export const questionMarkStyle = {
  marginLeft: '9px',
};

export const stretch = { alignItems: 'stretch' };

export const TotalReturnStyle = {
  justifyContent: 'flex-start',
};

export const viewMoreLink = { marginRight: 15 };

export const leftOutline = { flex: 6.2 };
export const rightOutline = { flex: 3.8 };
export const leftStatsBlock = { flex: 1.2 };
export const rightStatsBlock = { flex: 1.7 };

export const policyCardBasicInfoGroup = {
  alignItems: 'flex-end',
  ...rightOutline,
};
