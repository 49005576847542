// @flow

/* eslint-disable global-require */
/* eslint-disable no-return-assign */

import React, { useState, useEffect, useLayoutEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useHistory } from 'react-router';
import { CardTitle } from '../shared/texts/title';
import { POLICY_DETAILS } from '../../router/routes';
import PolicySelector from './PolicySelector';
import globals from '../../config/globals';

import {
  TabItem,
  TabsIndicator,
  TabsContainer,
  MainContainer,
  TabsScrollable,
} from './container';

// icons
import PolicyDetailsIcon from '../../assets/images/tabs/policy-details.svg';
import BeneficiariesIcon from '../../assets/images/tabs/beneficiaries.svg';
import ContributionIcon from '../../assets/images/tabs/contribution.svg';
import PerformanceIcon from '../../assets/images/tabs/performance.svg';
import EncashmentIcon from '../../assets/images/tabs/encashment.svg';
import DocumentsIcon from '../../assets/images/tabs/documents.svg';
import StrategyIcon from '../../assets/images/tabs/strategy.svg';

import { usePolicies } from '../../hooks/policies.hooks';
import { isInvestmentTypePolicy } from '../../utils/policy';

type Props = {
  onChangeTab: (tabKey: string) => void,
  onChangePolicyId: (id: string) => void,
  tabType: string,
  policyNumber: string,
  customerName: string,
  customerId: number,
};

const PolicyTabItems = [
  { ...globals.POLICY_TABS.DETAILS, icon: PolicyDetailsIcon },
  { ...globals.POLICY_TABS.BENEFICIARIES, icon: BeneficiariesIcon },
  { ...globals.POLICY_TABS.CONTRIBUTION, icon: ContributionIcon },
  { ...globals.POLICY_TABS.ENCASHMENT, icon: EncashmentIcon },
  { ...globals.POLICY_TABS.PERFORMANCE, icon: PerformanceIcon },
  { ...globals.POLICY_TABS.STRATEGY, icon: StrategyIcon },
  { ...globals.POLICY_TABS.DOCUMENTS, icon: DocumentsIcon },
];

const ProducerTabItems = [
  { ...globals.PRODUCER_TABS.POLICIES, icon: PolicyDetailsIcon },
];

const PERFORMANCE_TAB = globals.POLICY_TABS.PERFORMANCE.key;
const ENCASHMENT_TAB = globals.POLICY_TABS.ENCASHMENT.key;
const STRATEGY_TAB = globals.POLICY_TABS.STRATEGY.key;

const NONVUL_HIDDEN_TABS = [STRATEGY_TAB, ENCASHMENT_TAB, PERFORMANCE_TAB];

const Tabs = ({
  onChangeTab,
  onChangePolicyId,
  tabType,
  policyNumber,
  customerName,
  customerId,
}: Props) => {
  const policies = usePolicies(!!customerId, customerId);
  const location = useLocation();
  const history = useHistory();

  const TabItems = tabType === 'producer' ? ProducerTabItems : PolicyTabItems;

  const [currentTab, setCurrentTab] = useState(0);
  const [currentPolicy, setCurrentPolicy] = useState();

  const currentPolicyIsNonVul =
    !isEmpty(currentPolicy) &&
    !isInvestmentTypePolicy(currentPolicy.policyType);

  PolicyTabItems.filter(tab => NONVUL_HIDDEN_TABS.includes(tab.key))
    // eslint-disable-next-line no-param-reassign
    .forEach(tab => (tab.isHidden = currentPolicyIsNonVul));

  function onClickTab(tabItem, i) {
    onChangeTab(tabItem.key);
    setCurrentTab(i);
  }

  function updatePolicies() {
    const { policyNumber: statePolicyNumber } = location.state || {};
    const statePolicy = policies.find(
      p => [statePolicyNumber, policyNumber].includes(p.policyNumber),
    );

    const previousPolicyId = !statePolicy
      ? localStorage.getItem(globals.STORAGE_POLICY_ID)
      : statePolicy.policyId;

    const defaultSelectedPolicy = // set default selected policy
      policies.find(pol => +pol.policyId === +previousPolicyId) ||
      policies[0];

    setCurrentPolicy(defaultSelectedPolicy);

    if (location.state && location.state.policyNumber) {
      history.replace({
        pathname: POLICY_DETAILS,
        state: {},
      });
    }
  }

  useLayoutEffect(() => {
    const hash = (window.location.hash || '').replace('#', '');
    const tabIndex = hash ? TabItems.findIndex(item => item.key === hash) : 0;

    if (TabItems[tabIndex]) {
      onClickTab(TabItems[tabIndex], tabIndex);
    } else {
      onClickTab(TabItems[0], 0);
    }
  }, []);

  useEffect(() => {
    updatePolicies();
  }, [customerId, policies]);

  useEffect(() => {
    if (currentPolicy) {
      if (typeof onChangePolicyId === 'function') {
        onChangePolicyId(currentPolicy.policyId);
      }

      const isConditionalTab = NONVUL_HIDDEN_TABS.includes(
        // tabs that could be hidden
        PolicyTabItems[currentTab].key,
      );

      // reset tabs if current tab should be hidden
      if (currentPolicyIsNonVul && isConditionalTab) {
        onClickTab(TabItems[0], 0);
      }
    }
  }, [currentPolicy]);

  useEffect(() => {
    if (!tabType && policies.length && !currentPolicy) {
      updatePolicies();
    }
  }, [policies, customerId]);

  return (
    <MainContainer>
      {!!(currentPolicy && policies.length && tabType !== 'producer') && (
        <PolicySelector
          policies={policies}
          currentPolicy={currentPolicy}
          onChangePolicy={setCurrentPolicy}
        />
      )}
      {tabType === 'producer' && (
        <CardTitle style={{ alignSelf: 'center' }}>{customerName}</CardTitle>
      )}

      <TabsContainer>
        <TabsScrollable>
          {!!policies.length && TabItems.map(
            (tabItem, i) =>
              !tabItem.isHidden && (
                <TabItem
                  key={tabItem.key}
                  active={currentTab === i}
                  onClick={() => onClickTab(tabItem, i)}
                  href={i > 0 ? `#${tabItem.key}` : '#'}
                >
                  <img src={tabItem.icon} alt={tabItem.key} height="19" />
                  <span>{tabItem.label}</span>

                  <TabsIndicator active={currentTab === i} />
                </TabItem>
              ),
          )}
        </TabsScrollable>
      </TabsContainer>
    </MainContainer>
  );
};

export default Tabs;
