import {
  REQUEST_PERFORMANCE_DATA,
  REQUEST_RISK_RETURN_PERFORMANCE,
  SUCCESS_PERFORMANCE_DATA,
  SUCCESS_RISK_RETURN_PERFORMANCE,
} from '../constants/types';

const defaultData = {
  loading: false,
  data: [],
};

const loadingData = {
  loading: true,
  data: [],
};

const initialState = {
  portfolioAllocations: { ...defaultData },
};

const PerformanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PERFORMANCE_DATA:
      return { ...state, portfolioAllocations: { ...loadingData } };

    case REQUEST_RISK_RETURN_PERFORMANCE:
      return { ...state, riskReturn: { ...loadingData } };

    case SUCCESS_PERFORMANCE_DATA:
      return {
        ...state,
        portfolioAllocations: {
          loading: false,
          data: action.payload.portfolioAllocations,
        },
      };

    case SUCCESS_RISK_RETURN_PERFORMANCE:
      return {
        ...state,
        riskReturn: {
          loading: false,
          data: action.payload.riskReturn,
        },
      };

    default:
      return { ...state };
  }
};

export default PerformanceReducer;
