// @flow

import React from 'react';
import PolicyNotices from '../../components/documents/policy-notices';
import PolicyContract from '../../components/documents/policy-contract';
import DownloadsPopup from '../../components/terms-conditions-popup/downloads';

type Props = {
  contracts: any[],
  handleDownloadDocumentClick: Function,
  handleViewDocumentClick: Function,
  notices: any[],
};

const DocumentsScreen = ({
  contracts,
  handleDownloadDocumentClick,
  handleViewDocumentClick,
  notices,
}: Props) => (
  <>
    <PolicyContract
      data={contracts}
      handleDownloadDocumentClick={handleDownloadDocumentClick}
      handleViewDocumentClick={handleViewDocumentClick}
    />
    <PolicyNotices
      data={notices}
      handleDownloadDocumentClick={handleDownloadDocumentClick}
      handleViewDocumentClick={handleViewDocumentClick}
    />
    <DownloadsPopup />
  </>
);

export default DocumentsScreen;
