import {
  REQUEST_GET_MEDIA,
  SUCCESS_GET_MEDIA,
  FAILURE_GET_MEDIA,
} from '../types/media';

import getMediaAPI from '../../api/media';

const { REACT_APP_CMS_URL } = process.env;

const requestGetMedia = () => ({ type: REQUEST_GET_MEDIA });

const successGetMedia = (media) => ({
  type: SUCCESS_GET_MEDIA,
  payload: { media },
});

const failureGetMedia = (err) => ({
  type: FAILURE_GET_MEDIA,
  payload: { err },
});

const getMedia = () => async (dispatch) => {
  dispatch(requestGetMedia());

  try {
    const { data = {} } = await getMediaAPI();

    if (data && data.logo && data.logo.url) {
      data.logo.url = `${REACT_APP_CMS_URL || ''}${data.logo.url}`;
    } else {
      data.logo.url = '';
    }

    dispatch(successGetMedia(data));
  } catch (error) {
    dispatch(failureGetMedia(error));
  }
};

export default getMedia;
