// @flow

import React from 'react';
import Routes from './router/index';
import './App.css';

type Props = {
  onIdle: Function;
};

const App = ({ onIdle }: Props) => <Routes onIdle={onIdle} />;

export default App;
