// @flow

import React, { useState } from 'react';
import type Moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import PolicyOverview from '../overview';
import EmptyPolicy from '../../empty-policy';
import Spinner from '../../shared/spinner';
import strings from '../../../config/strings';
import { policiesOverviewListTitle } from '../style';
import { SectionTitle } from '../../shared/texts/title';
import type { PolicyType } from '../../../models/policies';
import Button from '../../shared/table/button';

type Policy = {
  policyNumber: string,
  policyId: number,
  policyType: PolicyType,
  startDate: Moment,
  endDate: Moment,
  insuredName: string,
  insuredDob: Moment,
  totalContribution: number,
  totalReturnPerc: number,
  totalLoss: number,
  nextInstallmentDate: Moment,
  nextInstallmentAmount: number,
  currency: string,
  currentPolicyValue: number,
  policyAnniversaryDate: Moment,
};

type Props = {
  handlePolicyViewMoreClick: Function,
  userPolicies: Array<Policy>,
  producer: Boolean,
};

const PoliciesList = ({
  handlePolicyViewMoreClick,
  userPolicies,
  producer,
}: Props) => {
  const [showAll, setShowAll] = useState(false);

  const {
    loading: policiesLoading,
    requested: policiesRequested,
  } = useSelector(state => state.policies.policiesList);

  const {
    loading: customerPoliciesLoading,
    requested: customerPoliciesRequested,
  } = useSelector(state => state.policies.customerPolicies);

  const requested = producer
    ? customerPoliciesRequested
    : policiesRequested;

  const loading = customerPoliciesLoading || policiesLoading;
  const renderedUserPolicies = userPolicies.slice(0, !showAll ? 3 : undefined);

  const renderPolicies = () => {
    if (producer && customerPoliciesLoading) {
      return <Spinner />;
    }

    if (!producer && policiesLoading) {
      return <Spinner />;
    }

    return !isEmpty(userPolicies)
      ? renderedUserPolicies.map(policy => (
        <PolicyOverview
          handlePolicyViewMoreClick={handlePolicyViewMoreClick}
          policy={policy}
          key={policy.policyId}
        />
      ))
      : requested && <EmptyPolicy />
  }

  return (
    <div>
      {!producer && (
        <SectionTitle style={policiesOverviewListTitle}>
          {strings.SECTION_TITLE_POLICIES_LIST}
        </SectionTitle>
      )}
      {renderPolicies()}
      {!showAll && !loading && userPolicies.length > 3 && (
        <Button onClick={() => setShowAll(true)}>
          {strings.LOAD_MORE}
        </Button>
      )}
    </div>
  );
};

export default PoliciesList;
