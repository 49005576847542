// @flow

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocumentsNotices,
  getDocumentsContracts,
} from '../redux/actions/documents';

export const useDocumentsPolicyContracts = (policyId: number) => {
  const dispatch = useDispatch();

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);
  const { account = {} } = useSelector(state => state.user);
  const { username } = account.data.user;

  const { loading, requested, data } = useSelector(
    state => state.documents.contracts,
  );

  useEffect(() => {
    if (policyId && (policyId !== previousPolicyId || !requested)) {
      dispatch(getDocumentsContracts(policyId, username));
    }
  }, [policyId, username]);

  return { loading, data };
};

export const useDocumentsPolicyNotices = (policyId: number) => {
  const dispatch = useDispatch();

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);
  const { account = {} } = useSelector(state => state.user);

  const { loading, requested, data } = useSelector(
    state => state.documents.notices,
  );

  const { username } = account.data.user;

  useEffect(() => {
    if (policyId && (policyId !== previousPolicyId || !requested)) {
      dispatch(getDocumentsNotices(policyId, username));
    }
  }, [policyId]);

  return { loading, data };
};
