import {
  SET_CURRENT_POLICY_ID,
  REQUEST_CUSTOMER_POLICIES_LIST,
  SUCCESS_CUSTOMER_POLICIES_LIST,
  REQUEST_POLICIES_LIST,
  SUCCESS_POLICIES_LIST,
  FAILURE_POLICIES_LIST,
  REQUEST_POLICY_BENEFITS,
  SUCCESS_POLICY_BENEFITS,
  REQUEST_POLICY_PAYMENT_DETAILS,
  SUCCESS_POLICY_PAYMENT_DETAILS,
  REQUEST_ENCASHMENT,
  SUCCESS_ENCASHMENT,
  REQUEST_PREMIUM_ALLOCATION,
  SUCCESS_PREMIUM_ALLOCATION,
  REQUEST_BENEFICIARIES,
  SUCCESS_BENEFICIARIES,
  USER_LOGOUT,
} from '../constants/types';

import globals from '../../config/globals';

const defaultData = {
  currentPolicyId: null,
  requested: false,
  loading: false,
  data: [],
};

const loadingData = {
  requested: false,
  loading: true,
  data: [],
};

const policyDetailsInitialState = {
  policyPaymentDetails: { ...defaultData, data: {} },
  premiumAllocation: { ...defaultData },
  policyBenefits: { ...defaultData },
  policyEncashment: { ...defaultData },
  policyBeneficiaries: { ...defaultData },
};

const initialState = {
  currentPolicyId: localStorage.getItem(globals.STORAGE_POLICY_ID),
  customerPolicies: { ...defaultData },
  policiesList: { ...defaultData },
  currentCustomerId: null,

  ...policyDetailsInitialState,
};

const PoliciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_POLICY_ID:
      if (state.currentPolicyId === action.payload.currentPolicyId) {
        return { ...state };
      }

      localStorage.setItem(globals.STORAGE_POLICY_ID, action.payload.currentPolicyId);
      return { ...state, ...policyDetailsInitialState, currentPolicyId: action.payload.currentPolicyId };

    case REQUEST_POLICIES_LIST:
      return {
        ...state,
        policiesList: { ...loadingData },
      };

    case REQUEST_CUSTOMER_POLICIES_LIST:
      return {
        ...state,
        customerPolicies: { ...loadingData },
        currentCustomerId: action.payload.currentCustomerId,
      };

    case FAILURE_POLICIES_LIST:
      return {
        ...state,
        policiesList: { loading: false, requested: true, data: [] },
      };

    case SUCCESS_POLICIES_LIST:
      return {
        ...state,
        policiesList: { loading: false, requested: true, data: action.payload.policies },
      };

    case SUCCESS_CUSTOMER_POLICIES_LIST:
      return {
        ...state,
        customerPolicies: { loading: false, requested: true, data: action.payload.policies },
      };

    case REQUEST_POLICY_BENEFITS:
      return { ...state, policyBenefits: { ...loadingData } };

    case SUCCESS_POLICY_BENEFITS:
      return {
        ...state,
        policyBenefits: { loading: false, requested: true, data: action.payload.benefits },
      };

    case REQUEST_POLICY_PAYMENT_DETAILS:
      return { ...state, policyPaymentDetails: { ...loadingData, data: {} } };

    case SUCCESS_POLICY_PAYMENT_DETAILS:
      return {
        ...state,
        policyPaymentDetails: {
          loading: false,
          requested: true,
          data: action.payload.paymentDetails,
        },
      };

    case REQUEST_ENCASHMENT:
      return { ...state, policyEncashment: { ...loadingData } };

    case SUCCESS_ENCASHMENT:
      return {
        ...state,
        policyEncashment: { loading: false, requested: true, data: action.payload.encashments },
      };

    case REQUEST_BENEFICIARIES:
      return { ...state, policyBeneficiaries: { ...loadingData } };

    case SUCCESS_BENEFICIARIES:
      return {
        ...state,
        policyBeneficiaries: {
          loading: false,
          requested: true,
          data: action.payload.beneficiaries,
        },
      };

    case REQUEST_PREMIUM_ALLOCATION:
      return { ...state, premiumAllocation: { ...loadingData } };

    case SUCCESS_PREMIUM_ALLOCATION:
      return {
        ...state,
        premiumAllocation: {
          loading: false,
          requested: true,
          data: action.payload.premiumAllocation,
        }
      };

    case USER_LOGOUT:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default PoliciesReducer;
