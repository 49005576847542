// @flow

import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  decimalWithCommas,
  formatCurrency,
  numberWithCommas,
} from '../../utils/numbers';
import { barStyle } from './styles';
import EmptyData from './empty-data';
import colors from '../../config/colors';
import strings from '../../config/strings';
import RenderTooltipWithoutTitle from './tooltip-notitle';

require('./rounded-corners');

type Props = {
  data: any[],
  labels: string[],
  spacing: Number,
  currency: String,
  contributionName: String,
};

const VerticalBarChart = ({
  data,
  labels,
  spacing = 0,
  contributionName,
  currency = 'USD',
}: Props) => (
  <>
    <Bar
      data={{
        labels,
        datasets: [
          {
            data,
            label: contributionName,
            ...barStyle,
          },
        ],
      }}
      options={{
        cornerRadius: 12,
        legend: { display: false },
        lineHeightAnnotation: {
          always: false,
          hover: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'transparent',
                zeroLineColor: 'rgb(222, 222, 222)',
                zeroLineWidth: 1,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                fontSize: 14,
                fontFamily: 'Avenir-Heavy',
                fontColor: colors.primaryDark,
                callback: value => numberWithCommas(value),
              },
              gridLines: { drawBorder: false },
            },
          ],
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: spacing,
            bottom: spacing - 15,
          },
        },
        tooltips: {
          enabled: false,
          xPadding: 15,
          yPadding: 15,

          bodyFontColor: colors.primaryDark,
          bodyFontFamily: 'Avenir-Medium',
          bodyFontSize: 20,

          custom: function CustomToolTip(tooltipModel) {
            return RenderTooltipWithoutTitle(
              tooltipModel,
              this,
              value =>
                `${formatCurrency(currency, value < 0)}${decimalWithCommas(Math.abs(value))}`,
            );
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          labels: {
            render: () => '',
          },
          datalabels: { display: false },
        },
      }}
    />
    {!data.length && <EmptyData>{strings.NO_DATA}</EmptyData>}
  </>
);

export default VerticalBarChart;
