import styled from 'styled-components';

const duration = '1.4s';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.offset}px;
`;

export const StyledSvg = styled.svg`
  -webkit-animation: rotator ${duration} linear infinite;
          animation: rotator ${duration} linear infinite;

  @-webkit-keyframes rotator {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
    }
  }

  @keyframes rotator {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
    }
  }
`;

export const StyledCircle = styled.circle`
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: dash ${duration} ease-in-out infinite, colors 5.6s ease-in-out infinite;
            animation: dash ${duration} ease-in-out infinite, colors 5.6s ease-in-out infinite;
  }

  @-webkit-keyframes colors {
    0% {
      stroke: ${props => props.theme.primary};
    }
    100% {
      stroke: ${props => props.theme.primary};
    }
  }

  @keyframes colors {
    0% {
      stroke: ${props => props.theme.primary};
    }
    100% {
      stroke: ${props => props.theme.primary};
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
              transform: rotate(450deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
              transform: rotate(450deg);
    }
  }
`;
