// @flow

import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import { MainContainer, ContentWrapper } from '..';
import { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getFooter, getTheme } from '../../redux/actions/theme.action';

import MainHeader from '../main-header/header';
import MainFooter from '../main-footer';
import globals from '../../config/globals';

type Props = {
  children: Node,
  userProfile: {
    firstName: string,
    lastName: string,
    fullname: string,
    dob: string,
    maritalStatus: string,
    address: string,
    type: 'Male' | 'Female' | 'Company',
    nationality: number,
    email: string,
    phoneNumbers: Array<string>,
    profilePic: ?string,
  },
  logoUrl: string,
};

const key = globals.COOKIES_STORAGE_KEY;

const MainPage = ({ children, userProfile, logoUrl }: Props) => {
  const dispatch = useDispatch();
  const themeColors = useSelector(state => state.theme.colors);
  const [cookiesAllowed, setCookiesAllowed] = useState(
    localStorage.getItem(key),
  );

  const allowCookies = () => {
    setCookiesAllowed(true);
    localStorage.setItem(key, true);
  };

  useEffect(() => {
    // TODO: maybe use graphQL
    // fetch needed data w/ 1 API
    dispatch(getFooter());
    dispatch(getTheme());
  }, []);

  return (
    <ThemeProvider theme={themeColors}>
      <MainContainer>
        <MainHeader
          cookiesAllowed={cookiesAllowed}
          allowCookies={allowCookies}
          userProfile={userProfile}
          logoUrl={logoUrl}
        />
        <ContentWrapper extraMargin={!cookiesAllowed}>
          {children}
        </ContentWrapper>
        <MainFooter />
      </MainContainer>
    </ThemeProvider>
  );
};

export default MainPage;
