import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  position: relative;
  margin-top: 15px;
`;

export const SearchBox = styled.input`
  padding: 13px;
  border-radius: 3px;
  border: solid 1px ${props => props.theme.white3};
  color: ${props => props.theme.slateGrey};
  padding-left: 48px;
  position: relative;
  opacity: 0.65;

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const SearchIcon = styled.img`
  width: 17px;
  height: 16px;
  position: absolute;
  opacity: 0.65;
  left: 18px;
  top: 13px;
`;
