// @flow

import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import AccountValueChart from './chart';
import strings from '../../../config/strings';
import { PaddedSectionBlock } from '../container';
import { performanceSectionTitle } from './styles';
import { formatShortDate } from '../../../utils/date';
import { SectionTitle2 } from '../../shared/texts/title';
import ChartDescription from '../risk-return/chart-description';

import type {
  ChartDescription as ChartDescriptionType,
  FundAccountValue,
} from '../../../models/funds';
import type { DropDownOption } from '../../../models/form';

type Props = {
  chartInfo: ChartDescriptionType,
  currency: { id: number, name: string },
  funds: Array<FundAccountValue>,
  fundsOptions: Array<DropDownOption>,
  handleFundsDropdownClick: Function,
  handlePeriodPickerClick: Function,
  isLoading: boolean,
  selectedFundId: number | string,
  selectedPeriod: number,
};

const AccountValue = ({
  chartInfo,
  currency,
  funds,
  fundsOptions,
  handleFundsDropdownClick,
  handlePeriodPickerClick,
  isLoading,
  selectedFundId,
  selectedPeriod,
}: Props) => {
  const labels = [];
  const accountValue = [];
  const profitLoss = [];

  if (!isEmpty(funds) && selectedFundId !== -1)
    funds[selectedFundId].stats.forEach(fund => {
      labels.push(formatShortDate(fund.effectiveDate));
      accountValue.push(fund.policyValue);
      profitLoss.push(fund.profitLoss);
    });

  return (
    <Fragment>
      <SectionTitle2 style={performanceSectionTitle}>
        {strings.SECTION_TITLE_STRATEGY_ACCOUNT_VALUE_MOVEMENT_CHART}
      </SectionTitle2>

      <PaddedSectionBlock>
        <AccountValueChart
          accountValue={accountValue}
          currency={currency}
          fundsOptions={fundsOptions}
          handleFundsDropdownClick={handleFundsDropdownClick}
          handlePeriodPickerClick={handlePeriodPickerClick}
          labels={labels}
          loading={isLoading}
          profitLoss={profitLoss}
          selectedFundId={selectedFundId}
          selectedPeriod={selectedPeriod}
        />

        <ChartDescription chartInfo={chartInfo} />
      </PaddedSectionBlock>
    </Fragment>
  );
};

export default AccountValue;
