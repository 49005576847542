import styled from 'styled-components';
import { arrowUp } from '../../../assets/images';
import { size as deviceSize } from '../../../config/device';

export const DropdownItem = styled.li`
  font-family: Avenir-Roman;
  list-style: none;
  cursor: pointer;
  padding: 15px 0;
  padding-right: 10px;
  background: ${props => props.theme.white};
  display: ${props => (props.visible ? 'block' : 'none')};

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #505055;
  text-indent: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ noMaxWidth, size }) => {
    if (!noMaxWidth) {
      return `max-width: ${size === 'large' ? '85%' : '25ch'};`;
    }

    return '';
  }}

  ${({ simple }) => {
    if (!simple) {
      return `
        border-bottom: 1px solid ${props => props.theme.white4};

        &:last-child,
        :first-child {
          border-bottom-width: 0;
        }

        &:first-child {
          font-family: Avenir-Heavy;
          font-size: 11px;
          font-weight: 900;
        }

        &:last-child {
          padding-bottom: 0;
        }
      `;
    }

    return '';
  }}

  ${({ theme, active }) => {
    if (active) {
      return `
        &:nth-child(2) {
          background-image: url(${arrowUp});
          background-repeat: no-repeat;
          background-position: right 18px center;
          font-family: Avenir-Heavy;
          color: ${theme.primary};
          background-color: ${theme.cobaltBlue07};
          border-radius: 3px;
          border: 0;
          padding-right: 40px;
        }
      `;
    }

    return '';
  }}

  ${({ compact }) => {
    if (compact) {
      return `
        font-size: 14px;
        max-width: initial;
        border-bottom: 1px solid #5050504f;

        span {
          padding-left: 15px;
          font-weight: 800;
        }
      `;
    }

    return '';
  }}

  &:hover {
    color: ${props => props.theme.primary};
  }

  @media (max-width: ${deviceSize.tablet}) {
    max-width: unset;
    width: calc(100% - 40px);
  }
`;

export const DropdownList = styled.div`
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.paleGrey2};
  max-height: ${props => (props.active ? '375px' : '0')};
  padding-bottom: ${props => (props.active ? '15px' : '0')};
  position: absolute;
  overflow-y: auto;
  z-index: 2;
  margin: 0;
  top: 0;
  ${props =>
    props.right !== undefined ? `right: ${props.right}px;` : 'left: 0;'};
  background: ${props => props.theme.white};
  width: ${props =>
    props.simple
      ? `${props.width}px`
      : props.size === 'large'
      ? 'calc(100% - 15px * 2)'
      : '224px'};

  @media (max-width: ${deviceSize.tablet}) {
    ${props =>
      props.simple
        ? 'max-width: calc(100vw - 100px);'
        : 'width: calc(100% + 4px);'}
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  margin-right: 5%;
  width: ${({ size }) => size === 'large' && '100%'};
  margin-bottom: ${({ size }) => (size === 'normal' ? '0rem' : '2rem')};
`;

export const DropdownBox = styled.div`
  padding: 0 16px;
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px rgba(100, 101, 109, 0.65);
  width: ${({ size }) =>
    size === 'small'
      ? '210px'
      : size === 'large'
      ? 'calc(100% - 16px * 2)'
      : '220px'};
  height: ${({ size }) => (size === 'small' ? 40 : 50)}px;
  justify-content: space-between;
  align-items: center;
  display: flex;

  ${({ simple, width }) => {
    if (simple) {
      return `
        height: auto;
        width: ${width || '150px'};
        border: none;
        padding: 0;
      `;
    }

    return '';
  }}

  span {
    height: 24px;
    font-family: Avenir-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: ${props => props.theme.slateGrey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${({ size }) => (size === 'large' ? '90%' : '20ch')};
  }

  @media (max-width: ${deviceSize.tablet}) {
    width: ${({ size }) => size !== 'large' && '100%'};
  }
`;

export const SimpleTitle = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${props => props.theme.primaryDark};
  margin-right: 10px;
`;

export const currenciesDropDown = { width: 80 };
