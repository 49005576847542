// @flow

import React from 'react';

import strings from '../../../config/strings';
import { Header, LevelsHeaderContainer } from './styles';

const LevelsHeader = () => (
  <LevelsHeaderContainer>
    <Header>{strings.PRODUCER_TABLE_LEVELS}</Header>
    <Header>{strings.PRODUCER_TABLE_TOTAL_COUNT}</Header>
    <Header>{strings.PRODUCER_TABLE_TOTAL_ANNUALIZED}</Header>
    <Header>{strings.PRODUCER_TABLE_TOTAL_POLICIES}</Header>
    <Header>{strings.PRODUCER_TABLE_TOTAL_NEW_ANNUALIZED}</Header>
    <div />
  </LevelsHeaderContainer>
);

export default LevelsHeader;
