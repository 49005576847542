// @flow

import React from 'react';
import { TextLabel, TextValue } from './text';
import { formatDate } from '../../../utils/date';
import { CardContainer, CardInfoEntry, ColumnSection } from './container';

type InfoEntry = {
  label: String,
  value: String,
  type: 'text' | 'number' | 'date',
};

type Props = {
  data: InfoEntry[],
};

const InfoCard = ({ data }: Props) => {
  function formatValue(value, type, key) {
    switch (type) {
      case 'date': {
        let formatted = formatDate(value);

        if (key === 'Effective date' || key === 'Expiry date') {
          formatted += ' Noon';
        }

        return formatted;
      }

      default:
        return value;
    }
  }

  const dataCol1 = data.slice(0, Math.ceil(data.length / 2));
  const dataCol2 = data.slice(Math.ceil(data.length / 2));
  const dataCols = [dataCol1, dataCol2];

  return (
    <CardContainer>
      {dataCols.map((el, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ColumnSection key={i}>
          {el.map(({ value, label, type }) => (
            <CardInfoEntry key={label}>
              <TextLabel>{label}</TextLabel>
              <TextValue>{formatValue(value, type, label)}</TextValue>
            </CardInfoEntry>
          ))}
        </ColumnSection>
      ))}
    </CardContainer>
  );
};

export default InfoCard;
