// @flow

import React, { Fragment } from 'react';

import type {
  ChartDescription as ChartDescriptionType,
  FundAnalysis,
} from '../../../models/funds';
import FundsAnalysisChart from './chart';
import strings from '../../../config/strings';
import { SectionTitle2 } from '../../shared/texts/title';
import { strategyListTitle, SectionBlock } from '../container';
import ChartDescription from '../risk-return/chart-description';

type Props = {
  chartInfo: ChartDescriptionType,
  funds: Array<FundAnalysis>,
  policyCurrency: string,
};

const Analysis = ({ chartInfo, funds, policyCurrency }: Props) => (
  <Fragment>
    <SectionTitle2 style={strategyListTitle}>
      {strings.SECTION_TITLE_STRATEGY_ANALYSIS_CHART}
    </SectionTitle2>

    <SectionBlock>
      <FundsAnalysisChart funds={funds} policyCurrency={policyCurrency} />
      <ChartDescription chartInfo={chartInfo} />
    </SectionBlock>
  </Fragment>
);

export default Analysis;
