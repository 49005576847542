import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 50px;
  justify-content: space-around !important;
`;

export { ButtonsContainer };
