// @flow

import React, { Fragment } from 'react';
import type { Moment } from 'moment';

import type { Policy, PolicyFund } from '../../models/policies';
import { PerformanceTabSwitch, PolicyInfoCards } from '../../components';
import Dropdown from '../../components/shared/dropdown';
import { currenciesDropDown } from '../../components/shared/dropdown/container';

type Props = {
  availablePolicyCurrencies: Object[],
  activeTabKey: string,
  currency: { id: number, name: string },
  dateAsAt: Moment,
  funds: Array<PolicyFund>,
  handleCurrencyChange: Function,
  handleDateChange: Function,
  handleTabSwitch: Function,
  isCalendarOpen: boolean,
  policyId: number,
  policyInfo: Policy,
  setCalendarOpenState: Function,
};

const Performance = ({
  activeTabKey,
  availablePolicyCurrencies,
  currency,
  dateAsAt,
  funds,
  handleCurrencyChange,
  handleDateChange,
  handleTabSwitch,
  isCalendarOpen,
  policyId,
  policyInfo,
  setCalendarOpenState,
}: Props) => (
  <Fragment>
    {availablePolicyCurrencies.length > 1 && (
      <Dropdown
        options={availablePolicyCurrencies}
        selected={currency}
        onSelect={handleCurrencyChange}
        style={currenciesDropDown}
      />
    )}

    <PolicyInfoCards
      currency={currency}
      policyInfo={policyInfo}
      isCalendarOpen={isCalendarOpen}
      setCalendarOpenState={setCalendarOpenState}
      dateAsAt={dateAsAt}
      handleDateChange={handleDateChange}
    />

    <PerformanceTabSwitch
      handleTabSwitch={handleTabSwitch}
      activeTabKey={activeTabKey}
      policyInfo={policyInfo}
      funds={funds}
      policyId={policyId}
      currency={currency}
    />
  </Fragment>
);

export default Performance;
