// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';

import strings from '../../config/strings';
import type FormUtils from '../../models/form';
import { FormSubmitButton, InputWrapper } from './styles';
import { TextboxValidation, OnboardingFormWrapper } from '../../components';

type Props = {
  formUtils: FormUtils,
  changePassword: boolean,
  handleFormSubmit: Function,
  logoUrl: string,
  themeColors: Object,
  username: string,
  hideUserOTP: boolean,
};

const ResetPasswordScreen = ({
  logoUrl,
  username,
  handleFormSubmit,
  formUtils,
  themeColors,
  changePassword,
  hideUserOTP,
}: Props) => (
  <OnboardingFormWrapper
    formUtils={formUtils}
    handleFormSubmit={handleFormSubmit}
    logoSrc={logoUrl}
    title={strings.ONBOARDING_RESET_PASSWORD_TITLE}
    themeColors={themeColors}
    showFooter={!changePassword}
    noOnBoarding={changePassword}
  >
    {changePassword && (
      <InputWrapper>
        <TextboxValidation
          errorMessage={
            formUtils.errors.password && formUtils.errors.password.message
          }
          formUtils={formUtils}
          label={strings.FORM_INPUT_LABEL_OLD_PASSWORD}
          name="oldPassword"
          placeholder={strings.FORM_INPUT_PLACEHOLDER_PASSWORD}
          type="password"
        />
      </InputWrapper>
    )}
    {!changePassword && !hideUserOTP && (
      <InputWrapper>
        <TextboxValidation
          errorMessage={strings.VALIDATION_ERROR_MESSAGE_REQUIRED}
          formUtils={formUtils}
          label={strings.FORM_INPUT_LABEL_USERNAME}
          name="username"
          defaultValue={username}
          placeholder={strings.FORM_INPUT_PLACEHOLDER_USERNAME}
          type="text"
          isDisabled={!isEmpty(username)}
        />
      </InputWrapper>
    )}
    {!changePassword && !hideUserOTP && (
      <InputWrapper>
        <TextboxValidation
          errorMessage={strings.VALIDATION_ERROR_MESSAGE_REQUIRED}
          formUtils={formUtils}
          label={strings.FORM_INPUT_LABEL_OTP}
          name="otp"
          placeholder={strings.FORM_INPUT_PLACEHOLDER_OTP}
          type="text"
        />
      </InputWrapper>
    )}
    <InputWrapper>
      <TextboxValidation
        errorMessage={
          formUtils.errors.password && formUtils.errors.password.message
        }
        formUtils={formUtils}
        label={strings.FORM_INPUT_LABEL_NEW_PASSWORD}
        name="password"
        placeholder={strings.FORM_INPUT_PLACEHOLDER_PASSWORD}
        type="password"
      />
    </InputWrapper>
    <InputWrapper>
      <TextboxValidation
        errorMessage={
          formUtils.errors.passwordConfirmation &&
          formUtils.errors.passwordConfirmation.message
        }
        formUtils={formUtils}
        label={strings.FORM_INPUT_LABEL_CONFIRMATION_PASSWORD}
        name="passwordConfirmation"
        placeholder={strings.FORM_INPUT_PLACEHOLDER_PASSWORD}
        type="password"
      />
    </InputWrapper>
    <FormSubmitButton
      value={strings.ONBOARDING_RESET_PASSWORD_FORM_SUBMIT_BUTTON}
    />
  </OnboardingFormWrapper>
);
export default ResetPasswordScreen;
