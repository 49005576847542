// @flow

import React, { useEffect } from 'react';
import type { Moment } from 'moment';
import isEmpty from 'lodash/isEmpty';

import {
  ContentContainer,
  DatePickerContainer,
  TitleContainer,
  ToolTipContainer,
} from './container';
import {
  arrowDownLoss,
  arrowDownLoss2x,
  arrowDownLoss3x,
  arrowUpProfit,
  arrowUpProfit2x,
  arrowUpProfit3x,
  calendar,
  calendar2x,
  calendar3x,
} from '../../../assets/images';
import Outline from './outline';
import DatePicker from '../../form/date';
import { CalendarIcon, GrowthIcon } from './images';
import { CardTitle2 } from '../../shared/texts/title';
import Tooltip from '../../shared/toolpin/hint-toolpin';
import { CardContent2 } from '../../shared/texts/content';

type Props = {
  containerStyle?: Object,
  handleDateChange?: Function,
  hasLargeDisclaimer?: boolean,
  helpText?: string,
  isCalendarOpen?: boolean,
  isEditable?: boolean,
  maxDate: Moment,
  minDate: Moment,
  setCalendarOpenState?: Function,
  title: string,
  type?: ?'date' | 'increase' | 'decrease',
  value: string,
  keyid: string,
};

const Card = ({
  containerStyle,
  handleDateChange,
  hasLargeDisclaimer,
  helpText,
  isCalendarOpen,
  isEditable,
  maxDate,
  minDate,
  setCalendarOpenState,
  title,
  value,
  type,
  keyid,
}: Props) => {
  const setCalendarPosition = () => {
    if (isCalendarOpen) {
      const calendarDateInput = document
        .getElementById('calendarDateInput')
        .getBoundingClientRect();

      const calendarRect = document.getElementById('dateAsAtCalendar');

      calendarRect.style.top = `${calendarDateInput.top + 40}px`;
      calendarRect.style.display = 'block';
    }
  };

  useEffect(() => {
    setCalendarPosition();
  }, [isCalendarOpen]);

  useEffect(() => {
    window.addEventListener('scroll', setCalendarPosition);

    return () => {
      window.removeEventListener('scroll', setCalendarPosition);
    };
  }, [isCalendarOpen]);

  return (
    <Outline style={containerStyle}>
      <TitleContainer>
        <CardTitle2>{title}</CardTitle2>
        {!isEmpty(helpText) && (
          <ToolTipContainer>
            <Tooltip
              hint={helpText}
              isLargeDisclaimer={hasLargeDisclaimer}
              keyid={keyid}
            />
          </ToolTipContainer>
        )}
      </TitleContainer>

      <ContentContainer
        id="calendarDateInput"
        onClick={() => {
          setCalendarOpenState(true);
        }}
        isEditable={isEditable}
      >
        {type === 'date' && (
          <CalendarIcon
            src={calendar}
            srcSet={`${calendar2x} 2x, ${calendar3x} 3x`}
            alt="calendar"
          />
        )}

        <CardContent2>{value}</CardContent2>

        {type === 'increase' && (
          <GrowthIcon
            src={arrowUpProfit}
            srcSet={`${arrowUpProfit2x} 2x, ${arrowUpProfit3x} 3x`}
            alt="growth_arrow"
          />
        )}

        {type === 'decrease' && (
          <GrowthIcon
            src={arrowDownLoss}
            srcSet={`${arrowDownLoss2x} 2x, ${arrowDownLoss3x} 3x`}
            alt="growth_arrow"
          />
        )}
      </ContentContainer>

      {type === 'date' && isEditable && isCalendarOpen && (
        <DatePickerContainer id="dateAsAtCalendar">
          <DatePicker
            handleDateChange={date => handleDateChange(date)}
            isCalendarOpen={isCalendarOpen}
            isInline
            key="dateAsAtCalendar"
            maxDate={maxDate}
            minDate={minDate}
            setCalendarOpenState={setCalendarOpenState}
            value={value}
          />
        </DatePickerContainer>
      )}
    </Outline>
  );
};

Card.defaultProps = {
  containerStyle: {},
  handleDateChange: () => {},
  hasLargeDisclaimer: false,
  helpText: '',
  isCalendarOpen: false,
  isEditable: false,
  setCalendarOpenState: () => {},
  type: null,
};

export default Card;
