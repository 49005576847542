// @flow

import axios, { CancelToken } from 'axios';

import { isEmpty, isNull } from 'lodash';
import CustomError from '../utils/error';
import FileDownloader from '../utils/downloader';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

let currentSubLevelsSource = null;

const getQueryString = (userInfo, filter, isParent) => {
  const {
    userType,
    userTypeLink,
    producerId,
    searchKey,
    allPolicies,
  } = userInfo;

  let query = '';

  if (userType) {
    query += `userType=${userType}&`;
  }

  if (userTypeLink) {
    query += `userTypeLink=${userTypeLink}&`;
  }

  if (!isParent) {
    query += `shouldRetrieveAllPolicies=${allPolicies ? 'Y' : 'N'}&`;
  }

  if (producerId) {
    query += `producerId=${producerId}&`;
  }

  if (
    searchKey &&
    searchKey.startsWith('agent:') &&
    searchKey.split('agent:')[1]
  ) {
    query += `agent=${searchKey.split('agent:')[1]}&`;
  } else if (searchKey) {
    query += `searchKey=${searchKey}&`;
  }

  return query + filter;
};

const getProducer = async (userInfo, filter, isParent = false) => {
  const { WB_API_CACHE = {} } = window;

  const type = isParent ? 'parent' : 'agents';
  const query = getQueryString(userInfo, filter, isParent).replace(/&$/, '');

  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/producers/levels/${type}/policies/stats?${query}`;

  if (WB_API_CACHE[url] && typeof WB_API_CACHE[url] === 'object') {
    return { data: WB_API_CACHE[url] };
  }

  const response = await axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });

  window.WB_API_CACHE[url] = response.data;

  return response;
};

export const getProducerSubLevels = async (userInfo, filter) => {
  const { WB_API_CACHE = {} } = window;

  if (currentSubLevelsSource) {
    currentSubLevelsSource.cancel();
  }

  const source = CancelToken.source();
  currentSubLevelsSource = source;

  let query = getQueryString(userInfo, filter).replace(/&$/, '');

  if (isNull(userInfo.userTypeLink)) {
    query += `&userTypeLink=null`;
  }

  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL || ''}/producers/sublevels?${query}`;

  if (WB_API_CACHE[url]) {
    return { data: WB_API_CACHE[url] };
  }

  const response = await axios.get(`${url}`, {
    cancelToken: source.token,
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });

  window.WB_API_CACHE[url] = response.data;

  return response;
};

export const getProducerPoliciesCsv = id => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/producers/policies/export/csv?producerId=${id}`;

  return FileDownloader(url, { Accept: REACT_APP_ACCEPT_HEADER_WB });
};

export const getProducerCsv = userInfo => {
  const query = getQueryString(userInfo, '', true);

  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/producers/levels/agents/policies/stats/export/csv?${query}`;

  return FileDownloader(url, { Accept: REACT_APP_ACCEPT_HEADER_WB });
};

export default getProducer;
