import {
  REQUEST_GET_MEDIA,
  SUCCESS_GET_MEDIA,
  FAILURE_GET_MEDIA,
} from '../types/media';

const initialState = {
  errorMessage: '',
  loading: false,
  data: {
    logo: {
      url: '/images/logo.svg',
    },
  },
};

const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_MEDIA:
      return { ...state, loading: true };

    case SUCCESS_GET_MEDIA:
      return { ...state, loading: false, data: action.payload.media };

    case FAILURE_GET_MEDIA:
      return { ...state, loading: false, errorMessage: action.payload.err };

    default:
      return { ...state };
  }
};

export default MediaReducer;
