// @flow

import styled, { css, keyframes } from 'styled-components';
import colors from '../../../config/colors';

const firstOpenKeyframe = keyframes`
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform: translateY(6px) rotate(45deg);
  }
`;

const secondOpenKeyframe = keyframes`
  50% {
    transform: translateY(-6px) rotate(0);
  }
  100% {
    transform: translateY(-6px) rotate(135deg);
  }
`;

const firstCloseKeyFrame = keyframes`
 0% {
    transform: translateY(6px) rotate(45deg);
  }
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform:translateY(0) rotate(0) ;
  }
`;

const secondCloseKeyFrame = keyframes`
  0%{
    transform: translateY(-6px) rotate(135deg);
  }
  50% {
    transform:translateY(-6px) rotate(0) ;
  }
  100% {
    transform:rotate(0) translateY(0);
  }
`;

const firstOpenAnimation = css`
  animation: 0.2s linear ${firstOpenKeyframe} forwards;
`;

const seconOpenAnimation = css`
  animation: 0.2s linear ${secondOpenKeyframe} forwards;
`;

const firstCloseAnimation = css`
  animation: 0.2s linear ${firstCloseKeyFrame} forwards;
`;

const secondCloseAnimation = css`
  animation: 0.2s linear ${secondCloseKeyFrame} forwards;
`;

const StyledBurger = styled.button`
  display: flex;
  margin: 0.5px;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  border: 0;
  background-color: ${colors.cobaltBlue};
  border-radius: 2.7px;
  outline: none;
  cursor: pointer;
  :hover {
    background-color: ${({ animationState }) =>
      animationState ? `${colors.cobaltBlue}` : `${colors.frenchBlue}`};
  }
  div {
    width: 27px;
    outline: none;
    height: 3px;
    margin: 1.5px;
    border-radius: 1.4px;
    background-color: ${colors.white};
    :first-child {
      ${({ animationState }) =>
        animationState === undefined
          ? ''
          : animationState
          ? firstOpenAnimation
          : firstCloseAnimation}
    }
    :nth-child(2) {
      opacity: ${({ animationState }) =>
        animationState === undefined ? '' : animationState ? '0' : '1'};
    }
    :nth-child(3) {
      ${({ animationState }) =>
        animationState === undefined
          ? ''
          : animationState
          ? seconOpenAnimation
          : secondCloseAnimation}
    }
  }
`;

export default StyledBurger;
