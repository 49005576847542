import styled from 'styled-components';

export const PopupContent = styled.div`
  width: 100%;
  max-width: 602px;
  background-color: ${props => props.theme.white};
`;

export const Subject = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.white4};
`;

export const EmptySpace = styled.div`
  height: 250px;
  width: 100%;
`;

export const HintSpace = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const SelectSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
