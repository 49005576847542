import {
  REQUEST_GET_INBOX,
  SUCCESS_GET_INBOX,
  FAILURE_GET_INBOX,
  REQUEST_GET_INBOX_MESSAGES,
  SUCCESS_GET_INBOX_MESSAGES,
  REQUEST_SEND_INBOX_MESSAGE,
  SUCCESS_SEND_INBOX_MESSAGE,
  FAILURE_SEND_INBOX_MESSAGE,
  SUCCESS_CLEAR_INBOX,
  SUCCESS_READ_INBOX,
} from '../types/inbox';

import readInboxConversationAPI from '../../api/inbox/read-conversation';
import sendInboxMessageAPI from '../../api/inbox/send-message';
import getInboxMessagesAPI from '../../api/inbox/get-messages';
import getInboxAPI from '../../api/inbox/get-inbox';

import showToast from '../../utils/toast';
import strings from '../../config/strings';

// #region Inbox Conversations
const requestGetInbox = () => ({ type: REQUEST_GET_INBOX });

const successGetInbox = (inbox, append) => ({
  type: SUCCESS_GET_INBOX,
  payload: { inbox, append },
});

const failureGetInbox = err => ({
  type: FAILURE_GET_INBOX,
  payload: { err },
});

export const getInbox = (searchQuery, page = 1) => async dispatch => {
  if (!searchQuery && page === 1) {
    dispatch(requestGetInbox());
  }

  try {
    const { data } = await getInboxAPI(searchQuery, page);

    dispatch(successGetInbox(data.conversations, page !== 1));
  } catch (error) {
    dispatch(failureGetInbox(error));
    showToast();
  }
};
// #endregion

// #region Get Inbox Messages
const requestGetInboxMessages = conversationId => ({
  type: REQUEST_GET_INBOX_MESSAGES,
  payload: { conversationId },
});

const successGetInboxMessages = (messages, conversationId) => ({
  type: SUCCESS_GET_INBOX_MESSAGES,
  payload: { messages, conversationId },
});

export const getInboxMessages = conversationId => async dispatch => {
  dispatch(requestGetInboxMessages(conversationId));

  try {
    const { data } = await getInboxMessagesAPI(conversationId);

    dispatch(successGetInboxMessages(data.messages, conversationId));
  } catch (error) {
    console.error(error);
  }
};
// #endregion

// #region Send Inbox Messages
const requestSendInboxMessage = () => ({ type: REQUEST_SEND_INBOX_MESSAGE });
const failureSendInboxMessage = () => ({ type: FAILURE_SEND_INBOX_MESSAGE });

const successSendInboxMessage = (message, conversationId) => ({
  type: SUCCESS_SEND_INBOX_MESSAGE,
  payload: { message, conversationId },
});

export const sendInboxMessage = (
  message,
  conversationId,
  subject,
  extra,
) => async dispatch => {
  dispatch(requestSendInboxMessage());

  const msgData = { message, subject, extra };

  try {
    const { data } = await sendInboxMessageAPI(msgData, conversationId);

    dispatch(successSendInboxMessage(data.message, conversationId));
  } catch (error) {
    dispatch(failureSendInboxMessage());
    showToast(strings.SENDING_INBOX_MESSAGE_FAILED);
  }
};
// #endregion

export const clearCurrentInbox = () => ({ type: SUCCESS_CLEAR_INBOX });

export const readInboxConversation = conversationId => {
  readInboxConversationAPI(conversationId);

  return {
    type: SUCCESS_READ_INBOX,
    payload: { conversationId },
  };
};
