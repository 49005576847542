import styled from 'styled-components';

const MainHeaderDiv = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export default MainHeaderDiv;
