import styled from 'styled-components';

const EmptyData = styled.span`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-family: Avenir-Medium;
  color: ${props => props.theme.primaryDark};
  position: absolute;
  top: ${({ size }) => (size === 'large' ? '10%' : '40%')};
  left: ${({ size }) => (size === 'large' ? '10%' : '50%')};
`;

export default EmptyData;
