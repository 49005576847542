import {
  REQUEST_GET_PRIVACY_POLICY,
  SUCCESS_GET_PRIVACY_POLICY,
  FAILURE_GET_PRIVACY_POLICY,
  UPDATE_USER,
} from '../constants/types';

import submitTermsAPI from '../../api/submit-terms';
import getPrivacyPolicyAPI from '../../api/privacy-policy';

const requestGetPrivacyPolicy = () => ({ type: REQUEST_GET_PRIVACY_POLICY });

const successGetPrivacyPolicy = (data) => ({
  type: SUCCESS_GET_PRIVACY_POLICY,
  payload: { data },
});

const failureGetPrivacyPolicy = (err) => ({
  type: FAILURE_GET_PRIVACY_POLICY,
  payload: { err },
});

const getPrivacyPolicy = () => async (dispatch) => {
  dispatch(requestGetPrivacyPolicy());

  try {
    const { data } = await getPrivacyPolicyAPI();

    // filter only sections with titles
    data.sections = data.sections.filter(s => s.title);

    dispatch(successGetPrivacyPolicy(data));
  } catch (error) {
    dispatch(failureGetPrivacyPolicy(error));
  }
};

const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: { user },
})

export const submitTerms = () => async (dispatch) => {
  try {
    const { data } = await submitTermsAPI();

    dispatch(updateUser(data.user));
  } catch (error) {
    // console.error(error);
  }
};

export default getPrivacyPolicy;
