import {
  REQUEST_GET_DOCUMENTS_CONTRACTS,
  SUCCESS_GET_DOCUMENTS_CONTRACTS,
  SET_CURRENT_POLICY_ID,
  REQUEST_GET_DOCUMENTS_NOTICES,
  SUCCESS_GET_DOCUMENTS_NOTICES,
} from '../constants/types';

import getPolicyDocumentsNotices from '../../api/documents-notices';
import getPolicyDocumentsContracts from '../../api/documents-contracts';

const setCurrentPolicyId = currentPolicyId => ({
  type: SET_CURRENT_POLICY_ID,
  payload: { currentPolicyId },
});

// #region Documents Contracts
const requestGetDocumentsContracts = () => ({
  type: REQUEST_GET_DOCUMENTS_CONTRACTS,
});

const successGetDocumentsContracts = contracts => ({
  type: SUCCESS_GET_DOCUMENTS_CONTRACTS,
  payload: { contracts },
});

export const getDocumentsContracts = (policyId, username) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestGetDocumentsContracts());

  try {
    const { data } = await getPolicyDocumentsContracts(policyId, username);

    dispatch(successGetDocumentsContracts(data));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion

// #region Documents Notices
const requestGetDocumentsNotices = () => ({
  type: REQUEST_GET_DOCUMENTS_NOTICES,
});

const successGetDocumentsNotices = notices => ({
  type: SUCCESS_GET_DOCUMENTS_NOTICES,
  payload: { notices },
});

export const getDocumentsNotices = (policyId, username) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestGetDocumentsNotices());

  try {
    const { data } = await getPolicyDocumentsNotices(policyId, username);

    dispatch(successGetDocumentsNotices(data));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion
