// @flow

import React from 'react';
import { isEmpty } from 'lodash';
import { Link, useHistory } from 'react-router-dom';

import strings from '../config/strings';
import EmptyData from '../components/empty-data';
import { eye, emptyPolicy, arrowLeftGrey } from '../assets/images';

import {
  PRODUCER_CUSTOMER_INFORMATION,
  PRODUCER_POLICY_DETAILS,
} from '../router/routes';

import { DownloadIcon as DetailsIcon } from '../components/producer/table/styles';
import Checkbox from '../components/shared/checkbox';
import Spinner from '../components/shared/spinner';
import Table from '../components/shared/table';
import { LinkContainer, ArrowDiv } from './customer-information/styles';
import { BackToSearchLink } from '../components/shared/texts/navigation-links';

type Props = {
  data: any[],
  page: Number,
  loading: boolean,

  agentInfo: any,
  activePolicies: Boolean,

  onLoadMore: Function,
  onChangeAllPolicies: Function,
};

const ProducerPolicies = ({
  data,
  loading,
  agentInfo,
  onLoadMore,
  activePolicies,
  onChangeAllPolicies,
}: Props) => {
  const style = {
    width: 110,
    display: 'block',
    cursor: 'pointer',
    textDecoration: 'none',
  };

  const disabled = {
    cursor: 'initial',
    color: 'grey',
    opacity: 0.3,
  };

  const history = useHistory();

  const producerAgentPolicies = !activePolicies
    ? strings.PRODUCER_AGENT_POLICIES
    : strings.PRODUCER_AGENT_POLICIES.filter(p => p.key !== 'status');

  const LinkAnchor = ({ item, style, children }) => {
    if (item.status !== 'Active') {
      return <span style={style}>{children}</span>;
    }

    return (
      <Link
        style={style}
        to={`${PRODUCER_POLICY_DETAILS.replace(
          ':id',
          item.ownerId,
        )}?policyNumber=${item.policyNumber}`}
      >
        {children}
      </Link>
    );
  };

  const refinedData = data.map(item => ({
    ...item,
    viewDetails: () => (
      <LinkAnchor
        item={item}
        style={{ ...style, ...(item.status === 'Active' ? {} : disabled) }}
      >
        {strings.PRODUCER_TABLE_VIEW_DETAILS}
        <DetailsIcon src={eye} alt="view" height="11" width="19" />
      </LinkAnchor>
    ),
    ownerName: () => (
      <Link
        to={`${PRODUCER_CUSTOMER_INFORMATION.replace(
          ':id',
          item.ownerId,
        )}?customerName=${item.ownerName}`}
        style={style}
      >
        {item.ownerName}
      </Link>
    ),
  }));

  return (
    <>
      <LinkContainer onClick={() => history.goBack()}>
        <ArrowDiv>
          <img src={arrowLeftGrey} alt="arrow left" />
        </ArrowDiv>
        <BackToSearchLink>{strings.PRODUCER_BACK_TO_SEARCH}</BackToSearchLink>
      </LinkContainer>
      <Table
        headerData={strings.PRODUCER_AGENT_INFO}
        contentData={[agentInfo]}
        nomargin
      />
      <Checkbox
        checkboxValue={activePolicies}
        onChangeCheckboxValue={onChangeAllPolicies}
        label={strings.FILTER_SHOW_ACTIVE}
      />
      <Table
        headerData={producerAgentPolicies}
        onLoadMore={onLoadMore}
        contentData={refinedData}
        nomargin
      />
      {loading && <Spinner />}

      {isEmpty(refinedData) && (
        <EmptyData
          title={strings.NO_PRODUCER_POLICIES}
          src={emptyPolicy}
          key="empty_policies"
        />
      )}
    </>
  );
};

export default ProducerPolicies;
