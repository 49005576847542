import {
  SUCCESS_GET_CONTRIBUTION_CHART,
  SUCCESS_GET_CONTRIBUTION_INFO,
  SET_CURRENT_POLICY_ID,
} from '../constants/types';

import getContributionInfoAPI from '../../api/contribution-info';
import getContributionChartAPI from '../../api/contribution-chart';

const setCurrentPolicyId = currentPolicyId => ({
  type: SET_CURRENT_POLICY_ID,
  payload: { currentPolicyId },
});

// #region Contribution Chart
const successGetContributionChart = (chart) => ({
  type: SUCCESS_GET_CONTRIBUTION_CHART,
  payload: { chart },
});

export const getContributionChart = (policyId, type) => async (dispatch) => {
  dispatch(setCurrentPolicyId(policyId));

  try {
    const { data } = await getContributionChartAPI(policyId, type);

    dispatch(successGetContributionChart(data));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion

// #region Contribution Info
const successGetContributionInfo = (info, append) => ({
  type: SUCCESS_GET_CONTRIBUTION_INFO,
  payload: { info, append },
});

export const getContributionInfo = (policyId, page) => async (dispatch) => {
  dispatch(setCurrentPolicyId(policyId));

  try {
    const { data } = await getContributionInfoAPI(policyId, page);

    dispatch(successGetContributionInfo(data, page !== 1));
  } catch (error) {
    // TODO: handle error
  }
};
// #endregion
