// @flow

import React from 'react';

import {
  blockSeparator,
  currency,
  displayIcon,
  FooterClickableRow,
  horizontalDivider,
  inlineText,
  justifyBetween,
  leftOutline,
  leftStatsBlock,
  policyCardBasicInfoGroup,
  rightOutline,
  rightStatsBlock,
  stretch,
  viewMoreLink,
} from '../style';

import { arrowDownLoss, arrowUpProfit } from '../../../assets/images';

import {
  decimalWithCommas,
  formatCurrency,
  isEmptyNumber,
} from '../../../utils/numbers';
import {
  Identification,
  MainContent,
  MainContent2,
} from '../../shared/texts/content';

import ArrowLeft from '../../arrow';
import strings from '../../../config/strings';
import globals from '../../../config/globals';
import BorderlessLink from '../../shared/texts/link';
import HorizontalDottedDivider from '../../shared/divider';
import { LeftOutline, Outline, RightOutline } from './outline';
import { RowContainer, fullWidth } from '../../shared/container';
import { formatDate, formatShortMonthDate } from '../../../utils/date';
import { ContentBlock, ConvertableBlock, GroupBlock } from './container';
import { ProductType, SubTitle, SubTitle2 } from '../../shared/texts/title';
import { getPolicyIcon, isInvestmentTypePolicy } from '../../../utils/policy';

import type { PolicyType } from '../../../models/policies';

type Props = {
  handlePolicyViewMoreClick: Function,
  policy: {
    policyNumber: string,
    policyId: number,
    policyType: PolicyType,
    startDate: string,
    endDate: string,
    insuredName: string,
    insuredDob: string,
    totalContribution: number,
    totalReturnPerc: number,
    totalLoss: number,
    nextInstallmentDate: string,
    nextInstallmentAmount: number,
    currency: string,
    currentPolicyValue: number,
    policyAnniversaryDate: string,
  },
};

const PolicyOverview = ({ handlePolicyViewMoreClick, policy }: Props) => (
  <Outline>
    <ConvertableBlock>
      <LeftOutline>
        <ConvertableBlock>
          <GroupBlock style={policyCardBasicInfoGroup}>
            <ContentBlock style={displayIcon}>
              <img
                style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                src={getPolicyIcon(policy.policyType)}
                alt="policy_overview"
              />
            </ContentBlock>

            <ContentBlock>
              <Identification>
                {strings.POLICY_ID_TITLE} {policy.policyNumber}
              </Identification>

              {policy.policyType && (
                <ProductType>
                  {globals.POLICY_TYPES[policy.policyType].label}
                </ProductType>
              )}
            </ContentBlock>
          </GroupBlock>

          <GroupBlock style={leftOutline}>
            <ContentBlock hasBorder style={leftStatsBlock}>
              <SubTitle>{strings.POLICY_INFO_EFFECTIVE_DATE}</SubTitle>

              <MainContent>{formatDate(policy.startDate)}</MainContent>
            </ContentBlock>

            <ContentBlock style={rightStatsBlock}>
              <SubTitle>{strings.POLICY_INSURED_BY_TITLE}</SubTitle>

              <MainContent>{policy.insuredName}</MainContent>
            </ContentBlock>
          </GroupBlock>
        </ConvertableBlock>

        <HorizontalDottedDivider style={horizontalDivider} />

        <ConvertableBlock>
          <GroupBlock style={rightOutline}>
            <ContentBlock>
              <SubTitle2>{strings.NEXT_PAYMENT}</SubTitle2>
            </ContentBlock>
          </GroupBlock>

          <GroupBlock style={leftOutline}>
            <ContentBlock style={leftStatsBlock}>
              <MainContent2>
                {policy.nextInstallmentAmount !== -1 && strings.DUE_DATE_TITLE}{' '}
                {policy.nextInstallmentAmount !== -1
                  ? formatShortMonthDate(policy.nextInstallmentDate)
                  : strings.PAYMENT_DUE_DATE_UNKNOWN}
              </MainContent2>
            </ContentBlock>

            {policy.nextInstallmentAmount !== -1 && (
              <ContentBlock style={rightStatsBlock}>
                <MainContent2>
                  {strings.DUE_AMOUNT_TITLE}{' '}
                  {!isEmptyNumber(policy.nextInstallmentAmount) &&
                    globals.CURRENCIES[policy.currency].value}{' '}
                  {decimalWithCommas(policy.nextInstallmentAmount)}
                </MainContent2>
              </ContentBlock>
            )}
          </GroupBlock>
        </ConvertableBlock>
      </LeftOutline>

      <RightOutline>
        <GroupBlock style={stretch}>
          <ContentBlock style={blockSeparator}>
            <SubTitle>{strings.TOTAL_CONTRIBUTION_TITLE}</SubTitle>

            <div style={inlineText}>
              <MainContent style={{ ...currency, ...inlineText }}>
                {globals.CURRENCIES[policy.currency].value}{' '}
              </MainContent>{' '}
              <MainContent style={inlineText}>
                {decimalWithCommas(policy.totalContribution)}
              </MainContent>
            </div>
          </ContentBlock>

          <ContentBlock shouldHide={!isInvestmentTypePolicy(policy.policyType)}>
            <SubTitle>{strings.PERFORMANCE_POLICY_PROFIT_LOSS_LABEL}</SubTitle>

            <RowContainer style={{ ...justifyBetween, ...fullWidth }}>
              <div style={inlineText}>
                <MainContent style={{ ...currency, ...inlineText }}>
                  {formatCurrency(policy.currency, policy.totalLoss < 0)}{' '}
                </MainContent>{' '}
                <MainContent style={inlineText}>
                  {decimalWithCommas(policy.totalLoss, true)}
                </MainContent>
              </div>
              <img
                src={policy.totalReturnPerc < 0 ? arrowDownLoss : arrowUpProfit}
                alt="arrow_up_down"
              />
            </RowContainer>
          </ContentBlock>
        </GroupBlock>

        <HorizontalDottedDivider style={horizontalDivider} />

        <ContentBlock>
          <FooterClickableRow
            onClick={() => handlePolicyViewMoreClick(policy.policyNumber)}
            onKeyPress={event => {
              if (event.key === 'Enter')
                handlePolicyViewMoreClick(policy.policyNumber);
            }}
            role="button"
            tabIndex={0}
          >
            <BorderlessLink style={viewMoreLink}>
              {strings.POLICY_VIEW_MORE_LINK}
            </BorderlessLink>
            <ArrowLeft />
          </FooterClickableRow>
        </ContentBlock>
      </RightOutline>
    </ConvertableBlock>
  </Outline>
);

export default PolicyOverview;
