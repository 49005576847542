// @flow

import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import 'chartjs-plugin-lineheight-annotation';

import {
  tooltipStyle,
  chartGridLinesStyle,
  yAxisTickStyle,
  xAxisTickStyle,
  lineHeightAnnotationStyle,
} from './styles';
import EmptyData from './empty-data';
import colors from '../../config/colors';
import { formatDate } from '../../utils/date';
import { decimalWithCommas, numberWithCommas } from '../../utils/numbers';

type PointStyle = { color: string, shape: string };

type Props = {
  chartPointsStyle: Array<PointStyle>,
  data: Array<string | number>,
  data2: Array<string | number>,
  data3: Array<string | number>,
  data4: Array<string | number>,
  dataName: string,
  data3Name: string,
  data4Name: string,
  errorMessage: string,
  labels: Array<string>,
  symbol: string,
  tooltipLabels: Array<string>,
};

const getEdgeValuesOfMultipleArrays = (arraysObj: Object) => {
  const arraysGroup = Array.prototype.concat
    .apply([], Object.values(arraysObj))
    .filter(Boolean);

  const maxVal = arraysGroup ? Math.max(...arraysGroup) : 0;
  const minVal = arraysGroup ? Math.min(...arraysGroup) : 0;

  return { maxVal, minVal };
};

const customTooltips = function(tooltip) {
  // Tooltip Element
  let tooltipEl = document.getElementById('tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'tooltip';
    tooltipEl.innerHTML = '<div></div>';
    this._chart.canvas.parentNode.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(getBody);

    let innerHtml = '<div>';

    titleLines.forEach(title => {
      const style = `background:${tooltip.backgroundColor}`;
      tooltipEl.style.fontFamily = `${tooltip.titleFontFamily}`;
      tooltipEl.style.fontSize = `${tooltip.titleFontSize}px`;
      tooltipEl.style.fontStyle = `${tooltip.titleFontStyle}`;
      tooltipEl.style.color = `${tooltip.titleFontColor}`;
      tooltipEl.style.textAlign = `${tooltip.titleAlign}`;
      tooltipEl.style.marginBottom = `${tooltip.titleMarginBottom}`;
      tooltipEl.style.wordSpacing = `${tooltip.titleSpacing}`;

      const span = `<span class="chartjs-tooltip-key" style="${style}"></span>`;

      innerHtml += `<div><div>${span}${title}</div></div>`;
    });

    innerHtml += '</div><div>';

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];
      let style = `background:${colors.backgroundColor}`;
      style += `; border-color:${colors.borderColor}`;
      style += '; border-width: 2px';
      const span = `<span class="chartjs-tooltip-key" style="${style}"></span>`;
      const innerContent = `<div>${span}${body}</div>`;

      // Every even/odd create a new div
      if (i % 2 == 0) innerHtml += `<div>${innerContent}`;
      else innerHtml += `${innerContent}</div>`;
    });

    // If is a odd number of itens close the last open div
    if (bodyLines.count % 2 == 1) innerHtml += '</div></div>';
    else innerHtml += '</div>';

    const tableRoot = tooltipEl.querySelector('div');
    tableRoot.innerHTML = innerHtml;
  }

  const positionY = this._chart.canvas.offsetTop;
  const positionX = this._chart.canvas.offsetLeft;

  // let offset = tooltip.caretX + 0;
  let offset = tooltip.caretX + 20;
  let offsetY = tooltip.caretY + 20;

  if (offset < tooltip.width) {
    offset = tooltip.width;
  } else if (tooltip.caretX > this._chart.width - tooltip.width) {
    offset = this._chart.width - tooltip.width;
  }

  if (offsetY < tooltip.height) offsetY = tooltip.height;
  else if (tooltip.caretY > this._chart.height - tooltip.height)
    offsetY = this._chart.height - tooltip.height - 20;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + offsetY}px`;
  tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
  tooltipEl.style.fontSize = `${tooltip.bodyFontSize}px`;
  tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
  tooltipEl.style.color = tooltip.bodyFontColor;
  tooltipEl.style.textAlign = tooltip.bodyAlign;
  tooltipEl.style.padding = `${tooltip.yPadding}px ${tooltip.xPadding}px`;
  tooltipEl.style.boxShadow = `${tooltip.shadowOffsetX}px ${tooltip.shadowOffsetY}px ${tooltip.shadowBlur}px ${tooltip.shadowBlur}`;
  tooltipEl.style.backgroundColor = `${tooltip.backgroundColor}`;
  tooltipEl.style.wordSpacing = `${tooltip.bodySpacing}`;
  tooltipEl.style.borderRadius = `${tooltip.cornerRadius}`;
  tooltipEl.style.width = '100%';
};

const LineChart = ({
  chartPointsStyle,
  data,
  data2,
  data3,
  data4,
  errorMessage,
  labels,
  dataName,
  data3Name,
  data4Name,
  symbol,
  tooltipLabels,
}: Props) => (
  <Fragment>
    <Line
      data={{
        labels,
        datasets: [
          {
            data: data2,
            showLine: false,
            pointRadius: 0,
          },
          {
            label: dataName,
            data,
          },
          {
            label: data3Name,
            data: data3,
            showLine: false,
            pointRadius: 0,
          },
          {
            label: data4Name,
            data: data4,
            borderColor: colors.warning,
            pointRadius: 0,
          },
        ],
      }}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        legend: { display: false },
        tooltips: {
          ...tooltipStyle,
          enabled: false,
          mode: 'x-axis',
          custom: customTooltips,
          callbacks: {
            title: tooltipItem => formatDate(tooltipItem[0].xLabel),
            label: (tooltipItem, dataArr) => {
              const datasetLabel =
                dataArr.datasets[tooltipItem.datasetIndex].label ||
                tooltipLabels[tooltipItem.index];

              const tooltipLabel =
                tooltipItem.datasetIndex !== 0
                  ? `${datasetLabel}: ${symbol} ${decimalWithCommas(
                      tooltipItem.yLabel,
                    )}`
                  : `${datasetLabel}: ${decimalWithCommas(
                      tooltipItem.yLabel,
                    )} Units`;

              return tooltipLabel;
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        lineHeightAnnotation: {
          always: false,
          hover: true,
          ...lineHeightAnnotationStyle,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: { ...xAxisTickStyle, maxRotation: 20 },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                ...chartGridLinesStyle,
              },
              ticks: {
                ...yAxisTickStyle,
                suggestedMin: 0,
                callback: value => numberWithCommas(value),
                max:
                  Math.ceil(
                    getEdgeValuesOfMultipleArrays({
                      data,
                      data4,
                    }).maxVal / 10,
                  ) * 10,
                min:
                  Math.floor(
                    getEdgeValuesOfMultipleArrays({
                      data,
                      data4,
                    }).minVal / 10,
                  ) * 10,
              },
            },
          ],
        },
        elements: {
          line: { fill: false, borderColor: colors.primary, borderWidth: 2.5 },
          point: {
            pointStyle: chartPointsStyle.map(style => style.shape),
            backgroundColor: chartPointsStyle.map(style => style.color),
            radius: 5,
            hoverRadius: 5,
            borderColor: chartPointsStyle.map(style => style.color),
          },
        },
      }}
    />

    {isEmpty(data) && <EmptyData size="large">{errorMessage}</EmptyData>}
  </Fragment>
);

export default LineChart;
