// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strings from '../config/strings';
import Groupby from '../utils/groupby';
import getDocument from '../api/documents';
import LibraryScreen from '../pages/library';
import Spinner from '../components/shared/spinner';
import { getLibrary } from '../redux/actions/library';
import { ImageExtractor, FileViewer } from '../utils/downloader';

const Library = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(state => state.library);

  useEffect(() => {
    dispatch(getLibrary());

    // special case library root must hide overflowing carousels
    const rootEl = document.getElementById('root');
    const header = document.getElementById('main-header');

    rootEl.style.overflow = 'hidden';
    header.style.position = 'fixed';

    return () => {
      rootEl.style = undefined;
      header.style = undefined;
    };
  }, []);

  const getDocumentImage = async (
    name: string,
    path: string,
    imgId: string,
  ) => {
    const response = await getDocument(name, path);
    const image = ImageExtractor(response.data, imgId);

    return image;
  };

  const formattedData = data.map(el => {
    const info = { ...el };

    if (el.topic === 'Video library') {
      info.documentPath = `https://www.youtube.com/embed/${el.documentPath}?autoplay=1`;
      info.yt = `https://www.youtube.com/embed/${el.documentPath}?autoplay=1`;
      info.buttonText = 'Watch video';
    } else if (el.topic === 'Articles') {
      info.buttonText = strings.ARTICLE_READ_BUTTON;
    } else if (el.topic === 'Brochures') {
      info.buttonText = strings.READ_MORE;
    } else if (el.topic === 'Useful links and websites') {
      info.buttonText = strings.VIEW_LINK;
    }

    return info;
  });

  const groupedData = Groupby(formattedData, 'topic');

  const handleViewArticleButtonClick = (name: string, path: string) => {
    const {
      REACT_APP_WB_BACKEND_URL,
      REACT_APP_ACCEPT_HEADER_WB,
    } = process.env;
    const params = { name, path };
    const url = `${REACT_APP_WB_BACKEND_URL || ''}/documents`;
    const headers = {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    };

    return FileViewer(url, headers, params);
  };

  const renderContent = () => (
    <LibraryScreen
      sections={groupedData}
      getDocumentImage={getDocumentImage}
      handleViewArticleButtonClick={handleViewArticleButtonClick}
    />
  );

  return loading ? <Spinner /> : renderContent();
};

export default Library;
