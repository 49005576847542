import styled from 'styled-components';
import {
  ConvertableRowToColContainer,
  RowContainer as RowContainerStyle,
} from '../shared/container';
import { size } from '../../config/device';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${({ centerClause }) => (centerClause ? 'width:100%' : '')}
`;

export const CardContainer = styled(ConvertableRowToColContainer)`
  background-color: ${props =>
    props.empty ? props.theme.paleGrey3 : props.theme.white};
  box-sizing: border-box;
  justify-content: space-between;
  border: 1px ${props => (props.empty ? `dashed` : `solid`)}
    ${props => props.theme.paleGrey3};
  border-radius: 3px;
  padding: 13px 15px;
  margin-bottom: 10px;
  flex-wrap: nowrap;

  @media (max-width: ${size.tablet}) {
    padding: 15px;
    align-items: flex-start;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: ${props => props.theme.cobaltBlue10};
  margin-right: 13px;
  align-self: center;

  @media (max-width: ${size.tablet}) {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
`;

export const DocumentDiv = styled.div`
  flex-direction: column;
  display: flex;
  width: 23px;
  align-self: center;
  height: 23px;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-right: 20px;
  min-width: 300px;

  flex: 8;

  ${({ centerClause }) => (centerClause ? 'margin: 0;width:100%' : '')}
`;

export const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

export const ViewDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  margin-right: 8%;
`;

export const ViewLogo = styled.div`
  display: flex;
  flex-direction: column;
  width: 19px;
  height: 11px;
  align-self: center;
  margin-left: 10px;
`;

export const DownloadLogo = styled.div`
  display: flex;
  flex-direction: row;
  transform: scale(0.45);
`;

export const DownloadDiv = styled(RowContainerStyle)`
  justify-content: space-between;
`;

export const GroupBlock = styled(RowContainer)`
  flex: ${({ isSubtitle }) => (isSubtitle ? 2 : 'unset')};
  margin-right: 15px;

  @media (max-width: ${size.tablet}) {
    margin-left: 45px;
    margin-top: ${({ isSubtitle }) => (isSubtitle ? '-1%' : '22px')};
  }
`;
