import React from 'react';
import { successIcon, successIcon2x, successIcon3x } from '../../assets/images';
import { Outline } from './styles';

const Success = () => (
  <Outline>
    <img
      src={successIcon}
      srcSet={`${successIcon2x} 2x, ${successIcon3x} 3x`}
      alt="success"
    />
  </Outline>
);

export default Success;
