// @flow

import React from 'react';
import VerticalBarChart from '../charts/bar-vertical';

import strings from '../../config/strings';
import ContributionDropdown from './dropdown';
import { Container, ScrollableContainer, ChartContainer } from './styles';

type Props = {
  currency: String,
  contributions: any[],
  showDropdown: Boolean,
  onChangeContributionType: Function,
};

const ContributionChart = ({
  contributions = [],
  currency = 'USD',
  onChangeContributionType,
  showDropdown,
}: Props) => (
  <Container>
    <ContributionDropdown {...{ showDropdown, onChangeContributionType }} />
    <ScrollableContainer>
      <ChartContainer>
        <VerticalBarChart
          spacing={60}
          currency={currency}
          data={contributions.map(c => c.value)}
          labels={contributions.map(c => c.label)}
          contributionName={strings.CONTRIBUTIONS_POPUP}
        />
      </ChartContainer>
    </ScrollableContainer>
  </Container>
);

export default ContributionChart;
