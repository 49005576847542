// @flow

import { useEffect } from 'react';
import type { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPerformanceByPolicyId,
  getRiskReturnPerformanceByPolicyId,
} from '../redux/actions';
import { isEmptyNumber } from '../utils/numbers';

export const usePerformance = (
  policyId: number,
  dateAsAt: Moment,
  currencyId: number,
  shouldUpdate: boolean,
) => {
  const dispatch = useDispatch();

  const performanceAllocations = useSelector(
    state => state.performance.portfolioAllocations,
  );

  useEffect(() => {
    if (shouldUpdate && policyId && currencyId) {
      dispatch(getPerformanceByPolicyId(policyId, dateAsAt, currencyId));
    }
  }, [policyId, dateAsAt, shouldUpdate, currencyId]);

  return performanceAllocations;
};

export const useRiskReturnPerformance = (
  policyId: number,
  dateAsAt: Moment,
  shouldUpdate: boolean,
) => {
  const dispatch = useDispatch();

  const polId = useSelector(state => state.policies.currentPolicyId);
  const riskReturnPerformance = useSelector(
    state => state.performance.riskReturn,
  );

  useEffect(() => {
    if (
      (!isEmptyNumber(policyId) &&
        !isEmptyNumber(polId) &&
        policyId !== polId) ||
      (!isEmptyNumber(policyId) && !isEmptyNumber(polId) && shouldUpdate)
    ) {
      dispatch(getRiskReturnPerformanceByPolicyId(policyId, dateAsAt));
    }
  }, [policyId, dateAsAt, shouldUpdate]);

  return riskReturnPerformance;
};
