const colors = Object.freeze({
  // local color keys
  barneyPurple: '#9600bd',
  barneyPurple20: '#d5b7dc',
  battleshipGrey: '#797b7f',
  blueBerry20: 'rgba(65, 66, 154, 0.2)',
  blueBlue: '#1d38d1',
  cobaltBlue: ' #031892',
  cobaltBlue07: 'rgba(3, 24, 146, 0.07)',
  cobaltBlue10: 'rgba(0, 23, 145, 0.1)',

  dusk: '#535386',
  duskBlue: '#2a3282',
  frenchBlue: '#606DB7',
  lightBlueGrey80: 'rgba(210, 213, 229, 0.8)',
  lightPeriWinkle15: 'rgba(203, 210, 255, 0.15)',
  nightBlue: '#020b41',
  paleGrey2: '#e4e6ef',
  paleGrey3: '#e8ecee',
  paleGrey70: 'rgba(228, 230, 239, 0.7)',
  slateGrey10: 'rgba(80, 80, 85, 0.1)',
  slateGrey65: 'rgba(80, 80, 85, 0.65)',
  slateGrey90: 'rgba(80, 80, 85, 0.90)',
  warmGrey: '#979797',
  white2: '#fbfbfb',
  white3: '#dedede',
  white4: '#e1e1e1',

  // cms color keys
  danger: '#d56655',
  info: '#41429a',
  paleGrey: '#f5f6f7',
  paleGreyDark: '#e4e6ef',
  primary: '#001791',
  primaryDark: '#020b41',
  secondary: '#9200b8',
  secondaryDark: '#89489b',
  slateGrey: '#505055',
  success: '#7cc365',
  warning: '#fcb614',
  white: '#ffffff',
  whiteDark: '#fbfbfb',
  whiteDarker: '#dedede',
  whitishGrey: '#d9d9d9',
});

export const colorPalette = [
  'rgb(143, 209, 204, 0.8)',
  'rgb(137, 72, 155, 0.8)',
  'rgb(252, 182, 20, 0.8)',
  'rgb(65, 66, 154, 0.8)',
  'rgb(243, 133, 146, 0.8)',
  'rgb(146, 0, 184, 0.8)',
  'rgb(42, 50, 130, 0.8)',
  'rgb(124, 195, 101, 0.8)',
  'rgb(80, 80, 85, 0.8)',
  'rgb(252, 223, 221, 0.8)',
  'rgb(2, 11, 65, 0.8)',
  'rgb(83, 83, 134, 0.8)',
  'rgb(0, 23, 145, 0.8)',
  'rgb(161,218,209, 0.8)',
  'rgb(213, 102, 85, 0.8)',
];

export default colors;
