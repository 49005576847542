import styled from 'styled-components';
import { size } from '../../../config/device';
import { CardContent } from '../texts/content';
import { InformationTitle } from '../texts/title';

export const TextLabel = styled(InformationTitle)`
  white-space: pre;

  @media (max-width: ${size.tablet}) {
    white-space: unset;
  }
`;

export const TextValue = styled(CardContent)`
  text-align: right;
  margin-left: 10px;
`;
