import React from 'react';
import LibrarySection from '../components/library/section';

const Library = ({
  sections,
  getDocumentImage,
  handleViewArticleButtonClick,
}) =>
  sections.map(section => (
    <LibrarySection
      key={section.topic}
      title={section.topic}
      data={section.data}
      getDocumentImage={getDocumentImage}
      handleViewArticleButtonClick={handleViewArticleButtonClick}
    />
  ));
export default Library;
