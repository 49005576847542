import {
  REQUEST_GET_THEME,
  SUCCESS_GET_THEME,
  FAILURE_GET_THEME,
  SUCCESS_GET_FOOTER,
} from '../constants/types';

import defaultColors from '../../config/colors';

const initialState = {
  colors: defaultColors,
  footer: {},
};

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_THEME:
      return { ...state, loading: true };

    case SUCCESS_GET_THEME:
      return { ...state, loading: false, colors: { ...state.colors, ...action.payload.colors } };

    case FAILURE_GET_THEME:
      return { ...state, loading: false };

    case SUCCESS_GET_FOOTER:
      return { ...state, footer: action.payload.footer };

    default:
      return { ...state };
  }
};

export default ThemeReducer;
