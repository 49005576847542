// @flow

import type { Moment } from 'moment';

import {
  SET_CURRENT_POLICY_ID,
  REQUEST_PERFORMANCE_DATA,
  REQUEST_RISK_RETURN_PERFORMANCE,
  SUCCESS_PERFORMANCE_DATA,
  SUCCESS_RISK_RETURN_PERFORMANCE,
} from '../constants/types';
import { getPerformance, getRiskReturnPerformance } from '../../api';

const setCurrentPolicyId = currentPolicyId => ({
  type: SET_CURRENT_POLICY_ID,
  payload: { currentPolicyId },
});

const requestPerformance = () => ({ type: REQUEST_PERFORMANCE_DATA });

const requestRiskReturnPerformance = () => ({
  type: REQUEST_RISK_RETURN_PERFORMANCE,
});

const successPerformance = portfolioAllocations => ({
  type: SUCCESS_PERFORMANCE_DATA,
  payload: { portfolioAllocations },
});

const successRiskReturnPerformance = (data: Object) => ({
  type: SUCCESS_RISK_RETURN_PERFORMANCE,
  payload: { riskReturn: data },
});

export const getPerformanceByPolicyId = (
  id,
  dateAsAt,
  currencyId,
) => async dispatch => {
  dispatch(setCurrentPolicyId(id));
  dispatch(requestPerformance());

  try {
    const res = await getPerformance(id, dateAsAt, currencyId);
    const performancedata = res.data;

    dispatch(successPerformance(performancedata));
  } catch (error) {
    // TODO: handle error
  }
};

export const getRiskReturnPerformanceByPolicyId = (
  id: number,
  dateAsAt: Moment,
) => async dispatch => {
  dispatch(setCurrentPolicyId(id));
  dispatch(requestRiskReturnPerformance());

  try {
    const { data } = await getRiskReturnPerformance(id, dateAsAt);

    dispatch(successRiskReturnPerformance(data));
  } catch (error) {
    // TODO: handle error
  }
};
