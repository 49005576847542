export const defaultData = {
  requested: false,
  loading: false,
  data: [],
};

export const loadingData = {
  requested: false,
  loading: true,
  data: [],
};

export const defaultDataObject = {
  requested: false,
  loading: false,
  data: {},
};

export const loadingDataObject = {
  requested: false,
  loading: true,
  data: {},
};
