// @flow

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContributionChart, getContributionInfo } from '../redux/actions/contribution';

export const useContributionChart = (policyId: string, type: 'TOTAL' | 'REGULAR' | 'ADHOC') => {
  const dispatch = useDispatch();

  const { loading, data } = useSelector(
    state => state.contribution.chart,
  );

  useEffect(() => {
    if (policyId && type) {
      dispatch(getContributionChart(policyId, type));
    }
  }, [policyId, type]);

  return { loading, data };
};

export const useContributionInfo = (policyId: string, page: number) => {
  const dispatch = useDispatch();

  const { requested, data } = useSelector(state => state.contribution.info);

  useEffect(() => {
    if (policyId && page) {
      dispatch(getContributionInfo(policyId, page));
    }
  }, [policyId, page]);

  return { loading: !requested, data };
};
