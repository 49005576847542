// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../../utils/error';

const { REACT_APP_CMS_URL } = process.env;

const getNotifications = () => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_CMS_URL ||
    ''}/notifications?_sort=created_at:DESC&_limit=10`;
  return axios.get(url);
};

export default getNotifications;
