import styled from 'styled-components';
import device from '../../../config/device';

export const Button = styled.div`
  width: 250px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  height: ${props => props.size}px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};

  ${({ inverted, theme }) => {
    if (!inverted) {
      return '';
    }

    return `
      background-color: ${theme.cobaltBlue10};
      color: ${theme.primary};
    `;
  }}

  ${({ type }) =>
    type === 'square' &&
    `
      width: 35px;
      height: 35px;
    `};

  @media ${device.mobileL} {
    width: ${({ type }) => type !== 'square' && '166px'};
  }
`;

export const Icon = styled.img`
  position: relative;
  margin-right: 10px;
  display: none;
  width: 18px;
  top: 1px;

  @media ${device.mobileL} {
    display: initial;
  }
`;

export const Text = styled.span`
  line-height: ${props => props.size}px;
  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: ${props => props.size}px;
  color: ${({ inverted, theme }) => (inverted ? theme.primary : theme.white)};
`;
