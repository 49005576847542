import styled from 'styled-components';
import { ErrorText } from '../shared/texts/content';

const ErrorLabel = styled(ErrorText)`
  opacity: 0;
  max-height: 0;
  transition: all 0.8s;

  ${({ hasError }) =>
    hasError &&
    `
    opacity:  1;
    max-height: 50px;
    transform: translateY(10px);
    `}
`;

export default ErrorLabel;
