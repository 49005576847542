import React from 'react';
import axios from 'axios';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from './serviceWorker';

import './index.css';
import App from './App';
import reducers from './redux/reducers';

import { LOGIN } from './router/routes';
import { logoutUser } from './redux/actions/user';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(reducers);
const persistor = persistStore(store);
const { dispatch } = store;

const logout = () => {
  dispatch(logoutUser());

  setTimeout(() => {
    window.location = LOGIN;
  }, 200);
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App onIdle={logout} />
    </PersistGate>
  </Provider>,
  document.getElementById('root'));

// Add a 401 response interceptor
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401 && window.location !== LOGIN) {
    logout();
  }
  return Promise.reject(error);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
