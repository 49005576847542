import styled from 'styled-components';
import device, { size } from '../../config/device';
import { ConvertableRowToColContainer } from '../shared/container';

const cardVW = 38;

export const Container = styled.div`
  position: relative;

  &:first-child {
    padding-top: 150px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 91%;
    left: -7vw;
    top: 8.5%;
    background-image: linear-gradient(to right, ${props => props.theme.paleGrey}, transparent);
    width: 8vw;
    z-index: 100;
  }

  &:after {
    content: '';
    position: absolute;
    height: 91%;
    right: -11vw;
    top: 8.5%;
    background-image: linear-gradient(to right, transparent, ${props => props.theme.paleGrey});
    width: 16vw;
  }

  @media ${device.laptopL} {
    &:before {
      left: -11vw;
      width: 11vw;
    }
  }

  @media ${device.desktop} {
    &:before {
      left: -20vw;
      width: 30vw;
    }

    &:after {
      right: -20vw;
      width: 30vw;
    }
  }
`;

export const CardsCarousel = styled(ConvertableRowToColContainer)`
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-top: 30px;
  margin-bottom: 60px;
  position: relative;

  transition: transform 0.5s;

  ${({ scrollRight }) =>
    `transform: translateX(calc(${-scrollRight * cardVW}vw - ${20 *
      scrollRight}px));`}

  @media (max-width: ${size.tablet}){
    flex-wrap:wrap;
    transform: translateX(0)
  }
`;

export const CarouselArrow = styled.div`
  position: absolute;
  top: 55%;
  left: -50px;
  right: -50px;
  display: flex;
  z-index: 100;
  justify-content: space-between;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;

export const CardLayout = styled.div`
  width: ${cardVW}vw;
  max-width: 600px;
  flex-shrink: 0;
  border-radius: 3px;
  margin-right: 20px;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  background-color: ${props => props.theme.white};

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${size.tablet}) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const Info = styled.div`
  padding: 40px 30px;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const Content = styled.p`
  height: 7rem;
  overflow: hidden;
  margin: 24px 0 38px 0;
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  font-family: Avenir-Roman;
  color: ${props => props.theme.slateGrey};

  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: 1000px) {
    height: 7rem;
  }

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
  }
`;

export const Title = styled.h5`
  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.17px;
  color: ${props => props.theme.primaryDark};
  margin: 0;

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
    letter-spacing: 0.14px;
  }
`;

export const Button = styled.div`
  height: 45px;
  cursor: pointer;
  line-height: 2.7;
  border-radius: 3px;
  text-align: center;
  background-color: ${props => props.theme.primary};
  transition: background-color 0.1s;
  font-family: Avenir-Medium;

  &:hover {
    background-color: #d6e2f8;
  }

  &:hover > span {
    color: ${props => props.theme.primary};
  }
`;

export const ButtonText = styled.span`
  font-family: Avenir-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${props => props.theme.white};

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
    line-height: 19.2px;
  }
`;
