import styled from 'styled-components';
import { size } from '../../../config/device';

export const MainContainer = styled.div`
  margin-bottom: ${({ nomargin }) => (nomargin ? 0 : 40)}px;
  overflow-x: auto;

  -ms-overflow-style: ${({ contentLength, shouldShowCustomOverflow }) =>
    (contentLength === 0 || !shouldShowCustomOverflow) && 'none'};
  ::-webkit-scrollbar {
    display: ${({ contentLength, shouldShowCustomOverflow }) =>
      (contentLength === 0 || !shouldShowCustomOverflow) && 'none'};
  }

  @media (max-width: ${size.tablet}) {
    margin-right: calc(-1 * (100% - 85.76%) / 2);
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0;
  overflow-x: hidden;

  tr {
    th,
    td {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }

  tr > td {
    ${({ customStyle }) =>
      customStyle &&
      customStyle.colAlign &&
      customStyle.colAlign.map(
        elt => `
      &:nth-child(${elt.col}) {
        text-align: ${elt.value};
      }
     `,
      )}
  }

  tr > th {
    ${({ customStyle }) => {
      let extraStyle = '';

      extraStyle +=
        customStyle && customStyle.headerAlign
          ? customStyle.headerAlign.map(
              elt => `
        &:nth-child(${elt.col}) {
          text-align: ${elt.value};
        }
       `,
            )
          : '';

      extraStyle +=
        customStyle && customStyle.colWidth
          ? customStyle.colWidth.map(
              elt => `
      &:nth-child(${elt.col}) {
        width: ${elt.value};
      }
     `,
            )
          : '';

      return extraStyle;
    }}
  }

  thead {
    tr {
      th {
        opacity: 0.9;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: 0.12px;
        color: ${props => props.theme.slateGrey};
        font-family: Avenir-Medium;
        padding: ${({ shouldScrollInside }) =>
          shouldScrollInside ? '10px 15px' : '10px 30px'};

        @media (max-width: ${size.tablet}) {
          font-size: 10.4px;
          letter-spacing: 0.1px;
        }
      }
    }
  }

  tbody {
    tr {
      background-color: ${props => props.theme.white};

      td {
        padding: 20px 30px;
        color: ${props => props.theme.primaryDark};
        min-width: 75px;
        font-family: Avenir-Roman;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.05px;
        text-align: right;

        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-left: ${props => props.rowBorderStyle && props.rowBorderStyle};

          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: -0.06px;
          font-family: Avenir-Medium;

          @media (max-width: ${size.tablet}) {
            font-size: 12.8px;
            letter-spacing: -0.05px;
          }
        }

        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }

  ${({ shouldScrollInside }) =>
    shouldScrollInside &&
    `
        thead {
          display: block;
        }

        tbody {
            display: block;
            max-height: 50vh;
            overflow-y: scroll;
            -ms-overflow-style: none;
            ::-webkit-scrollbar {
              display: none;
            }
        }
    `}

  p,
  a {
    color: ${props => props.theme.primaryDark};
    font-family: Avenir-Roman;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.05px;
  }

  a {
    text-decoration: underline;
  }
`;

export const Spacer = styled.tr`
  height: 10px;
`;

export const CustomRow = styled.tr`
  td {
    border: none !important;
    color: ${props => props.theme.white} !important;
    background-color: ${props => props.theme.primary};

    p {
      color: ${props => props.theme.white};
    }
  }
`;

export const ScrollableLinksContainer = styled.div`
  overflow-y: auto;
  width: calc(100% + 50px * 2);
  height: 100%;
  margin: 30px -50px 0 -50px;

  @media (max-width: ${size.tablet}) {
    width: calc(100% + 40px * 2);
    margin: 30px -40px 0 -40px;
  }
`;

export const TableRow = styled.tr`
  td {
    border-bottom: 1px solid #e1e1e1;
    position: relative;

    &:last-child {
      border: none;

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: 30px;
        height: 1px;
        background: #e1e1e1;
        width: 100%;
      }
    }

    &:first-child {
      border: none;

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 30px;
        height: 1px;
        background: #e1e1e1;
        width: 100%;
      }
    }
  }

  &:nth-last-child(2) {
    td {
      border: none !important;

      &:after {
        content: none !important;
      }
    }
  }

  ${({ hasExtraRow }) =>
    hasExtraRow &&
    `
  &:nth-last-child(5) {
    td {
      border: none;

      &:after {
        display: none;
      }
    }
  }
  `}
`;
