// @flow

import React from 'react';
import { emptyEncashment } from '../../assets/images';
import { NoDataTitle, NoDataSubtitle } from '../shared/texts/content';
import { MainDiv, ImageDiv } from './styles';

type Props = {
  src?: string,
  style: object,
  subtitle: string,
  title: string,
};

const EmptyData = ({ src, style, title, subtitle }: Props) => (
  <MainDiv style={style}>
    <ImageDiv>
      <img src={src} alt="empty data" />
    </ImageDiv>

    <NoDataTitle>{title}</NoDataTitle>
    <NoDataSubtitle>{subtitle}</NoDataSubtitle>
  </MainDiv>
);

EmptyData.defaultProps = {
  src: emptyEncashment,
};

export default EmptyData;
