import { get } from 'axios';

import {
  SUCCESS_GET_THEME,
  REQUEST_GET_THEME,
  FAILURE_GET_THEME,
  SUCCESS_GET_FOOTER,
} from '../constants/types';

const { REACT_APP_CMS_URL } = process.env;

const requestGetTheme = () => ({ type: REQUEST_GET_THEME });

const successGetTheme = (colors) => ({
  type: SUCCESS_GET_THEME,
  payload: { colors },
});

const failureGetTheme = (err) => ({
  type: FAILURE_GET_THEME,
  payload: { err },
});

const successGetFooter = (footer) => ({
  type: SUCCESS_GET_FOOTER,
  payload: { footer },
});

export const getTheme = () => (
  async (dispatch) => {
    dispatch(requestGetTheme());

    try {
      const { data: cmsColors } = await get(`${REACT_APP_CMS_URL}/theme`);
      const colors = {};

      Object.entries(cmsColors).forEach(([key, val]) => {
        if (key.includes('color_') && val && val.value) {
          const colorKey = key.split('color_')[1];
          colors[colorKey] = val.value;
        }
      });

      dispatch(successGetTheme(colors));
    } catch (error) {
      dispatch(failureGetTheme(error));
    }
  }
);

export const getFooter = () => (
  async (dispatch) => {
    try {
      const { data: footer } = await get(`${REACT_APP_CMS_URL}/footer`);
      dispatch(successGetFooter(footer));
    } catch (error) {
      console.error(error);
    }
  }
);
