import styled from 'styled-components';

export const Line1 = styled.div`
  width: 10px;
  height: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 2px;
  background: ${props => props.color || props.theme.blueBlue};
  transform: rotate(-45deg);
  transform-origin: right bottom;
`;

export const Line2 = styled.div`
  width: 10px;
  height: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  background: ${props => props.color || props.theme.blueBlue};
  transform: rotate(45deg);
  transform-origin: right top;
`;
