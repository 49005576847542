// @flow

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import type { Moment } from 'moment';

import globals from '../config/globals';
import { PerformanceScreen } from '../pages';
import { usePerformance, usePolicies, usePolicyBasicInfo } from '../hooks';

type Props = {
  policyId: number,
  customerId: number,
};

const Performance = ({ policyId, customerId }: Props) => {
  const [additionalCurrency, setAdditionalCurrency] = useState({
    id: null,
    name: '',
  });
  const [isCalendarOpen, setCalendarOpenState] = useState(false);
  const [shouldUpdate, setUpdateState] = useState(true);
  const [dateAsAt, setDateAsAt] = useState(
    moment()
      .startOf('day')
      .format(),
  );

  const [activeTabKey, setActiveTabKey] = useState(
    globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.LIST_VIEW,
  );

  useEffect(() => {
    setUpdateState(true);
  }, [policyId, additionalCurrency]);

  const { data: currentPolicy } = usePolicyBasicInfo(policyId, customerId);

  const portfolioAllocations = usePerformance(
    policyId,
    dateAsAt,
    additionalCurrency.id,
    shouldUpdate,
  );

  const policies = usePolicies(false, customerId);

  const selectedPolicy =
    !isEmpty(policies) &&
    policyId &&
    policies.find(pol => pol.policyId === policyId);
  const policyStartDate = selectedPolicy && selectedPolicy.startDate;

  const defaultPolicyInfo = {
    currency: '',
    netInvestedAmount: 0,
    policyValue: 0,
    totalProfitLoss: 0,
    totalReturn: 0,
    lastDate: null,
    surrenderValue: 0,
  };

  const policyInfo = isEmpty(portfolioAllocations.data)
    ? defaultPolicyInfo
    : portfolioAllocations.data.policyInfo;

  if (!isEmpty(policyInfo)) policyInfo.startDate = policyStartDate;

  const funds = isEmpty(portfolioAllocations.data)
    ? []
    : portfolioAllocations.data.funds;

  if (!isEmpty(funds)) {
    funds.map(fund => {
      fund.fundDocuments = { label: fund.fundDescription, links: [] };

      fund.documents.map(document =>
        fund.fundDocuments.links.push({
          label: document,
          path: fund.documentPath,
          name: document,
        }),
      );

      return fund;
    });
  }

  useEffect(() => {
    if (policyInfo.lastDate !== null) {
      setUpdateState(false);
    }
  }, [policyInfo.lastDate]);

  useEffect(() => {
    if (
      !isEmpty(policyInfo.lastDate) &&
      dateAsAt ===
        moment()
          .startOf('day')
          .format()
    ) {
      setDateAsAt(policyInfo.lastDate);
    }

    if (!isEmpty(currentPolicy.currencies) && isNull(additionalCurrency.id)) {
      const defaultPolicyCurrency = currentPolicy.currencies.find(
        curr => curr.isDefault,
      );

      setAdditionalCurrency(defaultPolicyCurrency);
    }
  }, [policyInfo, dateAsAt, additionalCurrency, currentPolicy]);

  const handleTabSwitch = (key: string) => {
    setActiveTabKey(key);
  };

  const handleDateChange = (date: Moment) => {
    setDateAsAt(date);
    setCalendarOpenState(false);
    setUpdateState(true);
  };

  const handleCurrencyChange = (currencyId: number) => {
    const { currencies } = currentPolicy;
    const selectedCurrency = currencies.find(curr => curr.id === currencyId);

    setAdditionalCurrency(selectedCurrency);
  };

  return (
    <PerformanceScreen
      handleTabSwitch={handleTabSwitch}
      activeTabKey={activeTabKey}
      availablePolicyCurrencies={currentPolicy.currencies || []}
      currency={additionalCurrency}
      policyInfo={policyInfo}
      funds={funds}
      isCalendarOpen={isCalendarOpen}
      setCalendarOpenState={setCalendarOpenState}
      dateAsAt={dateAsAt}
      handleCurrencyChange={handleCurrencyChange}
      handleDateChange={handleDateChange}
      policyId={policyId}
    />
  );
};

export default Performance;
