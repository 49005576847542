// @flow

import React from 'react';

import { SearchContainer } from './styles';

import strings from '../../../config/strings';
import { filterButtonIcon } from '../../../assets/images';
import Search from '../../shared/search';
import ActionButton from '../../shared/action-button';

type Props = {
  onSearch: Function;
  onFilter: Function;
};

const FilterSearch = ({ onSearch, onFilter }: Props) => (
  <SearchContainer>
    <ActionButton
      style={{ marginRight: 20 }}
      title={strings.FILTER_BUTTON}
      icon={filterButtonIcon}
      onClick={onFilter}
    />
    <Search onSearch={onSearch} />
  </SearchContainer>
);

export default FilterSearch;
