// @flow

import moment from 'moment';
import type { Moment } from 'moment';
import { isUndefined, isNull } from 'lodash';

import strings from '../config/strings';

const getDate = () => moment().format('MMM Do YYYY');

export const formatDate = (date: string) =>
  isUndefined(date) || isNull(date)
    ? strings.NULL_DATE_REPLACEMENT
    : moment(date).format('MMMM DD, YYYY');

export const formatShortMonthDate = (date: string) =>
  isUndefined(date) || isNull(date)
    ? strings.NULL_DATE_REPLACEMENT
    : moment(date).format('MMM DD, YYYY');

export const formatShortDate = (date: string) =>
  moment(date).format('DD-MMM-YY');

export const dateToString = (date: Moment) => moment(date).format('DD/MM/YYYY');

export default getDate;
