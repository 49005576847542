import colors from '../../../config/colors';

export const indicatorSeparator = {
  display: 'none',
  marginLeft: '80%',
};

export const indicatorsContainer = {
  marginRight: '19px',
};

export const singleValue = {
  color: colors.silver,
};

export const placeholderStyle = {
  color: colors.battleshipGrey,
  fontFamily: 'Avenir-book',
};
export const container = {
  width: '100%',
  position: 'relative',
  boxSizing: 'border-box',
};

export const arrowContainer = {
  color: colors.slateGrey10,
  padding: 0,
  margin: 0,
};

export const dropDownMenuListDesign = {
  borderWidth: 0,
  borderStyle: 'solid',
  borderRadius: 4,
  color: colors.slateGrey10,
  width: '100%',
};

export const optionContainerStyle = {
  backgroundColor: 'white',
  height: 50,
  cursor: 'pointer',
  color: colors.slateGrey,
  padding: 0,
  textIndent: 18,
  display: 'flex',
  alignItems: 'center',
  '&:focus': {
    backgroundColor: colors.paleGrey,
  },
  '&:hover': {
    backgroundColor: colors.paleGrey,
  },
  '&:active': {
    backgroundColor: colors.white,
  },
  '&:first-child': {
    borderTopWidth: 0,
  },
};

export const controlStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.slateGrey10,
  width: '100%',
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: colors.slateGrey10,
  borderRadius: 3,
  cursor: 'pointer',
  outline: 'none',
  color: colors.slateGrey10,
};

export const inputStyle = {
  color: colors.battleshipGrey,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-55%)',
  textIndent: 7,
};

export const valueContainer = {
  padding: 0,
  backgroundColor: 'transparent',
  height: 50,
  textIndent: 18,
  color: colors.slateGrey10,
};
