import styled from 'styled-components';

import BorderlessLink, {
  BorderlessLinkLight,
} from '../../components/shared/texts/link';
import { SubmitButton } from '../../components';
import { Subtitle } from '../../components/shared/texts/content';

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FormSubmitButton = styled(SubmitButton)`
  margin-top: 20px;
`;

export const NavigationButton = styled(BorderlessLink)`
  text-align: center;
  margin-top: 40px;
`;

export const Description = styled(Subtitle)`
  margin-bottom: 30px;
  margin-top: -20px;
`;

export const DismissButton = styled(BorderlessLinkLight)`
  text-align: center;
  margin-top: 40px;
`;
