// @flow

import React, { useState } from 'react';

import Card from './card';
import { SectionTitle2 } from '../shared/texts/title';
import { Container, CardsCarousel, CarouselArrow } from './styles';
import { navArrowLeft3x, navArrowRight3x } from '../../assets/images';

type Props = {
  title: String,
  data: any[],
  getDocumentImage: Function,
  handleViewArticleButtonClick: Function,
};

const LibrarySection = ({
  title,
  data,
  getDocumentImage,
  handleViewArticleButtonClick,
}: Props) => {
  const [position, setPosition] = useState(0);

  const scrollPrevious = () => {
    setPosition(pos => (pos - 1 < 0 ? 0 : pos - 1));
  };

  const scrollNext = () => {
    setPosition(pos => (pos + 2 > data.length - 1 ? pos : pos + 1));
  };

  return (
    <Container>
      <CarouselArrow>
        <input
          type="image"
          src={navArrowLeft3x}
          alt="prev"
          onClick={scrollPrevious}
          style={{
            visibility: position === 0 ? 'hidden' : 'visible',
            outline: 'none',
            height: 25,
            width: 16,
          }}
        />
        <input
          type="image"
          src={navArrowRight3x}
          alt="next"
          onClick={scrollNext}
          style={{
            visibility: position + 2 > data.length - 1 ? 'hidden' : 'visible',
            outline: 'none',
            height: 25,
            width: 16,
          }}
        />
      </CarouselArrow>

      <SectionTitle2>{title}</SectionTitle2>
      <CardsCarousel scrollRight={position}>
        {data.map(card => (
          <Card
            key={card.title}
            {...card}
            getDocumentImage={getDocumentImage}
            handleViewArticleButtonClick={handleViewArticleButtonClick}
          />
        ))}
      </CardsCarousel>
    </Container>
  );
};

export default LibrarySection;
