// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getDocument = (name: string, path: string) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL || ''}/documents`;
  const params = { name, path };
  const headers = {
    'Content-Type': 'application/json',
    Accept: REACT_APP_ACCEPT_HEADER_WB,
  };

  return axios.get(url, { headers, params, responseType: 'blob' });
};

export default getDocument;
