import styled from 'styled-components';

import {
  ColumnContainer,
  ConvertableRowToColContainer,
  RowContainer,
} from '../container';

export const CardContainer = styled(ConvertableRowToColContainer)`
  border-radius: 3px;
  margin-bottom: 40px;
  padding: 20px 5% 40px 5%;
  background-color: ${props => props.theme.white};
  justify-content: space-between;
  align-items: flex-start;

  > div:last-child > :last-child {
    border: 0;
  }
`;

export const ColumnSection = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
`;

export const CardInfoEntry = styled(RowContainer)`
  padding-top: 20px;
  padding-bottom: 15px;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: solid 1px #d9d9d9;
  width: calc(100% - 50px);
`;
