export const ARTICLE_DETAILS = '/articles/:id';
export const CHANGE_PASSWORD = '/change-password';
export const CUSTOMER_INFORMATION = '/customer-information';
export const FAQS = '/faqs';
export const INBOX = '/inbox';
export const FORGET_PASSWORD = '/forget-password';
export const INDEX = '/';
export const LIBRARY = '/library';
export const LOGIN = '/login';
export const OVERVIEW = '/overview';
export const POLICY_DETAILS = '/policy-details';
export const PRIVACY_POLICY = '/terms-and-conditions';
export const PRODUCER = '/producer';
export const PRODUCER_POLICIES = '/producer/:id/policies';
export const PRODUCER_POLICY_DETAILS = '/producer/:id/policy-details';
export const PRODUCER_CUSTOMER_INFORMATION =
  '/producer/:id/customer-information';
export const PROFILE_DETAILS = '/profile-details';
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_SUCCESS = '/reset-password/success';
export const SERVICE_FORMS = '/service-forms';
