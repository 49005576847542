// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import type { Moment } from 'moment';

import CustomError from '../utils/error';
import { dateToString } from '../utils/date';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getBenchmarking = (policyId: string, dateAsAt: Moment) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/policies/${policyId}/funds/benchmarking`;
  const params = { date: dateToString(dateAsAt) };

  return axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
    params,
  });
};

export default getBenchmarking;
