import styled from 'styled-components';
import { size } from '../../config/device';

const Logo = styled.img`
  width: 111px;
  height: 45px;
  object-fit: contain;
  transform: scale(2);

  @media (max-width: ${size.tablet}) {
    transform: scale(1.6);
  }
`;

export default Logo;
