// @flow

import React from 'react';
import { StyledSvg, StyledCircle, Container } from './container';

type Props = {
  style: any;
  size: Number;
  offset: Number;
};

const Spinner = ({ style, size = 65, offset = 250 }: Props) => (
  <Container style={style} offset={offset}>
    <StyledSvg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size + 1} ${size + 1}`} xmlns="http://www.w3.org/2000/svg">
      <StyledCircle fill="none" strokeWidth="6" strokeLinecap="round" cx={Math.round(size / 2)} cy={Math.round(size / 2)} r={Math.floor(size / 20) * 10} />
    </StyledSvg>
  </Container>
);

export default Spinner;
