import styled from 'styled-components';
import colors from '../../config/colors';
import { size } from '../../config/device';
import { SectionTitle } from '../shared/texts/title';
import { ColumnContainer } from '../shared/container';

export const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 821px;

  & > div {
    flex: 1;
    width: 50%;
  }

  #footer {
    left: -100%;
  }

  @media (max-width: ${size.laptop}) {
    & > div {
      width: 100%;

      &:first-child {
        display: none;
      }
    }

    #footer {
      left: initial;
    }
  }
`;

export const OnBoardingContainer = styled.div`
  width: 50%;
  background-size: 35%, cover;
  background-image: url(${props => props.topLayerImage}),
    url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat, repeat;
`;

const MainContainer = styled(ColumnContainer)`
  background-color: ${colors.paleGrey};
  min-height: 100vh;
  position: relative;

  @media (max-width: ${size.tablet}) {
    padding: 10% 16.67% 0;
  }
`;

export const Title = styled(SectionTitle)`
  margin-top: 84px;
  text-align: center;
`;

export const Form = styled.form`
  width: 300px;
  margin-top: 50px;

  @media (max-width: ${size.tablet}) {
    width: 100%;
  }
`;

export const StampContainer = styled.div`
  margin-bottom: -43px;
  margin-top: 44px;
`;

export default MainContainer;
