import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${props => props.theme.paleGrey};
  position: relative;
`;

export default MainContainer;
