import {
  REQUEST_GET_PRODUCER_POLICIES,
  SUCCESS_GET_PRODUCER_POLICIES,
  REQUEST_GET_PRODUCER_EXPANDED,
  SUCCESS_GET_PRODUCER_EXPANDED,
  REQUEST_GET_PRODUCER,
  SUCCESS_GET_PRODUCER,
  FAILURE_GET_PRODUCER,
  SUCCESS_GET_FILTERS,
  SUCCESS_GET_LEVELS,
} from '../types/producer';
import { USER_LOGOUT } from '../constants/types';

const initialState = {
  errorMessage: '',
  loading: true,
  filters: [],
  levels: [],
  data: [],

  // Expanded producer data
  expanded: {
    loading: false,
    data: [],
  },

  // Agent policies list
  agentPolicies: {
    loading: false,
    data: [],
    id: 0,
  }

};

const producerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_PRODUCER:
      if (action.payload.resetOriginal) {
        return { ...state, loading: true, data: [] };
      }

      return { ...state, loading: true };

    case SUCCESS_GET_PRODUCER:
      return { ...state, loading: false, data: action.payload.producer };

    case FAILURE_GET_PRODUCER:
      return { ...state, loading: false, errorMessage: action.payload.err };

    case SUCCESS_GET_FILTERS:
      return { ...state, filters: action.payload.filters };

    case SUCCESS_GET_LEVELS:
      return { ...state, levels: action.payload.levels };

    case REQUEST_GET_PRODUCER_EXPANDED:
      return {
        ...state,
        expanded: {
          loading: true,
          data: [],
        },
      };

    case SUCCESS_GET_PRODUCER_EXPANDED:
      return {
        ...state,
        expanded: {
          loading: false,
          data: action.payload.expanded,
        }
      };

    case REQUEST_GET_PRODUCER_POLICIES:
      return {
        ...state,
        agentPolicies: {
          loading: true,
          id: action.payload.producerId,
          data: action.payload.resetOriginal ? [] : state.agentPolicies.data,
        },
      };

    case SUCCESS_GET_PRODUCER_POLICIES:
      if (action.payload.append) {
        return {
          ...state,
          agentPolicies: {
            loading: false,
            id: action.payload.producerId,
            data: [...state.agentPolicies.data, ...action.payload.policies],
          }
        };
      }

      return {
        ...state,
        agentPolicies: {
          loading: false,
          id: action.payload.producerId,
          data: action.payload.policies,
        }
      };

    case USER_LOGOUT:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default producerReducer;
