// @flow
const GroupBy = (array: Array, filter: string) => {
  const result = {};
  array.forEach(item => {
    if (result[item[filter]]) {
      result[item[filter]].push(item);
    } else {
      result[item[filter]] = [item];
    }
  });
  const data = Object.entries(result).map(el => {
    const [key, value] = el;
    const info = { [filter]: key, data: value };
    return info;
  });
  return data;
};

export default GroupBy;
