// @flow

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import strings from '../../config/strings';
import { LOGIN } from '../../router/routes';
import { OnboardingFormWrapper } from '../../components';
import { FormSubmitButton, Description } from './styles';
import { getFooter, getTheme } from '../../redux/actions';

type Props = {
  handleFormSubmit: Function,
  history: { location: Object },
};

const ResetPasswordSuccess = ({ handleFormSubmit, history }: Props) => {
  const formUtils = useForm();
  const { colors: themeColors } = useSelector(state => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getTheme());
  }, []);

  const { isReset, isFirstTimeReset } = history.location.state;

  const defaultTitleMessage = isFirstTimeReset
    ? strings.ONBOARDING_RESET_PASSWORD_SUCCESS_TITLE
    : strings.CHANGE_PASSWORD;

  return (
    <OnboardingFormWrapper
      formUtils={formUtils}
      handleFormSubmit={formUtils.handleSubmit(handleFormSubmit)}
      logoSrc={
        history.location.state ? history.location.state.media.logoUrl : ''
      }
      title={
        isReset
          ? strings.ONBOARDING_RESET_PASSWORD_SUCCESS_TITLE
          : defaultTitleMessage
      }
      themeColors={themeColors}
      isSuccess
    >
      <Description>
        {isReset
          ? strings.ONBOARDING_RESET_PASSWORD_FORM_SUCCESS_SUBTITLE
          : strings.ONBOARDING_CHANGE_PASSWORD_FORM_SUCCESS_SUBTITLE}
      </Description>

      <Link to={{ pathname: LOGIN }}>
        <FormSubmitButton
          value={strings.ONBOARDING_RESET_PASSWORD_FORM_SUCCESS_SUBMIT_BUTTON}
        />
      </Link>
    </OnboardingFormWrapper>
  );
};

export default ResetPasswordSuccess;
