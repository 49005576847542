// @flow

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import * as qs from 'query-string';
import * as yup from 'yup';

import isEmpty from 'lodash/isEmpty';
import strings from '../config/strings';
import { resetPassword } from '../redux/actions';
import { RESET_PASSWORD_SUCCESS } from '../router/routes';
import { ResetPassword as ResetPasswordScreen } from '../pages';
import { getFooter, getTheme } from '../redux/actions/theme.action';

type Props = { history: { push: Function } };

const ResetPassword = ({ history }: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const themeColors = useSelector(state => state.theme.colors);
  const { logo = {} } = useSelector(state => state.media.data);

  const [username, setUsername] = useState(null);
  const [hideUserOTP, setHideUserOTP] = useState(true);

  const isFirstTimeReset = !isEmpty(history.location.state);

  const usernameOtpValidation = {
    otp: yup.string().required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
    username: yup.string().required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
  };

  const schema = yup.object().shape({
    ...(!isFirstTimeReset ? usernameOtpValidation : {}),
    password: yup.string().required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        strings.VALIDATION_ERROR_MESSAGE_EQUAL_FIELDS,
      )
      .required(strings.VALIDATION_ERROR_MESSAGE_REQUIRED),
  });

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getTheme());

    const credentials = qs.parse(location.search) || {};
    setUsername(credentials.username || null);

    const { credentials: stateCreds = {} } = history.location.state || {};

    if (!stateCreds.otp || !stateCreds.username) {
      setHideUserOTP(false);
    }
  }, []);

  const handleFormSubmit = async (data) => {
    try {

      const { credentials } = history.location.state || {};

      const body = {
        password: data.password,
        username: data.username,
        otp: data.otp,

        ...credentials,
      };

      const result = await dispatch(resetPassword(body));

      if (!result.success) {
        if (result.message === 'NEW_PASSWORD_NOT_VALID') {
          return setError('passwordConfirmation', {
            type: 'manual',
            message: strings.SERVER_ERROR_INVALID_NEW_PASS,
          });
        }

        if (result.message === 'OLD_PASSWORD_NOT_VALID') {
          return setError('passwordConfirmation', {
            type: 'manual',
            message: strings.SERVER_ERROR_INVALID_OLD_PASS,
          });
        }

        throw new Error(strings.SERVER_ERROR);
      }

      history.push({
        pathname: RESET_PASSWORD_SUCCESS,
        state: { isFirstTimeReset, media: { logoUrl: logo.url } },
      });

      return true;
    } catch (error) {
      return setError('passwordConfirmation', {
        type: 'manual',
        message: strings.SERVER_ERROR,
      });
    }
  };

  return (
    <ResetPasswordScreen
      logoUrl={logo.url}
      username={username}
      themeColors={themeColors}
      hideUserOTP={hideUserOTP}
      handleFormSubmit={handleFormSubmit}
      formUtils={{ register, handleSubmit, errors }}
    />
  );
};

export default ResetPassword;
