import styled from 'styled-components';
import { size } from '../../../config/device';

export const Header = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const Container = styled.div`
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 23px 25px;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  background-color: ${props => props.theme.white2};

  @media (max-width: ${size.tablet}) {
    padding: 20px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 23px;
  padding-top: 30px;
  padding-bottom: 7px;
  border-top: 2px solid ${props => props.theme.paleGreyDark};
`;

export const Content = styled.div`
  transition: max-height 0.5s linear;
  overflow: hidden;
  overflow-y: auto;

  max-height: ${props => (props.open ? props.height || 500 : 0)}px;
`;

export const CollapsibleIcon = styled.img`
  width: 30px;
  height: 30px;

  @media (max-width: ${size.tablet}) {
    width: 24px;
    height: 24px;
  }
`;
