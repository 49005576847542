// @flow

import React from 'react';

import { Container, Input, Label } from './styles';

type Props = {
  label: String;
  checkboxValue: Boolean;
  onChangeCheckboxValue: (value: boolean) => void;
};

const Checkbox = ({ label, checkboxValue, onChangeCheckboxValue }: Props) => {
  const handleChange = (event) => {
    const value = event.target.checked;
    onChangeCheckboxValue(value);
  };

  return (
    <Container>
      <Label checked={checkboxValue}>
        {label}
        <Input type="checkbox" checked={checkboxValue} onChange={handleChange} />
      </Label>
    </Container>
  );
};

export default Checkbox;
