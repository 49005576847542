// @flow

import React, { useEffect, useReducer, useRef, Fragment } from 'react';
import { Doughnut } from 'react-chartjs-2';

import 'chartjs-plugin-labels';
import 'chartjs-plugin-piechart-outlabels';

import {
  chartLabelFontStyle,
  chartLegendFontStyle,
  chartLegendStyle,
  tooltipStyle,
} from './styles';
import { decimalWithCommas } from '../../utils/numbers';
import colors, { colorPalette } from '../../config/colors';

type Props = {
  currency: string,
  datalabels: { position?: 'outside' | 'default' },
  legendAlign: 'start' | 'center' | 'end',
  legendPosition: 'bottom' | 'chartArea' | 'left' | 'right' | 'top',
  data: Array<number> | Array<string>,
  labels: Array<string>,
};

const DoughnutChart = ({
  currency,
  data,
  datalabels,
  labels,
  legendAlign,
  legendPosition,
}: Props) => {
  const chartRef = useRef(null);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <Fragment>
      <Doughnut
        ref={chartRef}
        data={{
          labels,
          datasets: [
            {
              data,
              backgroundColor: colorPalette,
              borderWidth: 0,
            },
          ],
        }}
        options={{
          cutoutPercentage: 55,
          plugins: {
            labels: {
              render(args) {
                if (
                  (datalabels && datalabels.position === 'outside') ||
                  args.percentage <= 2
                ) {
                  return '';
                }

                return `${args.percentage} %`;
              },
              ...chartLabelFontStyle,
            },
            outlabels: {
              display: datalabels && datalabels.position === 'outside',
              stretch: 30,
              text: `${currency} %v.2`,
              textAlign: 'center',
              font: {
                resizable: true,
                minSize: '11.5',
                family: 'Avenir-Medium',
              },
            },
            datalabels: { display: false },
          },
          lineHeightAnnotation: {
            always: false,
            hover: false,
          },
          legend: {
            labels: {
              ...chartLegendFontStyle,
              ...chartLegendStyle,
            },
            display: !!legendPosition,
            align: legendAlign || 'start',
            position: legendPosition || 'bottom',
            onClick: e => e.stopPropagation(),
          },
          legendCallback: legendPosition
            ? undefined
            : chart => {
                const ul = document.createElement('ul');
                ul.style.marginTop = '60px';
                const { backgroundColor } = chart.data.datasets[0];
                chart.data.labels.forEach((label, index) => {
                  ul.innerHTML += `
                    <li style="list-style:none;margin-bottom:3px;">
                      <div style="display:flex;flex-direction:row;align-items:center">
                        <div style="background-color:${
                          backgroundColor[index]
                        };width:9px;height:9px;border-radius:50%;margin-right:6px"></div>
                        <p style="color:${
                          colors.primaryDark
                        };font-family:Avenir-Medium;font-size:12px;letter-spacing:-0.07px">${label}</p>
                      </div>
                    </li>
                  `;
                });

                return ul.outerHTML;
              },
          aspectRatio: 1,
          responsive: true,
          maintainAspectRatio: true,
          rotation: 50,
          tooltips: {
            ...tooltipStyle,
            callbacks: {
              title: (tooltipItem, dataArr) => {
                const title = dataArr.labels[tooltipItem[0].index];
                return title;
              },
              label: (tooltipItem, values) => {
                const label = `${currency} ${decimalWithCommas(
                  values.datasets[0].data[tooltipItem.index],
                )}`;

                return label;
              },
            },
          },
          scales: {
            display: false,
            gridLines: { display: false, drawBorder: false },
          },
          layout: {
            padding: datalabels &&
              datalabels.position === 'outside' && {
                bottom: 60,
                right: 20,
                top: 20,
              },
          },
        }}
      />

      {!legendPosition && ignored > 0 && (
        <div
          dangerouslySetInnerHTML={{
            __html: chartRef.current.chartInstance.generateLegend(),
          }}
        />
      )}
    </Fragment>
  );
};

export default DoughnutChart;
