import styled from 'styled-components';
import Textbox from '../text-input';
import { calendar, arrowDown } from '../../../assets/images';

export const DateInput = styled(Textbox).attrs({
  readOnly: true,
})`
  background: url(${calendar}) no-repeat 20px center,
    url(${arrowDown}) no-repeat right 25px center;
  padding-right: 25px;
  padding-left: 20px;
  text-indent: 26px;
  cursor: pointer;
`;

export default DateInput;
