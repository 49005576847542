import styled, { css, keyframes } from 'styled-components';

import { size } from '../../config/device';
import { ConvertableRowToColContainer } from '../../components/shared/container';

const openRotateArrowKeyFrame = keyframes`
0% {
    transform: rotate(180deg);
  }
  100% {
    transform:rotate(0);
  }

`;

const closeRotateArrowKeyFrame = keyframes`
0% {
  transform: rotate(0 )

}
100%{
  transform: rotate(180deg)
}
`;

const openRotateAnimation = css`
  animation: 0.2s linear ${openRotateArrowKeyFrame} forwards;
`;

const closeRotateAnimation = css`
  animation: 0.2s linear ${closeRotateArrowKeyFrame} forwards;
`;

const slideDownKeyFrame = keyframes`
0%{height:0; opacity:0;visibility:collapse;}
0%{height:70px; opacity:0.2;visibility:visible;}
100%{height:230px; opacity:1;visibility:visible;}

`;

const slideUpKeyFrame = keyframes`
0%{height:230px; opacity:1;visibility:visible;}
50%{height:70px; opacity:0;visibility:collapse;}
100%{height:0; opacity:0;visibility:collapse;}
`;

const slideDownAnimation = css`
  animation: 0.2s linear ${slideDownKeyFrame} forwards;
`;

const slideUpAnimation = css`
  animation: 0.2s linear ${slideUpKeyFrame} forwards;
`;

export const PageContainer = styled(ConvertableRowToColContainer)`
  justify-content: space-between;
  align-items: flex-start;
  width: 60%;
  margin-left: 180px;

  @media (max-width: ${size.tablet}) {
    width: 100%;
    margin-left: 0;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.white};
  padding: 25px 34px 30px 34px;
  box-sizing: border-box;
  width: 228px;
  height: auto;
  max-height: 425px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 #e4e6ef;
  margin-bottom: 30px;

  position: fixed;
  left: 20vw;

  @media (max-width: ${size.tablet}) {
    position: initial;
    width: 100%;
  }
`;

export const MenuItemContainer = styled.div`
  width: 159px;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  margin-top: 12px;
  padding-top: 10px;

  ${props => (props.pageMenuItem ? 'margin: 3px 0;' : '')}

  @media (max-width: ${size.tablet}) {
    width: 100%;
  }
`;

export const ItemHighlighter = styled.div`
  width: 198px;
  position: absolute;
  top: 0px;
  left: -19px;
  height: 45px;
  background-color: ${props => props.theme.cobaltBlue07};

  @media (max-width: ${size.tablet}) {
    width: 100%;
  }
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  border: solid 0.2px;
  margin-top: 10px;
  color: ${props => props.theme.paleGrey};
`;

export const ProfileFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  background-color: transparent;

  position: relative;
  left: 25vw;

  @media (max-width: ${size.tablet}) {
    position: initial;
    margin-left: 0;
    width: 100%;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-top: 10px;
  position: relative;
  justify-content: space-between;
`;

export const CalenderDiv = styled.div`
  width: 17px;
  height: 14px;
  position: absolute;
  opacity: 0.4;
  right: 7.5px;
  top: 19px;
`;

export const CalenderHolder = styled.div`
  background-color: ${props => props.theme.slateGrey10};
  position: absolute;
  right: 13.5px;
  top: 19px;
`;

export const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin: 70px;
`;

export const CollapsableSpace = styled.div`
  display: flex;
  flex-direction: column;
  visibility: ${({ animationState }) =>
    animationState || animationState === undefined ? 'visible' : 'collapse'};
  ${({ animationState }) =>
    animationState === undefined
      ? ''
      : animationState
      ? slideUpAnimation
      : slideDownAnimation}
    }


    @media (max-width: ${size.tablet}){
      visibility: ${({ animationState }) =>
        !animationState || animationState === undefined
          ? 'collapse'
          : 'visible'};

           ${({ animationState }) =>
             animationState === undefined
               ? 'height:0; opacity:0;visibility:collapse;'
               : !animationState
               ? slideUpAnimation
               : slideDownAnimation}
    }
    }
`;

export const ArrowDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 9px;
  width: 12px;
  align-self: center;
`;

export const ArrowImage = styled.img`
  object-fit: contain;
  transform:rotate(180deg);

  ${({ animationState }) =>
    animationState === undefined
      ? ''
      : animationState
      ? openRotateAnimation
      : closeRotateAnimation}
    }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
