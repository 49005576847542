// @flow

import React, { useState } from 'react';
import Table from '../../shared/table';
import globals from '../../../config/globals';
import strings from '../../../config/strings';
import {
  CollapsibleIcon,
  RowContainer,
  CollapsibleDiv,
  tableCustomStyle,
} from './styles';
import { collabsiblePlus, collabsibleMinus } from '../../../assets/images';
import { policyDetailsListTitle } from './container';
import { SectionTitle2 } from '../../shared/texts/title';

type Props = {
  data: any[],
  currency: string,
  policyType: { [key: string]: any },
};

const PolicyBenefits = ({ data = {}, currency, policyType }: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  const investmentTypes = globals.POLICY_TYPE_BENEFITS_HEADER;
  const isInvestmentType = investmentTypes.includes(policyType);
  const POLICY_COVERAGE_TABLE_HEADER = [
    {
      key: 'benefitDescription',
      type: 'text',
      label: strings.TABLE_HEADER_BENEFITS,
    },
    { key: 'startDate', type: 'date', label: strings.TABLE_HEADER_START_DATE },
    { key: 'endDate', type: 'date', label: strings.TABLE_HEADER_EXPIRY_DATE },
    {
      key: 'sumAssured',
      type: 'currency',
      label: strings.TABLE_HEADER_SUM_INSURED,
      custom: { shouldReplaceNullWithString: false },
    },
    {
      key: 'premium',
      type: 'currency',
      label: isInvestmentType
        ? strings.TABLE_HEADER_COST_VUL
        : strings.TABLE_HEADER_COST_OTHER,
    },
  ];

  const info = data.sumInsured || {};
  const footerNotes =
    info.indexation && info.indexationPeriod
      ? `* Sum insured indexation of ${info.indexation}% is applied for ${info.indexationPeriod} years`
      : '';

  return (
    data && (
      <>
        <RowContainer>
          <SectionTitle2 style={policyDetailsListTitle}>
            {strings.SECTION_TITLE_POLICY_COVERAGE}
          </SectionTitle2>
          <CollapsibleIcon
            onClick={() => setIsClicked(!isClicked)}
            src={isClicked ? collabsibleMinus : collabsiblePlus}
            alt="collapsible"
          />
        </RowContainer>
        <CollapsibleDiv isClicked={isClicked}>
          <Table
            headerData={POLICY_COVERAGE_TABLE_HEADER}
            contentData={data.benefits}
            currency={currency}
            footerNotes={footerNotes}
            customStyle={tableCustomStyle}
          />
        </CollapsibleDiv>
      </>
    )
  );
};

export default PolicyBenefits;
