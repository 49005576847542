export const scrollableContainer = (width, height, orientation) => ({
  height: orientation === 'horizontal' ? 20 : height,
  width: orientation === 'horizontal' ? width : 20,
});

export const scrollbarHorizontal = (width, topPosition) => ({
  overflowX: 'scroll',
  overflowY: 'hidden',
  width,
  position: 'absolute',
  top: topPosition,
  right: 0,
  left: 0,
  zIndex: 15,
});

export const scrollbarVertical = (height, topPosition) => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
  height,
  position: 'absolute',
  top: topPosition,
  right: 0,
  zIndex: 15,
});
