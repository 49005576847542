import Color from 'color';
import styled from 'styled-components';
import device from '../../config/device';

export const Container = styled.div`
  flex-grow: 2;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  background-color: ${props => props.theme.white};

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const TopContainer = styled.div`
  padding: 30px;
  padding-bottom: 0;
`;

export const BottomContainer = styled.div`
  height: 90px;
  display: flex;
  padding: 20px 30px;
  box-sizing: border-box;
  border-top: 1px solid ${props => props.theme.white4};
`;

export const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.white4};

  & > div {
    padding: 3px 0;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
`;

export const ConversationHead = styled.div`
  padding: 3px 20px;
`;

export const CloseP = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 900;
  text-align: right;
  cursor: pointer;
  color: ${props => props.theme.primaryDark};

  span {
    position: relative;
    top: -3px;
  }

  img {
    position: relative;
    left: 3px;
    top: 2px;
  }
`;

export const SenderP = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${props => props.theme.primaryDark};
`;

export const SubjectP = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${props => props.theme.primaryDark};
`;

export const DateP = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: oblique;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-align: right;
  color: ${props => props.theme.slateGrey};
`;

export const BodyContainer = styled.div`
  scroll-behavior: smooth;
  padding: 20px 0;
  overflow: auto;
  height: 45vh;

  box-sizing: border-box;
  padding-right: 10px;
  margin-right: -10px;
`;

export const MessageContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: row${props => props.userMessage ? '-reverse' : ''};

  &:last-child {
    margin-bottom: 0;
  }

  div {
    display: none;

    @media ${device.mobileL} {
      display: initial;
    }
  }
`;

export const MessageText = styled.p`
  width: 360px;
  border-radius: 3px;
  margin-right: 20px;
  margin-left: 20px;
  min-height: 42px;
  padding: 15px 20px;

  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.3px;

  ${({ userMessage, theme }) => {
    if (userMessage) {
      return `
        margin-right: 0;
        color: ${theme.primary};
        background-color: ${Color(theme.primary).alpha(0.1).string()};
      `;
    }

    return `
      margin-left: 0;
      color: ${theme.secondary};
      background-color: ${Color(theme.secondary).alpha(0.1).string()};
    `;
  }}

  @media ${device.mobileL} {
    margin-right: 20px;
    margin-left: 20px;
  }

  i {
    font-style: oblique;
    font-size: 12px;
  }
`;

export const InputText = styled.input`
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.slateGrey65};
  padding: 0 20px;
  width: 120px;
  border: 0;

  @media ${device.mobileL} {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }
`;
