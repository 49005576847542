import styled from 'styled-components';
import colors from '../../../config/colors';
import { size } from '../../../config/device';
import { ColumnContainer } from '../../shared/container';

const Outline = styled(ColumnContainer)`
  height: 68px;
  padding: 16px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  background-color: ${colors.white};
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 20px;
  flex-shrink: 0;
  width: 165px;

  :last-child {
    margin: 0;
  }

  @media (max-width: ${size.tablet}) {
    height: 54px;
    padding: 13px 16.4px;
  }
`;

export default Outline;
