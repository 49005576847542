import {
  REQUEST_GET_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS,
  FAILURE_GET_NOTIFICATIONS,
} from '../types/notifications';

const initialState = {
  errorMessage: '',
  loading: false,
  data: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_NOTIFICATIONS:
      return { ...state, loading: true, data: [] };

    case SUCCESS_GET_NOTIFICATIONS:
      return { ...state, loading: false, data: action.payload.notifications };

    case FAILURE_GET_NOTIFICATIONS:
      return { ...state, loading: false, errorMessage: action.payload.err };

    default:
      return { ...state };
  }
};

export default notificationsReducer;
