// @flow

import React, { useState } from 'react';

import {
  questionMark,
  exclamation,
  exclamation2x,
  exclamation3x,
} from '../../../assets/images';
import strings from '../../../config/strings';
import { TooltipTitle } from '../texts/title';
import { TotalHintContent } from '../texts/content';
import { ArrowUp, HintContainer, HintOutline, ImageDiv } from './styles';

type Props = {
  hint: string,
  isLargeDisclaimer?: boolean,
  keyid: string,
};
const ToolPin = ({ hint, isLargeDisclaimer, keyid }: Props) => {
  const [showHint, setShowHint] = useState(false);

  const getPosition = () => {
    const disclaimerIcon = document
      .getElementById(`diclaimerIcon-${keyid}`)
      .getBoundingClientRect();

    const disclaimerContainer = document.getElementById(
      `disclaimerContainer-${keyid}`,
    );

    disclaimerContainer.style.top = `${disclaimerIcon.top + 20}px`;
  };

  window.onscroll = () => {
    if (showHint) getPosition();
  };

  const handleDisclaimerDisplay = () => {
    setShowHint(!showHint);
    getPosition();
  };

  return (
    <div>
      <ImageDiv
        id={`diclaimerIcon-${keyid}`}
        role="button"
        tabIndex={0}
        onClick={handleDisclaimerDisplay}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleDisclaimerDisplay();
          }
        }}
        onBlur={() => setShowHint(false)}
      >
        {isLargeDisclaimer ? (
          <img
            alt="disclaimer"
            src={exclamation}
            srcSet={`${exclamation2x} 2x, ${exclamation3x} 3x`}
          />
        ) : (
          <img src={questionMark} alt="hint" />
        )}
      </ImageDiv>

      <HintOutline showHint={showHint} id={`disclaimerContainer-${keyid}`}>
        <ArrowUp />
        <HintContainer isLarge={isLargeDisclaimer}>
          {isLargeDisclaimer && (
            <TooltipTitle>{strings.TOOLTIP_TITLE}</TooltipTitle>
          )}
          <TotalHintContent>{hint}</TotalHintContent>
        </HintContainer>
      </HintOutline>
    </div>
  );
};

ToolPin.defaultProps = {
  isLargeDisclaimer: false,
};

export default ToolPin;
