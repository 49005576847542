// @flow

import type { Moment } from 'moment';

import {
  FAILURE_STRATEGY_FUNDS_PRICES,
  FAILURE_STRATEGY_ACCOUNT_VALUE,
  FAILURE_STRATEGY_FUNDS_AVERAGING,
  REQUEST_STRATEGY_ACCOUNT_VALUE,
  REQUEST_STRATEGY_BENCHMARKING,
  REQUEST_STRATEGY_FUND_ANALYSIS,
  REQUEST_STRATEGY_FUNDS_AVERAGING,
  REQUEST_STRATEGY_FUNDS_PRICES,
  REQUEST_STRATEGY_TRANSFERS,
  SET_CURRENT_POLICY_ID,
  SUCCESS_STRATEGY_ACCOUNT_VALUE,
  SUCCESS_STRATEGY_BENCHMARKING,
  SUCCESS_STRATEGY_FUND_ANALYSIS,
  SUCCESS_STRATEGY_FUNDS_AVERAGING,
  SUCCESS_STRATEGY_TRANSFERS,
  SUCCESS_STRATEGY_FUNDS_PRICES,
} from '../constants/types';

import {
  getAccountValue,
  getFundsAnalysis as getFundsAnalysisAPI,
  getFundsAveraging as getFundsAveragingAPI,
  getStrategyTransfers as getStrategyTransfersAPI,
  getBenchmarking as getBenchmarkingAPI,
  getFundsPrices as getFundsPricesAPI,
} from '../../api';

const setCurrentPolicyId = currentPolicyId => ({
  type: SET_CURRENT_POLICY_ID,
  payload: { currentPolicyId },
});

const requestStrategyTransfers = () => ({ type: REQUEST_STRATEGY_TRANSFERS });
const requestFundAnalysis = () => ({ type: REQUEST_STRATEGY_FUND_ANALYSIS });
const requestBenchmarking = () => ({ type: REQUEST_STRATEGY_BENCHMARKING });
const requestFundsAveraging = () => ({
  type: REQUEST_STRATEGY_FUNDS_AVERAGING,
});
const requestAccountValue = () => ({ type: REQUEST_STRATEGY_ACCOUNT_VALUE });

const requestFundsPrices = () => ({
  type: REQUEST_STRATEGY_FUNDS_PRICES,
});

const successStrategyTransfers = transfers => ({
  type: SUCCESS_STRATEGY_TRANSFERS,
  payload: { transfers },
});

const successFundAnalysis = analysis => ({
  type: SUCCESS_STRATEGY_FUND_ANALYSIS,
  payload: { analysis },
});

const successBenchmarking = benchmarking => ({
  type: SUCCESS_STRATEGY_BENCHMARKING,
  payload: { benchmarking },
});

const successFundsAveraging = (averaging: Object) => ({
  type: SUCCESS_STRATEGY_FUNDS_AVERAGING,
  payload: { averaging },
});

const successFundsPrices = (prices: Object) => ({
  type: SUCCESS_STRATEGY_FUNDS_PRICES,
  payload: { prices },
});

const successAccountValue = (accountValue: Object) => ({
  type: SUCCESS_STRATEGY_ACCOUNT_VALUE,
  payload: { accountValue },
});

const failureGetFundsAveraging = (error: Object) => ({
  type: FAILURE_STRATEGY_FUNDS_AVERAGING,
  payload: { error },
});

const failureGetFundsPrices = (error: Object) => ({
  type: FAILURE_STRATEGY_FUNDS_PRICES,
  payload: { error },
});

const failureAccountValue = (error: Object) => ({
  type: FAILURE_STRATEGY_ACCOUNT_VALUE,
  payload: { error },
});

export const getStrategyTransfers = policyId => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestStrategyTransfers());

  try {
    const { data: transfers } = await getStrategyTransfersAPI(policyId);

    dispatch(successStrategyTransfers(transfers));
  } catch (error) {
    // TODO: handle error
  }
};

export const getFundsAnalysis = (
  policyId: number,
  dateAsAt: Moment,
) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestFundAnalysis());

  try {
    const { data } = await getFundsAnalysisAPI(policyId, dateAsAt);

    dispatch(successFundAnalysis(data));
  } catch (error) {
    // TODO: handle error
  }
};

export const getBenchmarking = (
  policyId: number,
  dateAsAt: Moment,
) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestBenchmarking());

  try {
    const { data } = await getBenchmarkingAPI(policyId, dateAsAt);

    dispatch(successBenchmarking(data));
  } catch (error) {
    // TODO: handle error
  }
};

export const getFundsAveraging = (policyId: number) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestFundsAveraging());

  try {
    const { data: averaging } = await getFundsAveragingAPI(policyId);

    dispatch(successFundsAveraging(averaging));
  } catch (error) {
    dispatch(failureGetFundsAveraging(error));
  }
};

export const getFundsPrices = (
  policyId: number,
  period: number,
  fundId: number,
) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestFundsPrices());

  try {
    const { data: prices } = await getFundsPricesAPI(policyId, period, fundId);

    dispatch(successFundsPrices(prices));
  } catch (error) {
    dispatch(failureGetFundsPrices(error));
  }
};

export const getAccountValueByPolicyId = (
  policyId: number,
  fundId: number,
  period: number,
  currencyId: number,
) => async dispatch => {
  dispatch(setCurrentPolicyId(policyId));
  dispatch(requestAccountValue());

  try {
    const { data } = await getAccountValue(
      policyId,
      fundId,
      period,
      currencyId,
    );

    dispatch(successAccountValue(data));
  } catch (error) {
    dispatch(failureAccountValue(error));
  }
};
