/* eslint-disable import/prefer-default-export */

// @flow

import globals from '../config/globals';
import type { PolicyType } from '../models/policies';

import { policyOverview, policyOverviewP, policyOverviewE, policyOverviewT, policyOverviewD } from '../assets/images';

const investmentPolicyTypes = globals.POLICY_TYPE_INVESTMENT;
const policyTypes = Object.keys(globals.POLICY_TYPES);

export const isInvestmentTypePolicy = (policyType: PolicyType) => {
  if (!policyType || !policyTypes.includes(policyType)) {
    return false;
  }

  return investmentPolicyTypes.includes(policyType);
};

export const getPolicyIcon = (policyType: PolicyType) => {
  switch (policyType) {
    case 'P':
      return policyOverviewP;

    case 'L':
      return policyOverview;

    case 'E':
      return policyOverviewE;

    case 'T':
      return policyOverviewT;

    case 'D':
      return policyOverviewD;

    default:
      return policyOverview;
  }
};
