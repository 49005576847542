import styled from 'styled-components';
import { size } from '../../config/device';
import { RowContainer } from '../shared/container';

export const Spacer = styled.div`
  height: 55px;

  @media (max-width: ${size.tablet}) {
    height: 100px;
  }

  @media (max-width: ${size.mobileM}) {
    height: 130px;
  }
`;

export const FooterLeft = styled(RowContainer)`
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;

  span {
    padding: 0 10px;
    border-right: solid 2px ${props => props.theme.whiteDarker};
    height: 14px;
    display: flex;
    align-items: center;

    ${(props) => {
    if (!props.grey) {
      return `
          &:nth-child(1) {
            font-family: Avenir-Medium;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.5;
            letter-spacing: 0.11px;
            text-align: right;
            color: ${props.theme.primaryDark};
          }

          &:nth-child(2) {
            font-family: Avenir-Roman;
            letter-spacing: 0.12px;
            font-style: normal;
            font-size: 13px;
            color: ${props.theme.primary};
          }

          &:nth-child(3) {
            font-family: Avenir-Medium;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: oblique;
            line-height: 2.31;
            letter-spacing: 0.13px;
            color: ${props.theme.slateGrey};
          }
        `;
    }

    return `
        font-family: Avenir-Medium;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.31;
        letter-spacing: 0.13px;
        color: ${props.theme.paleGrey};

        @media (max-width: ${size.laptop}) {
          &:nth-child(1) {
            font-family: Avenir-Medium;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.5;
            letter-spacing: 0.11px;
            text-align: right;
            color: ${props.theme.primaryDark};
          }

          &:nth-child(2) {
            font-family: Avenir-Roman;
            letter-spacing: 0.12px;
            font-style: normal;
            font-size: 13px;
            color: ${props.theme.primary};
          }

          &:nth-child(3) {
            font-family: Avenir-Medium;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: oblique;
            line-height: 2.31;
            letter-spacing: 0.13px;
            color: ${props.theme.slateGrey};
          }
        }
      `;
}}

    &:last-child {
      border: none;
    }
  }

  @media (max-width: ${size.mobileS}) {
    span {
      height: 60px;
    }
  }
`;

export const FooterRight = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;

  a li {
    font-family: Avenir-Medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.11px;
    text-align: right;
    color: ${props => props.theme.slateGrey};
    float: left;
    height: 14px;
    padding: 0 10px;
    list-style-type: none;
    border-right: solid 2px ${props => props.theme.whiteDarker};
    white-space: nowrap;
  }

  a:last-child li {
    border: none;
  }

  @media (max-width: ${size.tablet}) {
    margin-top: 30px;
  }
`;

export const FooterContainer = styled(RowContainer)`
  border-top: solid 2px ${props => props.theme.whiteDarker};
  justify-content: space-between;
  width: calc(100vw - 42px);
  position: absolute;
  overflow: hidden;
  margin: 0 21px;
  padding: 11px 0 20px;
  bottom: 0;
  flex-wrap: wrap;
`;
