// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import strings from '../../config/strings';
import type FormUtils from '../../models/form';
import { FORGET_PASSWORD } from '../../router/routes';
import { TextboxValidation, OnboardingFormWrapper } from '../../components';
import { FormSubmitButton, InputWrapper, NavigationButton } from './styles';

type Props = {
  formUtils: FormUtils,
  handleFormSubmit: Function,
  logoUrl: string,
  themeColors: Object,
};

const LoginScreen = ({
  logoUrl,
  handleFormSubmit,
  formUtils,
  themeColors,
}: Props) => (
  <OnboardingFormWrapper
    formUtils={formUtils}
    handleFormSubmit={handleFormSubmit}
    logoSrc={logoUrl}
    title={strings.ONBOARDING_LOGIN_TITLE}
    themeColors={themeColors}
  >
    <InputWrapper>
      <TextboxValidation
        errorMessage={strings.VALIDATION_ERROR_MESSAGE_REQUIRED}
        formUtils={formUtils}
        label={strings.FORM_INPUT_LABEL_USERNAME}
        name="username"
        placeholder={strings.FORM_INPUT_PLACEHOLDER_USERNAME}
        type="text"
      />
    </InputWrapper>

    <InputWrapper>
      <TextboxValidation
        errorMessage={
          formUtils.errors.password &&
          formUtils.errors.password.type === 'manual'
            ? formUtils.errors.password.message
            : strings.VALIDATION_ERROR_MESSAGE_REQUIRED
        }
        formUtils={formUtils}
        label={strings.FORM_INPUT_LABEL_PASSWORD}
        name="password"
        placeholder={strings.FORM_INPUT_PLACEHOLDER_PASSWORD}
        type="password"
      />
    </InputWrapper>

    <FormSubmitButton value={strings.ONBOARDING_LOGIN_FORM_SUBMIT_BUTTON} />

    <Link to={{ pathname: FORGET_PASSWORD }}>
      <NavigationButton>
        {strings.ONBOARDING_LOGIN_FORGOT_PASSWORD_LABEL}
      </NavigationButton>
    </Link>
  </OnboardingFormWrapper>
);
export default LoginScreen;
