// @flow

import React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import DatePickerInput from './input';

type Props = {
  handleDateChange?: Function,
  isCalendarOpen?: boolean,
  isInline?: boolean,
  maxDate: Moment,
  minDate: Moment,
  setCalendarOpenState?: Function,
  title: string,
  value: string,
};

const DatePicker = ({
  handleDateChange,
  isCalendarOpen,
  isInline,
  keyId,
  maxDate,
  minDate,
  setCalendarOpenState,
  title,
  value,
}: Props) =>
  (!isInline || (isInline && isCalendarOpen)) && (
    <ReactDatePicker
      selected={Date.parse(value)}
      onChange={date => handleDateChange(date)}
      inline={isInline}
      onClickOutside={() => setCalendarOpenState(false)}
      onChangeRaw={e => e.preventDefault()}
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
      minDate={Date.parse(minDate)}
      maxDate={Date.parse(maxDate)}
      showDisabledMonthNavigation
      customInput={!isInline && <DatePickerInput />}
      placeholderText={title}
      dateFormat="MMMM dd, yyyy"
      key={keyId}
    />
  );

DatePicker.defaultProps = {
  handleDateChange: () => {},
  isCalendarOpen: false,
  isInline: false,
  setCalendarOpenState: () => {},
};

export default DatePicker;
