// @flow

import React from 'react';
import Spinner from '../components/shared/spinner';
import InboxHeader from '../components/inbox/header';
import Conversations from '../components/inbox/conversations';
import ConversationDetails from '../components/inbox/conversation-details';
import { InboxContent } from '../components/inbox/styles';
import EmptyData from '../components/empty-data';
import strings from '../config/strings';
import PopupOverlay from '../containers/popup';
import ConversationPopup from '../components/inbox/popup';

type Props = {
  loading: Boolean,
  composing: Boolean,
  detailsOpen: Boolean,

  messages: any[],
  userPolicies: any[],
  conversations: any[],
  sendingMessage: Boolean,
  emptyConversations: Boolean,
  currentConversation: String,

  toggleCompose: Function,
  toggleDetails: Function,
  onConversationClick: (id: number) => void,
  onSelectSubject: Function,
  onLoadMore: Function,
  onCompose: Function,
  onSearch: Function,
};

const Inbox = ({
  loading,
  messages,
  composing,
  detailsOpen,
  userPolicies,
  conversations,
  toggleCompose,
  toggleDetails,
  onSendMessage,
  sendingMessage,
  emptyConversations,
  currentConversation,
  onConversationClick,
  onSelectSubject,
  onLoadMore,
  onSearch,
}: Props) => (
  <>
    <InboxHeader onSearch={onSearch} onCompose={toggleCompose} />
    <PopupOverlay
      isOpen={composing}
      handleClosePopup={toggleCompose}
      containerStyle={{ paddingBottom: 0, width: '50vw', maxWidth: 602 }}
    >
      <ConversationPopup
        userPolicies={userPolicies}
        sendingMessage={sendingMessage}
        onSelectSubject={onSelectSubject}
        onSendMessage={(message, subject, policy) =>
          onSendMessage(message, null, subject, policy)
        }
      />
    </PopupOverlay>
    <InboxContent>
      {loading && <Spinner />}
      {!loading && (
        <Conversations
          shrink={detailsOpen}
          onLoadMore={onLoadMore}
          conversations={conversations}
          currentConversation={currentConversation}
          onConversationClick={onConversationClick}
        />
      )}
      {detailsOpen && (
        <ConversationDetails
          messages={messages}
          onClose={toggleDetails}
          onSendMessage={message => onSendMessage(message, currentConversation)}
          currentConversation={conversations.find(
            c => c.id === currentConversation,
          )}
        />
      )}
    </InboxContent>
    {emptyConversations && (
      <EmptyData
        title={strings.NO_RECENT_MESSAGES}
        subtitle={strings.ALL_MESSAGES_HERE}
      />
    )}
  </>
);

export default Inbox;
