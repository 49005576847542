// @flow

import React, { useState, useEffect } from 'react';

import Dropdown from '../shared/dropdown';
import strings from '../../config/strings';
import moveElementPos from '../../utils/arrays';

type Props = {
  showDropdown: Boolean;
  onChangeContributionType: Function;
};

const contributionTypes = [
  { id: 1, name: strings.CONTRIBUTION_TITLE_TOTAL, key: 'TOTAL' },
  { id: 2, name: strings.CONTRIBUTION_TITLE_REGULAR, key: 'REGULAR' },
  { id: 3, name: strings.CONTRIBUTION_TITLE_ADHOC, key: 'ADHOC' },
];

const ContributionDropdown = ({ onChangeContributionType, showDropdown }: Props) => {
  const [contributionIndex, setContributionIndex] = useState(0);

  const contributionTypesCpy = [...contributionTypes];

  const options = [{ id: -1, name: '' }];

  moveElementPos(contributionTypesCpy, contributionIndex, 0);
  options.push(...contributionTypesCpy);

  useEffect(() => {
    const selectedContribution = contributionTypes[contributionIndex];
    onChangeContributionType(selectedContribution.key);
  }, [contributionIndex])

  if (!showDropdown) {
    return null;
  }

  return (
    <Dropdown
      options={options}
      title={options[1].name}
      selected={options[contributionIndex + 1]}
      onSelect={id => +id >= 0 ? setContributionIndex(id - 1) : null}
      size='normal'
      style={{ height: '48px' }}
    />
  )
};

export default ContributionDropdown;
