// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { isEmpty } from 'lodash';

import getProducerAPI from '../api/producer';
import ProducerPoliciesScreen from '../pages/producer-policies';
import { getProducerPolicies } from '../redux/actions/producer';

import globals from '../config/globals';
import Spinner from '../components/shared/spinner';
import ExportButton from '../components/producer/export-button';

const ProducerPolicies = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [agentInfo, setAgentInfo] = useState({});
  const [allPolicies, setAllPolicies] = useState(false);
  const [filtersQueryString, setFiltersQueryString] = useState('');

  const producerData = useSelector(state => state.producer.data);

  const { loading, data } = useSelector(state => state.producer.agentPolicies);

  useEffect(() => {
    const { filters, searchTerm, isAllPolicies } = history.location.state || {};

    let qs = filters;

    if (searchTerm) qs += `&searchKey=${searchTerm}`;

    setFiltersQueryString(qs);
    setAllPolicies(isAllPolicies);
  }, [history.location.state]);

  useEffect(() => {
    dispatch(getProducerPolicies(page, allPolicies, id, filtersQueryString));
  }, [page, allPolicies, filtersQueryString]);

  useEffect(() => {
    const agent = producerData.find(p => p.userId === id);

    if (agent) {
      setAgentInfo(agent);
    } else {
      const userInfo = { producerId: id, allPolicies };

      getProducerAPI(userInfo, filtersQueryString, false).then(response => {
        if (!isEmpty(response.data)) setAgentInfo(response.data[0]);
      });
    }
  }, [producerData, allPolicies]);

  useEffect(() => {
    if (data.length && agentInfo.agentName) {
      setAgentInfo(a => ({
        ...a,
        agentName: data[0].agentName,
        renderExport: () => <ExportButton id={id} />,
      }));
    }
  }, [JSON.stringify(data), JSON.stringify(agentInfo)]);

  const handleAllPoliciesFlagClick = v => {
    setAllPolicies(!v);
    setPage(1);
  };

  const renderContent = () => (
    <ProducerPoliciesScreen
      data={data}
      agentInfo={agentInfo}
      loading={loading && page > 1}
      activePolicies={!allPolicies}
      onChangeAllPolicies={handleAllPoliciesFlagClick}
      onLoadMore={
        page * globals.PRODUCER_POLICIES_PAGING > data.length
          ? undefined
          : () => setPage(p => p + 1)
      }
    />
  );

  return loading && page === 1 ? <Spinner /> : renderContent();
};

export default ProducerPolicies;
