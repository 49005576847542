import styled from 'styled-components';

export const Submit = styled.input.attrs({ type: 'submit' })`
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 3px;
  text-align: center;
  background-color: ${props => props.theme.primary};
  transition: background-color 0.1s;
  font-family: Avenir-Medium;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  padding: 13px 0;

  &:hover {
    background-color: #d6e2f8;
    color: ${props => props.theme.primary};
  }
`;

export default Submit;
