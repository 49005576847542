import styled from 'styled-components';

export const tabsContainer = { justifyContent: 'flex-start' };

export const tabsWrapper = { padding: '0 40px' };

export const StrategyContentOutline = styled.div`
  background-color: ${props => props.theme.white};
  padding: 34px 0;
`;

export const StrategyContentWrapper = styled.div`
  width: 92%;
  margin: auto;
`;
