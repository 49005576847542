// @flow
import React, { useState } from 'react';
import InfoCard from '../../shared/info-card';
import { CollapsibleIcon, RowContainer, CollapsibleDiv } from './styles';
import { collabsiblePlus, collabsibleMinus } from '../../../assets/images';
import { SectionTitle2 } from '../../shared/texts/title';
import strings from '../../../config/strings';

type Props = {
  data: { [key: string]: any },
};
const prepareInfoData = data => [
  { value: data.agentName, label: strings.PRODUCER_NAME, type: 'text' },
  {
    value: data.agentPhoneNumber,
    label: strings.MOBILE_NUMBER,
    type: 'text',
  },
  {
    value: data.agentEmail,
    label: strings.FORM_INPUT_LABEL_EMAIL,
    type: 'text',
  },
];

const PolicyBrokerInfo = ({ data }: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <>
      <RowContainer>
        <SectionTitle2 style={{ marginBottom: '30px' }}>
          {strings.PRODUCER_DETAILS}
        </SectionTitle2>
        <CollapsibleIcon
          onClick={() => setIsClicked(!isClicked)}
          src={isClicked ? collabsibleMinus : collabsiblePlus}
          alt="collapsible"
        />
      </RowContainer>
      <CollapsibleDiv isClicked={isClicked}>
        <InfoCard data={prepareInfoData(data)} />
      </CollapsibleDiv>
    </>
  );
};

export default PolicyBrokerInfo;
