import styled from 'styled-components';

export const Spacer = styled.div`
  height: 120px;
`;

export const Container = styled.div`
  position: relative;
  z-index: 10000;
  margin: 20px;
  padding: 20px 30px;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  background-color: #eeeff8;

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const Title = styled.h3`
  color: ${props => props.theme.primary};
  font-family: Avenir-Medium;
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-family: Avenir-Medium;
  margin-bottom: 15px;
  opacity: 0.9;
  font-size: 14px;
  line-height: 27px;
  color: ${props => props.theme.primary};
`;

export const Button = styled.div`
  cursor: pointer;
  font-size: 14px;
  line-height: 2.7;
  border-radius: 3px;
  text-align: center;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  transition: background-color .1s;
  font-family: Avenir-Medium;
  padding: 8px 55px;
  display: inline;

  &:hover {
    background-color: #d6e2f8;
    color: ${props => props.theme.primary};
  }
`;
