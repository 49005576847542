import styled from 'styled-components';

export const NotificationContainer = styled.div`
  background-color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 43px;
  left: -322px;
  width: 383px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 ${props => props.theme.paleGrey70};
  max-height: 80vh;
  overflow: auto;
`;

export const ArrowUp = styled.div`
  width: 0;
  position: absolute;
  top: 32px;
  right: 81px;
  height: 0;
  z-index: 9;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${props => props.theme.white};
  transform: translateX(350%);
`;

export const NotificationWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: center;
  cursor: pointer;
  outline: none;
`;

export const ImageWrapper = styled.div`
  display: flex;

  align-self: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 20px 0px 15px;
  height: 70px;
  background-color: ${props =>
    props.read === true ? props.theme.white : props.theme.paleGrey};
`;

export const TextContainer = styled.div`
  margin-left: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 46px;
  flex-direction: column;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50px;
  align-self: flex-end;
  justify-self: flex-end;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.paleGrey70};
`;

export const DividerHolder = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  background-color: ${props =>
    props.read === true ? props.theme.white : props.theme.paleGrey};
`;

export const EndListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  justify-content: center;
`;
