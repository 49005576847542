import {
  REQUEST_GET_USER,
  SUCCESS_GET_USER,
  FAILURE_GET_USER,
  REQUEST_LOGIN,
  SUCCESS_LOGIN,
  USER_LOGOUT,
  UPDATE_USER,
} from '../constants/types';

const initialState = {
  errorMessage: '',
  loading: false,
  data: {},
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_USER:
      return { ...state, loading: true };

    case SUCCESS_GET_USER:
      return { ...state, loading: false, data: action.payload.info };

    case FAILURE_GET_USER:
      return { ...state, loading: false, errorMessage: action.payload.err };

    case REQUEST_LOGIN:
      return { ...state };

    case SUCCESS_LOGIN:
      return {
        ...state,
        account: {
          loading: false,
          requested: true,
          data: action.payload.account,
        },
      };

    case UPDATE_USER:
      return {
        ...state,
        account: {
          loading: false,
          requested: true,
          data: {
            ...state.account.data,
            user: action.payload.user,
          },
        },
      };

    case USER_LOGOUT:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default UserReducer;
