import styled from 'styled-components';
import { size } from '../../config/device';
import { Subtitle } from '../shared/texts/content';

const Description = styled(Subtitle)`
  margin-top: 30px;
  margin-bottom: -10px;
  opacity: 0.9;
  width: 30%;

  @media (max-width: ${size.tablet}) {
    width: 130%;
  }
`;

export default Description;
