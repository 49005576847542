import styled from 'styled-components';

import {
  ColumnContainer,
  ConvertableRowToColContainer,
  RowContainer,
} from '../shared/container';
import colors from '../../config/colors';
import { size } from '../../config/device';

export const strategyListTitle = { marginBottom: 5 };

export const Container = styled.div`
  margin-bottom: 60px;
`;

export const SectionBlock = styled(ConvertableRowToColContainer)`
  background-color: ${props => props.theme.white};
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3px;
  margin-bottom: 60px;

  > :nth-child(n) {
    border-right: 1px solid ${props => props.theme.whitishGrey};
    flex: 1;
    align-self: stretch;
    justify-content: flex-start;

    @media (max-width: ${size.tablet}) {
      border: 0;
      border-bottom: 1px solid ${props => props.theme.whitishGrey};
    }
  }

  > :last-child {
    border: 0;
  }

  @media (max-width: ${size.tablet}) {
    padding: 30px 18px;
  }
`;

export const PaddedSectionBlock = styled(SectionBlock)`
  padding: 40px 25px;
`;

export const SectionDescriptionBlock = styled(ColumnContainer)`
  align-items: flex-start;
  padding: 37px 48px;
  position: relative;
  width: calc(100% - 100px);
  min-height: ${({ isChart }) => isChart && '600px'};
  width: 100% !important

  :first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }

  > :first-child {
    margin-bottom: 20px;
  }

  @media (max-width: ${size.tablet}) {
    padding: 30px 0;
    width: 100%;
    min-height: ${({ isChart }) => isChart && '210px'};
  }
`;

export const PaddedSectionDescriptionBlock = styled(SectionDescriptionBlock)`
  padding: 10px 50px;

  :first-child {
    padding-left: 50px;
  }

  :last-child {
    padding-right: 50px;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 50px;
`;

export const DescriptionBlockContainer = styled(ColumnContainer)`
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(157, 157, 191, 0.2);
  background-color: ${colors.white};
  padding: 8px;
  width: 28%;
  height: 90px;
  margin-bottom: 3px;
  justify-content: space-between;
  align-items: flex-start;

  > p {
    display: inline-block;
  }
`;

export const StatsContainer = styled(RowContainer)`
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const dateCard = { width: 240, marginBottom: 50 };
