// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';

import globals from '../../../config/globals';
import BubbleChart from '../../charts/bubble';
import strings from '../../../config/strings';
import { SectionDescriptionBlock } from '../container';

import type { FundAnalysis } from '../../../models/funds';

type Props = {
  funds: Array<FundAnalysis>,
  policyCurrency: string,
};

const FundsAnalysisChart = ({ funds, policyCurrency }: Props) => (
  <SectionDescriptionBlock isChart>
    <BubbleChart
      data={funds.map(fund => ({
        x: fund.fundStyleCode,
        y: fund.fundSizeCode,
        rCustom: fund.totalUnits,
      }))}
      labels={funds.map(fund => fund.fundDescription)}
      xAxis={{
        labels: globals.CHART_ANALYSIS_LABELS.STYLE,
        min: 0,
        max: 4,
      }}
      yAxis={{
        labels: globals.CHART_ANALYSIS_LABELS.SIZE,
        min: 0,
        max: 3,
      }}
      rAxis={{
        symbol:
          !isEmpty(policyCurrency) && globals.CURRENCIES[policyCurrency].value,
      }}
      config={{
        tooltip: { label: { display: true, ref: 'rAxis' } },
        displayDatalabel: false,
      }}
      errorMessage={strings.STRATEGY_AVERAGING_ANALYSIS_EMPTY_DATA_MESSAGE}
    />
  </SectionDescriptionBlock>
);

export default FundsAnalysisChart;
