import styled from 'styled-components';
import colors from '../../../config/colors';
import { ColumnContainer } from '../../shared/container';

export const Outline = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 ${colors.paleGrey2};
  background-color: ${colors.white};
  margin-bottom: 20px;
`;

const SectionContainer = styled(ColumnContainer)`
  padding: 20px 30px 0 30px;
  width: 100%;
  box-sizing: border-box;
`;

export const LeftOutline = styled(SectionContainer)`
  flex: 7.2;
`;

export const RightOutline = styled(SectionContainer)`
  flex: 2.8;
  background-color: ${colors.white2};
  align-self: stretch;
`;
