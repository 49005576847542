import styled from 'styled-components';
import colors from '../../config/colors';
import { TotalHintContent } from '../shared/texts/content';

export const  tableCol = {
  colAlign: [
    { col: 2, value: 'left' },
    { col: 8, value: 'left' },
    { col: 9, value: 'left' },
  ],
  headerAlign: [{ col: 2, value: 'left' }],
};

export const tableRow = `5px solid ${colors.info}`;

export const BarChartTicks = styled(TotalHintContent)`
  margin: 0 12px;
`;
