// @flow

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import strings from '../../config/strings';

import {
  Image,
  Title,
  Content,
  Section,
  SectionContainer,
  ContentOutline,
  Footer,
} from './styles';

type Props = {
  data: any[];
  image: any;
};

const getImageUrl = (image) => (
  process.env.REACT_APP_CMS_URL + image.url
);

const anchor = (title: string) => title.toLowerCase().replace(/\s+/g, '-');

const PrivacyPolicy = ({ data = [], image }: Props) => (
  <SectionContainer>
    {image && (
      <Image src={getImageUrl(image)} height="200" />
    )}
    <ContentOutline>
      <ol>
        {
          data.map(section => (
            <li key={anchor(section.title)}>
              <Link smooth to={`#${anchor(section.title)}`}>
                {section.title}
              </Link>
            </li>
          ))
        }
      </ol>
    </ContentOutline>
    {
      data.map(section => (
        <Section id={anchor(section.title)} key={section.title}>
          <Title>{section.title}</Title>
          {/* eslint-disable-next-line react/no-danger */}
          <Content dangerouslySetInnerHTML={{ __html: section.description }} />
        </Section>
      ))
    }
    <Footer>{strings.ARTICLE_DETAILS_FOOTER}</Footer>
  </SectionContainer>
);

export default PrivacyPolicy;
