// @flow

import React, { useState } from 'react';
import type { Moment } from 'moment';
import moment from 'moment';

import strings from '../../../config/strings';

import {
  CollapsibleIcon,
  CollapsibleDiv,
  dateCard,
  MobileCalendarDiv,
  HeaderWrapper,
  RowContainer,
  ToolTipContainer,
} from './styles';

import { collabsiblePlus, collabsibleMinus } from '../../../assets/images';

import { formatDate } from '../../../utils/date';
import { MainContent3 } from '../../shared/texts/content';
import { Subtitle5, SectionTitle2 } from '../../shared/texts/title';
import { formatCurrency, decimalWithCommas } from '../../../utils/numbers';
import {
  policyDetailsListTitle,
  SectionContainer,
  ListRow,
  PieChartWrapper,
  ListRowContainer,
} from './container';

import SwitchContainer from '../../switch-container';
import DoughnutChart from '../../charts/doughnut';
import globals from '../../../config/globals';
import Dropdown from '../../shared/dropdown';
import DatePicker from '../../form/date';
import Tooltip from '../../shared/toolpin/hint-toolpin';
import { currenciesDropDown } from '../../shared/dropdown/container';

type Props = {
  availableCurrencies: Object[],
  data: any[],
  currency: string,
  effectiveDate: Moment,
  dateChange: Moment,
  isCalendarOpen: boolean,
  setCalendarOpenState: Function,
  handleCurrencyChange: Function,
  handleDateChange: Function,
};

const PremiumAllocation = ({
  availableCurrencies,
  data = [],
  currency,
  effectiveDate,
  isCalendarOpen,
  setCalendarOpenState,
  handleCurrencyChange,
  handleDateChange,
  dateChange,
  maxDate,
}: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState(
    globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.LIST_VIEW,
  );

  function renderCalender() {
    return (
      <div style={dateCard}>
        <DatePicker
          minDate={effectiveDate}
          maxDate={maxDate}
          value={
            dateChange
              ? formatDate(dateChange)
              : formatDate(
                  moment()
                    .startOf('day')
                    .format(),
                )
          }
          handleDateChange={handleDateChange}
          isCalendarOpen={isCalendarOpen}
          isInline={false}
          key="premiumAllocationsDate"
          setCalendarOpenState={setCalendarOpenState}
          title={strings.PERFORMANCE_POLICY_DATE_LABEL}
        />
      </div>
    );
  }

  return (
    !!data.length && (
      <SectionContainer>
        <HeaderWrapper>
          <RowContainer>
            <SectionTitle2 style={policyDetailsListTitle}>
              {strings.SECTION_TITLE_PREMIUM_ALLOCATION}
            </SectionTitle2>
          </RowContainer>

          <CollapsibleIcon
            onClick={() => setIsClicked(!isClicked)}
            src={isClicked ? collabsibleMinus : collabsiblePlus}
            alt="collapsible"
          />

          <RowContainer>
            {availableCurrencies.length > 1 && (
              <Dropdown
                options={availableCurrencies}
                onSelect={handleCurrencyChange}
                selected={currency}
                style={currenciesDropDown}
              />
            )}

            <MobileCalendarDiv>{renderCalender()}</MobileCalendarDiv>
          </RowContainer>
        </HeaderWrapper>

        <CollapsibleDiv isClicked={isClicked}>
          <SwitchContainer
            handleTabClick={setActiveTabKey}
            activeTabKey={activeTabKey}
          >
            {
              {
                [`${globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.LIST_VIEW}`]: (
                  <ListRowContainer>
                    {data.map(elt => (
                      <ListRow key={elt.key}>
                        <RowContainer>
                          <Subtitle5>{elt.key}</Subtitle5>
                          <ToolTipContainer>
                            <Tooltip
                              hint={
                                strings.SECTION_TITLE_PREMIUM_ALLOCATION_HELP_TEXT.find(
                                  el => el.key === elt.key,
                                )
                                  ? strings.SECTION_TITLE_PREMIUM_ALLOCATION_HELP_TEXT.find(
                                      el => el.key === elt.key,
                                    ).label
                                  : ''
                              }
                              keyid={elt.key}
                            />
                          </ToolTipContainer>
                        </RowContainer>
                        <MainContent3>
                          {`${formatCurrency(
                            currency.name,
                            elt.value < 0,
                          )} ${decimalWithCommas(Math.abs(elt.value))}`}
                        </MainContent3>
                      </ListRow>
                    ))}
                  </ListRowContainer>
                ),
                [`${globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.CHART_VIEW}`]: (
                  <PieChartWrapper>
                    <DoughnutChart
                      legendAlign="end"
                      legendPosition="right"
                      currency={formatCurrency(currency.name)}
                      data={data
                        .filter(el => !Number.isNaN(el.value))
                        .map(el => el.value)}
                      labels={data
                        .filter(el => !Number.isNaN(el.value))
                        .map(el => el.key)}
                      datalabels={{ position: 'outside' }}
                    />
                  </PieChartWrapper>
                ),
              }[activeTabKey]
            }
          </SwitchContainer>
        </CollapsibleDiv>
      </SectionContainer>
    )
  );
};

export default PremiumAllocation;
