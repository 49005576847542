// @flow

import React from 'react';
import type { Node } from 'react';

import Overlay from './overlay';
import { SubTitle3 } from '../texts/title';
import strings from '../../../config/strings';
import { ContentContainer, DismissContainer, DismissIcon } from './content';
import { crossIcon, crossIcon2x, crossIcon3x } from '../../../assets/images';

type Props = {
  children: Node,
  handleCloseBtnClick: Function,
  isOpen: boolean,
  style: any,
};

const Popup = ({ style, children, isOpen, handleCloseBtnClick }: Props) => (
  <Overlay isOpen={isOpen}>
    <ContentContainer style={style}>
      {handleCloseBtnClick && (
        <DismissContainer onClick={handleCloseBtnClick}>
          <SubTitle3>{strings.POPUP_DISMISS_LABEL}</SubTitle3>
          <DismissIcon
            src={crossIcon}
            srcSet={`${crossIcon2x} 2x, ${crossIcon3x} 3x`}
            alt="dismissIcon"
          />
        </DismissContainer>
      )}

      {children}
    </ContentContainer>
  </Overlay>
);

export default Popup;
