// @flow

import React from 'react';

import globals from '../../../config/globals';
import BubbleChart from '../../charts/bubble';
import { SectionDescriptionBlock } from '../container';

import type { RiskReturnFundPerformance } from '../../../models/funds';

type Props = {
  funds: Array<RiskReturnFundPerformance>,
};

const FundsChart = ({ funds }: Props) => (
  <SectionDescriptionBlock isChart>
    <BubbleChart
      data={funds.map(fund => ({
        x: fund.volatility,
        y: fund.performance,
        rCustom: fund.totalUnitsUsd,
        rSymbol: globals.CURRENCIES[fund.currency].value,
        rLabel: fund.totalUnits,
      }))}
      labels={funds.map(fund => fund.fundDescription)}
      xAxis={{
        symbol: '%',
        title: 'Volatility',
        suggestedMin: Math.min(...funds.map(fund => fund.volatility)) - 0.5,
        suggestedMax: Math.max(...funds.map(fund => fund.volatility)) + 0.5,
      }}
      yAxis={{
        symbol: '%',
        title: 'Performance',
        suggestedMin: Math.min(...funds.map(fund => fund.performance)) - 6,
        suggestedMax: Math.max(...funds.map(fund => fund.performance)) + 6,
      }}
      config={{
        tooltip: { label: { ref: 'xyAxis' } },
        displayDatalabel: false,
      }}
    />
  </SectionDescriptionBlock>
);

export default FundsChart;
