import strings from '../config/strings';

const showToast = (message = strings.REQUEST_FAILED) => {
  if (document.getElementById('toast')) {
    return false;
  }

  const toast = document.createElement('div');
  toast.className = 'Toast';
  toast.innerHTML = message;
  toast.id = 'toast';

  document.getElementById('root').appendChild(toast);

  setTimeout(() => {
    toast.style.opacity = 1;
    toast.style.top = '2.5%';
  }, 500);

  setTimeout(() => {
    toast.style.opacity = 0;
    toast.style.top = '-2.5%';
  }, 4000);

  setTimeout(() => {
    document.getElementById('root').removeChild(toast);
  }, 5000);

  return true;
};

export default showToast;
