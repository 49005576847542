import React from 'react';
import strings from '../../config/strings';
import { emptyPolicy } from '../../assets/images';
import { NoDataTitle, NoDataSubtitle } from '../shared/texts/content';
import { MainDiv, ImageDiv, SubDiv, MessageDiv } from './styles';

const EmptyPolicy = () => (
  <MainDiv>
    <SubDiv>
      <ImageDiv>
        <img src={emptyPolicy} alt="empty policy" />
      </ImageDiv>
      <MessageDiv>
        <NoDataTitle>{strings.NO_ACTIVE_POLICIES}</NoDataTitle>
        <NoDataSubtitle>{strings.ALL_POLICIES_HERE}</NoDataSubtitle>
      </MessageDiv>
    </SubDiv>
  </MainDiv>
);

export default EmptyPolicy;
