// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Spinner from '../../shared/spinner';
import BeneficiaryCard from './beneficiary-card';
import strings from '../../../config/strings';
import { SectionTitle2 } from '../../shared/texts/title';
import { TabContainer, PageContainer } from './styles';
import { useBeneficiaries } from '../../../hooks/policies.hooks';

type Props = {
  policyId: string,
};

const BeneficiaryTab = ({ policyId }: Props) => {
  const { loading, data: beneficiaries } = useBeneficiaries(policyId);
  const primaryBeneficiaries = beneficiaries.filter(
    item => item.type === 'PRIMARY',
  );

  const contingentBeneficiaries = beneficiaries.filter(
    item => item.type === 'CONTINGENT',
  );

  primaryBeneficiaries.sort(
    ({ sharePerc: firstShare }, { sharePerc: secondShare }) =>
      secondShare - firstShare,
  );

  if (contingentBeneficiaries.length > 0) {
    contingentBeneficiaries.sort(
      ({ sharePerc: firstShare }, { sharePerc: secondShare }) =>
        secondShare - firstShare,
    );
  }

  return loading ? (
    <Spinner />
  ) : (
    <PageContainer>
      <SectionTitle2>{(primaryBeneficiaries.length <=1 ) ? strings.PRIMARY_BENEFICIARY : strings.PRIMARY_BENEFICIARIES}</SectionTitle2>
      <TabContainer>
        {!isEmpty(primaryBeneficiaries) &&
          primaryBeneficiaries.map(item => (
            <BeneficiaryCard key={item.beneficiaryId} beneficiary={item} />
          ))}
      </TabContainer>
      {contingentBeneficiaries.length > 0 && (
        <React.Fragment>
          <SectionTitle2>{(contingentBeneficiaries.length <=1 ) ? strings.CONTINGENT_BENEFICIARY : strings.CONTINGENT_BENEFICIARIES}</SectionTitle2>
          <TabContainer>
            {contingentBeneficiaries.map(item => (
              <BeneficiaryCard key={item.beneficiaryId} beneficiary={item} />
            ))}
          </TabContainer>
        </React.Fragment>
      )}
    </PageContainer>
  );
};

export default BeneficiaryTab;
