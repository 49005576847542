// @flow

import React from 'react';
import strings from '../../config/strings';
import DocumentCard from '../document-card';
import { SectionTitle2 } from '../shared/texts/title';
import { policyContractListTitle, SectionContainer } from './styles';

type Props = {
  data: any[],
  handleDownloadDocumentClick: Function,
  handleViewDocumentClick: Function,
};

const PolicyContract = ({
  data = [],
  handleDownloadDocumentClick,
  handleViewDocumentClick,
}: Props) => (
  <SectionContainer>
    <SectionTitle2 style={policyContractListTitle}>
      {strings.SECTION_TITLE_POLICY_CONTRACT}
    </SectionTitle2>
    {data.length < 1 && (
      <DocumentCard empty emptyClause={strings.NO_POLICY_CONTRACT} />
    )}
    {data.map(el => (
      <DocumentCard
        documentName={el.description}
        documentType={el.documentType}
        handleDownloadDocumentClick={handleDownloadDocumentClick}
        handleViewDocumentClick={handleViewDocumentClick}
        docType="contracts"
        urlPath={el.path}
        key={el.id}
      />
    ))}
  </SectionContainer>
);

export default PolicyContract;
