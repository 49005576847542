// @flow

import React, { useState, useCallback } from 'react';

import {
  Container,
  LevelHead,
  LevelBody,
  LevelContainer,
  IconContainer,
} from './styles';

import ProducerTableHead from './head';
import LevelsHeaderContainer from './levels-header';

import { ProducerStats } from '../../../models/producer';

import Spinner from '../../shared/spinner';
import { getProducerCsv } from '../../../api/producer';
import { CollapsibleIcon } from '../../shared/collapsible/styles';
import { collabsiblePlus, collabsibleMinus } from '../../../assets/images';
import ProducerTableRow from './row';

type Props = {
  allPolicies: Boolean,
  filter: String,
  searchTerm: String,
  data: ProducerStats[],
  onExpandRow: Function,
  isParent: Boolean,
  setOpen: any,
  open: any,
};

const ProducerTable = ({
  allPolicies,
  data,
  filter,
  searchTerm,
  onExpandRow,
  isParent,
  setOpen,
  open,
}: Props) => {
  const [childData, setChildData] = useState([]);
  const [loadingChildData, setLoadingChildData] = useState(false);

  const togglePanel = (level, id) => {
    setLoadingChildData(true);

    onExpandRow(level, id).then((res) => {
      setChildData(res.data);
      setLoadingChildData(false);
    });

    setOpen(prevMap => {
      const newValue = !prevMap.get(`${level}_${id}`);

      const newMap = new Map(prevMap);

      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of prevMap.entries()) {
        if (key.startsWith(`${level}_`)) {
          newMap.set(key, false);
        }
      }

      newMap.set(`${level}_${id}`, newValue);

      return newMap;
    });
  };

  const renderCollapsibleIcon = useCallback((level: string, id: number, disabled: boolean) => (
    <IconContainer disabled={disabled} onClick={() => !disabled && togglePanel(level, id)}>
      {!open.get(`${level}_${id}`)
        ? <CollapsibleIcon src={collabsiblePlus} alt="+" />
        : <CollapsibleIcon src={collabsibleMinus} alt="-" />
      }
    </IconContainer>
  ), [open, onExpandRow, childData, loadingChildData]);

  const renderChildTable = (d) => {
    if (d.length && d[0].userId) {
      return (
        d.map(el => (
          <ProducerTableRow
            key={el.userId}
            {...{ data: el, filter, allPolicies, searchTerm }}
          />
        ))
      );
    }

    const element = (
      <ProducerTable
        {...{
          filter,
          searchTerm,
          onExpandRow,
          allPolicies,
          data: d,
          setOpen,
          open,
        }}
      />
    );

    return React.cloneElement(element);
  };

  return (
    <Container>
      {isParent && (
        <LevelsHeaderContainer />
      )}
      {!!(isParent && data.length && data[0].userId) && data.map(stats => (
        <ProducerTableRow
          key={stats.userId}
          {...{ data: stats, filter, allPolicies, searchTerm }}
        />
      ))}
      {!!(data.length && !data[0].userId) && data.map(stats => (
        <LevelContainer key={`${stats.userTypeCode}_${stats.levelId}`}>
          <LevelHead>
            <ProducerTableHead
              stats={stats}
              filter={filter}
              id={`${stats.userTypeCode}_${stats.levelId}`}
              searchTerm={searchTerm}
              onExport={getProducerCsv}
              renderIcon={renderCollapsibleIcon}
            />
          </LevelHead>

          {open.get(`${stats.userTypeCode}_${stats.levelId}`) && (
            <LevelBody>
              {loadingChildData
                ? <Spinner />
                : renderChildTable(childData)
              }
            </LevelBody>
          )}
        </LevelContainer>
      ))}
    </Container>
  );
};

export default ProducerTable;
