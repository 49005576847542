import styled from 'styled-components';

export const Container = styled.div`
  top: 75px;
  width: 100%;
  z-index: 100;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 #e4e6ef;
  background-color: ${props => props.theme.white};

  &:after, &:before {
    bottom: 100%;
    left: 85px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    border-width: 15px;
    margin-left: -15px;
  }
`;

export const DropdownContainer = styled.div`
  margin: 0 10px 20px 10px;
`;

export const ContentWrapper = styled.div`
  padding: 30px 23px;
`;

export const DropdownsWrapper = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
`;

export const ActionsWrapper = styled.div`
  justify-content: space-between;
  padding-top: 13px;
  flex-wrap: wrap;
  display: flex;
`;

export const CheckboxWrapper = styled.div`
  margin: 0 10px;
`;

export const Button = styled.div`
  display: inline-block;
  cursor: pointer;

  width: 210px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3px;

  font-size: 18px;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  font-family: Avenir-Medium;
  color: ${props => props.theme.primary};
  border: solid 2px ${props => props.theme.primary};

  ${({ theme, type }) => {
    if (type === 'positive') {
      return `
        color: ${theme.white};
        background-color: ${theme.primary};
      `;
    }

    return '';
  }}
`;
