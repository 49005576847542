// @flow
import React from 'react';
import moment from 'moment';
import strings from '../../config/strings';
import {
  CardContainer,
  TextContainer,
  RowContainer,
  HorizontalDivider,
  DividerHolder,
} from './styles';
import {
  NotificationContent,
  NotificationLink,
  NotificationDate,
} from '../shared/texts/content';

import ProfilePic from '../profile-pic';

type Props = {
  data: Array<any>,
};
const NotificationCard = ({ data }: Props) => {
  const { title, updated_at: updatedAt, read } = data;
  const date = moment(updatedAt).fromNow();

  return (
    <>
      <CardContainer read={read}>
        <ProfilePic />
        <TextContainer>
          <NotificationContent>{title}</NotificationContent>
          <RowContainer>
            <NotificationLink>{strings.VIEW_INBOX}</NotificationLink>
            <NotificationDate>{date}</NotificationDate>
          </RowContainer>
        </TextContainer>
      </CardContainer>
      <DividerHolder read={read}>
        <HorizontalDivider />
      </DividerHolder>
    </>
  );
};

export default NotificationCard;
