// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  defaultProfilePic,
  defaultBusinessProfilePic,
} from '../../assets/images';
import { Outline, Image } from './outline';

type Props = {
  profilePicSrc: ?string,
  containerStyle?: Object,
  isCompany?: boolean,
  withDot?: boolean,
};

const ProfilePic = ({ containerStyle, isCompany, profilePicSrc, withDot }: Props) => (
  <Outline style={containerStyle} withDot={withDot}>
    {isEmpty(profilePicSrc) ? (
      <Image
        src={isCompany ? defaultBusinessProfilePic : defaultProfilePic}
        alt="default_profile"
      />
    ) : (
        <Image src={profilePicSrc} alt="profile_image" />
      )}
  </Outline>
);

ProfilePic.defaultProps = {
  containerStyle: {},
  isCompany: false,
  withDot: false,
};

export default ProfilePic;
