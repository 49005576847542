// @flow

import styled from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const ArrowDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 18px
  height: 14px;
  margin-right: 10px;
`;
