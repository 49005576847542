import {
  REQUEST_GET_SERVICE_FORMS,
  SUCCESS_GET_SERVICE_FORMS,
  FAILURE_GET_SERVICE_FORMS,
} from '../constants/types';

import getServiceFormsAPI from '../../api/service-forms';

const requestGetServiceForms = () => ({ type: REQUEST_GET_SERVICE_FORMS });

const successGetServiceForms = (forms) => ({
  type: SUCCESS_GET_SERVICE_FORMS,
  payload: { forms },
});

const failureGetServiceForms = (err) => ({
  type: FAILURE_GET_SERVICE_FORMS,
  payload: { err },
});

const getServiceForms = () => async (dispatch) => {
  dispatch(requestGetServiceForms());

  try {
    const { data } = await getServiceFormsAPI();

    dispatch(successGetServiceForms(data));
  } catch (error) {
    dispatch(failureGetServiceForms(error));
  }
};

export default getServiceForms;
