import styled from 'styled-components';
import colors from '../../../config/colors';

export const HintOutline = styled.div`
  display: ${({ showHint }) => (showHint ? 'block' : 'none')};
  position: fixed;
  z-index: 20;
  margin-left: -85px;
`;

export const HintContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ isLarge }) => (isLarge ? '241px' : '159px')};
  padding: 15px;
  border-radius: 3px;
  box-shadow: -1px 0px 6px 0 ${colors.paleGrey2};
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  z-index: 9;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${colors.white};
  transform: translateX(350%);
`;

export const ImageDiv = styled.div`
  outline: none;
  cursor: pointer;
`;
