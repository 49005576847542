import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 24px;
`;

export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  display: block;
  padding: 0 0 0 35px;
  cursor: pointer;

  opacity: 0.9;
  font-size: 16px;
  line-height: 27px;
  font-family: Avenir-Roman;
  color: ${props => props.theme.slateGrey};

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 2px solid ${props => props.theme.primary};
    border-radius: 2px;
    z-index: 1;
    transition: all .28s cubic-bezier(.4, 0, .2, 1);
    transition-property: background-color, border-color;
  }

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 12px;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    transform: rotate(45deg);
    z-index: 2;
    transition: border-color .28s cubic-bezier(.4, 0, .2, 1);
  }

  ${({ theme, checked }) => {
    if (checked) {
      return `
        &:before {
          background-color: ${theme.primary};
          border-color: ${theme.primary};
        }

        &:after {
          border-color: #fff;
        }
      `
    }

    return '';
  }}
`;
