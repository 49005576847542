// @flow

import React from 'react';
import {
  scrollableContainer,
  scrollbarHorizontal,
  scrollbarVertical,
} from './styles';

type Props = {
  id: String,
  orientation: 'horizontal' | 'vertical',
  scrollbarwidth: number,
  scrollbarHeight: number,
  scrollableHeight: number,
  scrollableWidth: number,
  topPosition: number,
};

const ScrollBarImitation = ({
  id,
  orientation,
  scrollableHeight,
  scrollableWidth,
  scrollbarHeight,
  scrollbarwidth,
  topPosition,
}: Props) => (
  <div
    id={id}
    style={
      orientation === 'horizontal'
        ? scrollbarHorizontal(scrollbarwidth, topPosition)
        : scrollbarVertical(scrollbarHeight, topPosition)
    }
  >
    <div
      style={scrollableContainer(
        scrollableWidth,
        scrollableHeight,
        orientation,
      )}
    />
  </div>
);

export default ScrollBarImitation;
