// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import type { Moment } from 'moment';
import { dateToString } from '../utils/date';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getPremiumAllocation = (
  policyId: string,
  dateChange: Moment,
  currencyId: number,
) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const dateStr = dateToString(dateChange);
  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/policies/${policyId}/allocations/premium?date=${dateStr}&currencyId=${currencyId}`;

  return axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });
};

export default getPremiumAllocation;
