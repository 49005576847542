// @flow

import React, { Fragment } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { Chart, Bubble } from 'react-chartjs-2';

import {
  axisTitle,
  chartGridLinesStyle,
  tooltipStyleNoTitle,
  yAxisTickStyle,
  xAxisTickStyle,
} from './styles';
import {
  decimalWithCommas,
  isEmptyNumber,
  numberWithCommas,
} from '../../utils/numbers';
import EmptyData from './empty-data';
import strings from '../../config/strings';
import colors, { colorPalette } from '../../config/colors';

import 'chartjs-plugin-style';
import 'chartjs-plugin-datalabels';

type PointObject = { x: number, y: number, r: number };
type ChartAxisConfs = {
  suggestedMin?: number,
  symbol?: string,
  title?: string,
  labels?: Array<string>,
};

type Props = {
  config?: {
    tooltip: {
      label: { display: boolean, ref: 'rAxis' | 'xAxis' | 'yAxis' | 'xyAxis' },
    },
    displayDatalabel?: boolean,
  },
  data: Array<PointObject>,
  labels: Array<string>,
  xAxis?: ?ChartAxisConfs,
  yAxis?: ?ChartAxisConfs,
  rAxis?: ?ChartAxisConfs,
  errorMessage?: string,
};

const BubbleChart = ({
  config,
  data,
  labels,
  xAxis,
  yAxis,
  rAxis,
  errorMessage,
}: Props) => (
  <Fragment>
    <Bubble
      data={{
        datasets: [
          {
            data,
            backgroundColor: colorPalette,
            borderWidth: 0,
            hoverBorderColor: colors.paleGreyDark,
            hoverBorderWidth: 2,
            hoverRadius: 5,
            radius: context => {
              const index = context.dataIndex;
              const dataArr = context.dataset.data[index];
              const size = context.chart.width;
              const base =
                dataArr.rCustom / Math.max(...data.map(elt => elt.rCustom));
              const value = (size / 5) * base;
              return value;
            },
          },
        ],
        labels,
      }}
      plugins={[
        {
          beforeDatasetsDraw(chart) {
            Chart.helpers.canvas.clipArea(chart.ctx, chart.chartArea);
          },
          afterDatasetsDraw(chart) {
            Chart.helpers.canvas.unclipArea(chart.ctx);
          },
        },
      ]}
      options={{
        plugins: {
          datalabels: {
            display:
              config.displayDatalabel || isUndefined(config.displayDatalabel)
                ? 'auto'
                : false,
            anchor: 'start',
            align: 'bottom',
            clamp: true,
            color: colors.primaryDark,
            font: {
              family: 'Avenir-Medium',
              weight: 500,
            },

            clip: { left: false, top: false, right: false, bottom: false },
            formatter: value => {
              const defaultRValue = value.r;
              const bubbleRValue = isEmptyNumber(defaultRValue)
                ? value.rCustom
                : defaultRValue;

              const datalabel =
                !isEmpty(rAxis) && !isEmpty(rAxis.symbol)
                  ? `${rAxis.symbol} ${decimalWithCommas(bubbleRValue)}`
                  : decimalWithCommas(bubbleRValue);

              return datalabel;
            },
          },
        },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        lineHeightAnnotation: {
          always: false,
          hover: false,
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: !isEmpty(xAxis.title),
                labelString: xAxis.title,
                ...axisTitle,
              },
              gridLines: { display: false },
              ticks: {
                min: xAxis.min && xAxis.min,
                max: xAxis.max && xAxis.max,
                callback: value => {
                  let tick = value;

                  if (!isEmpty(xAxis) && !isEmpty(xAxis.labels)) {
                    tick = xAxis.labels[value];
                  } else if (!isEmpty(xAxis) && !isEmpty(xAxis.symbol)) {
                    tick = `${numberWithCommas(value)}${xAxis.symbol}`;
                  }

                  return tick;
                },
                ...xAxisTickStyle,
                minRotation: 0,
                suggestedMin:
                  !isEmpty(xAxis) && !isEmptyNumber(xAxis.suggestedMin)
                    ? xAxis.suggestedMin
                    : !isEmpty(xAxis) &&
                      !isEmpty(xAxis.labels) &&
                      Math.min(...Object.keys(xAxis.labels)),
                suggestedMax:
                  !isEmpty(xAxis) && !isEmptyNumber(xAxis.suggestedMax)
                    ? xAxis.suggestedMax
                    : !isEmpty(xAxis) &&
                      !isEmpty(xAxis.labels) &&
                      Math.max(...Object.keys(xAxis.labels)),
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: !isEmpty(yAxis.title),
                labelString: yAxis.title,
                ...axisTitle,
              },
              gridLines: chartGridLinesStyle,
              ticks: {
                min: yAxis.min && yAxis.min,
                max: yAxis.max && yAxis.max,
                callback: value => {
                  let tick = value;

                  if (!isEmpty(yAxis) && !isEmpty(yAxis.labels)) {
                    tick = yAxis.labels[value];
                  } else if (!isEmpty(yAxis) && !isEmpty(yAxis.symbol)) {
                    tick = `${numberWithCommas(value)}${yAxis.symbol}`;
                  }

                  return tick;
                },
                ...yAxisTickStyle,
                suggestedMin:
                  !isEmpty(yAxis) && !isEmptyNumber(yAxis.suggestedMin)
                    ? yAxis.suggestedMin
                    : !isEmpty(yAxis) &&
                      !isEmpty(yAxis.labels) &&
                      Math.min(...Object.keys(yAxis.labels)),
                suggestedMax:
                  !isEmpty(yAxis) && !isEmptyNumber(yAxis.suggestedMax)
                    ? yAxis.suggestedMax
                    : !isEmpty(yAxis) &&
                      !isEmpty(yAxis.labels) &&
                      Math.max(...Object.keys(yAxis.labels)) + 1,
              },
            },
          ],
        },
        tooltips: {
          ...tooltipStyleNoTitle,
          callbacks: {
            label: (tooltipItem, dataArr) => {
              let tooltipLabel: '';
              const title = `${dataArr.labels[tooltipItem.index]}:`;

              if (
                isUndefined(config.tooltip.label.display) ||
                config.tooltip.label.display
              ) {
                switch (config.tooltip.label.ref) {
                  case 'xyAxis': {
                    if (
                      !isEmpty(xAxis) &&
                      !isEmpty(xAxis.symbol) &&
                      !isEmpty(xAxis.title) &&
                      !isEmpty(yAxis) &&
                      !isEmpty(yAxis.symbol) &&
                      !isEmpty(yAxis.title)
                    ) {
                      tooltipLabel = `${xAxis.title}: ${decimalWithCommas(
                        tooltipItem.xLabel,
                      )}${xAxis.symbol}, ${yAxis.title}: ${decimalWithCommas(
                        tooltipItem.yLabel,
                      )}${yAxis.symbol}`;
                    }

                    if (!config.displayDatalabel) {
                      const symbol =
                        rAxis && rAxis.symbol
                          ? rAxis.symbol
                          : dataArr.datasets[tooltipItem.datasetIndex].data[
                              tooltipItem.index
                            ].rSymbol;

                      const itemData =
                        dataArr.datasets[tooltipItem.datasetIndex].data[
                          tooltipItem.index
                        ];

                      const value = itemData.rLabel || itemData.rCustom;

                      tooltipLabel += `, Value: ${symbol} ${decimalWithCommas(
                        value,
                      )}`;
                    }
                    break;
                  }

                  case 'rAxis': {
                    tooltipLabel = `${rAxis.symbol} `;
                    tooltipLabel += decimalWithCommas(
                      dataArr.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ].r ||
                        dataArr.datasets[tooltipItem.datasetIndex].data[
                          tooltipItem.index
                        ].rCustom,
                    );
                    break;
                  }

                  default:
                    tooltipLabel = `(${tooltipItem.xLabel}, ${tooltipItem.yLabel})`;
                    break;
                }
              }

              const tooltip = [title, tooltipLabel];

              return tooltip;
            },
          },
        },
      }}
    />

    {isEmpty(data) && <EmptyData>{errorMessage}</EmptyData>}
  </Fragment>
);

BubbleChart.defaultProps = {
  config: { tooltip: { label: { display: true } }, displayDatalabel: true },
  xAxis: { title: '', symbol: '', labels: [] },
  yAxis: { title: '', symbol: '', labels: [], suggestedMin: null },
  rAxis: { title: '', symbol: '', labels: null },
  errorMessage: strings.NO_DATA,
};

export default BubbleChart;
