// @flow

import React from 'react';
import { isUndefined, isNull } from 'lodash';

import StatsBlock from './stats-block';
import strings from '../../../config/strings';
import { StatsContainer } from '../container';
import { decimalWithCommas } from '../../../utils/numbers';

import type { FundInfo } from '../../../models/funds';

type Props = {
  info: FundInfo,
  policyCurrency: string,
};

const Stats = ({ info, policyCurrency }: Props) => (
  <StatsContainer>
    {!(
      isNull(info.purchaseTotalUnits) || isUndefined(info.purchaseTotalUnits)
    ) && (
      <StatsBlock
        title={strings.STRATEGY_AVERAGING_STATS_LABELS.purchaseTotalUnits}
        value={decimalWithCommas(info.purchaseTotalUnits)}
      />
    )}

    {!(
      isNull(info.purchaseTotalAmount) || isUndefined(info.purchaseTotalAmount)
    ) && (
      <StatsBlock
        title={strings.STRATEGY_AVERAGING_STATS_LABELS.purchaseTotalAmount}
        value={`${policyCurrency} ${decimalWithCommas(
          info.purchaseTotalAmount,
        )}`}
      />
    )}

    {!(
      isNull(info.purchaseAveragePrice) ||
      isUndefined(info.purchaseAveragePrice)
    ) && (
      <StatsBlock
        title={strings.STRATEGY_AVERAGING_STATS_LABELS.purchaseAveragePrice}
        value={`${policyCurrency} ${decimalWithCommas(
          info.purchaseAveragePrice,
        )}`}
      />
    )}

    {!(
      isNull(info.deductedTotalUnits) || isUndefined(info.deductedTotalUnits)
    ) && (
      <StatsBlock
        title={strings.STRATEGY_AVERAGING_STATS_LABELS.deductedTotalUnits}
        value={decimalWithCommas(info.deductedTotalUnits)}
      />
    )}

    {!(
      isNull(info.deductedTotalAmount) || isUndefined(info.deductedTotalAmount)
    ) && (
      <StatsBlock
        title={strings.STRATEGY_AVERAGING_STATS_LABELS.deductedTotalAmount}
        value={`${policyCurrency} ${decimalWithCommas(
          info.deductedTotalAmount,
        )}`}
      />
    )}

    {!(
      isNull(info.deductedAveragePrice) ||
      isUndefined(info.deductedAveragePrice)
    ) && (
      <StatsBlock
        title={strings.STRATEGY_AVERAGING_STATS_LABELS.deductedAveragePrice}
        value={`${policyCurrency} ${decimalWithCommas(
          info.deductedAveragePrice,
        )}`}
      />
    )}
  </StatsContainer>
);

export default Stats;
