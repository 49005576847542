// @flow

import React from 'react';
import { isEmpty } from 'lodash';

import Stats from './stats';
import LineChart from '../../charts/line';
import Dropdown from '../../shared/dropdown';
import globals from '../../../config/globals';
import strings from '../../../config/strings';
import { SectionDescriptionBlock, ChartContainer } from '../container';

import type { FundInfo } from '../../../models/funds';
import type { DropDownOption } from '../../../models/form';

type Props = {
  chartData: {
    stats: Array<number | string>,
    average: Array<number>,
    labels: Array<string>,
  },
  chartPointsStyle: Array<string>,
  fundInfo: FundInfo,
  fundsOptions: Array<DropDownOption>,
  handleFundsDropdownClick: Function,
  policyCurrency: string,
  selectedFundId: number | string,
};

const FundsAveragingChart = ({
  chartData,
  chartPointsStyle,
  fundsOptions,
  fundInfo,
  handleFundsDropdownClick,
  policyCurrency,
  selectedFundId,
}: Props) => (
  <>
    <SectionDescriptionBlock isChart>
      {fundsOptions.length > 2 && (
        <Dropdown
          options={fundsOptions}
          title={fundsOptions.filter(opt => opt.id === selectedFundId)[0].name}
          selected={fundsOptions.filter(opt => opt.id === selectedFundId)[0]}
          onSelect={id => id !== -1 && handleFundsDropdownClick(id)}
          size="large"
        />
      )}

      <Stats
        info={fundInfo}
        policyCurrency={
          !isEmpty(policyCurrency) && globals.CURRENCIES[policyCurrency].value
        }
      />

      <ChartContainer>
        <LineChart
          data={chartData.stats && chartData.stats.map(elem => elem.fundPrice)}
          data2={
            chartData.stats && chartData.stats.map(elem => elem.allocatedUnits)
          }
          data3={chartData.stats && chartData.stats.map(elem => elem.fundValue)}
          data4={chartData.average}
          labels={chartData.labels}
          tooltipLabels={chartData.tooltipLabels}
          dataName="Priced at"
          data3Name="Total Value"
          data4Name={`Average price as of ${new Date(
            Date.now(),
          ).toLocaleDateString()}`}
          symbol={
            !isEmpty(policyCurrency) && globals.CURRENCIES[policyCurrency].value
          }
          chartPointsStyle={chartPointsStyle}
          errorMessage={strings.STRATEGY_AVERAGING_ANALYSIS_EMPTY_DATA_MESSAGE}
        />
      </ChartContainer>
    </SectionDescriptionBlock>
  </>
);

export default FundsAveragingChart;
