// @flow

import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Notifications from '../../containers/notifications';
import ProfileInfo from './profile';
import HamburgerNav from '../shared/hamburger-menu/hamurger-nav';
import strings from '../../config/strings';
import { LogoutLink, PageName } from '../shared/texts/navigation-links';
import NavigationDropDown from '../navigation-dropdown';
import { logout } from '../../assets/images';
import { LogoDiv, ProfileDiv, HorizontalDivider } from '../shared';
import { logoutUser } from '../../redux/actions/user';

import {
  HamburgerDiv,
  FixedDiv,
  UpperDiv,
  MenuWrapper,
  RightHeaderWrapper,
  ItemWrapper,
  SectionDivider,
  MarginRight,
  ImageWrapper,
  PageNameWrapper,
} from './styles';

import {
  FAQS,
  INDEX,
  LOGIN,
  LIBRARY,
  SERVICE_FORMS,
  PRIVACY_POLICY,
  POLICY_DETAILS,
  ARTICLE_DETAILS,
  PROFILE_DETAILS,
  PRODUCER,
  INBOX,
  PRODUCER_POLICIES,
  PRODUCER_POLICY_DETAILS,
  PRODUCER_CUSTOMER_INFORMATION,
} from '../../router/routes';

import globals from '../../config/globals';
import CookieDisclaimer from '../cookie-disclaimer';

type Props = {
  userProfile: ?{
    firstName: string,
    lastName: string,
    fullname: string,
    dob: string,
    maritalStatus: string,
    address: string,
    type: 'Male' | 'Female' | 'Company',
    nationality: number,
    email: string,
    phoneNumbers: Array<string>,
    profilePic: ?string,
  },
  logoUrl: string,
  allowCookies: Function,
  cookiesAllowed: Boolean,
};

const MainHeader = ({
  cookiesAllowed,
  allowCookies,
  userProfile,
  logoUrl,
}: Props) => {
  const dispatch = useDispatch();
  const [animationState, setAnimationState] = useState();
  const history = useHistory();
  const { pathname: path, hash } = useLocation();
  let userDisplayName = userProfile.firstName;
  userDisplayName += !isEmpty(userProfile.lastName)
    ? ` ${userProfile.lastName.charAt(0)}.`
    : '';

  let location;

  switch (path) {
    case INDEX:
      location = strings.OVERVIEW;
      break;

    case POLICY_DETAILS:
      location = strings.POLICY_DETAILS;

      if (hash) {
        const KEY = hash.slice(1).toUpperCase();
        if (Object.keys(globals.POLICY_TABS).includes(KEY)) {
          location = globals.POLICY_TABS[KEY].label;
        }
      }
      break;

    case INBOX:
      location = strings.INBOX;
      break;

    case LIBRARY:
      location = strings.LIBRARY;
      break;

    case PROFILE_DETAILS:
      location = strings.PROFILE_DETAILS;
      break;

    case SERVICE_FORMS:
      location = strings.SECTION_TITLE_SERVICE_FORMS;
      break;

    case PRODUCER:
      location = strings.PRODUCER;
      break;

    case FAQS:
      location = strings.FAQS;
      break;

    case PRIVACY_POLICY:
      location = strings.PRIVACY_POLICY;
      break;

    case LOGIN:
      location = strings.LOGIN;
      break;

    default:
      if (
        path.match(new RegExp(ARTICLE_DETAILS.replace(':id', '[0-9]+'), 'g'))
      ) {
        location = strings.OVERVIEW;
      } else if (
        path.match(new RegExp(PRODUCER_POLICIES.replace(':id', '[0-9]+'), 'g'))
      ) {
        location = strings.PRODUCER_POLICIES_TITLE;
      } else if (
        path.match(new RegExp(PRODUCER_POLICY_DETAILS.replace(':id', '[0-9]+'), 'g'))
      ) {
        location = strings.POLICY_DETAILS;
      } else if (
        path.match(new RegExp(PRODUCER_CUSTOMER_INFORMATION.replace(':id', '[0-9]+'), 'g'))
      ) {
        location = strings.PRODUCER_CUSTOMER_INFORMATION;
      } else {
        location = '';
      }
  }

  const setPageTitle = () => {
    const pageTitle = `${location}${' - '}${strings.WEALTH_BUILDER}`;
    document.title = pageTitle;
  };

  useEffect(() => {
    setPageTitle();
  }, [location]);

  const onMenuClick = () => {
    setAnimationState(!animationState);
  };

  const onChangePath = () => {
    setTimeout(() => {
      setAnimationState(false);
    }, 150);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    history.push(LOGIN);
  };

  return (
    <FixedDiv id="main-header">
      <CookieDisclaimer {...{ cookiesAllowed, allowCookies }} />
      <UpperDiv>
        <div style={MenuWrapper}>
          <div style={HamburgerDiv}>
            <HamburgerNav
              animationState={animationState}
              onClick={onMenuClick}
            />
          </div>

          <NavigationDropDown
            animationState={animationState}
            onChangePath={onChangePath}
          />
        </div>
        <div style={RightHeaderWrapper}>
          <LogoDiv>
            <div
              role="button"
              tabIndex={0}
              style={ImageWrapper}
              onClick={() => history.push(INDEX)}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  history.push(INDEX);
                }
              }}
            >
              {logoUrl && <img src={logoUrl} alt="logo" height="45" />}
            </div>

            <SectionDivider isHiddenSM={false}>
              <HorizontalDivider />
            </SectionDivider>

            <PageNameWrapper>
              <PageName>{location}</PageName>
            </PageNameWrapper>
          </LogoDiv>

          <ProfileDiv style={MarginRight}>
            <div style={ItemWrapper}>
              <Notifications />
            </div>

            <SectionDivider>
              <HorizontalDivider />
            </SectionDivider>

            <div
              tabIndex={0}
              role="button"
              style={ItemWrapper}
              onClick={handleLogout}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleLogout();
                }
              }}
            >
              <div style={ImageWrapper}>
                <img src={logout} alt="logout" width="16" />
              </div>
              <LogoutLink>{strings.LOGOUT}</LogoutLink>
            </div>

            <SectionDivider>
              <HorizontalDivider />
            </SectionDivider>

            {!isEmpty(userProfile) && (
              <div
                style={ItemWrapper}
                onClick={() => history.push(PROFILE_DETAILS)}
                tabIndex={0}
                role="button"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleLogout();
                  }
                }}
              >
                <ProfileInfo
                  displayName={userDisplayName}
                  profilePicSrc={userProfile.profilePic}
                  userType={userProfile.type}
                />
              </div>
            )}
          </ProfileDiv>
        </div>
      </UpperDiv>
    </FixedDiv>
  );
};

export default MainHeader;
