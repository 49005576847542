/* eslint-disable react/no-array-index-key */
// @flow

import React, { Fragment, useEffect, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';

import GrowthIcon from './images';
import {
  arrowDownLoss,
  arrowDownLoss2x,
  arrowDownLoss3x,
  arrowUpProfit,
  arrowUpProfit2x,
  arrowUpProfit3x,
} from '../../../assets/images';

import { DocumentLinkLabel } from './links';
import { NoDataSubtitle } from '../texts/content';
import { RowContainer } from '../container';
import PopupContent from './popup-contents';
import { formatDate } from '../../../utils/date';

import {
  Spacer,
  CustomRow,
  TableContainer,
  MainContainer,
  TableRow,
} from './container';

import {
  numberWithCommas,
  formatCurrency,
  decimalWithCommas,
  isEmptyNumber,
} from '../../../utils/numbers';

import Button from './button';
import strings from '../../../config/strings';
import PopupOverlay from '../../../containers/popup';
import { FileViewer } from '../../../utils/downloader';
import ScrollBarImitation from '../scrollbar';

type HeaderDataElement = {
  key: String,
  label: String,
  type: 'text' | 'number' | 'integer' | 'currency' | 'date' | 'growth' | 'link',
  custom?: { shouldReplaceNullWithString: boolean },
};

type CustomStyle = {
  isBoldCurrency?: boolean,
};

type Props = {
  onLoadMore: Function,
  headerData: HeaderDataElement[],
  contentData: any[],
  customStyle?: CustomStyle,
  rowBorderStyle: Object,
  currency: String,
  totals: String[],
  totalsData?: Object[],
  customRow: any,
  nomargin: Boolean,
  footerNotes: string,
  shouldScrollInside?: Boolean,
};

const htmlElementIds = {
  horizontalMainContainerScrollbarImitation:
    'mainContainerHorizontalScrollbarImititation',
  verticalDataTableScrollbarImitation: 'dataTableVerticalScrollbarImititation',
};

const Table = ({
  onLoadMore,
  headerData = [],
  contentData = [],
  customStyle,
  rowBorderStyle,
  currency = '$',
  totals = [],
  totalsData = [],
  customRow,
  nomargin,
  footerNotes,
  shouldScrollInside,
}: Props) => {
  const [scrollbarwidth, setScrollbarWidth] = useState();
  const [scrollableWidth, setScrollableWidth] = useState();
  const [scrollbarHeight, setScrollbarHeight] = useState();
  const [scrollableHeight, setScrollableHeight] = useState();
  const [scrollbarTopPosition, setScrollbarTopPosition] = useState(0);
  const [scrollbarPosition, setScrollbarPosition] = useState(0);
  const [isPopupOpen, setPopupOpenState] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const setInitialScrollbarState = () => {
    const mainContainer = document.getElementById('mainContainer');
    const tableBody = document.getElementById('dataTableBody');

    setScrollbarWidth(mainContainer.offsetWidth);
    setScrollableWidth(mainContainer.scrollWidth);

    setScrollbarHeight(tableBody.offsetHeight);
    setScrollableHeight(tableBody.scrollHeight);
  };

  const setTableHeaderColumnsWidth = () => {
    if (shouldScrollInside) {
      const table = document.getElementById('dataTable');

      const thead = table.querySelector('thead');
      const tbody = table.querySelector('tbody');

      const theadColumns = thead.querySelectorAll('tr th');
      const tbodyColumns = tbody.querySelectorAll('tr td');

      if (theadColumns) {
        theadColumns.forEach((column, index) => {
          if (tbodyColumns && tbodyColumns[index]) {
            const allBodyColumns = [...tbodyColumns];
            const bodyColumnWidth = allBodyColumns[index].clientWidth;
            const columnWidth = bodyColumnWidth - 30;

            // eslint-disable-next-line no-param-reassign
            column.style.width = `${columnWidth}px`;
          }
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setInitialScrollbarState);

    return () => {
      window.removeEventListener('resize', setInitialScrollbarState);
    };
  }, []);

  useEffect(() => {
    setInitialScrollbarState();
    setTableHeaderColumnsWidth();
  }, [
    document.getElementById('dataTable') &&
      document.getElementById('dataTable').scrollWidth,
  ]);

  function handleLinkClick(name: string, path: string) {
    const {
      REACT_APP_WB_BACKEND_URL,
      REACT_APP_ACCEPT_HEADER_WB,
    } = process.env;

    const url = `${REACT_APP_WB_BACKEND_URL || ''}/documents`;
    const headers = {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    };
    const params = { name, path };

    FileViewer(url, headers, params);
  }

  function handleViewMoreLinksClick(data) {
    setPopupOpenState(true);
    setSelectedRowData(data);
  }

  function formatRow(value, type, data, custom) {
    if (value === undefined) {
      return '';
    }

    switch (type) {
      case 'date':
        return formatDate(value);

      case 'integer':
        return numberWithCommas(value);

      case 'number': // supports decimal numbers
        return decimalWithCommas(value);

      case 'currency': {
        const dataCurrency = (custom && custom.currency) || currency;

        return `${
          !isEmptyNumber(value) ? formatCurrency(dataCurrency, value < 0) : ''
        } ${decimalWithCommas(
          value,
          true,
          custom && custom.shouldReplaceNullWithString,
        )}`;
      }

      case 'growth':
        return (
          <RowContainer>
            {value < 0 ? (
              <GrowthIcon
                src={arrowDownLoss}
                alt="decrease_icon"
                srcSet={`${arrowDownLoss2x} 2x, ${arrowDownLoss3x} 3x`}
              />
            ) : (
              <GrowthIcon
                src={arrowUpProfit}
                alt="increase_icon"
                srcSet={`${arrowUpProfit2x} 2x, ${arrowUpProfit3x} 3x`}
              />
            )}
            <p>{decimalWithCommas(value)}</p>
          </RowContainer>
        );

      case 'link':
        return (
          <React.Fragment>
            {{
              0: <p>{value.label}</p>,
              1: (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    handleLinkClick(
                      value.links[0].name.split(',')[0],
                      value.links[0].path,
                    )
                  }
                  onKeyPress={event => {
                    if (event.key === 'Enter')
                      handleLinkClick(
                        value.links[0].name.split(',')[0],
                        value.links[0].path,
                      );
                  }}
                >
                  <DocumentLinkLabel>{value.label}</DocumentLinkLabel>
                </div>
              ),
            }[value.links.length] || (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleViewMoreLinksClick(data)}
                onKeyPress={event => {
                  if (event.key === 'Enter') handleViewMoreLinksClick(data);
                }}
              >
                <DocumentLinkLabel>{value.label}</DocumentLinkLabel>
              </div>
            )}
          </React.Fragment>
        );

      case 'jsx':
        return value();

      default:
        return value;
    }
  }

  function renderContentRow(rowData, rowIndex, useHeader = false) {
    const heavyFont = {
      fontWeight: '900',
      fontFamily: 'Avenir-Heavy',
    };

    if (useHeader) {
      return headerData.map((head, i) => (
        <td
          key={`${head.key}_${i}`}
          style={
            head.type === 'currency' &&
            customStyle &&
            (isUndefined(customStyle.isBoldCurrency) ||
              customStyle.isBoldCurrency)
              ? heavyFont
              : {}
          }
        >
          {formatRow(rowData[head.key], head.type, rowData, {
            ...head.custom,
            currency: rowData.currency,
          })}
        </td>
      ));
    }

    return Object.entries(rowData).map(([key, value], i) => (
      <td key={`${key}_${i}`}>{value}</td>
    ));
  }

  function renderTotalRow(shouldCalculateFromContent = true, rowData) {
    return headerData.map((head, i) => {
      const key = `total_${head.key}_${i}`;

      if (i === 0) {
        return (
          <td key={key}>
            {strings.TOTAL} {rowData.currency}
          </td>
        );
      }

      if (shouldCalculateFromContent && totals.includes(head.key)) {
        const value = contentData.reduce(
          (acc, curr) => acc + +`${curr[head.key]}`.replace(/[^0-9.-]/, ''),
          0,
        );

        return <td key={key}>{formatRow(value, head.type)}</td>;
      }
      if (!shouldCalculateFromContent) {
        return (
          <td key={key}>
            {formatRow(rowData[head.totalsKey], head.type, null, {
              currency: rowData.currency,
            })}
          </td>
        );
      }

      return <td key={key} />;
    });
  }

  function renderCustomRow() {
    return headerData.map((head, i) => {
      const key = `custom_${head.key}_${i}`;

      return <td key={key}>{formatRow(customRow[head.key], head.type)}</td>;
    });
  }

  const shouldRenderTotalRow = !!(
    totals.length &&
    headerData.length &&
    contentData.length
  );

  const shouldRenderCustomRow = !!(
    customRow &&
    headerData.length &&
    contentData.length
  );

  const handleTableHorizontalScroll = event => {
    const customScrollbarScrollLeftPos = event.target.scrollLeft;
    const customScrollbarScrollTopPos = event.target.scrollTop;
    const defaultScrollbarScrollLeftPos = event.currentTarget.scrollLeft;
    const defaultScrollbarScrollTopPos = event.currentTarget.scrollTop;

    if (
      !(customScrollbarScrollTopPos > 0 || defaultScrollbarScrollTopPos > 0)
    ) {
      if (defaultScrollbarScrollLeftPos !== scrollbarPosition) {
        const scrollbarImitation = document.getElementById(
          htmlElementIds.horizontalMainContainerScrollbarImitation,
        );

        if (scrollbarImitation) {
          scrollbarImitation.scrollLeft = defaultScrollbarScrollLeftPos;
        }

        setScrollbarPosition(defaultScrollbarScrollLeftPos);
      }

      if (customScrollbarScrollLeftPos !== scrollbarPosition) {
        const mainContainer = document.getElementById('mainContainer');

        if (mainContainer) {
          mainContainer.scrollLeft = customScrollbarScrollLeftPos;
        }

        setScrollbarPosition(customScrollbarScrollLeftPos);
      }
    }
  };

  const handleTableVerticalScroll = event => {
    const customScrollbarScrollTopPos = event.target.scrollTop;
    const defaultScrollbarScrollTopPos = event.currentTarget.scrollTop;

    if (defaultScrollbarScrollTopPos !== scrollbarTopPosition) {
      const scrollbarImitation = document.getElementById(
        htmlElementIds.verticalDataTableScrollbarImitation,
      );

      if (scrollbarImitation) {
        scrollbarImitation.scrollTop = defaultScrollbarScrollTopPos;
      }

      setScrollbarTopPosition(defaultScrollbarScrollTopPos);
    }

    if (customScrollbarScrollTopPos !== scrollbarTopPosition) {
      const dataTableBody = document.getElementById('dataTableBody');

      if (dataTableBody) {
        dataTableBody.scrollTop = customScrollbarScrollTopPos;
      }

      setScrollbarTopPosition(customScrollbarScrollTopPos);
    }
  };

  return (
    <>
      <MainContainer
        id="mainContainer"
        nomargin={nomargin || !isEmpty(footerNotes)}
        contentLength={contentData.length}
        onScroll={handleTableHorizontalScroll}
        shouldShowCustomOverflow={!shouldScrollInside}
      >
        {shouldScrollInside && (
          <ScrollBarImitation
            scrollableWidth={scrollableWidth}
            scrollbarwidth={scrollbarwidth}
            topPosition={30}
            orientation="horizontal"
            id={htmlElementIds.horizontalMainContainerScrollbarImitation}
          />
        )}

        <PopupOverlay
          isOpen={isPopupOpen}
          handleClosePopup={() => setPopupOpenState(false)}
        >
          <PopupContent
            dataArray={
              !isEmpty(selectedRowData)
                ? selectedRowData.fundDocuments.links
                : []
            }
            handleLinkClick={handleLinkClick}
          />
        </PopupOverlay>

        <TableContainer
          id="dataTable"
          rowBorderStyle={rowBorderStyle}
          customStyle={customStyle}
          shouldScrollInside={shouldScrollInside}
        >
          {!!headerData.length && (
            <thead>
              <tr>
                {headerData.map(row => (
                  <th key={row.key}>{row.label}</th>
                ))}
              </tr>
            </thead>
          )}

          <tbody
            id="dataTableBody"
            onScroll={handleTableVerticalScroll}
            shouldShowCustomOverflow={false}
          >
            {shouldScrollInside && (
              <ScrollBarImitation
                id={htmlElementIds.verticalDataTableScrollbarImitation}
                scrollbarHeight={scrollbarHeight}
                scrollableHeight={scrollableHeight}
                topPosition={120}
                orientation="vertical"
              />
            )}

            {contentData.map((rowContent, i) => (
              <TableRow key={i} hasExtraRow={shouldRenderCustomRow}>
                {renderContentRow(rowContent, i, !!headerData.length)}
              </TableRow>
            ))}

            {shouldRenderTotalRow &&
              !isEmpty(totalsData) &&
              totalsData.map(data => (
                <>
                  <CustomRow>{renderTotalRow(false, data)}</CustomRow>
                  <Spacer />
                </>
              ))}

            {shouldRenderTotalRow && isEmpty(totalsData) && (
              <CustomRow>{renderTotalRow()}</CustomRow>
            )}

            {shouldRenderTotalRow && shouldRenderCustomRow && <Spacer />}

            {shouldRenderCustomRow && (
              <Fragment>
                <Spacer />
                <Spacer />
                <CustomRow>{renderCustomRow()}</CustomRow>
              </Fragment>
            )}

            <Spacer />
          </tbody>
        </TableContainer>

        {!!onLoadMore && (
          <Button onClick={onLoadMore}>{strings.LOAD_MORE}</Button>
        )}
      </MainContainer>

      {footerNotes && (
        <NoDataSubtitle style={{ textAlign: 'left', marginBottom: 40 }}>
          {footerNotes}
        </NoDataSubtitle>
      )}
    </>
  );
};

Table.defaultProps = {
  customStyle: {
    isBoldCurrency: true,
  },
  shouldScrollInside: false,
  totalsData: [],
};

export default Table;
