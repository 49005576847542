import styled from 'styled-components';

export const Outline = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.theme.primary};
  position: relative;

  ${({ theme, withDot }) => {
    if (withDot) {
      return `
      ::after {
        top: -5px;
        right: -5px;
        position: absolute;

        width: 7px;
        height: 7px;
        content: ' ';

        border-radius: 50%;
        border: 2px solid white;
        background-color: ${theme.secondary};
      }
    `;
    }

    return '';
  }}
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
