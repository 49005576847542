// @flow

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Table from '../../shared/table';
import { useEncashments } from '../../../hooks/policies.hooks';
import strings from '../../../config/strings';
import Spinner from '../../shared/spinner';
import EmptyData from '../../empty-data';

const ENCASHMENT_TABLE_HEADER = [
  {
    key: 'effectiveDate',
    label: strings.TABLE_HEADER_EFFECTIVE_DATE,
    type: 'date',
  },
  {
    key: 'surrenderAmount',
    label: strings.TABLE_HEADER_PARTIAL_AMOUNT_SURRENDERED,
    type: 'currency',
    totalsKey: 'totalSurrenderedAmount',
  },
  {
    key: 'surrenderFee',
    label: strings.TABLE_HEADER_PARTIAL_SURRENDER_FEE,
    type: 'currency',
    totalsKey: 'totalSurrenderedFee',
  },
  {
    key: 'capitalGainTax',
    label: strings.TABLE_HEADER_CAPITAL_GAIN_TAX,
    type: 'currency',
    totalsKey: 'totalCapitalGainTax',
  },
  {
    key: 'netSurrenderAmount',
    label: strings.TABLE_HEADER_NET_PARTIAL_AMOUNT_SURRENDERED,
    type: 'currency',
    totalsKey: 'totalNetSurrenderdAmount',
  },
];

type Props = {
  policyId: string,
};
const EncashmentTab = ({ policyId }: Props) => {
  const [policyCurrency, setPolicyCurrency] = useState(null);
  const { data: policies } = useSelector(state => state.policies.policiesList);

  const { loading, data } = useEncashments(policyId);

  const selectedPolicy =
    policies && policies.filter(pol => pol.policyId === policyId);

  useEffect(() => {
    if (!isEmpty(selectedPolicy)) setPolicyCurrency(selectedPolicy[0].currency);
  }, [JSON.stringify(selectedPolicy)]);

  const formattedData = data
    .filter(item => item.policyId)
    .map(item => {
      const encashment = item;
      encashment.surrenderFee = Math.abs(encashment.surrenderFee);
      encashment.surrenderAmount = Math.abs(encashment.surrenderAmount);
      encashment.netSurrenderAmount = Math.abs(encashment.netSurrenderAmount);

      return encashment;
    });

  const totalsData = data
    .filter(item => !item.policyId)
    .reduce((accumulator, total) => {
      accumulator[total.currency] = total;

      return accumulator;
    }, {});

  return loading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Table
        headerData={ENCASHMENT_TABLE_HEADER}
        contentData={formattedData}
        currency={policyCurrency}
        customStyle={{ isBoldCurrency: false }}
        totals={ENCASHMENT_TABLE_HEADER.slice(1).map(h => h.totalsKey)}
        totalsData={Object.values(totalsData)}
      />

      {formattedData.length < 1 && (
        <EmptyData
          style={{ marginTop: -30 }}
          title={strings.NO_RECENT_ENCASHMENT}
          subtitle={strings.ALL_ENCASHMENTS_HERE}
        />
      )}
    </React.Fragment>
  );
};

export default EncashmentTab;
