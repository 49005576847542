// @flow

import React from 'react';
import ProfilePic from '../profile-pic';

import {
  DateP,
  CloseP,
  HeadContainer,
  AvatarContainer,
  ConversationHead,
  SubjectP,
  SenderP,
} from './chat-styles';

import strings from '../../config/strings';
import { dateToString } from '../../utils/date';
import { crossIcon2x } from '../../assets/images';

type Props = {
  onClose: Function,
  externalSubject: string,
  currentConversation: any,
};

const getSubject = (currentConversation: any, externalSubject: string) =>
  externalSubject || (currentConversation && currentConversation.subject);

const ConversationHeader = ({ currentConversation, externalSubject, onClose }: Props) => (
  <HeadContainer>
    <AvatarContainer>
      <ProfilePic containerStyle={{ minWidth: 43 }} />
      <ConversationHead>
        <SenderP>{strings.INBOX_SUPPORT_NAME}</SenderP>
        <SubjectP>
          {strings.INBOX_SUBJECT}:&nbsp;{getSubject(currentConversation, externalSubject)}
        </SubjectP>
      </ConversationHead>
    </AvatarContainer>
    <div>
      {!!onClose && (
        <CloseP onClick={onClose}>
          <span>{strings.POPUP_DISMISS_LABEL}</span>
          <img src={crossIcon2x} alt="x" width="20" height="20" />
        </CloseP>
      )}
      {currentConversation
        ? <DateP>{dateToString(currentConversation.created_at)}</DateP>
        : null
      }
    </div>
  </HeadContainer>
);

export default ConversationHeader;
