// @flow

import React from 'react';
import ProfilePic from '../profile-pic';
import { RowContainer } from '../shared/container';
import { profilePicture, UserDisplayName } from './styles';

type Props = {
  displayName: string,
  userType: 'Male' | 'Female' | 'Company',
  profilePicSrc: ?string,
};

const Profile = ({ displayName, userType, profilePicSrc }: Props) => (
  <RowContainer>
    <UserDisplayName>{displayName}</UserDisplayName>
    <ProfilePic
      containerStyle={profilePicture}
      profilePicSrc={profilePicSrc}
      isCompany={userType === 'Company'}
    />
  </RowContainer>
);

export default Profile;
