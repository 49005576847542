// @flow

import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { Moment } from 'moment';

import {
  StrategyContentOutline,
  StrategyContentWrapper,
  tabsContainer,
  tabsWrapper,
} from './styles';
import globals from '../../config/globals';
import Tabs from '../../components/shared/tabs';
import Analysis from '../../components/strategy/analysis';
import Averaging from '../../containers/strategy/averaging';
import GlobalFilters from '../../components/strategy/filters';
import RiskReturn from '../../components/strategy/risk-return';
import DailyFunds from '../../components/strategy/daily-funds';
import StrategyRedirects from '../../components/strategy/redirects';
import StrategyBenchmarking from '../../components/strategy/benchmarking';
import StrategyTransferSwitching from '../../components/strategy/transfer-switching';

import type {
  ChartDescription,
  FundAnalysis,
  FundAveraging,
  RiskReturnFundPerformance,
} from '../../models/funds';

type Props = {
  analysis: { chartInfo: ChartDescription, funds: Array<FundAnalysis> },
  averaging: { chartInfo: ChartDescription, funds: Array<FundAveraging> },
  benchmarking: any[],
  chartInfo: ChartDescription,
  dateAsAt: Moment,
  funds: Array<RiskReturnFundPerformance>,
  handleDateChange: Function,
  handleRedirectsLoadMoreClick: Function,
  isCalendarOpen: boolean,
  policyCurrency: string,
  setCalendarOpenState: Function,
  pricesFundsOptions: any[],
  loadingDailyFunds: boolean,
  setPricesPeriod: Function,
  setPricesFundId: Function,
  pricesPeriod: Number,
  pricesFundId: Number,
  prices: any[],
  redirectTransfers: any[],
  shouldLoadMore: Boolean,
  switchingTransfers: any[],
};

const StrategyScreen = ({
  analysis,
  averaging,
  benchmarking,
  chartInfo,
  dateAsAt,
  funds,
  handleDateChange,
  handleRedirectsLoadMoreClick,
  isCalendarOpen,
  policyCurrency,
  setCalendarOpenState,
  pricesFundsOptions,
  loadingDailyFunds,
  setPricesPeriod,
  setPricesFundId,
  pricesPeriod,
  pricesFundId,
  prices,
  redirectTransfers,
  shouldLoadMore,
  switchingTransfers,
}: Props) => (
  <StrategyContentOutline>
    <StrategyContentWrapper>
      <Tabs
        tabsWrapperStyle={tabsWrapper}
        tabsArr={Object.values(globals.STRATEGY_SUB_TABS)}
        containerStyle={tabsContainer}
        sideContainerTabKey={globals.STRATEGY_SUB_TABS.ANALYTICS.key}
        sideContainer={
          <GlobalFilters
            dateAsAt={dateAsAt}
            handleDateChange={handleDateChange}
            isCalendarOpen={isCalendarOpen}
            maxDate={!isEmpty(analysis) && analysis.chartInfo.lastDate}
            setCalendarOpenState={setCalendarOpenState}
          />
        }
        content={{
          [globals.STRATEGY_SUB_TABS.ANALYTICS.key]: (
            <Fragment>
              {!isEmpty(analysis) && (
                <Analysis
                  chartInfo={analysis.chartInfo}
                  funds={analysis.funds.sort((a, b) => a.fundId - b.fundId)}
                  policyCurrency={policyCurrency}
                />
              )}

              <RiskReturn
                chartInfo={chartInfo}
                funds={funds.sort((a, b) => a.fundId - b.fundId)}
                policyCurrency={policyCurrency}
              />

              <Averaging
                chartInfo={averaging.chartInfo}
                funds={averaging.funds}
                policyCurrency={policyCurrency}
              />

              <DailyFunds
                funds={prices.funds}
                pricesPeriod={pricesPeriod}
                chartInfo={prices.chartInfo}
                policyCurrency={policyCurrency}
                onPeriodChange={setPricesPeriod}
                fundsOptions={pricesFundsOptions}
                onFundIdChange={setPricesFundId}
                loading={loadingDailyFunds}
                fundId={pricesFundId}
              />

              <StrategyBenchmarking data={benchmarking} />
            </Fragment>
          ),
          [globals.STRATEGY_SUB_TABS.ALLOCATIONS.key]: (
            <Fragment>
              <StrategyTransferSwitching data={switchingTransfers} />

              <StrategyRedirects
                data={redirectTransfers}
                handleLoadMoreClick={handleRedirectsLoadMoreClick}
                shouldLoadMore={shouldLoadMore}
              />
            </Fragment>
          ),
        }}
      />
    </StrategyContentWrapper>
  </StrategyContentOutline>
);

export default StrategyScreen;
