/* eslint-disable no-param-reassign */

function RenderTooltipWithoutTitle(tooltipModel, chartEl, formatDataValue) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = [];
    // const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach((title) => {
      innerHtml += `<tr><th>${title}</th></tr>`;
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      if (body.length === 1) {
        const labelStyles = 'opacity: 0.9;font-weight: 500;font-size: 14px;color:#505055';
        const valueStyles = 'font-weight: 900;line-height: 24px;letter-spacing: 0.2px;';
        const [label, value] = body[0].split(': ');

        body = `
          <span style='${labelStyles}'>${label}</span>
          <br />
          <span style='${valueStyles}'>
            ${typeof formatDataValue === 'function' ? formatDataValue(value) : value}
          </span>
        `;
      }

      const colors = tooltipModel.labelColors[i];
      let style = `background:${colors.backgroundColor}`;
      style += `; border-color:${colors.borderColor}`;
      style += '; border-width: 2px';
      const span = `<span style="${style}"></span>`;
      innerHtml += `<tr><td>${span}${body}</td></tr>`;
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = chartEl._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
  tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.color = tooltipModel.bodyFontColor;
  tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
  tooltipEl.style.pointerEvents = 'none';

  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.backgroundColor = '#fff';
  tooltipEl.style.boxShadow = '0 0 6px 0 #e4e6ef';
};

export default RenderTooltipWithoutTitle;
