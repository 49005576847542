// @flow

import React from 'react';
import { isNull } from 'lodash';
import CardOutline from './card-outline';
import { CardTitle2 } from '../shared/texts/title';
import { CardContent2 } from '../shared/texts/content';
import { CardsContainer, TitleContainer, ContentContainer } from './styles';

import strings from '../../config/strings';
import { formatCurrency, decimalWithCommas } from '../../utils/numbers';

type CardProps = {
  label: String,
  value: String,
};

const Card = ({ label, value }: CardProps) => (
  <CardOutline>
    <TitleContainer>
      <CardTitle2>{label}</CardTitle2>
    </TitleContainer>
    <ContentContainer>
      <CardContent2>{value}</CardContent2>
    </ContentContainer>
  </CardOutline>
);

type Props = {
  info: {
    totalPremiumDue: string,
    totalRegularContributions: string,
    totalAdhocContributions: string,
    totalContributions: string,
  },
  isInvestmentType: Boolean,
};

const renderCardValue = data => {
  let value = '';

  data.forEach((elt, index) => {
    if (index > 0) {
      value = value.concat('\n');
    }

    value = value.concat(
      `${formatCurrency(elt.currency, elt.value < 0)}${decimalWithCommas(
        Math.abs(elt.value),
      )}`,
    );
  });

  return value;
};

const ContributionCards = ({ info, isInvestmentType }: Props) =>
  !!info && (
    <CardsContainer>
      <Card
        label={strings.CONTRIBUTION_TITLE_REGULAR}
        value={renderCardValue(info.totalRegularContributions)}
      />

      {isInvestmentType && (
        <Card
          label={strings.CONTRIBUTION_TITLE_ADHOC}
          value={renderCardValue(info.totalAdhocContributions)}
        />
      )}

      {isInvestmentType && (
        <Card
          label={strings.CONTRIBUTION_TITLE_TOTAL}
          value={renderCardValue(info.totalContributions)}
        />
      )}

      {!isNull(info.totalPremiumDue) && (
        <Card
          label={strings.CONTRIBUTION_TITLE_TOTAL_DUE}
          value={renderCardValue(info.totalPremiumDue)}
        />
      )}
    </CardsContainer>
  );

export default ContributionCards;
