// @flow

import React from 'react';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import {
  useDocumentsPolicyContracts,
  useDocumentsPolicyNotices,
} from '../hooks/documents';

import { formatDate } from '../utils/date';
import Spinner from '../components/shared/spinner';
import DocumentsScreen from '../pages/policy-details/documents';
import FileDownloader, { FileViewer } from '../utils/downloader';

import type PolicyDocType from '../models/documents';

type Props = { policyId: number };

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const Documents = ({ policyId }: Props) => {
  const { account = {} } = useSelector(state => state.user);
  const { username } = account.data.user;

  const { loading, data: contracts } = useDocumentsPolicyContracts(policyId);
  const { data: notices } = useDocumentsPolicyNotices(policyId);

  const formattedContracts = contracts.map(item => {
    const formattedData = { ...item };

    formattedData.updatedDate = formatDate(item.updatedDate);
    formattedData.description = capitalize(item.description);

    return formattedData;
  });

  const formattedNotices = notices.map(item => {
    const formattedData = { ...item };

    formattedData.updatedDate = formatDate(item.updatedDate);
    formattedData.description = capitalize(item.description);

    return formattedData;
  });

  const handleDownloadDocumentClick = (
    name: string,
    docType: PolicyDocType,
  ) => {
    const baseUrl = REACT_APP_WB_BACKEND_URL || '';
    const url = `${baseUrl}/policies/${policyId}/documents/${docType}/export`;
    const params = { name, username };

    const headers = {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    };

    return FileDownloader(url, headers, params);
  };

  const handleViewDocumentClick = (name: string, docType: PolicyDocType) => {
    const baseUrl = REACT_APP_WB_BACKEND_URL || '';
    const url = `${baseUrl}/policies/${policyId}/documents/${docType}/export`;
    const params = { name, username };

    const headers = {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    };

    return FileViewer(url, headers, params);
  };

  function renderContent() {
    return (
      <DocumentsScreen
        contracts={formattedContracts}
        handleDownloadDocumentClick={handleDownloadDocumentClick}
        handleViewDocumentClick={handleViewDocumentClick}
        notices={formattedNotices}
      />
    );
  }

  return loading ? <Spinner /> : renderContent();
};

export default Documents;
