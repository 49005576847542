import {
  REQUEST_GET_SERVICE_FORMS,
  SUCCESS_GET_SERVICE_FORMS,
  FAILURE_GET_SERVICE_FORMS,
} from '../constants/types';

const initialState = {
  errorMessage: '',
  requested: false,
  loading: false,
  data: [],
};

const ServiceFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_SERVICE_FORMS:
      return { ...state, loading: true };

    case SUCCESS_GET_SERVICE_FORMS:
      return { ...state, requested: true, loading: false, data: action.payload.forms };

    case FAILURE_GET_SERVICE_FORMS:
      return { ...state, loading: false, errorMessage: action.payload.err };

    default:
      return { ...state };
  }
};

export default ServiceFormsReducer;
