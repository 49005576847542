// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../../utils/error';

const { REACT_APP_CMS_URL } = process.env;

const ReadNotifications = notiifcationId => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_CMS_URL}/notifications/${notiifcationId}/read`;

  return axios.post(url);
};

export default ReadNotifications;
