import axios from 'axios';
import { isEmpty } from 'lodash';
import CustomError from '../utils/error';

const { REACT_APP_CMS_URL } = process.env;

const submitTerms = () => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_CMS_URL || ''}/authentication/submit-terms`;

  return axios.post(url, {}, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export default submitTerms;
