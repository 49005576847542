// @flow

import React, { useEffect, useState } from 'react';
import type { Moment } from 'moment';

import {
  CarouselArrow,
  CardsCarouselContainer,
  CardsContainer,
  dateCard,
  Slides,
} from './container';
import Card from './card';
import {
  navArrowLeft,
  navArrowLeft2x,
  navArrowLeft3x,
  navArrowRight,
  navArrowRight2x,
  navArrowRight3x,
} from '../../../assets/images';
import NavArrow from './carousel';
import strings from '../../../config/strings';
import { formatDate } from '../../../utils/date';
import { decimalWithCommas, formatCurrency } from '../../../utils/numbers';
import type { PolicyInfo } from '../../../models/policies';

type Props = {
  currency: { id: number, name: string },
  dateAsAt: Moment,
  handleDateChange: Function,
  isCalendarOpen: boolean,
  policyInfo: PolicyInfo,
  setCalendarOpenState: Function,
};

const PolicyInfoCards = ({
  currency,
  dateAsAt,
  handleDateChange,
  isCalendarOpen,
  policyInfo,
  setCalendarOpenState,
}: Props) => {
  const [position, setPosition] = useState(0);
  const [isNavArrowRightHidden, setNavArrowRightHidden] = useState(false);
  const [isNavArrowLeftHidden, setNavArrowLeftHidden] = useState(true);

  const getSliderBoundaries = () => {
    const slider = document.getElementById('slider');
    const maxWidth = slider.getBoundingClientRect().width;
    const sliderCont = document.getElementById('slider-container');
    const container = sliderCont.getBoundingClientRect().width;

    const maxPos = maxWidth - container;

    return maxPos;
  };

  const setInitialNavArrowsState = () => {
    const maxPos = getSliderBoundaries();

    setPosition(0);
    setNavArrowLeftHidden(position - 225 <= 0);
    setNavArrowRightHidden(position >= maxPos);
  };

  useEffect(() => {
    setInitialNavArrowsState();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setInitialNavArrowsState);

    return () => {
      window.removeEventListener('resize', setInitialNavArrowsState);
    };
  }, []);

  const scrollPrevious = () => {
    const maxPos = getSliderBoundaries();

    const addedPx =
      maxPos > 0 && position > 0 && position >= maxPos - 225
        ? position - 225
        : 0;

    setPosition(addedPx);

    setNavArrowLeftHidden(position - 225 <= 0);
    setNavArrowRightHidden(addedPx >= maxPos);
  };

  const scrollNext = () => {
    const maxPos = getSliderBoundaries();

    const addedPx = position < maxPos ? position + 225 : position;
    setPosition(addedPx);

    setNavArrowRightHidden(position + 225 >= maxPos);
    setNavArrowLeftHidden(addedPx <= 0);
  };

  return (
    <CardsCarouselContainer>
      <CarouselArrow>
        <NavArrow
          src={navArrowLeft}
          srcSet={`${navArrowLeft2x} 2x, ${navArrowLeft3x} 3x`}
          alt="prev"
          onClick={scrollPrevious}
          isHidden={isNavArrowLeftHidden}
        />
        <NavArrow
          src={navArrowRight}
          srcSet={`${navArrowRight2x} 2x, ${navArrowRight3x} 3x`}
          alt="next"
          onClick={scrollNext}
          isHidden={isNavArrowRightHidden}
        />
      </CarouselArrow>

      <CardsContainer id="slider-container">
        <Slides id="slider" scrollRight={position}>
          <Card
            keyid="date"
            title={strings.PERFORMANCE_POLICY_DATE_LABEL}
            containerStyle={dateCard}
            type="date"
            value={formatDate(dateAsAt)}
            helpText={strings.PERFORMANCE_TAB_POLICY_DATE_HELP_TEXT}
            isEditable
            isCalendarOpen={isCalendarOpen}
            setCalendarOpenState={setCalendarOpenState}
            handleDateChange={handleDateChange}
            minDate={policyInfo.startDate}
            maxDate={policyInfo.lastDate}
          />

          <Card
            keyid="investment"
            title={strings.PERFORMANCE_POLICY_INVESTED_LABEL}
            value={`${formatCurrency(
              currency.name,
              policyInfo.netInvestedAmount < 0,
            )}${decimalWithCommas(Math.abs(policyInfo.netInvestedAmount))}`}
            helpText={strings.PERFORMANCE_TAB_POLICY_INVESTED_AMOUNT_HELP_TEXT}
          />

          <Card
            keyid="value"
            title={strings.PERFORMANCE_POLICY_VALUE_LABEL}
            value={`${formatCurrency(
              currency.name,
              policyInfo.policyValue < 0,
            )}${decimalWithCommas(Math.abs(policyInfo.policyValue))}`}
            helpText={strings.POLICY_VALUE_HELP_TEXT}
          />

          <Card
            keyid="estimated"
            title={strings.PERFORMANCE_POLICY_SURRENDER_VALUE_LABEL}
            value={`${formatCurrency(
              currency.name,
              policyInfo.surrenderValue < 0,
            )}${decimalWithCommas(Math.abs(policyInfo.surrenderValue))}`}
            helpText={strings.SURRENDER_VALUE_HELP_TEXT}
            hasLargeDisclaimer
          />

          <Card
            title={strings.PERFORMANCE_POLICY_PROFIT_LOSS_LABEL}
            value={`${formatCurrency(
              currency.name,
              policyInfo.totalGainTotal < 0,
            )}${decimalWithCommas(Math.abs(policyInfo.totalGainTotal))}`}
            type={policyInfo.totalGainTotal < 0 ? 'decrease' : 'increase'}
            helpText={strings.PERFORMANCE_POLICY_PROFIT_LOSS_HELP_TEXT}
          />

          <Card
            title={strings.PERFORMANCE_POLICY_TOTAL_RETURN_LABEL}
            value={`${decimalWithCommas(policyInfo.totalPercentage)} %`}
            type={policyInfo.totalPercentage < 0 ? 'decrease' : 'increase'}
            helpText={strings.RETURN_HINT}
          />
        </Slides>
      </CardsContainer>
    </CardsCarouselContainer>
  );
};

export default PolicyInfoCards;
