// @flow

import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

import { tooltipStyle } from './styles';
import colors from '../../config/colors';
import { decimalWithCommas } from '../../utils/numbers';

require('./rounded-corners');

type Props = {
  data: Array<number> | Array<string>,
  labels: Array<string>,
  tooltipLabels: Object,
};

const addPercent = (value, percentage) => {
  const tmp = value * (percentage / 100);
  const result = tmp + value;

  return result;
};

const HorizontalBarChart = ({ data, labels, tooltipLabels }: Props) => (
  <HorizontalBar
    data={{
      labels,
      datasets: [
        {
          data,
          backgroundColor: data.map(value =>
            value < 0 ? colors.danger : colors.success,
          ),
          barThickness: 11,
          label: 'data',
        },
        {
          data: new Array(data.length).fill(
            data.length === 1 && data[0] === 0
              ? 100
              : Math.ceil(addPercent(Math.max(...data), 2)),
          ),
          barThickness: 11,
          label: 'positiveData',
        },
        {
          data: new Array(data.length).fill(
            data.length === 1 && data[0] === 0
              ? -100
              : Math.floor(addPercent(Math.min(...data), -2)),
          ),
          barThickness: 11,
          label: 'negativeData',
        },
      ],
    }}
    options={{
      plugins: { datalabels: { display: false } },
      lineHeightAnnotation: {
        always: false,
        hover: false,
      },
      cornerRadius: 12,
      legend: { display: false },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: 'transparent',
              display: true,
              zeroLineColor: 'rgb(222, 222, 222)',
              zeroLineWidth: 1,
            },
            ticks: { display: false },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              fontColor: colors.primaryDark,
              fontFamily: 'Avenir-Heavy',
              fontSize: 14,
            },
            gridLines: { drawBorder: false },
          },
          {
            position: 'right',
            offset: true,
            ticks: {
              display: true,
              min: 0,
              max: data.length - 1,
              maxTicksLimit: data.length,
              callback: (value, index) => decimalWithCommas(data[index]),
              fontColor: colors.primaryDark,
              fontFamily: 'Avenir-Heavy',
              fontSize: 14,
            },
            gridLines: { display: false },
          },
        ],
      },
      tooltips: {
        ...tooltipStyle,
        filter: tooltipItem => tooltipItem.datasetIndex === 0,
        callbacks: {
          title: tooltipItem => {
            const tooltipKey = tooltipItem[0].label;
            const { key, description } = tooltipLabels[tooltipKey];
            const title = `${description} (${key})`;

            return title;
          },
          label: tooltipItem => `P/L: ${decimalWithCommas(tooltipItem.value)}%`,
        },
      },
      maintainAspectRatio: true,
      responsive: true,
    }}
  />
);

export default HorizontalBarChart;
