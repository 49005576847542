import styled from 'styled-components';

const HorizontalDottedDivider = styled.hr`
  width: 100%;
  border: 0;
  border-bottom: 2px;
  border-style: dashed;
  border-color: ${props => props.theme.white3};
`;

const SectionDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.white3};
`;

export const OverviewScreenSectionDivider = styled(SectionDivider)`
  margin-top: 31px;
  margin-bottom: 40px;
`;

export default HorizontalDottedDivider;
