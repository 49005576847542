// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { RowContainer, ViewPolicies } from './styles';

import { eye } from '../../../assets/images';
import strings from '../../../config/strings';

import { ProducerStats } from '../../../models/producer';
import { PRODUCER_POLICIES } from '../../../router/routes';
import { decimalWithCommas } from '../../../utils/numbers';

type Props = {
  data: ProducerStats,
  filter: string,
  allPolicies: boolean,
  searchTerm: string,
};

const ProducerTableRow = ({ data, filter, allPolicies, searchTerm }: Props) => (
  <RowContainer>
    <div>{data.userDetails}</div>
    <div>{decimalWithCommas(data.totalPoliciesNbr)}</div>
    <div>$ {decimalWithCommas(data.totalGrossPremium)}</div>
    <div>{decimalWithCommas(data.totalNewPolicies)}</div>
    <div>$ {decimalWithCommas(data.totalAnnualPremium)}</div>
    <div>
      <Link
        to={{
          pathname: PRODUCER_POLICIES.replace(':id', data.userId),
          state: { filters: filter, searchTerm, isAllPolicies: allPolicies },
        }}
      >
        {strings.PRODUCER_TABLE_VIEW_POLICIES}
        <ViewPolicies src={eye} alt="view" />
      </Link>
    </div>
  </RowContainer>
);

export default ProducerTableRow;
