// @flow

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Container, Content, ButtonContainer, Button } from './styles';
import PopupOverlay from '../../containers/popup';
import strings from '../../config/strings';

const DownloadsPopup = () => {
  const [open, setOpen] = useState(false);
  const { downloadableContent } = useSelector(state => state.privacyPolicy.data);

  useEffect(() => {
    const allowDownloads = localStorage.getItem('allowDownloads');

    if (!allowDownloads) {
      setOpen(true);
    }
  }, []);

  const onClick = () => {
    localStorage.setItem('allowDownloads', true);
    setOpen(false);
  };

  return (
    <PopupOverlay
      isOpen={open && downloadableContent}
      containerStyle={{ backgroundColor: '#eee' }}
    >
      <Container>
        <Content>{downloadableContent}</Content>
        <ButtonContainer>
          <Button onClick={onClick}>
            {strings.ACCEPT_TERMS_CONDITIONS_BUTTON}
          </Button>
        </ButtonContainer>
      </Container>
    </PopupOverlay>
  );
};

export default DownloadsPopup;
