import styled from 'styled-components';
import { size } from '../../../config/device';

export const CardContent2 = styled.p`
  font-family: Avenir-Heavy;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.19px;
  color: ${props => props.theme.primaryDark};
  white-space: pre;

  @media (max-width: ${size.tablet}) {
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 0.15px;
  }
`;

export const Header = styled.p`
  font-family: Avenir-Heavy;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: ${props => props.theme.primaryDark};
`;

export const Identification = styled.p`
  font-family: Avenir-Heavy;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: -0.41px;
  color: ${props => props.theme.secondary};

  @media (max-width: ${size.tablet}) {
    font-size: 10.4px;
    letter-spacing: -0.33px;
  }
`;

export const MainContent = styled.p`
  font-family: Avenir-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.19px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 16px;
    letter-spacing: 0;
  }
`;

export const MainContent2 = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.9;
  line-height: 1.71;
  letter-spacing: 0.13px;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    letter-spacing: 0.1px;
  }
`;

export const MainContent3 = styled.p`
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 11.2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.04px;
  }
`;

export const MainContent4 = styled.p`
  font-family: Avenir-Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${props => props.theme.primaryDark};
  font-weight: 900;

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
    letter-spacing: 0.12px;
    line-height: 1.88;
  }
`;

export const TotalHintContent = styled.p`
  font-family: Avenir-Roman;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${props => props.theme.slateGrey};
  align-self: center;
  white-space: pre-line;

  @media (max-width: ${size.tablet}) {
    font-size: 10.4px;
    letter-spacing: 0;
  }
`;

export const Subtitle = styled.p`
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0;
  text-align: center;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 14.4px;
  }
`;

export const NoDataSubtitle = styled.p`
  font-family: Avenir-Medium;
  line-height: 1.88;
  letter-spacing: -0.06px;
  text-align: center;
  font-size: 16px;
  color: ${props => props.theme.slateGrey65};
`;

export const NoDataTitle = styled.p`
  font-family: Avenir-Medium;
  font-size: 18px;
  letter-spacing: -0.06px;
  color: ${props => props.theme.slateGrey};
`;

export const CardContent = styled.p`
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.15px;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
    letter-spacing: 0.12px;
  }
`;

export const ProfileMenuItem = styled.p`
  font-family: ${({ isHighlighted }) =>
    isHighlighted ? `Avenir-Heavy` : `Avenir-Medium`};
  font-size: 16px;
  color: ${({ isHighlighted, theme }) =>
    isHighlighted ? `${theme.cobaltBlue}` : `${theme.slateGrey}`};
  line-height: 24px;
  letter-spacing: 0.15px;

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
    letter-spacing: 0.12px;
    line-height: 1.88;
  }
`;

export const DisclaimerContent = styled.p`
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${props => props.theme.slateGrey};

  @media (max-width: ${size.tablet}) {
    font-size: 12.8px;
    letter-spacing: 0;
  }
`;

export const DescriptionText = styled.p`
  opacity: 0.9;
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0;
  color: ${props => props.theme.slateGrey};
`;

export const NotificationContent = styled.p`
  font-size: 14px;
  font-family: Avenir-Medium;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.04px;
  color: ${props => props.theme.slateGrey};
`;

export const NotificationLink = styled.p`
  font-size: 14px;
  font-family: Avenir-Medium;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.04px;
  color: ${props => props.theme.slateGrey};
`;

export const NotificationDate = styled.p`
  font-family: Avenir-Medium;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.03px;
  color: ${props => props.theme.slateGrey};
`;

export const ErrorText = styled.p`
  font-family: Avenir-Medium;
  font-size: 12px;
  font-style: oblique;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 500;
  color: ${props => props.theme.danger};
`;

export const DateContent = styled.p`
  font-family: Avenir-Heavy;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.19px;
  color: ${props => props.theme.primaryDark};

  @media (max-width: ${size.tablet}) {
    font-size: 10px;
    line-height: 19.2px;
    letter-spacing: 0.15px;
  }
`;
