export default Object.freeze({
  ADDRESS: 'Address',
  ALL_POLICIES_HERE: 'All active policies will appear here',
  ALL_PRODUCER_POLICIES_HERE: 'All policies will appear here',
  ALL_ENCASHMENTS_HERE: 'All encashments will appear here.',
  ALL_MESSAGES_HERE: 'All messages will appear here.',
  ALLOW_NOTIFICATIONS: 'Allow notifications via SMS',
  ACCEPT_TERMS_CONDITIONS_BUTTON: 'Accept',
  ARTICLE_DETAILS_DOWNLOAD: 'Download PDF',
  ARTICLE_DETAILS_FOOTER: '~ You reached the end ~',
  ARTICLE_DETAILS_GO_BACK: 'Go back',
  ARTICLE_READ_BUTTON: 'Read Article',
  PRODUCER_BACK_TO_SEARCH: 'Back to search',
  POST_ARTICLE_DATE: ', by Nasco',
  PRE_ARTICLE_DATE: 'Posted on',
  BENEFICIARY: 'Beneficiary',
  PRIVACY_POLICY: 'Terms and Conditions',
  CHANGE_PASSWORD: 'Change password',
  CHANGE_PASSWORD_SUCCESS_TITLE: 'Successful password change',
  CONTACTS: 'Contacts',
  CONTINGENT_BENEFICIARY: 'Contingent beneficiary',
  CONTINGENT_BENEFICIARIES: 'Contingent beneficiaries',
  COOKIE_DISCLAIMER_TITLE: 'Use of Cookies',
  COOKIE_DISCLAIMER:
    'Dear Visitor, this website uses permanent cookies to ensure data privacy as well as to provide access to special content and technical functionalities. With your consent, we may also use cookies for website analysis. For details on the content of the cookies, read our ',
  DATA_PRIVACY: 'Data Privacy Statement.',
  ALLOW_COOKIES: 'Allow',
  DOCUMENT_LOADER_TEXT: 'Loading document',
  DOWNLOAD: 'Download',
  DUE_AMOUNT_TITLE: 'Amount:',
  DUE_DATE_TITLE: 'Due date:',
  FULL_NAME: 'Full name',
  INBOX: 'Inbox',
  LEGEND_NET_INVESTED_AMOUNT:
    '* Net Amount Invested excludes interest earned on the HYFIA but includes unrealized Capital Gains and Dividends generated by other instruments.',
  LIBRARY: 'Library',
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  FAQS: 'FAQs',
  TOTAL: 'Total',
  PRODUCER: 'Producer',
  PRODUCER_DETAILS: 'Producer’s details',
  PRODUCER_POLICIES_TITLE: 'Filter results view policies',
  PRODUCER_CUSTOMER_INFORMATION: 'Customer Information',
  LOAD_MORE: 'Load More',
  MORE_DOCUMENTS_POPUP_TITLE: 'More documents',
  NEXT_PAYMENT: 'Next payment:',
  NO_ACTIVE_POLICIES: 'You have no active policies',
  NO_DATA: 'No Data',
  NO_ENOTICES: 'The E-notices feature will be activated soon',
  NO_PRODUCER_POLICIES: "Producer doesn't have any active policy",
  NO_POLICY_CONTRACT: 'The policy contract will be available soon.',
  NO_RECENT_ENCASHMENT: 'You have no encashment',
  NO_RECENT_MESSAGES: 'You have no new messages',
  NULL_DATE_REPLACEMENT: 'N/A',
  NULL_NUMBER_REPLACEMENT: 'N/A',
  NULL_STRING_REPLACEMENT: 'N/A',
  OVERVIEW: 'Overview',
  SEND_BUTTON: 'Send',
  SEND_MESSAGE_PLACEHOLDER: 'Type Something...',
  COMPOSE_BUTTON: 'Compose',
  FILTER_BUTTON: 'Filter',
  FILTER_SEARCH_PLACEHOLDER: 'Search...',
  FILTER_SHOW_ACTIVE: 'Active policies only',
  FILTER_NONE: 'none',
  FILTER_APPLY: 'Apply',
  FILTER_CLEAR: 'Clear',
  FILTER_CLEAR_ALL: 'Clear all',
  FORM_INPUT_LABEL_OTP: 'One Time Password',
  FORM_INPUT_LABEL_EMAIL: 'Email',
  FORM_INPUT_LABEL_USERNAME: 'Username',
  FORM_INPUT_LABEL_OLD_PASSWORD: 'Old password',
  FORM_INPUT_LABEL_NEW_PASSWORD: 'New password',
  FORM_INPUT_LABEL_CONFIRMATION_PASSWORD: 'Confirm new password',
  FORM_INPUT_LABEL_PASSWORD: 'Password',
  FORM_INPUT_PLACEHOLDER_OTP: 'OTP',
  FORM_INPUT_PLACEHOLDER_EMAIL: 'Email address',
  FORM_INPUT_PLACEHOLDER_USERNAME: 'Username',
  FORM_INPUT_PLACEHOLDER_PASSWORD: '************',
  SECTION_TITLE_INBOX: 'All in inbox',
  SECTION_TITLE_FAQS: 'Frequently Asked Questions',
  ONBOARDING_FORGET_PASSWORD_SUBTITLE:
    'Enter the email associated with your account and we’ll send an email to reset your password',
  ONBOARDING_FORGET_PASSWORD_FORM_CANCEL_BUTTON: 'Cancel',
  ONBOARDING_FORGET_PASSWORD_FORM_SUBMIT_BUTTON: 'Send email',
  ONBOARDING_FORGET_PASSWORD_TITLE: 'Forgot password?',
  ONBOARDING_LOGIN_TITLE: 'Login to your account',
  ONBOARDING_RESET_PASSWORD_TITLE: 'Reset your password',
  ONBOARDING_RESET_PASSWORD_SUCCESS_TITLE: 'Success',
  ONBOARDING_LOGIN_FORGOT_PASSWORD_LABEL: 'Forget / Reset password',
  ONBOARDING_LOGIN_FORM_SUBMIT_BUTTON: 'Login',
  ONBOARDING_RESET_PASSWORD_FORM_SUBMIT_BUTTON: 'Reset my password',
  ONBOARDING_RESET_PASSWORD_FORM_SUCCESS_SUBMIT_BUTTON: 'Login',
  ONBOARDING_CHANGE_PASSWORD_FORM_SUCCESS_SUBTITLE:
    'You can now use your new password to login to your account',
  ONBOARDING_RESET_PASSWORD_FORM_SUCCESS_SUBTITLE:
    'If the email you enetered is linked to an account, you will receive an email with intrusctions.',
  PERFORMANCE_POLICY_DATE_LABEL: 'Date as of',
  PERFORMANCE_POLICY_INVESTED_LABEL: 'Net invested amount',
  PERFORMANCE_POLICY_PROFIT_LOSS_LABEL: 'Net Profit/Loss',
  PERFORMANCE_POLICY_PROFIT_LOSS_HELP_TEXT: 'Unrealized profit or loss',
  PERFORMANCE_POLICY_SURRENDER_VALUE_LABEL: 'Estimated Surrender Value',
  PERFORMANCE_POLICY_TOTAL_RETURN_LABEL: 'Net Profit/Loss %',
  PERFORMANCE_POLICY_VALUE_LABEL: 'Policy value',
  PERFORMANCE_PORTFOLIO_CHART_TITLE: 'Portfolio composition',
  PERFORMANCE_TAB_POLICY_DATE_HELP_TEXT:
    'View the policy value for a specific date; the date is set by default based on the latest available fund prices.',
  PERFORMANCE_TAB_POLICY_INVESTED_AMOUNT_HELP_TEXT:
    'Net of policy fees and insurance cost. The amount excludes interest earned on the HYFIA but includes unrealized Capital Gains and Dividends generated by other instruments.',
  PERFORMANCE_PORTFOLIO_PROFIT_LOSS_TITLE: 'Profit & loss',
  PERFORMANCE_PORTFOLIO_PROFIT_LOSS_CHART_LABELS_TITLE: 'Fund Name',
  PERFORMANCE_PORTFOLIO_PROFIT_LOSS_CHART_VALUES_TITLE: 'P/L % (Int./Div.)',
  PERSONAL_INFORMATION: 'Personal information',
  PLACEHOLDER_SEARCH_POLICY: 'Search policy',
  POLICY_DDL_TITLE: 'Policy # and Insured name',
  POLICY_DETAILS: 'Policy details',
  POLICY_ID_TITLE: 'Policy #:',
  POLICY_INFO_ANNIVERSARY: 'Next policy anniversary date',
  POLICY_INFO_EFFECTIVE_DATE: 'Effective date',
  POLICY_INFO_EXPIRY_DATE: 'Expiry date',
  POLICY_INFO_INSURED_DOB: "Insured's date of birth",
  POLICY_INFO_PAYMENT_FREQ: 'Payment frequency',
  POLICY_INFO_PAYMENT_METHOD: 'Payment method',
  POLICY_INFO_PAYMENT_DURATION: 'Payment duration in years',
  POLICY_INFO_PAYMENT_INDEXATION: 'Payment indexation rate per year',
  POLICY_INFO_PAYMENT_INDEXATION_DURATION:
    'Payment indexation duration in years',
  POLICY_INFO_PLAN_NAME: 'Plan name',
  POLICY_INFO_POLICY_OWNER: 'Name of Policy owner',
  POLICY_INFO_PREMIUM: 'Premium',
  POLICY_INFO_PREMIUM_FEES: 'Premium inclusive of fees',
  POLICY_INFO_PREMIUM_FEES_SPECIAL: 'Special Premium inclusive of fees',
  POLICY_INFO_PREMIUM_PLANNED: 'Planned periodic premium',
  POLICY_INFO_PREMIUM_WITH_TAX: 'Premium inclusive of fees and taxes',
  POLICY_INFO_PREMIUM_SINGLE: 'Single premium',
  POLICY_INSURED_BY_TITLE: 'Name of Insured',
  PRODUCER_NAME: 'Producer’s name',
  PROFILE_INPUT_ADDRESS_PLACEHOLDER:
    'Full Address (city, apt, floor, building…)',
  PROFILE_INPUT_DOB_PLACEHOLDER: 'Date of birth',
  PROFILE_INPUT_EMAIL_PLACEHOLDER: 'Email',
  PROFILE_INPUT_FULL_NAME_PLACEHOLDER: 'Full name',
  PROFILE_INPUT_MARITAL_STATUS_PLACEHOLDER: 'Marital Status',
  PROFILE_INPUT_NATIONALITY_PLACEHOLDER: 'Nationality',
  PROFILE_INPUT_PASSWORD_PLACEHOLDER: 'Password',
  PROFILE_INPUT_PHONE_NUMBER_PLACEHOLDER: 'Phone number',
  PROFILE_INPUT_USERNAME_PLACEHOLDER: 'Username',
  MOBILE_NUMBER: 'Mobile number',
  POLICY_VALUE_HELP_TEXT:
    'Policy values displayed are in policy’s original currency denomination, using the most current currency exchange rate.',
  POLICY_VIEW_MORE_LINK: 'View More Information',
  POPUP_DISMISS_LABEL: 'Close',
  PORTFOLIO_ALLOCATIONS_HELP_TEXT:
    'Regardless of the funds’ original currency denomination, fund values are displayed using the most current exchange rate of the contract currency.',
  PREFERENCES: 'Preferences',
  PRIMARY_BENEFICIARY: 'Primary beneficiary',
  PRIMARY_BENEFICIARIES: 'Primary beneficiaries',
  PROFILE_DETAILS: 'Profile details',
  PRODUCER_TABLE_EXPORT: 'Export',
  PRODUCER_TABLE_LEVELS: 'Levels',
  PRODUCER_TABLE_TOTAL_COUNT: 'Total count of policies',
  PRODUCER_TABLE_TOTAL_ANNUALIZED: 'Total annualized premium',
  PRODUCER_TABLE_TOTAL_POLICIES:
    'Total count of new policies\r\n(Current year)',
  PRODUCER_TABLE_TOTAL_NEW_ANNUALIZED:
    'Total new annualized premium (Current year)',
  PRODUCER_TABLE_VIEW_POLICIES: 'View Policies',
  PRODUCER_TABLE_VIEW_DETAILS: 'View details',
  RELATIONSHIP: 'Relationship',
  READ_MORE: 'Read more',
  CONTRIBUTIONS_POPUP: 'Contributions',
  CONTRIBUTION_TITLE_TOTAL: 'Total contributions',
  CONTRIBUTION_TITLE_ADHOC: 'Ad-hoc contributions',
  CONTRIBUTION_TITLE_REGULAR: 'Regular contributions',
  CONTRIBUTION_TITLE_TOTAL_DUE: 'Total payments due',
  CONTRIBUTION_TOTAL_CONTRIBUTIONS: '',
  CONTRIBUTION_TABLE_HEADERS: [
    { key: 'paymentMethod', label: 'Payment Method', type: 'text' },
    { key: 'status', label: 'Payment Status', type: 'text' },
    { key: 'contributionType', label: 'Type of contribution', type: 'text' },
    { key: 'premiumDueDate', label: 'Premium due date', type: 'date' },
    { key: 'premiumPaymentDate', label: 'Premium payment date', type: 'date' },
    { key: 'amount', label: 'Payment Amount', type: 'currency' },
  ],
  PAYMENT_DUE_DATE_UNKNOWN: 'Details of premium will be displayed on due date',
  PRODUCER_AGENT_INFO: [
    { key: 'agentName', label: '', type: 'text' },
    {
      key: 'totalPoliciesNbr',
      label: 'Total count of policies',
      type: 'number',
    },
    {
      key: 'totalGrossPremium',
      label: 'Total annualized premium',
      type: 'number',
    },
    {
      key: 'totalNewPolicies',
      label: 'Total count of new policies\r\n(Current year)',
      type: 'number',
    },
    {
      key: 'totalAnnualPremium',
      label: 'Total new annualized premium (Current year)',
      type: 'number',
    },
    { key: 'renderExport', label: '', type: 'jsx' },
  ],
  PRODUCER_AGENT_POLICIES: [
    { key: 'ownerName', label: 'Policy owner', type: 'jsx' },
    { key: 'insuredName', label: 'Insured', type: 'text' },
    { key: 'policyNumber', label: 'Policy number', type: 'text' },
    { key: 'startDate', label: 'Next policy due date', type: 'date' },
    { key: 'status', label: 'Policy status', type: 'text' },
    { key: 'frequency', label: 'Frequency', type: 'text' },
    { key: 'annualPremium', label: 'Annualized Premium', type: 'number' },
    { key: 'viewDetails', label: '', type: 'jsx' },
  ],
  PORTFOLIO_ALLOCATIONS_TABLE_HEADERS: [
    { key: 'fundTicker', label: 'Ticker', type: 'text' },
    { key: 'fundDocuments', label: 'Description', type: 'link' },
    { key: 'units', label: 'Units', type: 'number' },
    { key: 'bidPrice', label: 'Price', type: 'number' },
    { key: 'totalUnits', label: 'Fund/Policy Value', type: 'number' },
    { key: 'cost', label: 'Net invested amount', type: 'number' },
    {
      key: 'profitLoss',
      label: 'Net Capital Gain of Funds',
      type: 'number',
    },
    {
      key: 'profitLossPerc',
      label: 'Profit/Loss % (Capital Gain)',
      type: 'growth',
    },
    {
      key: 'interestDividend',
      label: 'Interest/Dividends (Reinvested)',
      type: 'number',
    },
    {
      key: 'interestDividendPercentage',
      label: 'Profit/Loss %(Int./Div.)',
      type: 'growth',
    },
    { key: 'totalGain', label: 'Net Profit/Loss', type: 'number' },
    {
      key: 'gainPercentage',
      label: 'Net Profit/Loss %',
      type: 'growth',
    },
  ],
  STRATEGY_ACCOUNT_VALUE_DROPDOWN_TITLE: 'Funds',
  STRATEGY_ACCOUNT_VALUE_EMPTY_DATA_MESSAGE: 'No data',
  STRATEGY_AVERAGING_DROPDOWN_TITLE: 'Funds',
  STRATEGY_AVERAGING_ANALYSIS_EMPTY_DATA_MESSAGE:
    'The "analysis" & "cost averaging" features apply only to foreign investments, mutual funds, exchange. Traded funds, and global equities or debt securities',
  STRATEGY_AVERAGING_STATS_LABELS: {
    purchaseTotalUnits: 'Total Units Purchased',
    purchaseTotalAmount: 'Total Amount Purchased',
    purchaseAveragePrice: 'Average Price Purchased per unit',
    deductedTotalUnits: 'Total Units Deducted',
    deductedTotalAmount: 'Total Amount Deducted',
    deductedAveragePrice: 'Average Price Deducted per unit',
  },
  STRATEGY_BENCHMARKING_TABLE_HEADER: [
    { key: 'fundName', type: 'text', label: 'Fund name' },
    { key: 'initialPurchase', type: 'date', label: 'Initial purchase' },
    { key: 'yearToDate', type: 'number', label: 'Year to date' },
    { key: 'firstYear', type: 'number', label: '1 year' },
    { key: 'thirdYear', type: 'number', label: '3 years' },
    { key: 'fifthYear', type: 'number', label: '5 years' },
    { key: 'tenthYear', type: 'number', label: '10 years' },
  ],
  STRATEGY_BENCHMARKING_FUND_TABLE_HEADER: [
    { key: 'fundDescription', type: 'text', label: 'Fund name' },
    { key: 'purchaseDate', type: 'date', label: 'Initial purchase' },
    { key: 'period1Perc', type: 'number', label: 'Year to date' },
    { key: 'period2Perc', type: 'number', label: '1 year' },
    { key: 'period3Perc', type: 'number', label: '3 years' },
    { key: 'period4Perc', type: 'number', label: '5 years' },
    { key: 'period5Perc', type: 'number', label: '10 years' },
  ],
  STRATEGY_BENCHMARKING_INDICES_TABLE_HEADER: [
    { key: 'fundDescription', type: 'text', label: 'Fund name' },
    { key: 'period1Perc', type: 'number', label: 'Year to date' },
    { key: 'period2Perc', type: 'number', label: '1 year' },
    { key: 'period3Perc', type: 'number', label: '3 years' },
    { key: 'period4Perc', type: 'number', label: '5 years' },
    { key: 'period5Perc', type: 'number', label: '10 years' },
  ],
  STRATEGY_REDIRECTS_TABLE_HEADER: [
    { key: 'effectiveDate', type: 'date', label: 'Effective date' },
    { key: 'savingBenefit', type: 'text', label: 'Asset allocation' },
    {
      key: 'fundDescription',
      type: 'text',
      label: 'Investment allocation',
    },
  ],
  STRATEGY_TRANSFER_SWITCHING_TABLE_HEADER: [
    { key: 'effectiveDate', type: 'date', label: 'Effective date' },
    { key: 'amount', type: 'currency', label: 'Transfered amount' },
    { key: 'fromFund', type: 'text', label: 'From fund' },
    { key: 'fromUnitPrice', type: 'currency', label: 'Unit price' },
    { key: 'fromUnitDeducted', type: 'number', label: 'Units deducted' },
    { key: 'toFund', type: 'text', label: 'To fund' },
    { key: 'toUnitPrice', type: 'currency', label: 'Unit price' },
    { key: 'toUnitDeducted', type: 'number', label: 'Units allocated' },
  ],
  RETURN_HINT:
    'Portfolio performance is cumulative, net of all contract fees. Includes reinvestment of dividends and/or capital gain.',
  SECTION_TITLE_ARTICLES_LIST: 'Articles you may be interested in',
  SECTION_TITLE_PERFORMANCE_ALLOCATION: 'Portfolio allocations',
  SECTION_TITLE_POLICIES_LIST: 'Active policies',
  SECTION_TITLE_POLICY_COVERAGE: 'Policy coverage and supplementary riders',
  SECTION_TITLE_POLICY_PAYMENT_DETAILS: 'Premium payment details',
  SECTION_TITLE_PREMIUM_ALLOCATION: 'Premium Allocation since inception',
  SECTION_TITLE_PREMIUM_ALLOCATION_HELP_TEXT: [
    {
      key: 'Insurance cost',
      label: 'Cost of insurance protection and any supplementary benefits.',
    },
    {
      key:
        'Contribution(s) net of front end load and any partial enchashment(s)',
      label: 'Insurance cost',
    },
    {
      key: 'Policy fee',
      label: "Annual fee for the upkeep of the policy on Bankers' systems.",
    },
    {
      key: 'Administrative fee',
      label:
        'Fee covering all monthly transactions effected on the policy; covers also the cost of the policy anniversary statement (PAS) and all inquiries initiated by a policy holder.',
    },
    { key: 'Partial encashment charges', label: 'Partial encashment charges' },
    {
      key: 'Fund management charges',
      label:
        'Charge applied to assets under management (AUM). It is calculated daily and applied monthly.',
    },
    {
      key: 'Bid/ask charges',
      label:
        'Spread between the cost of buying and selling a share or unit of investment.',
    },
    {
      key: 'Credited interest',
      label: 'Interest earned and applied to the policy value. ',
    },
    {
      key: 'Fund dividends',
      label: 'Dividend earned and applied to the policy value. ',
    },
    {
      key: 'Contributions allocated to units',
      label:
        'Premiums or contributions, net of all policy fees or expenses, used to purchase shares or units.',
    },
    { key: 'Fund to Fund Transfer Fees', label: 'Fund to Fund Transfer Fees' },
  ],
  SECURITY: 'Security',
  SERVICE_FORMS: 'Service Forms',
  SECTION_TITLE_STRATEGY_ACCOUNT_VALUE_MOVEMENT_CHART: 'Account value movement',
  SECTION_TITLE_STRATEGY_ANALYSIS_CHART: 'Analysis',
  SECTION_TITLE_STRATEGY_AVERAGING_CHART: 'Averaging',
  SECTION_TITLE_STRATEGY_DAILY_FUNDS_CHART: 'Fund price movement',
  SECTION_TITLE_STRATEGY_REDIRECTS: 'Redirect of future premium allocation',
  SECTION_TITLE_STRATEGY_RISK_RETURN: 'Risk vs return',
  SECTION_TITLE_STRATEGY_TRANSFER_SWITCHING: 'Fund to fund transfers',
  SECTION_TITLE_SERVICE_FORMS: 'Service Forms',
  SECTION_TITLE_POLICY_CONTRACT: 'Policy Wording',
  SECTION_TITLE_POLICY_NOTICES: 'E-Notices',
  SUBJECTS_INBOX_SELECT: 'Select Subject',
  POLICIES_INBOX_SELECT: 'Select Policy',
  SENDING_INBOX_MESSAGE: 'Sending...',
  SENDING_INBOX_MESSAGE_FAILED: 'Failed to send message, try again later!',
  INBOX_HINT:
    'In case your inquiry is related to a specific policy, please provide your policy number.',
  INBOX_SUPPORT_NAME: 'Support',
  INBOX_SENT_ON: 'Sent on',
  INBOX_SUBJECT: 'Subject',
  INBOX_DROPDOWN_NO_POLICY: 'No Policy',
  INBOX_DROPDOWN_NO_SUBJECT: 'No Subject',
  SUBJECTS_INBOX: [
    'Make a claim enquiry',
    'General policy enquiry about an existing policy',
    'Request a quotation or policy evaluation',
    'Give feedback about our portal',
    'Make a complaint',
    'Ask us to call you back',
    'Premium settlement',
    'Others',
  ],
  REQUEST_FAILED: 'Failed to fetch data! Server Error',
  SHARE: 'Share',
  SERVER_ERROR: 'Try again',
  SERVER_ERROR_TRY_AGAIN_LATER: 'Server is busy, try again later.',
  SERVER_ERROR_INVALID_OLD_PASS: 'You have entered wrong old password.',
  SERVER_ERROR_INVALID_NEW_PASS:
    'Password must be at least 8 characters, no more than 15 characters, and must include at least one letter and one numeric digit.',
  SERVER_ERROR_INVALID_CREDENTIALS: 'You have entered the wrong combination.',
  SERVER_ERROR_LOGIN_TRY_LATER:
    'User locked out. Please try again after 60 min.',
  SERVER_ERROR_LOGIN_BLOCKED:
    'User locked out, please contact our customer service team at 961-1-962700 ext: 1129, or via e-mail at customer-service@bankers-life.com or reset your password.',
  SERVER_ERROR_NOT_FOUND_EMAIL: 'Email does not exist',
  SURRENDER_VALUE_HELP_TEXT:
    'Exact policy surrender value will be calculated upon liquidation of Mutual fund/ETF units, which fluctuate up or down based on Stock Market conditions around the world. Ultimate surrender value may be lower or higher than the amount displayed herein above.\r\n\r\nAllow three working days from the receipt of your request by Bankers Assurance SAL for the completion of a partial or full liquidation of units.\r\n\r\nCapital gains tax shall apply to dividends earned and to realized capital gains; it will be deducted from your proceeds upon a partial or total surrender of cash from the policy / contract.',
  TABLE_HEADER_BENEFITS: 'Benefits description',
  TABLE_HEADER_CAPITAL_GAIN_TAX: 'Capital gain tax',
  TABLE_HEADER_COST_VUL: 'Cost of insurance since inception',
  TABLE_HEADER_COST_OTHER: 'Modal premium',
  TABLE_HEADER_EFFECTIVE_DATE: 'Effective date',
  TABLE_HEADER_EXPIRY_DATE: 'Expiry date',
  TABLE_HEADER_NET_PARTIAL_AMOUNT_SURRENDERED: 'Net partial amount surrendered',
  TABLE_HEADER_PARTIAL_AMOUNT_SURRENDERED: 'Partial amount surrendered',
  TABLE_HEADER_PARTIAL_SURRENDER_FEE: 'Partial surrender fee',
  TABLE_HEADER_DATE: 'Start Date',
  TABLE_HEADER_START_DATE: 'Effective date',
  TABLE_HEADER_SUM_INSURED: 'Sum insured',
  TOOLTIP_TITLE: 'Details',
  TOTAL_CONTRIBUTION_TITLE: 'Total Contribution',
  TOTAL_RETURN_LOSS_TITLE: 'Total Return',
  VALIDATION_ERROR_MESSAGE_INVALID_EMAIL: 'Invalid email format',
  VALIDATION_ERROR_MESSAGE_REQUIRED: 'Required field',
  VALIDATION_ERROR_MESSAGE_EQUAL_FIELDS: 'Passwords do not match',
  VIEW: 'View',
  VIEW_INBOX: 'View inbox',
  VIEW_LINK: 'View link',
  WATCH_VIDEO: 'Watch video',
  WEALTH_BUILDER: 'Wealthbuilder',
  YOU_REACHED_END_LIST: 'You’ve reached the end of the list. ',
  WARNING_INACTIVITY:
    'Warning: Within next 3 minutes, if you do not do anything, our system will redirect to the login page. Please save changed data.',
});
