// @flow

import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  ChartWrapper,
  ProfitLossBlockContainer,
  ProfitLossScrollableWrapper,
} from './container';

import strings from '../../config/strings';
import type Fund from '../../models/policies';
import { MainContent2 } from '../shared/texts/content';
import HorizontalBarChart from '../charts/bar-horizontal';
import { ChartBlockTitle, BarChartHeaderContainer } from './header';

type Props = {
  funds: Array<Fund>,
};

const ProfitLossBarChart = ({ funds }: Props) => (
  <ProfitLossBlockContainer>
    <ProfitLossScrollableWrapper>
      <ChartBlockTitle>
        {strings.PERFORMANCE_PORTFOLIO_PROFIT_LOSS_TITLE}
      </ChartBlockTitle>

      {!isEmpty(funds) && (
        <Fragment>
          <BarChartHeaderContainer>
            <MainContent2>
              {strings.PERFORMANCE_PORTFOLIO_PROFIT_LOSS_CHART_LABELS_TITLE}
            </MainContent2>
            <MainContent2>
              {strings.PERFORMANCE_PORTFOLIO_PROFIT_LOSS_CHART_VALUES_TITLE}
            </MainContent2>
          </BarChartHeaderContainer>

          <ChartWrapper>
            <HorizontalBarChart
              data={funds.map(fund => fund.interestDividendPercentage)}
              labels={funds.map(fund => fund.fundTicker)}
              tooltipLabels={funds.reduce((accumulator, fund) => {
                accumulator[fund.fundTicker] = {
                  key: fund.fundTicker,
                  description: fund.fundDescription,
                };

                return accumulator;
              }, {})}
            />
          </ChartWrapper>
        </Fragment>
      )}
    </ProfitLossScrollableWrapper>
  </ProfitLossBlockContainer>
);

export default ProfitLossBarChart;
