import React, { useState } from 'react';

import { Anchor } from './styles';
import Spinner from '../../shared/spinner';
import strings from '../../../config/strings';

import { DownloadIcon } from '../table/styles';
import { downloadIcon } from '../../../assets/images';
import { getProducerPoliciesCsv } from '../../../api/producer';

const ExportButton = ({ id, onExport, lowerPosition, disabled }) => {
  const [loading, setLoading] = useState(false);

  const pos = lowerPosition
    ? { top: 25, position: 'relative' }
    : {};

  const onClick = async () => {
    setLoading(true);
    if (onExport) {
      await onExport();
    } else {
      await getProducerPoliciesCsv(id);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Spinner
        offset="0"
        style={{ transform: 'scale(0.5)', ...pos }}
      />
    );
  }

  return (
    <Anchor onClick={onClick} disabled={disabled}>
      {strings.PRODUCER_TABLE_EXPORT}
      <DownloadIcon
        src={downloadIcon}
        alt="download"
        height="16"
        width="12"
      />
    </Anchor>
  );
}

export default ExportButton;
