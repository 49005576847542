import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../utils/date';
import { CHANGE_PASSWORD } from '../../router/routes';
import { calendar } from '../../assets/images';

import {
  PageContainer,
  ProfileFormDiv,
  TitleDiv,
  RowContainer,
  CalenderDiv,
  CalenderHolder,
  ProfileSection,
} from './styles';

import {
  ProfileDetails,
  ProfileInformation,
} from '../../components/shared/texts/title';

import InputDropDown from '../../components/shared/form-dropdown';

import { PasswordLink } from '../../components/shared/texts/navigation-links';
import InputField from '../../components/shared/input-field';
import TextArea from '../../components/shared/text-area';
import strings from '../../config/strings';
import ProfileMenu from './profile-menu';

const ProfilePage = () => {
  const history = useHistory();
  const [emails, setUserEmails] = useState([]);

  const {
    data: user,
    account: {
      data: {
        user: { username },
      },
    },
  } = useSelector(state => state.user);

  const {
    dob = '',
    email = '',
    fullname = '',
    nationality = '',
    maritalStatus = '',
    phoneNumber = '',
    address,
  } = user;

  useEffect(() => {
    if (!isEmpty(email)) {
      const userEmails = email.split(';');
      setUserEmails(userEmails);
    } else {
      setUserEmails([email]);
    }
  }, [email]);

  const formattedDob = isEmpty(dob) ? '' : formatDate(dob);

  return (
    <PageContainer>
      <ProfileMenu />
      <ProfileFormDiv>
        <ProfileDetails>{strings.PROFILE_DETAILS}</ProfileDetails>

        <ProfileSection className="personal-information">
          <TitleDiv>
            <ProfileInformation>
              {strings.PERSONAL_INFORMATION}
            </ProfileInformation>
          </TitleDiv>

          <InputField
            placeholder={strings.PROFILE_INPUT_FULL_NAME_PLACEHOLDER}
            value={fullname}
            disabled
          />

          <RowContainer style={{ marginTop: '20px' }}>
            <InputDropDown
              placeholder={strings.PROFILE_INPUT_NATIONALITY_PLACEHOLDER}
              value={nationality}
            />
          </RowContainer>

          <RowContainer>
            <InputField
              placeholder={strings.PROFILE_INPUT_DOB_PLACEHOLDER}
              value={formattedDob}
              disabled
            />

            <CalenderHolder>
              <CalenderDiv>
                <img src={calendar} alt="calender" />
              </CalenderDiv>
            </CalenderHolder>
          </RowContainer>

          <InputField
            placeholder={strings.PROFILE_INPUT_MARITAL_STATUS_PLACEHOLDER}
            value={maritalStatus}
            disabled
          />
        </ProfileSection>

        <ProfileSection className="contacts">
          <TitleDiv>
            <ProfileInformation>{strings.CONTACTS}</ProfileInformation>
          </TitleDiv>

          {emails.map(userEmail => (
            <InputField
              placeholder={strings.PROFILE_INPUT_EMAIL_PLACEHOLDER}
              value={userEmail}
              disabled
            />
          ))}

          <InputField
            placeholder={strings.PROFILE_INPUT_PHONE_NUMBER_PLACEHOLDER}
            value={phoneNumber}
            disabled
          />
        </ProfileSection>

        <ProfileSection className="address">
          <TitleDiv>
            <ProfileInformation>{strings.ADDRESS}</ProfileInformation>
          </TitleDiv>

          <TextArea
            placeholder={strings.PROFILE_INPUT_ADDRESS_PLACEHOLDER}
            value={address}
            disabled
          />
        </ProfileSection>

        <ProfileSection className="security">
          <TitleDiv>
            <ProfileInformation>{strings.SECURITY}</ProfileInformation>
          </TitleDiv>

          <InputField
            placeholder={strings.PROFILE_INPUT_USERNAME_PLACEHOLDER}
            value={username}
            disabled
          />

          <InputField
            placeholder={strings.FORM_INPUT_PLACEHOLDER_PASSWORD}
            value={strings.FORM_INPUT_PLACEHOLDER_PASSWORD}
            disabled
          />

          <PasswordLink onClick={() => history.push(CHANGE_PASSWORD)}>
            {strings.CHANGE_PASSWORD}
          </PasswordLink>
        </ProfileSection>

        {/* <ProfileSection className="preferences">
          <TitleDiv>
            <ProfileInformation>{strings.PREFERENCES}</ProfileInformation>
          </TitleDiv>
          <RowContainer>
            <Notification>{strings.ALLOW_NOTIFICATIONS}</Notification>
            <SwitchButton />
          </RowContainer>
        </ProfileSection> */}
      </ProfileFormDiv>
    </PageContainer>
  );
};

export default ProfilePage;
