import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isInvestmentTypePolicy } from '../utils/policy';

import {
  getPolicyBenefits,
  getPremiumAllocation,
  getPolicyPaymentDetails,
  getEncashment,
  getBeneficiaries,
  getPoliciesList,
  getCustomerPoliciesList,
} from '../redux/actions/policies.action';

export const usePolicies = (reload = false, customerId = null) => {
  const dispatch = useDispatch();

  const {
    data: customerPolicies,
    loading: customerPoliciesLoading,
  } = useSelector(state => state.policies.customerPolicies);

  const {
    data: policies,
    loading: policiesLoading,
    requested: policiesRequested,
  } = useSelector(state => state.policies.policiesList);

  const currentCustomerId = useSelector(
    state => state.policies.currentCustomerId,
  );

  const { account = {} } = useSelector(state => state.user);
  let username;

  if (account && account.data) {
    ({ username } = account.data.user);
  }

  useEffect(() => {
    if (!customerId) {
      if ((!policiesLoading && !policiesRequested) || reload) {
        dispatch(getPoliciesList(username));
      }

      return;
    }

    const sameCustomer = `${customerId}` === `${currentCustomerId}`;
    const loadingCustomer = customerId === '...';

    if (!loadingCustomer && !sameCustomer && !customerPoliciesLoading) {
      dispatch(getCustomerPoliciesList(customerId, username));
    }
  }, [customerId, currentCustomerId, username]);

  if (customerId === '...') {
    return [];
  }

  if (customerId) {
    return customerPolicies;
  }

  return policies;
};

export const usePolicyBasicInfo = (policyId, customerId) => {
  const [currencies, setCurrencies] = useState([]);
  const policies = usePolicies(false, customerId);
  const [basicInfo, setBasicInfo] = useState({});

  useEffect(() => {
    if (policies.length) {
      setBasicInfo(policies.find(p => p.policyId === policyId));
    }
  }, [policies, policyId, customerId]);

  useEffect(() => {
    if (!isEmpty(basicInfo)) {
      const {
        policyCurrency,
        currency,
        lbpCurrencyId,
        lbpCurrency,
      } = basicInfo;

      const policyCurrencies = [
        { id: policyCurrency, name: currency, isDefault: true },
        {
          id: lbpCurrencyId,
          name: lbpCurrency,
        },
      ]
        .filter(curr => curr.id)
        .sort((x, y) => Number(x) - Number(y));

      setCurrencies(policyCurrencies);
    }
  }, [JSON.stringify(basicInfo)]);

  return {
    loading: isEmpty(basicInfo),
    data: { ...basicInfo, currencies } || {},
  };
};

export const usePolicyPaymentDetails = (policyId, customerId) => {
  const dispatch = useDispatch();
  const policies = usePolicies(false, customerId);

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);

  const { loading, requested, data } = useSelector(
    state => state.policies.policyPaymentDetails,
  );

  useEffect(() => {
    const shouldReload = !requested && !loading;

    if (
      policyId &&
      (policyId !== previousPolicyId || shouldReload) &&
      customerId !== '...'
    ) {
      dispatch(getPolicyPaymentDetails(policyId));
    }
  }, [policies, policyId, customerId]);

  return { loading, data };
};

export const usePolicyBenefits = (policyId, customerId) => {
  const dispatch = useDispatch();
  const policies = usePolicies(false, customerId);

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);

  const { loading, requested, data } = useSelector(
    state => state.policies.policyBenefits,
  );

  useEffect(() => {
    const shouldReload = !requested && !loading;

    if (
      policyId &&
      (policyId !== previousPolicyId || shouldReload) &&
      customerId !== '...'
    ) {
      dispatch(getPolicyBenefits(policyId));
    }
  }, [policies, policyId, customerId]);

  return { loading, data };
};

export const useEncashments = policyId => {
  const dispatch = useDispatch();

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);

  const { loading, requested, data } = useSelector(
    state => state.policies.policyEncashment,
  );

  useEffect(() => {
    const shouldReload = !requested && !loading;

    if (policyId && (policyId !== previousPolicyId || shouldReload)) {
      dispatch(getEncashment(policyId));
    }
  }, [policyId]);

  return { loading, data };
};

export const usePremiumAllocation = (
  policyId,
  dateChange,
  policyType,
  shouldUpdate,
  currencyId,
) => {
  const dispatch = useDispatch();

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);

  const { loading, requested, data } = useSelector(
    state => state.policies.premiumAllocation,
  );

  useEffect(() => {
    const shouldReload = (!requested && !loading) || shouldUpdate;

    if (!policyType || !isInvestmentTypePolicy(policyType)) {
      return;
    }

    if (
      policyId &&
      (policyId !== previousPolicyId || shouldReload) &&
      currencyId
    ) {
      dispatch(getPremiumAllocation(policyId, dateChange, currencyId));
    }
  }, [policyId, dateChange, policyType, shouldUpdate, currencyId]);

  return { loading, data };
};

export const useBeneficiaries = policyId => {
  const dispatch = useDispatch();

  const previousPolicyId = useSelector(state => state.policies.currentPolicyId);

  const { loading, requested, data } = useSelector(
    state => state.policies.policyBeneficiaries,
  );

  useEffect(() => {
    const shouldReload = !requested && !loading;

    if (policyId && (policyId !== previousPolicyId || shouldReload)) {
      dispatch(getBeneficiaries(policyId));
    }
  }, [policyId]);

  return { loading, data };
};
