import React from 'react';
import Dropdown from '../shared/dropdown';
import strings from '../../config/strings';
import moveElementPos from '../../utils/arrays';

const searchPlaceholder = strings.PLACEHOLDER_SEARCH_POLICY;

const getPolicyTitle = policy => {
  const [firstName, lastName = ''] = policy.insuredName.split(',');
  const hasLastName = (typeof lastName === 'string' && lastName.length > 0);
  const initial = hasLastName ? ` ${lastName.charAt(0)}.` : '';
  return `${firstName}${initial} #${policy.policyNumber}`
};

const PolicySelector = ({ policies = [], currentPolicy = {}, onChangePolicy }) => {
  const title = getPolicyTitle(currentPolicy);

  // setting field `id` for the dropdown to track selected item
  const selected = { ...currentPolicy, id: currentPolicy.policyId };

  const options = [{ name: strings.POLICY_DDL_TITLE, id: -1 }];

  const policiesDDLItems = policies.map(policy => ({
    name: getPolicyTitle(policy),
    id: policy.policyId,
  }));

  const selectedPolicyIndex = policiesDDLItems.findIndex(
    policy => policy.id === selected.id,
  );

  moveElementPos(policiesDDLItems, selectedPolicyIndex, 0);
  options.push(...policiesDDLItems);

  function onSelect(id) {
    if (id !== -1) {
      onChangePolicy(policies.find(p => p.policyId === id));
    }
  }

  return <Dropdown {...{ options, title, selected, onSelect, searchPlaceholder }} compact />;
};

export default PolicySelector;
