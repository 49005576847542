// @flow

import React from 'react';
import { useHistory } from 'react-router';
import { POLICY_DETAILS } from '../router/routes';
import { usePolicies } from '../hooks/policies.hooks';
import { Overview as OverviewScreen } from '../pages';

const Overview = () => {
  const history = useHistory();
  const policies = usePolicies(true);

  const handlePolicyViewMoreClick = (policyNumber: number) => {
    history.push({
      pathname: POLICY_DETAILS,
      state: { policyNumber },
    });
  };

  return (
    <OverviewScreen
      handlePolicyViewMoreClick={handlePolicyViewMoreClick}
      userPolicies={policies}
    />
  );
};

export default Overview;
