import { get } from 'axios';

import {
  SUCCESS_GET_ARTICLES,
  REQUEST_GET_ARTICLES,
  FAILURE_GET_ARTICLES,
  SUCCESS_GET_ARTICLE_DETAILS,
} from '../constants/types';

const { REACT_APP_CMS_URL } = process.env;

const requestGetArticles = () => ({ type: REQUEST_GET_ARTICLES });

const successGetArticleDetails = (currentArticle) => ({
  type: SUCCESS_GET_ARTICLE_DETAILS,
  payload: { currentArticle },
});

const successGetArticles = (articles) => ({
  type: SUCCESS_GET_ARTICLES,
  payload: { articles },
});

const failureGetArticles = (err) => ({
  type: FAILURE_GET_ARTICLES,
  payload: { err },
});

export const getArticles = () => (
  async (dispatch) => {
    dispatch(requestGetArticles());

    const shouldBeFeatured = 'featured=true';
    const sort = '_sort=updated_at:DESC';

    // prepare query parameters
    const query = `${shouldBeFeatured}&${sort}`;

    try {
      const { data: articles } = await get(`${REACT_APP_CMS_URL}/articles?${query}`);
      dispatch(successGetArticles(articles));
    } catch (error) {
      dispatch(failureGetArticles(error));
    }
  }
);

export const getArticleDetails = (id) => (
  async (dispatch) => {
    try {
      const { data: article } = await get(`${REACT_APP_CMS_URL}/articles/${id}`);
      dispatch(successGetArticleDetails(article));
    } catch (error) {
      // TODO: handle error
    }
  }
);

export const setArticleDetails = (currentArticle) => (
  async (dispatch) => {
    dispatch(successGetArticleDetails(currentArticle))
  }
);

export const clearArticles = () => (
  successGetArticles([])
);
