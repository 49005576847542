// @flow

import React, { useState, useEffect } from 'react';

import {
  DropdownsWrapper,
  DropdownContainer,
} from './styles';

import Dropdown from '../../shared/dropdown';

import strings from '../../../config/strings';
import moveElementPos from '../../../utils/arrays';

type Props = {
  data: any[];
  selected: any[];
  onChangeFilter: (filter) => void;
};

const getSelected = (map, key) => {
  const value = map.get(key);
  return value ? value.selected || strings.FILTER_NONE : '';
}

const formatOptions = (map, key) => {
  const filter = map.get(key);

  if (filter) {
    const options = filter.options.map((name, id) => ({ id, name }));
    return [{ id: -1, name: `${strings.FILTER_CLEAR} ${filter.name}` }, ...options];
  }

  return [];
};

const Dropdowns = ({ data, selected, onChangeFilter }: Props) => {
  const [filtersMap, setFiltersMap] = useState(new Map());

  const updateFilter = (map) => {
    setFiltersMap(map);
    onChangeFilter(map);
  };

  const onSelect = (id, key) => {
    const newMap = new Map(filtersMap);

    const filter = newMap.get(key);
    let selectedValue = null;

    if (id < 0) {
      const options = filter.initialValues;
      newMap.set(key, { ...filter, options, selected: selectedValue });
    } else {
      const options = [...filter.options];
      moveElementPos(options, id, 0);

      selectedValue = filter.options[id];
      newMap.set(key, { ...filter, options, selected: selectedValue });
    }

    updateFilter(newMap);
  };

  useEffect(() => {
    if (data && data.length) {
      const newMap = new Map();

      data.forEach(filter => {
        const selectedFilter = selected.find(s => s.key === filter.key);
        const filterValues = [...filter.values];

        const selectedIndex = selectedFilter
          ? filterValues.findIndex(v => v === selectedFilter.value)
          : 0;

        const val = {
          name: filter.name,
          initialValues: filterValues,
          options: !selectedFilter ? filterValues : moveElementPos([...filterValues], selectedIndex, 0),
          selected: selectedFilter ? selectedFilter.value : null,
        };

        newMap.set(filter.key, val)
      });

      updateFilter(newMap);
    }
  }, [data]);

  return (
    <DropdownsWrapper>
      {
        data.map(({ key, name }) => (
          <DropdownContainer key={key}>
            <Dropdown
              title={`${name}: ${getSelected(filtersMap, key)}`}
              options={formatOptions(filtersMap, key)}
              selected={getSelected(filtersMap, key)}
              onSelect={(id) => onSelect(id, key)}
              size="small"
            />
          </DropdownContainer>
        ))
      }
    </DropdownsWrapper>
  );
};

export default Dropdowns;
