// @flow

const { REACT_APP_CMS_URL } = process.env;

type ImageSize = 'small' | 'medium' | 'large';

const getImageUrl = (image = {}, size: ImageSize) => {
  if (image.formats && size) {
    if (image.formats[size] && image.formats[size].url) {
      return `${REACT_APP_CMS_URL}${image.formats[size].url}`;
    }
  }

  return image.url ? `${REACT_APP_CMS_URL}${image.url}` : '';
};

export default getImageUrl;
