// @flow

import React from 'react';

import Table from '../shared/table';
import strings from '../../config/strings';
import { strategyListTitle, Container } from './container';
import { SectionTitle2 } from '../shared/texts/title';
import DocumentCard from '../document-card';

type Props = {
  data: any[],
  handleLoadMoreClick: Function,
  shouldLoadMore: Boolean,
};

const StrategyRedirects = ({
  data: fundRedirect = [],
  handleLoadMoreClick,
  shouldLoadMore,
}: Props) => {
  const fundRedirectData = fundRedirect.map(item => ({
    ...item,
    fundDescription: `${item.redirectPerc}% ${item.fundDescription}`,
  }));

  return (
    <Container>
      <SectionTitle2 style={strategyListTitle}>
        {strings.SECTION_TITLE_STRATEGY_REDIRECTS}
      </SectionTitle2>

      <Table
        headerData={strings.STRATEGY_REDIRECTS_TABLE_HEADER}
        contentData={fundRedirectData}
        nomargin
        onLoadMore={shouldLoadMore ? () => handleLoadMoreClick() : undefined}
      />

      {!fundRedirect.length && (
        <DocumentCard empty noIcon centerClause emptyClause={strings.NO_DATA} />
      )}
    </Container>
  );
};

export default StrategyRedirects;
