import styled from 'styled-components';

import { RowContainer } from '../../shared/container';

export const CardsCarouselContainer = styled.div`
  position: relative;
`;

export const CardsContainer = styled(RowContainer)`
  justify-content: space-between;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
`;

export const ContentContainer = styled(RowContainer)`
  width: 100%;
  position: relative;
  justify-content: flex-start;

  ${({ isEditable }) =>
    isEditable &&
    `
  cursor:pointer;
  `}
`;

export const dateCard = {
  position: 'relative',
  width: 240,
};

export const TitleContainer = styled(RowContainer)`
  align-items: flex-start;
`;

export const ToolTipContainer = styled.div`
  margin-left: 11px;
`;

export const DatePickerContainer = styled.div`
  z-index: 20;
  margin-left: -40px;
  display: none;
  position: fixed;
`;

export const CarouselArrow = styled.div`
  position: absolute;
  top: 40%;
  left: -15px;
  right: -15px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

export const Slides = styled.ul`
  padding: 0;
  right: 0;
  overflow-x: visible;
  overflow-y: visible;
  list-style: none;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  -webkit-transition: right 0.5s;
  -moz-transition: right 0.5s;
  -o-transition: right 0.5s;
  transition: right 0.5s;

  ${({ scrollRight }) =>
    scrollRight &&
    `
  right: ${scrollRight}px`}
`;
