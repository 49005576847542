// @flow

import React from 'react';

import {
  Chip,
  ClearAll,
  ChipText,
  Container,
  ChipCloseIcon,
  ChipsContainer,
} from './styles';

import { crossIcon3x } from '../../../assets/images';

type Props = {
  chips: any[];
  onClearChips: Function;
  onRemoveChip: (index: number) => void;
};

const FilterChips = ({ chips, onRemoveChip, onClearChips }: Props) => (
  !!(chips && chips.length) && (
    <Container>
      <ChipsContainer>
        {chips.map((chip, i) => (
          <Chip key={chip.text}>
            <ChipText>{chip.text}</ChipText>
            <ChipCloseIcon onClick={() => onRemoveChip(i)}>
              <img src={crossIcon3x} alt="x" width="17" height="17" />
            </ChipCloseIcon>
          </Chip>
        ))}
      </ChipsContainer>
      <ClearAll onClick={onClearChips}>
        Clear all
      </ClearAll>
    </Container>
  )
);

export default FilterChips;
