import { isNull, isUndefined } from 'lodash';

import globals from '../config/globals';
import strings from '../config/strings';

export const formatDecimal = (number = 0) =>
  `${number}`.includes('.') ? (+number).toFixed(2) : number;

export const numberWithCommas = (number = 0) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const decimalWithCommas = (
  number,
  isAbs = false,
  shouldReplaceNullWithString = true,
) => {
  let formatted = number;

  if (isNull(number) || isUndefined(number)) {
    formatted = shouldReplaceNullWithString
      ? strings.NULL_NUMBER_REPLACEMENT
      : '';
  } else if (isAbs) {
    formatted = numberWithCommas(formatDecimal(Math.abs(number)));
  } else {
    formatted = numberWithCommas(formatDecimal(number));
  }

  return formatted;
};

export const roundDecimalToInteger = number =>
  isNull(number) || isUndefined(number)
    ? strings.NULL_NUMBER_REPLACEMENT
    : numberWithCommas(number.toFixed(0));

export const formatCurrency = (currency = 'USD', isNegative = false) =>
  (isNegative ? '-' : '') + globals.CURRENCIES[currency].value;

export const isEmptyNumber = number => isNull(number) || isUndefined(number);
