// @flow

import React from 'react';
import InfoCard from '../../shared/info-card';
import strings from '../../../config/strings';

type Props = {
  data: { [key: string]: any },
};

const prepareInfoData = data => [
  { value: data.planName, label: strings.POLICY_INFO_PLAN_NAME, type: 'text' },
  {
    value: data.startDate,
    label: strings.POLICY_INFO_EFFECTIVE_DATE,
    type: 'date',
  },
  { value: data.endDate, label: strings.POLICY_INFO_EXPIRY_DATE, type: 'date' },
  {
    value: data.policyAnniversaryDate,
    label: strings.POLICY_INFO_ANNIVERSARY,
    type: 'date',
  },
  {
    value: data.ownerName,
    label: strings.POLICY_INFO_POLICY_OWNER,
    type: 'text',
  },
  {
    value: data.insuredName,
    label: strings.POLICY_INSURED_BY_TITLE,
    type: 'text',
  },
  {
    value: data.insuredDob,
    label: strings.POLICY_INFO_INSURED_DOB,
    type: 'date',
  },
];

const PolicyBasicInfo = ({ data }: Props) => (
  <InfoCard data={prepareInfoData(data)} />
);

export default PolicyBasicInfo;
