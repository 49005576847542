// @flow

import React from 'react';
import type { Node } from 'react';
import Switch from './switch';
import { Content, Container } from './container';

type Props = {
  activeTabKey: string,
  children: Node,
  handleTabClick: Function,
};

const SwitchContainer = ({ activeTabKey, children, handleTabClick }: Props) => (
  <Container>
    <Switch activeTabKey={activeTabKey} handleTabClick={handleTabClick} />
    <Content>{children}</Content>
  </Container>
);

export default SwitchContainer;
