// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import SubmitButton from '../../form/button';
import strings from '../../../config/strings';
import getImageUrl from '../../../utils/image';
import type Article from '../../../models/article';

import {
  Card,
  ImgContainer,
  ArticleContent,
  ArticleOverview,
  ArticleTitle,
} from './container';

type Props = {
  article: Article,
};

const ArticleCard = ({ article }: Props) => {
  function renderButton() {
    return <SubmitButton value={strings.ARTICLE_READ_BUTTON} />;
  }

  function renderLinkAnchor() {
    // link to external url
    if (article.link) {
      return (
        <a href={article.link} target="_blank" rel="noopener noreferrer">
          {renderButton()}
        </a>
      );
    }

    // local link to article details
    return <Link to={`/articles/${article.id}`}>{renderButton()}</Link>;
  }

  return (
    <Card>
      <ImgContainer>
        {article.image && (
          <img
            alt={article.image.alternativeText}
            src={getImageUrl(article.image, 'large')}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        )}
      </ImgContainer>
      <ArticleOverview>
        <ArticleTitle>{article.title}</ArticleTitle>
        <ArticleContent style={{ WebkitBoxOrient: 'vertical' }}>
          {article.content}
        </ArticleContent>

        {renderLinkAnchor()}
      </ArticleOverview>
    </Card>
  );
};

export default ArticleCard;
