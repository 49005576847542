import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { PRIVACY_POLICY } from './routes';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === PRIVACY_POLICY && location.hash) {
      return;
    }

    window.scrollTo(0, 0);
  }, [location]);

  return <React.Fragment />;
};

export default ScrollToTop;
