import Axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import {
  REQUEST_GET_PRODUCER_POLICIES,
  SUCCESS_GET_PRODUCER_POLICIES,
  REQUEST_GET_PRODUCER_EXPANDED,
  SUCCESS_GET_PRODUCER_EXPANDED,
  REQUEST_GET_PRODUCER,
  SUCCESS_GET_PRODUCER,
  FAILURE_GET_PRODUCER,
  SUCCESS_GET_FILTERS,
  SUCCESS_GET_LEVELS,
} from '../types/producer';

import getProducerLevelsAPI from '../../api/producer-levels';
import getProducerFiltersAPI from '../../api/producer-filters';
import getProducerPoliciesAPI from '../../api/producer-policies';
import getProducerAPI, { getProducerSubLevels } from '../../api/producer';

import showToast from '../../utils/toast';

const requestGetProducer = (resetOriginal) => ({
  type: REQUEST_GET_PRODUCER,
  payload: { resetOriginal },
});

const requestGetProducerExpanded = () => ({
  type: REQUEST_GET_PRODUCER_EXPANDED,
});

const requestGetProducerPolicies = (producerId, resetOriginal) => ({
  type: REQUEST_GET_PRODUCER_POLICIES,
  payload: { producerId, resetOriginal },
});

const successGetProducerPolicies = (producerId, policies, append) => ({
  type: SUCCESS_GET_PRODUCER_POLICIES,
  payload: { policies, producerId, append },
});

const successGetProducerExpanded = expanded => ({
  type: SUCCESS_GET_PRODUCER_EXPANDED,
  payload: { expanded },
});

const successGetProducer = producer => ({
  type: SUCCESS_GET_PRODUCER,
  payload: { producer },
});

const failureGetProducer = err => ({
  type: FAILURE_GET_PRODUCER,
  payload: { err },
});

const successGetProducerFilters = filters => ({
  type: SUCCESS_GET_FILTERS,
  payload: { filters },
});

const successGetProducerLevels = levels => ({
  type: SUCCESS_GET_LEVELS,
  payload: { levels },
});

export const getProducer = (userInfo, filter) => async dispatch => {
  const resetOriginal = !isEmpty(filter) || userInfo.searchKey;
  dispatch(requestGetProducer(resetOriginal));

  try {
    const { data } = await getProducerSubLevels(userInfo, filter);

    dispatch(successGetProducer(data));
  } catch (error) {
    if (!Axios.isCancel(error)) {
      dispatch(failureGetProducer(error));
      showToast();
    }
  }
};

export const getProducerExpanded = (userInfo, filter) => async dispatch => {
  dispatch(requestGetProducerExpanded());

  try {
    const { data } = await getProducerAPI(userInfo, filter);

    dispatch(successGetProducerExpanded(data));
  } catch (error) {
    console.error(error);
  }
};

export const getProducerPolicies = (
  pageNbr,
  allPolicies,
  producerId,
  filtersQueryString,
) => async dispatch => {
  dispatch(requestGetProducerPolicies(producerId, pageNbr === 1));

  try {
    const { data } = await getProducerPoliciesAPI(
      pageNbr,
      allPolicies,
      producerId,
      filtersQueryString,
    );

    dispatch(successGetProducerPolicies(producerId, data, pageNbr !== 1));
  } catch (error) {
    console.error(error);
  }
};

export const getProducerFilters = () => async dispatch => {
  try {
    const { data } = await getProducerFiltersAPI();

    dispatch(successGetProducerFilters(data));
  } catch (error) {
    console.error(error);
  }
};

export const getProducerLevels = level => async dispatch => {
  try {
    const { data } = await getProducerLevelsAPI();

    const currentLevel = data.findIndex(el => el.code === level);
    const levels = data.slice(currentLevel);

    dispatch(successGetProducerLevels(levels));
  } catch (error) {
    console.error(error);
  }
};
