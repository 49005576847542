import styled from 'styled-components';
import { DocumentLink } from '../texts/link';
import { size } from '../../../config/device';
import { ColumnContainer, RowContainer } from '../container';

export const DocumentLinkLabel = styled(DocumentLink)`
  text-decoration: underline;
  cursor: pointer;
`;

export const PopupLinksListContainer = styled.div`
  padding: 0 50px;

  @media (max-width: ${size.tablet}) {
    padding: 40px;
    width: calc(90% - 80px);
  }
`;

export const PopupLinkOutline = styled(RowContainer)`
  border-bottom: solid 1px #d9d9d9;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
`;

export const Block = styled(ColumnContainer)`
  align-items: flex-start;
  margin: 0 14px;
`;
