import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { USER_LOGOUT } from '../constants/types';

import UserReducer from './user';
import AccountReducer from './account';
import StrategyReducer from './strategy';
import ThemeReducer from './theme.reducer';
import DocumentsReducer from './documents';
import PerformanceReducer from './performance';
import ContributionReducer from './contribution';
import ArticlesReducer from './articles.reducer';
import PoliciesReducer from './policies.reducer';
import ServiceFormsReducer from './service-forms';
import PrivacyPolicyReducer from './privacy-policy';
import NotificationsReducer from './notifications';
import ProducerReducer from './producer';
import LibraryReducer from './library';
import InboxReducer from './inbox';
import MediaReducer from './media';
import FAQsReducer from './faqs';

const userPersistConfig = {
  key: 'user',
  storage,
};

const themePersistConfig = {
  key: 'theme',
  storage,
};

const mediaPersistConfig = {
  key: 'media',
  storage,
};

const producerPersistConfig = {
  key: 'producer',
  storage,
};

const policiesPersistConfig = {
  whitelist: ['currentPolicyId'],
  key: 'policies',
  storage,
};

// setup API cache
window.WB_API_CACHE = {};

const appReducer = combineReducers({
  // persisted in local storage
  user: persistReducer(userPersistConfig, UserReducer),
  theme: persistReducer(themePersistConfig, ThemeReducer),
  media: persistReducer(mediaPersistConfig, MediaReducer),
  producer: persistReducer(producerPersistConfig, ProducerReducer),
  policies: persistReducer(policiesPersistConfig, PoliciesReducer),

  // volatile
  faqs: FAQsReducer,
  inbox: InboxReducer,
  account: AccountReducer,
  library: LibraryReducer,
  articles: ArticlesReducer,
  strategy: StrategyReducer,
  documents: DocumentsReducer,
  notifications: NotificationsReducer,
  performance: PerformanceReducer,
  serviceForms: ServiceFormsReducer,
  contribution: ContributionReducer,
  privacyPolicy: PrivacyPolicyReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    storage.removeItem('currentPolicyId');
    storage.removeItem('token');

    // reset API cache
    window.WB_API_CACHE = {};

    // theme and media are always cached
    // user & producer & policies are reset individually
    // keep them in state to update redux persist (check individual reducers)
    const { theme, media, user, producer, policies } = state;

    // eslint-disable-next-line no-param-reassign
    state = { theme, media, user, producer, policies };
  }

  return appReducer(state, action);
};

export default rootReducer;
