// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  parseErrorId,
  isAuthBlockedError,
  isAuthBlockedTempError,
  isAuthBlockedResetPasswordRequired,
} from '../utils/strapi';

import { LoginScreen } from '../pages';
import strings from '../config/strings';
import { login } from '../redux/actions';
import { hasProducerAccess } from '../utils/user';
import { getUserInfo } from '../redux/actions/user';
import {
  INDEX,
  RESET_PASSWORD,
  PRODUCER,
  FORGET_PASSWORD,
} from '../router/routes';
import { getFooter, getTheme } from '../redux/actions/theme.action';

type Props = { history: { push: Function } };

const UserLogin = ({ history }: Props) => {
  const dispatch = useDispatch();
  const themeColors = useSelector(state => state.theme.colors);
  const { logo = {} } = useSelector(state => state.media.data);

  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async data => {
    let loginSuccess = false;

    try {
      const result = await dispatch(login(data));
      loginSuccess = true;

      if (result.changePass) {
        history.push({
          pathname: RESET_PASSWORD,
          state: {
            credentials: { otp: data.password, username: data.username },
          },
        });
      } else {
        dispatch(getUserInfo(data.username));
        const isProducer = hasProducerAccess(result.user.typeLinkCode);
        const nextRoute = isProducer ? PRODUCER : INDEX;
        history.push({ pathname: '/' });
      }
    } catch (error) {
      if (loginSuccess) {
        return;
      }

      if (!error || !error.response) {
        setError('password', {
          type: 'manual',
          message: strings.SERVER_ERROR_TRY_AGAIN_LATER,
        });

        return;
      }

      const errorData = error.response.data;
      const errorId = parseErrorId(errorData);

      let errorMessage = strings.SERVER_ERROR_INVALID_CREDENTIALS;

      if (isAuthBlockedError(errorId)) {
        errorMessage = strings.SERVER_ERROR_LOGIN_BLOCKED;
      } else if (isAuthBlockedTempError(errorId)) {
        errorMessage = strings.SERVER_ERROR_LOGIN_TRY_LATER;
      } else if (isAuthBlockedResetPasswordRequired(errorId)) {
        history.push({ pathname: FORGET_PASSWORD });
      }

      setError('password', {
        type: 'manual',
        message: errorMessage,
      });
    }
  };

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getTheme());
  }, []);

  return (
    <LoginScreen
      logoUrl={logo.url}
      handleFormSubmit={handleFormSubmit}
      themeColors={themeColors}
      formUtils={{ register, handleSubmit, errors }}
    />
  );
};

export default UserLogin;
