// @flow

import React from 'react';
import ServiceFormsList from '../components/service-forms/list';

type Props = {
  forms: any[],
  handleDownloadDocumentClick: Function,
  handleViewDocumentClick: Function,
};

const ServiceFormsScreen = ({
  forms,
  handleDownloadDocumentClick,
  handleViewDocumentClick,
}: Props) => (
  <ServiceFormsList
    data={forms}
    handleDownloadDocumentClick={handleDownloadDocumentClick}
    handleViewDocumentClick={handleViewDocumentClick}
  />
);

export default ServiceFormsScreen;
