// @flow

import axios from 'axios';
import { isEmpty } from 'lodash';

import type { Moment } from 'moment';

import CustomError from '../utils/error';
import { dateToString } from '../utils/date';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

export const getPerformance = (
  policyId: string,
  dateAsAt: Moment,
  currencyId: number,
) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');

  const dateStr = dateToString(dateAsAt);

  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/policies/${policyId}/performance?date=${dateStr}&currencyId=${currencyId}`;

  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });
};

export const getRiskReturnPerformance = (
  policyId: string,
  dateAsAt: Moment,
) => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_WB_BACKEND_URL ||
    ''}/policies/${policyId}/performance/risk-return`;
  const params = { date: dateToString(dateAsAt) };

  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
    params,
  });
};
