import styled from 'styled-components';

import { RowContainer } from '../shared/container';
import { SectionTitle3 } from '../shared/texts/title';

export const Container = styled.div`
  position: relative;
`;

export const ChartBlockTitle = styled(SectionTitle3)`
  margin-bottom: 40px;
`;

export const BarChartHeaderContainer = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;

  > :last-child {
    padding-right: 15px;
  }
`;
