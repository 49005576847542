// @flow

import React from 'react';
import * as qs from 'query-string';

import { useParams, useHistory, useLocation } from 'react-router';
import { PRODUCER_POLICY_DETAILS } from '../router/routes';
import { CustomerInformation as CustomerInformationScreen } from '../pages';
import { usePolicies } from '../hooks';

const CustomerInformation = () => {
  const history = useHistory();
  const location = useLocation();
  const { customerName } = qs.parse(location.search) || {};

  const { id: customerId } = useParams();
  const data = usePolicies(false, customerId);

  const handlePolicyViewMoreClick = (policyNumber: number) => {
    history.push(`${PRODUCER_POLICY_DETAILS.replace(':id', customerId)}?policyNumber=${policyNumber}`);
  };

  return (
    <CustomerInformationScreen
      handlePolicyViewMoreClick={handlePolicyViewMoreClick}
      userPolicies={data}
      customerName={customerName}
    />
  );
};

export default CustomerInformation;
