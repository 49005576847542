import React from 'react';
import Search from '../shared/search';

import strings from '../../config/strings';
import { SectionTitle } from '../shared/texts/title';
import ActionButton from '../shared/action-button';
import { plusIcon } from '../../assets/images';

import {
  sectionTitleStyle,
  ActionsContainer,
} from './styles';

const InboxHeader = ({ onSearch, onCompose }) => (
  <>
    <SectionTitle style={sectionTitleStyle}>
      {strings.SECTION_TITLE_INBOX}
    </SectionTitle>
    <ActionsContainer>
      <Search onSearch={onSearch} />
      <ActionButton
        onClick={onCompose}
        style={{ marginLeft: 20 }}
        title={strings.COMPOSE_BUTTON}
        iconStyle={{ top: 3 }}
        icon={plusIcon}
      />
    </ActionsContainer>
  </>
);

export default InboxHeader;
