// @flow

import React from 'react';
import Line from './line';
import Container from './container';

type Props = {
  active?: boolean,
};

const ListViewIcon = ({ active }: Props) => (
  <Container>
    <Line active={active} />
    <Line active={active} />
    <Line active={active} />
  </Container>
);

ListViewIcon.defaultProps = {
  active: true,
};

export default ListViewIcon;
