import styled from 'styled-components';

export const titleStyle = {
  marginBottom: 30,
  fontSize: 20,
};

export const SectionContainer = styled.div`
  border-radius: 3px;
  background-color: ${props => props.theme.white};
`;

export const Image = styled.img`
  object-fit: cover;
  height: 200px;
  width: 100%;
`;

export const Section = styled.section`
  margin-top: 60px;
`;

export const Content = styled.div`
  opacity: 0.9;
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 27px;
  color: ${props => props.theme.slateGrey};

  * {
    font-size: inherit;
    margin: 0;
  }
`;
