import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 23px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 #e4e6ef;
  background-color: ${props => props.theme.white};
  justify-content: space-between;
  display: flex;

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const ChipsContainer = styled.div`
  display: flex;
`;

export const Chip = styled.div`
  display: flex;
  width: 167px;
  border-radius: 3px;
  padding: 11px 15px 10px 15px;
  background-color: rgba(0, 23, 145, 0.07);
  justify-content: space-between;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ChipText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: Avenir-Medium;
  color: ${props => props.theme.primary};
`;

export const ChipCloseIcon = styled.span`
  top: 2px;
  cursor: pointer;
  position: relative;
`;

export const ClearAll = styled.span`
  cursor: pointer;
  font-family: Avenir-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.primary};
`;
