// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getLibrary = () => {
  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL || ''}/libraries`;

  return axios.get(`${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
  });
};

export default getLibrary;
