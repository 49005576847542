// @flow

import React from 'react';

import {
  MobileContent,
  MobileDateHint,
  ConversationItemContainer,
  ContentContainer,
  SenderName,
  SenderItem,
  DateHint,
  Subject,
  Content,
} from './styles';

import ProfilePic from '../profile-pic';
import strings from '../../config/strings';
import { dateToString } from '../../utils/date';

type Props = {
  data: any[],
  active: Boolean,
  shrink: Boolean,
  onItemClick: (item: any) => void,
};

const ConversationItem = ({ data, active, shrink, onItemClick }: Props) => {
  const policyId = data.extra ? data.extra.split(',')[0] : '';
  const subjectItem = policyId
    ? `${data.subject}: ${policyId}`
    : strings.INBOX_SUPPORT_NAME;

  return (
    <ConversationItemContainer
      shrink={shrink}
      active={active}
      onClick={onItemClick}
    >
      <div>
        <SenderItem shrink={shrink}>
          <ProfilePic withDot={data.read === false} />
          <SenderName>{strings.INBOX_SUPPORT_NAME}</SenderName>
        </SenderItem>
        <ContentContainer shrink={shrink}>
          <Subject>{subjectItem}</Subject>
          <span>&nbsp;-&nbsp;</span>
          <Content>{data.lastMessage}</Content>
        </ContentContainer>
        <DateHint shrink={shrink}>
          {strings.INBOX_SENT_ON}&nbsp;{dateToString(data.updated_at)}
        </DateHint>
      </div>
      <MobileContent shrink={shrink}>{data.lastMessage}</MobileContent>
      <MobileDateHint shrink={shrink}>
        {dateToString(data.updated_at)}
      </MobileDateHint>
    </ConversationItemContainer>
  );
};

export default ConversationItem;
