import styled from 'styled-components';

const Line = styled.div`
  width: 15px;
  height: 2px;
  opacity: ${props => !props.active && 0.4};
  background-color: ${props =>
    props.active ? props.theme.primary : props.theme.duskBlue};
`;

export default Line;
