// @flow

import React, { useState, useEffect } from 'react';
import FilterPopup from '../filter-popup';
import FilterSearch from './search';

import { FilterContainer } from './styles';

type Props = {
  filters: any[];
  selectedFilters: any[];

  onSearch: Function;
  onClearAll: Function;
  onApply: (filter) => void;

  checkboxValue: Boolean;
  onChangeCheckboxValue: (value: boolean) => void;
};

const FilterBar = ({
  checkboxValue, onChangeCheckboxValue, onSearch,
  filters, selectedFilters, onClearAll, onApply,
}: Props) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [toggleValue, setToggleValue] = useState(checkboxValue);

  const onFilter = () =>
    setFilterOpen(isOpen => !isOpen);

  const onClickClear = () => {
    onFilter();
    onClearAll();
  };

  const onClickApply = (filter) => {
    onFilter();
    onApply(filter);
    onChangeCheckboxValue(toggleValue);
  };

  useEffect(() => {
    setToggleValue(checkboxValue);
  }, [filterOpen]);

  return (
    <FilterContainer>
      <FilterSearch
        onFilter={onFilter}
        onSearch={onSearch}
      />

      {filterOpen && (
        <FilterPopup
          filters={filters}
          selectedFilters={selectedFilters}
          onChangeCheckboxValue={setToggleValue}
          checkboxValue={toggleValue}
          onClickClear={onClickClear}
          onClickApply={onClickApply}
        />
      )}
    </FilterContainer>
  );
};

export default FilterBar;
