// Articles
export const REQUEST_GET_ARTICLES = 'REQUEST_GET_ARTICLES';
export const SUCCESS_GET_ARTICLES = 'SUCCESS_GET_ARTICLES';
export const FAILURE_GET_ARTICLES = 'FAILURE_GET_ARTICLES';
export const SUCCESS_GET_ARTICLE_DETAILS = 'SUCCESS_GET_ARTICLE_DETAILS';

// Policies
export const SET_CURRENT_POLICY_ID = 'SET_CURRENT_POLICY_ID';
export const REQUEST_CUSTOMER_POLICIES_LIST = 'REQUEST_CUSTOMER_POLICIES_LIST';
export const SUCCESS_CUSTOMER_POLICIES_LIST = 'SUCCESS_CUSTOMER_POLICIES_LIST';
export const REQUEST_POLICIES_LIST = 'REQUEST_POLICIES_LIST';
export const SUCCESS_POLICIES_LIST = 'SUCCESS_POLICIES_LIST';
export const FAILURE_POLICIES_LIST = 'FAILURE_POLICIES_LIST';
export const REQUEST_POLICY_BENEFITS = 'REQUEST_POLICY_BENEFITS';
export const SUCCESS_POLICY_BENEFITS = 'SUCCESS_POLICY_BENEFITS';
export const REQUEST_POLICY_PAYMENT_DETAILS = 'REQUEST_POLICY_PAYMENT_DETAILS';
export const SUCCESS_POLICY_PAYMENT_DETAILS = 'SUCCESS_POLICY_PAYMENT_DETAILS';
export const REQUEST_PREMIUM_ALLOCATION = 'REQUEST_PREMIUM_ALLOCATION';
export const SUCCESS_PREMIUM_ALLOCATION = 'SUCCESS_PREMIUM_ALLOCATION';

// Performance
export const REQUEST_PERFORMANCE_DATA = 'REQUEST_PERFORMANCE_DATA';
export const SUCCESS_PERFORMANCE_DATA = 'SUCCESS_PERFORMANCE_DATA';
export const REQUEST_RISK_RETURN_PERFORMANCE =
  'REQUEST_RISK_RETURN_PERFORMANCE';
export const SUCCESS_RISK_RETURN_PERFORMANCE =
  'SUCCESS_RISK_RETURN_PERFORMANCE';

// Theme
export const REQUEST_GET_THEME = 'REQUEST_GET_THEME';
export const SUCCESS_GET_THEME = 'SUCCESS_GET_THEME';
export const FAILURE_GET_THEME = 'FAILURE_GET_THEME';
export const SUCCESS_GET_FOOTER = 'SUCCESS_GET_FOOTER';

// Encashment
export const REQUEST_ENCASHMENT = 'REQUEST_ENCASHMENT';
export const SUCCESS_ENCASHMENT = 'SUCCESS_ENCASHMENT';

// Beneficiaries
export const REQUEST_BENEFICIARIES = 'REQUEST_BENEFICIARIES';
export const SUCCESS_BENEFICIARIES = 'SUCCESS_BENEFICIARIES';

// Strategies
export const REQUEST_STRATEGY_TRANSFERS = 'REQUEST_STRATEGY_TRANSFERS';
export const SUCCESS_STRATEGY_TRANSFERS = 'SUCCESS_STRATEGY_TRANSFERS';
export const REQUEST_STRATEGY_FUND_ANALYSIS = 'REQUEST_STRATEGY_FUND_ANALYSIS';
export const SUCCESS_STRATEGY_FUND_ANALYSIS = 'SUCCESS_STRATEGY_FUND_ANALYSIS';
export const REQUEST_STRATEGY_BENCHMARKING = 'REQUEST_STRATEGY_BENCHMARKING';
export const SUCCESS_STRATEGY_BENCHMARKING = 'SUCCESS_STRATEGY_BENCHMARKING';
export const FAILURE_STRATEGY_FUNDS_PRICES = 'FAILURE_STRATEGY_FUNDS_PRICES';
export const REQUEST_STRATEGY_FUNDS_PRICES = 'REQUEST_STRATEGY_FUNDS_PRICES';
export const SUCCESS_STRATEGY_FUNDS_PRICES = 'SUCCESS_STRATEGY_FUNDS_PRICES';

export const FAILURE_STRATEGY_FUNDS_AVERAGING =
  'FAILURE_STRATEGY_FUNDS_AVERAGING';
export const REQUEST_STRATEGY_FUNDS_AVERAGING =
  'REQUEST_STRATEGY_FUNDS_AVERAGING';
export const SUCCESS_STRATEGY_FUNDS_AVERAGING =
  'SUCCESS_STRATEGY_FUNDS_AVERAGING';
export const FAILURE_STRATEGY_ACCOUNT_VALUE = 'FAILURE_STRATEGY_ACCOUNT_VALUE';
export const REQUEST_STRATEGY_ACCOUNT_VALUE = 'REQUEST_STRATEGY_ACCOUNT_VALUE';
export const SUCCESS_STRATEGY_ACCOUNT_VALUE = 'SUCCESS_STRATEGY_ACCOUNT_VALUE';

// Service Forms
export const REQUEST_GET_SERVICE_FORMS = 'REQUEST_GET_SERVICE_FORMS';
export const SUCCESS_GET_SERVICE_FORMS = 'SUCCESS_GET_SERVICE_FORMS';
export const FAILURE_GET_SERVICE_FORMS = 'FAILURE_GET_SERVICE_FORMS';

// Privacy Policy
export const REQUEST_GET_PRIVACY_POLICY = 'REQUEST_GET_PRIVACY_POLICY';
export const SUCCESS_GET_PRIVACY_POLICY = 'SUCCESS_GET_PRIVACY_POLICY';
export const FAILURE_GET_PRIVACY_POLICY = 'FAILURE_GET_PRIVACY_POLICY';

// Documents
export const REQUEST_GET_DOCUMENTS_CONTRACTS =
  'REQUEST_GET_DOCUMENTS_CONTRACTS';
export const SUCCESS_GET_DOCUMENTS_CONTRACTS =
  'SUCCESS_GET_DOCUMENTS_CONTRACTS';
export const REQUEST_GET_DOCUMENTS_NOTICES = 'REQUEST_GET_DOCUMENTS_NOTICES';
export const SUCCESS_GET_DOCUMENTS_NOTICES = 'SUCCESS_GET_DOCUMENTS_NOTICES';

// Contribution
export const REQUEST_GET_CONTRIBUTION_CHART = 'REQUEST_GET_CONTRIBUTION_CHART';
export const SUCCESS_GET_CONTRIBUTION_CHART = 'SUCCESS_GET_CONTRIBUTION_CHART';
export const REQUEST_GET_CONTRIBUTION_INFO = 'REQUEST_GET_CONTRIBUTION_INFO';
export const SUCCESS_GET_CONTRIBUTION_INFO = 'SUCCESS_GET_CONTRIBUTION_INFO';

// Notifications
export const REQUEST_GET_NOTIFICATIONS = 'REQUEST_GET_NOTIFICATIONS';
export const SUCCESS_GET_NOTIFICATIONS = 'SUCCESS_GET_NOTIFICATIONS';
export const FAILURE_GET_NOTIFICATIONS = 'FAILURE_GET_NOTIFICATIONS';

// User Info
export const REQUEST_GET_USER = 'REQUEST_GET_USER';
export const SUCCESS_GET_USER = 'SUCCESS_GET_USER';
export const FAILURE_GET_USER = 'FAILURE_GET_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';

// FAQS
export const REQUEST_GET_FAQS = 'REQUEST_GET_FAQS';
export const SUCCESS_GET_FAQS = 'SUCCESS_GET_FAQS';
export const FAILURE_GET_FAQS = 'FAILURE_GET_FAQS';

// ACCOUNT
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const REQUEST_REQUEST_PASSWORD_RESET = 'REQUEST_REQUEST_PASSWORD_RESET';
