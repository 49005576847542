import {
  REQUEST_LOGIN,
  SUCCESS_LOGIN,
  REQUEST_RESET_PASSWORD,
  REQUEST_REQUEST_PASSWORD_RESET,
} from '../constants/types';

const defaultData = {
  requested: false,
  loading: false,
  data: [],
};

const loadingData = {
  requested: false,
  loading: true,
  data: [],
};

const initialState = {
  account: { ...defaultData },
};

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN:
      return { ...state, account: { ...loadingData } };

    case SUCCESS_LOGIN:
      return {
        ...state,
        account: {
          loading: false,
          requested: true,
          data: action.payload.account,
        },
      };

    case REQUEST_RESET_PASSWORD:
      return { ...state, account: { ...loadingData } };

    case REQUEST_REQUEST_PASSWORD_RESET:
      return { ...state, account: { ...loadingData } };

    default:
      return { ...state };
  }
};

export default AccountReducer;
