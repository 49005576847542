import styled from 'styled-components';
import { size } from '../../config/device';

export const Container = styled.div`
  width: 60vw;
  min-width: 400px;

  @media (max-width: ${size.tablet}) {
    min-width: auto;
    width: 100%;
  }
`;

export const Content = styled.p`
  color: ${props => props.theme.primaryDark};
  font-family: Avenir-Medium;
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 50px;
`;

export const Button = styled.div`
  cursor: pointer;
  font-size: 14px;
  line-height: 2.7;
  border-radius: 3px;
  text-align: center;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  transition: background-color .1s;
  font-family: Avenir-Medium;
  padding: 8px 55px;
  display: inline;

  &:hover {
    background-color: #d6e2f8;
    color: ${props => props.theme.primary};
  }
`;
