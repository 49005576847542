// @flow

import React, { useState, useRef, Fragment, useEffect } from 'react';

import Arrow from '../../arrow';
import strings from '../../../config/strings';
import { searchIcon } from '../../../assets/images';
import useOutsideClick from '../../../hooks/useOutsideClick';

import {
  DropdownContainer,
  DropdownList,
  DropdownItem,
  DropdownBox,
} from './container';

import { SearchBox, SearchIcon, SearchBoxContainer } from './search';

type Props = {
  title: string,
  selected: any,
  options: any[],
  style: any,
  onSelect: (id: string) => void,
  searchPlaceholder: string,
  size: 'normal' | 'small' | 'large',
  compact: boolean,
};

const Dropdown = ({
  title = '',
  options,
  selected,
  onSelect,
  searchPlaceholder,
  compact = false,
  style,
  size = 'normal',
}: Props) => {
  const ref = useRef(null);
  const [items, setItems] = useState(options);
  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState('');

  // close dropdown if clicked outside
  useOutsideClick(ref, () => setActive(false));

  useEffect(() => {
    setItems([
      ...options.slice(0, 2),
      { id: -100 }, // search id
      ...options.slice(2),
    ]);
  }, [options]);

  function isSearchMatch(item, isSelected) {
    if (!searchText || item.id < 0 || isSelected) {
      return true;
    }

    return item.name.toLowerCase().includes(searchText.toLowerCase());
  }

  function toggleDropdown() {
    setActive(prevActiveState => !prevActiveState);
  }

  function onClickItem(option) {
    onSelect(option.id);
    setSearchText('');
    toggleDropdown();
  }

  function renderSearchBox() {
    return (
      !!searchPlaceholder && (
        <SearchBoxContainer>
          <SearchBox
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder={searchPlaceholder}
          />
          <SearchIcon
            src={searchIcon}
            alt={strings.PLACEHOLDER_SEARCH_POLICY}
          />
        </SearchBoxContainer>
      )
    );
  }

  function renderDropdownItem(item) {
    let selectedOption = selected && item.id === selected.id;

    if (typeof selected === 'string') {
      selectedOption = selected && item.name === selected;
    }

    return (
      <DropdownItem
        size={size}
        active={selectedOption}
        onClick={() => onClickItem(item)}
        visible={isSearchMatch(item, selectedOption)}
        compact={compact}
      >
        {!compact || item.id < 0 ? (
          item.name
        ) : (
          <>
            Policy: {item.name.split(' #')[0]}
            <br />
            <span>#{item.name.split(' #')[1]}</span>
          </>
        )}
      </DropdownItem>
    );
  }

  function renderOptions() {
    if (!items) {
      return null;
    }

    return items.map(item => (
      <Fragment key={item.id}>
        {item.id === -100 ? renderSearchBox() : renderDropdownItem(item)}
      </Fragment>
    ));
  }

  return (
    <DropdownContainer ref={ref} size={size}>
      <DropdownBox size={size} style={style} onClick={toggleDropdown}>
        <span>{title || selected.name}</span>
        <Arrow
          color="#001791"
          style={{ transform: 'rotate(90deg)', marginRight: 8 }}
        />
      </DropdownBox>
      <DropdownList size={size} active={active}>
        {renderOptions()}
      </DropdownList>
    </DropdownContainer>
  );
};

export default Dropdown;
