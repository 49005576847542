import styled from 'styled-components';

const TextArea = styled.textarea`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 189px;
  border-radius: 3px;
  background-color: ${props => props.theme.slateGrey10};
  box-sizing: border-box;
  padding: 16px;
  outline: none;
  border: none;
  margin-top: 20px;
  resize: none;
  font-family: Avenir-Book;
  font-size: 16px;
  color: ${props => props.theme.battleshipGrey};

  ::placeholder {
    font-family: Avenir-Book;
    font-size: 16px;
    color: ${props => props.theme.battleshipGrey};
  }
`;

export default TextArea;
