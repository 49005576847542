// @flow

import React from 'react';
import Table from '../shared/table';
import strings from '../../config/strings';
import globals from '../../config/globals';

type Props = {
  data: any[],
  currency: string,
  currentPage: Number,
  onLoadMore: Function,
};

const ContributionPayments = ({
  currentPage,
  onLoadMore,
  data = [],
  currency,
}: Props) =>
  !!data.length && (
    <>
      <Table
        onLoadMore={
          currentPage * globals.CONTRIBUTION_PAGING > data.length
            ? undefined
            : onLoadMore
        }
        headerData={strings.CONTRIBUTION_TABLE_HEADERS}
        contentData={data}
        currency={currency}
      />
    </>
  );

export default ContributionPayments;
