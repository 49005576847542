import styled from 'styled-components';
import { size } from '../../../config/device';

export const CollapsibleIcon = styled.img`
  @media (min-width: ${size.mobileL}) {
    display: none;
  }
  @media (max-width: ${size.mobileL}) {
    width: 24px;
    height: 24px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;

export const CollapsibleDiv = styled.div`
  @media (max-width: ${size.mobileL}) {
    display: ${props => (props.isClicked ? 'block' : 'none')};
  }
`;

export const dateCard = {
  marginRight: 47,
};

export const CalendarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${size.mobileL}) {
    display: none;
  }
`;

export const MobileCalendarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${size.mobileL}) {
    display: ${props => (props.isClicked ? 'block' : 'none')};
  }
`;

export const tableCustomStyle = {
  colWidth: [{ col: 1, value: '38%' }],
};

export const HeaderWrapper = styled(RowContainer)`
  margin-bottom: 25px;
`;

export const ToolTipContainer = styled.div`
  margin-left: 16px;
`;
