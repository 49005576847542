import React from 'react';
import { Line1, Line2 } from './lines';

const ArrowLeft = ({ style, color }) => (
  <div style={style}>
    <Line1 color={color} />
    <Line2 color={color} />
  </div>
);

export default ArrowLeft;
