// @flow

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import CustomError from '../utils/error';

const { REACT_APP_WB_BACKEND_URL, REACT_APP_ACCEPT_HEADER_WB } = process.env;

const getUserPolicies = async (id: string, username: string) => {
  const { WB_API_CACHE = {} } = window;

  if (isEmpty(REACT_APP_WB_BACKEND_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_WB_BACKEND_URL || ''}/policies`;

  const cachedData = WB_API_CACHE[`_user_policies_${id}`];

  if (id && cachedData && typeof cachedData === 'object') {
    return { data: cachedData };
  }

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    },
    params: {
      userId: id,
      username,
    },
  });

  if (id) {
    window.WB_API_CACHE[`_user_policies_${id}`] = response.data;
  }

  return response;
};

export default getUserPolicies;
