// @flow

import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import 'chartjs-plugin-lineheight-annotation';

import {
  tooltipStyle,
  chartGridLinesStyle,
  yAxisTickStyle,
  xAxisTickStyle,
  barStyle,
  lineHeightAnnotationStyle,
} from './styles';
import EmptyData from './empty-data';
import { formatDate } from '../../utils/date';
import { decimalWithCommas, numberWithCommas } from '../../utils/numbers';

type Props = {
  barData: Array<string | number>,
  barDataName: string,
  errorMessage: string,
  labels: Array<string>,
  lineData: Array<string | number>,
  lineDataName: string,
  symbol: string,
};

const LineChart = ({
  barData,
  barDataName,
  errorMessage,
  labels,
  lineData,
  lineDataName,
  symbol,
}: Props) => (
  <Fragment>
    <Line
      data={{
        labels,
        datasets: [
          {
            label: lineDataName,
            data: lineData,
            type: 'line',
          },
          {
            label: barDataName,
            data: barData,
            type: 'bar',
            ...barStyle,
          },
        ],
      }}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        cornerRadius: 12,
        legend: { display: false },
        tooltips: {
          ...tooltipStyle,
          mode: 'x-axis',
          callbacks: {
            title: tooltipItem => formatDate(tooltipItem[0].xLabel),
            label: (tooltipItem, dataArr) => {
              const datasetLabel =
                dataArr.datasets[tooltipItem.datasetIndex].label;
              const tooltipLabel = `${datasetLabel}: ${symbol} ${decimalWithCommas(
                tooltipItem.yLabel,
              )}`;

              return tooltipLabel;
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        lineHeightAnnotation: {
          always: false,
          hover: true,
          ...lineHeightAnnotationStyle,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: { ...xAxisTickStyle, maxRotation: 20 },
              offset: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                ...chartGridLinesStyle,
              },
              ticks: {
                ...yAxisTickStyle,
                callback: value => numberWithCommas(value),
              },
              offset: false,
            },
          ],
        },
        elements: {
          line: {
            fill: false,
            borderColor: 'rgb(243, 133, 146)',
            borderWidth: 2.5,
          },
          point: {
            pointStyle: 'circle',
            backgroundColor: 'rgb(243, 133, 146)',
            borderWidth: 0,
            hoverRadius: 3,
            radius: 1.5,
          },
        },
      }}
    />

    {isEmpty(lineData) && isEmpty(barData) && (
      <EmptyData>{errorMessage}</EmptyData>
    )}
  </Fragment>
);

export default LineChart;
