// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import globals from '../../config/globals';
import { BackToSearchLink } from '../../components/shared/texts/navigation-links';
import { arrowLeftGrey } from '../../assets/images';
import { LinkContainer, ArrowDiv } from './styles';
import { PoliciesList } from '../../components';
import Tabs from '../../components/tabs';
import strings from '../../config/strings';

type Props = {
  handlePolicyViewMoreClick: Function,
  userPolicies: Array<Policy>,
  customerName: String,
};

const CustomerInformation = ({
  handlePolicyViewMoreClick,
  userPolicies,
  customerName,
}: Props) => {
  const [currentTab, setCurrentTab] = useState();

  const history = useHistory();

  const renderCurrentTab = () => {
    switch (currentTab) {
      case globals.PRODUCER_TABS.POLICIES.key:
        return (
          <PoliciesList
            handlePolicyViewMoreClick={handlePolicyViewMoreClick}
            userPolicies={userPolicies}
            producer
          />
        );

      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <LinkContainer onClick={() => history.goBack()}>
        <ArrowDiv>
          <img src={arrowLeftGrey} alt="arrow left" />
        </ArrowDiv>
        <BackToSearchLink>{strings.PRODUCER_BACK_TO_SEARCH}</BackToSearchLink>
      </LinkContainer>
      <Tabs
        tabType="producer"
        onChangeTab={setCurrentTab}
        customerName={customerName}
      />
      {renderCurrentTab()}
    </React.Fragment>
  );
};

export default CustomerInformation;
