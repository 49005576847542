import styled from 'styled-components';

const IconDiv = styled.div`
  height: 100%;
  display: flex;
  width: 40px;
  cursor: pointer;
`;

export default IconDiv;
