// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import strings from '../../config/strings';
import { LOGIN } from '../../router/routes';
import type FormUtils from '../../models/form';
import { FormSubmitButton, InputWrapper, DismissButton } from './styles';
import { TextboxValidation, OnboardingFormWrapper } from '../../components';

type Props = {
  formUtils: FormUtils,
  handleFormSubmit: Function,
  logoUrl: string,
  themeColors: Object,
};

const ResetPasswordScreen = ({
  logoUrl,
  handleFormSubmit,
  formUtils,
  themeColors,
}: Props) => (
  <OnboardingFormWrapper
    formUtils={formUtils}
    handleFormSubmit={handleFormSubmit}
    logoSrc={logoUrl}
    subtitle={strings.ONBOARDING_FORGET_PASSWORD_SUBTITLE}
    title={strings.ONBOARDING_FORGET_PASSWORD_TITLE}
    themeColors={themeColors}
  >
    <InputWrapper>
      <TextboxValidation
        errorMessage={formUtils.errors.email && formUtils.errors.email.message}
        formUtils={formUtils}
        label={strings.FORM_INPUT_LABEL_EMAIL}
        name="email"
        placeholder={strings.FORM_INPUT_PLACEHOLDER_EMAIL}
        type="text"
      />
    </InputWrapper>

    <FormSubmitButton
      value={strings.ONBOARDING_FORGET_PASSWORD_FORM_SUBMIT_BUTTON}
    />

    <Link to={{ pathname: LOGIN }}>
      <DismissButton>
        {strings.ONBOARDING_FORGET_PASSWORD_FORM_CANCEL_BUTTON}
      </DismissButton>
    </Link>
  </OnboardingFormWrapper>
);
export default ResetPasswordScreen;
