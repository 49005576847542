// @flow

import axios from 'axios';
import { isEmpty } from 'lodash';
import CustomError from '../utils/error';

const { REACT_APP_CMS_URL } = process.env;

const login = (formdata: { username: string, password: string }) => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');

  const data = { identifier: formdata.username, password: formdata.password };

  const url = `${REACT_APP_CMS_URL || ''}/auth/local`;

  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const resetPasswordAPI = (formdata: {
  username: string,
  otp: string,
  password: string,
}) => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');
  const url = `${REACT_APP_CMS_URL || ''}/authentication/reset-password`;
  const data = {
    username: formdata.username,
    otp: formdata.otp,
    password: formdata.password,
  };

  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const changePasswordAPI = (formdata: {
  username: string,
  oldPassword: string,
  newPassword: string,
}) => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_CMS_URL || ''}/authentication/change-password`;
  const data = {
    username: formdata.username,
    oldPassword: formdata.oldPassword,
    newPassword: formdata.newPassword,
  };
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const requestPasswordReset = (email: string) => {
  if (isEmpty(REACT_APP_CMS_URL)) throw new CustomError('Empty URL');

  const url = `${REACT_APP_CMS_URL || ''}/authentication/forgot-password`;
  const data = { email };

  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export default login;
