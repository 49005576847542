// @flow

import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  TabItem,
  TabsContainer,
  TabsIndicator,
  TabsScrollable,
} from '../../tabs/container';

import type TabObj from '../../../models/tabs';

type Props = {
  activeTabKey: string,
  containerStyle: Object,
  handleTabSwitch: Function,
  tabsArr: Array<TabObj>,
  tabsWrapperStyle: Object,
};

const Outline = ({
  activeTabKey,
  containerStyle,
  handleTabSwitch,
  tabsArr,
  tabsWrapperStyle,
}: Props) => (
  <TabsContainer style={containerStyle}>
    <TabsScrollable style={tabsWrapperStyle}>
      {tabsArr.map(
        tabItem =>
          !tabItem.isHidden && (
            <TabItem
              key={tabItem.key}
              active={activeTabKey === tabItem.key}
              onClick={() => handleTabSwitch(tabItem.key)}
            >
              {!isEmpty(tabItem.icon) && (
                <img src={tabItem.icon} alt={tabItem.key} height="19" />
              )}
              <span>{tabItem.label}</span>

              <TabsIndicator active={activeTabKey === tabItem.key} />
            </TabItem>
          ),
      )}
    </TabsScrollable>
  </TabsContainer>
);

export default Outline;
