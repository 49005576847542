// @flow

import React from 'react';
import Table from '../shared/table';
import strings from '../../config/strings';

import { SectionTitle2 } from '../shared/texts/title';
import { strategyListTitle, Container } from './container';
import DocumentCard from '../document-card';

type Props = {
  data: any[],
};

const StrategyTransferSwitching = ({ data = [] }: Props) => (
  <Container>
    <SectionTitle2 style={strategyListTitle}>
      {strings.SECTION_TITLE_STRATEGY_TRANSFER_SWITCHING}
    </SectionTitle2>

    <Table
      headerData={strings.STRATEGY_TRANSFER_SWITCHING_TABLE_HEADER}
      contentData={data}
      nomargin
    />

    {!data.length && (
      <DocumentCard
        empty
        noIcon
        centerClause
        emptyClause={strings.NO_DATA}
      />
    )}
  </Container>
);

export default StrategyTransferSwitching;
