// @flow

import React, { useEffect, useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';

import {
  useFundsAveraging,
  usePolicyBasicInfo,
  useRiskReturnPerformance,
  useStrategyAnalysis,
  useStrategyBenchmarking,
  useStrategyTransfers,
} from '../../hooks';

import strings from '../../config/strings';
import globals from '../../config/globals';
import Spinner from '../../components/shared/spinner';
import { useFundsPrices } from '../../hooks/strategy';
import StrategyScreen from '../../pages/policy-details/strategy';

const defaultFundsOptions = [
  { id: -1, name: strings.STRATEGY_AVERAGING_DROPDOWN_TITLE },
];

type Props = {
  policyId: number,
  customerId: number,
};

const Strategy = ({ policyId, customerId }: Props) => {
  const today = moment();
  const [pricesFundId, setPricesFundId] = useState(0);
  const [pricesPeriod, setPricesPeriod] = useState(4);
  const [pricesFundsOptions, setPricesFundsOptions] = useState(
    defaultFundsOptions,
  );

  const [dateAsAt, setDateAsAt] = useState(today);
  const [redirectsPage, setRedirectsPage] = useState(1);
  const [policyCurrency, setPolicyCurrency] = useState();
  const [tempDateAsAt, setTempDateAsAt] = useState(null);
  const [shouldLoadMore, setLoadMoreFlag] = useState(false);
  const [isCalendarOpen, setCalendarOpenState] = useState(false);
  const [paginatedRedirects, setPaginatedRedirects] = useState([]);
  const [shouldUpdateDateApi, setUpdateDateApiState] = useState(true);

  const { data: analysis } = useStrategyAnalysis(
    policyId,
    dateAsAt,
    shouldUpdateDateApi,
  );

  const { data: benchmarking } = useStrategyBenchmarking(
    policyId,
    dateAsAt,
    shouldUpdateDateApi,
  );

  const { data: basicPolicyInfo } = usePolicyBasicInfo(policyId, customerId);

  const riskReturnPerformance = useRiskReturnPerformance(
    policyId,
    dateAsAt,
    shouldUpdateDateApi,
  );

  const { data: averaging, loading: loadingAveraging } = useFundsAveraging(
    policyId,
  );

  const { data: prices, loading: loadingDailyFunds } = useFundsPrices(
    policyId,
    pricesPeriod,
    pricesFundId,
  );

  const { data: transfers, loading: loadingTransfers } = useStrategyTransfers(
    policyId,
  );

  const { chartInfo = {}, funds = [] } =
    (riskReturnPerformance && riskReturnPerformance.data) || {};

  const loading = loadingAveraging || loadingTransfers;

  const shouldLoadMoreRedirectData = (page: number) =>
    globals.FUNDS_REDIRECT_PAGING * page < transfers.fundRedirect.length;

  useEffect(() => {
    if (!isEmpty(analysis) && isNull(tempDateAsAt)) {
      setUpdateDateApiState(false);
      setDateAsAt(analysis.chartInfo.lastDate);
      setTempDateAsAt(analysis.chartInfo.lastDate);
    }
  }, [analysis]);

  useEffect(() => {
    setPolicyCurrency(basicPolicyInfo.currency);
  }, [policyId, basicPolicyInfo]);

  useEffect(() => {
    const { funds: avgFunds = {} } = averaging;
    const options = [...pricesFundsOptions];
    Object.keys(avgFunds).forEach(fundId => {
      options.push({
        id: fundId,
        name: avgFunds[fundId].info.fundDescription,
      });
    });

    setPricesFundId(Object.keys(avgFunds)[0] || -1);
    setPricesFundsOptions(options);
  }, [averaging]);

  useEffect(() => {
    if (redirectsPage === 1 && !isEmpty(transfers.fundRedirect)) {
      setLoadMoreFlag(shouldLoadMoreRedirectData(redirectsPage));
    }

    if (!isEmpty(transfers.fundRedirect)) {
      const allRedirects = new Array(...transfers.fundRedirect);
      const currentRedirects = allRedirects.splice(
        0,
        globals.FUNDS_REDIRECT_PAGING * redirectsPage,
      );

      setPaginatedRedirects(currentRedirects);
    }
  }, [transfers.fundRedirect, redirectsPage]);

  const handleDateChange = (date: Moment) => {
    setDateAsAt(date);
    setCalendarOpenState(false);
    setUpdateDateApiState(true);
  };

  const handleRedirectsLoadMoreClick = () => {
    const page = redirectsPage + 1;

    setRedirectsPage(page);
    setLoadMoreFlag(shouldLoadMoreRedirectData(page));
  };

  function renderContent() {
    return (
      <StrategyScreen
        {...{
          prices,
          analysis,
          averaging,
          benchmarking,
          chartInfo,
          dateAsAt,
          funds,
          handleDateChange,
          handleRedirectsLoadMoreClick,
          isCalendarOpen,
          policyCurrency,
          setCalendarOpenState,
          loadingDailyFunds,
          pricesFundsOptions,
          setPricesFundId,
          setPricesPeriod,
          pricesPeriod,
          pricesFundId,
          redirectTransfers: paginatedRedirects,
          shouldLoadMore,
          switchingTransfers: transfers.fundSwitching,
        }}
      />
    );
  }

  return loading ? <Spinner /> : renderContent();
};

export default Strategy;
