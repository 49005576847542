// @flow

import React, { useState } from 'react';
import { isEmpty, isNull, isUndefined } from 'lodash';

import strings from '../../../config/strings';
import { MainContent3 } from '../../shared/texts/content';
import { isInvestmentTypePolicy } from '../../../utils/policy';
import { SectionTitle2, Subtitle5 } from '../../shared/texts/title';
import { CollapsibleIcon, RowContainer, CollapsibleDiv } from './styles';
import { formatCurrency, decimalWithCommas } from '../../../utils/numbers';
import { collabsiblePlus, collabsibleMinus } from '../../../assets/images';

import {
  policyDetailsListTitle,
  SectionContainer,
  ListRowContainer,
  ListRow,
} from './container';
import isTruthy from '../../../utils/strings';

type Props = {
  data: { [key: string]: any },
};

const PolicyPaymentDetails = ({ data }: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  const paymentDetails = [
    { key: strings.POLICY_INFO_PAYMENT_FREQ, value: data.paymentFrequency },
    { key: strings.POLICY_INFO_PAYMENT_METHOD, value: data.paymentMethod },
  ];

  if (
    data &&
    (data.frequency || '').toLowerCase() !== 'single' &&
    isInvestmentTypePolicy(data.policyType)
  ) {
    paymentDetails.push({
      key: strings.POLICY_INFO_PAYMENT_DURATION,
      value: data.paymentPeriod,
    });
  }

  if (
    data &&
    isInvestmentTypePolicy(data.policyType) &&
    (data.frequency || '').toLowerCase() !== 'single'
  ) {
    paymentDetails.push(
      {
        key: strings.POLICY_INFO_PAYMENT_INDEXATION,
        value: `${decimalWithCommas(data.paymentIndexation)} %`,
      },
      {
        key: strings.POLICY_INFO_PAYMENT_INDEXATION_DURATION,
        value:
          isNull(data.indexationDuration) ||
          isUndefined(data.indexationDuration)
            ? strings.NULL_STRING_REPLACEMENT
            : data.indexationDuration,
      },
    );
  }

  if ((data.frequency || '').toLowerCase() !== 'single' && data.premium) {
    const extraRowInfo = {
      key: '',
      value: `${formatCurrency(
        data.currency,
        data.premium < 0,
      )} ${decimalWithCommas(Math.abs(data.premium))}`,
    };

    switch (data.policyType) {
      case 'D':
        extraRowInfo.key = strings.POLICY_INFO_PREMIUM_WITH_TAX;
        break;

      case 'L':
      case 'E':
        extraRowInfo.key = strings.POLICY_INFO_PREMIUM_PLANNED;
        break;

      case 'T': {
        extraRowInfo.key = isTruthy(data.hasExtraPremium)
          ? strings.POLICY_INFO_PREMIUM_FEES_SPECIAL
          : strings.POLICY_INFO_PREMIUM_FEES;
        break;
      }

      case 'P': {
        const subType = data.policyNumber.substring(0, 3);

        if (subType === 'LAA' && isTruthy(data.hasExtraPremium)) {
          extraRowInfo.key = strings.POLICY_INFO_PREMIUM_FEES_SPECIAL;
        } else if (subType === 'LAA' && !isTruthy(data.hasExtraPremium)) {
          extraRowInfo.key = strings.POLICY_INFO_PREMIUM_FEES;
        } else {
          extraRowInfo.key = strings.POLICY_INFO_PREMIUM_WITH_TAX;
        }

        break;
      }

      default:
        extraRowInfo.key = strings.POLICY_INFO_PREMIUM;
        break;
    }

    paymentDetails.unshift(extraRowInfo);
  } else if (
    (data.frequency || '').toLowerCase() === 'single' &&
    data.premium &&
    !isInvestmentTypePolicy(data.policyType)
  ) {
    const extraRowInfo = {
      key: strings.POLICY_INFO_PREMIUM_SINGLE,
      value: `${formatCurrency(
        data.currency,
        data.premium < 0,
      )}${decimalWithCommas(Math.abs(data.premium))}`,
    };

    paymentDetails.unshift(extraRowInfo);
  }

  return (
    !isEmpty(data) && (
      <SectionContainer>
        <RowContainer>
          <SectionTitle2 style={policyDetailsListTitle}>
            {strings.SECTION_TITLE_POLICY_PAYMENT_DETAILS}
          </SectionTitle2>
          <CollapsibleIcon
            onClick={() => setIsClicked(!isClicked)}
            src={isClicked ? collabsibleMinus : collabsiblePlus}
            alt="collapsible"
          />
        </RowContainer>
        <CollapsibleDiv isClicked={isClicked}>
          <ListRowContainer>
            {paymentDetails.map(el => (
              <ListRow key={el.key}>
                <Subtitle5>{el.key}</Subtitle5>
                <MainContent3>{el.value}</MainContent3>
              </ListRow>
            ))}
          </ListRowContainer>
        </CollapsibleDiv>
      </SectionContainer>
    )
  );
};

export default PolicyPaymentDetails;
