// @flow

import React from 'react';
import { ThemeProvider } from 'styled-components';

import type { Node } from 'react';

import Logo from './logo';
import { SuccessStamp } from '..';
import Description from './styles';
import MainFooter from '../main-footer';
import type FormUtils from '../../models/form';

import MainContainer, {
  Form,
  Title,
  FlexContainer,
  StampContainer,
  OnBoardingContainer,
} from './container';

import { eventLogo, onBoarding3x } from '../../assets/images';

type Props = {
  children: Node,
  formUtils: FormUtils,
  handleFormSubmit: Function,
  isSuccess?: boolean,
  logoSrc: string,
  subtitle?: string,
  themeColors: Object,
  showFooter: boolean,
  noOnBoarding: boolean,
  title: string,
};

const Wrapper = ({
  children,
  formUtils,
  handleFormSubmit,
  isSuccess,
  logoSrc,
  subtitle,
  title,
  themeColors,
  showFooter,
  noOnBoarding,
}: Props) => {
  const renderMainContainer = () => (
    <MainContainer>
      <Logo src={logoSrc} alt="logo" />

      {isSuccess && (
        <StampContainer>
          <SuccessStamp />
        </StampContainer>
      )}

      <Title>{title}</Title>

      {subtitle && <Description>{subtitle}</Description>}

      <Form onSubmit={formUtils.handleSubmit(handleFormSubmit)}>
        {children}
      </Form>
      {showFooter !== false && <MainFooter />}
    </MainContainer>
  );

  const renderOnBoardingContent = () => (
    <FlexContainer>
      <OnBoardingContainer image={onBoarding3x} topLayerImage={eventLogo} />
      {renderMainContainer()}
    </FlexContainer>
  );

  return (
    <ThemeProvider theme={themeColors}>
      {noOnBoarding ? renderMainContainer() : renderOnBoardingContent()}
    </ThemeProvider>
  );
};

Wrapper.defaultProps = { isSuccess: null, subtitle: '' };

export default Wrapper;
