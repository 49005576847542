// @flow

import styled, { keyframes, css } from 'styled-components';
import { ColumnContainer } from '../shared/container';

export const NavigationDropDownContainer = styled(ColumnContainer)`
  width: 60px;
  height: auto;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${props => props.theme.primary};
  margin-top: 42px;
  max-height: 387px;
`;

export const NavigationTab = styled(ColumnContainer)`
  height: 109px;
  width: 100%;
  background-color: ${({ theme, showBackground }) =>
    showBackground ? `${theme.lightPeriWinkle15}` : ` ${theme.primary}`};
  :hover {
    background-color: ${props => props.theme.frenchBlue};
  }
`;

export const Selector = styled.div`
  height: 109px;
  width: 4px;
  border-radius: 5px;
  background-color: ${props => props.theme.white};
  position: absolute;
  left: -1.5px;
`;

export const LogoContainer = styled(ColumnContainer)`
  width: 17px;
  height: 16px;
`;

const slideDownKeyFrame = keyframes`
0%{max-height:0; opacity:0;visibility:collapse;}
50%{max-height:200px;opacity:1;visibility:visible;}
100%{max-height:387px; }
`;

const slideUpKeyFrame = keyframes`
0%{max-height:387px; opacity: 1;visibility:visible;}
50%{max-height:200px; opacity: 1;visibility:visible;}
100%{max-height:0;opacity:0;visibility:collapse;}
`;

const slideDownAnimation = css`
  animation: 0.25s linear ${slideDownKeyFrame} forwards;
`;

const slideUpAnimation = css`
  animation: 0.25s linear ${slideUpKeyFrame} forwards;
`;

export const NavigationSliderContainer = styled(ColumnContainer)`
  position: absolute;
  opacity: ${({ animationState }) => (animationState ? '1' : '0')};
  transition: opacity 0.3s linear forwards;
  background-color: ${props => props.theme.primary};
  width: 60px;
  box-shadow: 0 3px 12px 0 ${props => props.theme.lightBlueGrey80};
  border-radius: 3px;
  height: auto;
  max-height: 387px;
  overflow: hidden;
  visibility: ${({ animationState }) => !animationState && 'collapse'};

  ${({ animationState }) =>
    animationState === undefined
      ? ''
      : animationState
      ? slideDownAnimation
      : slideUpAnimation}
`;
