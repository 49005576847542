// @flow

import React from 'react';

import { Subtitle4 } from '../../shared/texts/title';
import { SectionDescriptionBlock } from '../container';
import { DescriptionText } from '../../shared/texts/content';

import type { ChartDescription as ChartDescriptionType } from '../../../models/funds';

type Props = {
  chartInfo: ChartDescriptionType,
};

const ChartDescription = ({ chartInfo }: Props) => (
  <SectionDescriptionBlock>
    <Subtitle4>{chartInfo.title}</Subtitle4>
    <DescriptionText>{chartInfo.description}</DescriptionText>
  </SectionDescriptionBlock>
);

export default ChartDescription;
