// @flow

import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import 'chartjs-plugin-lineheight-annotation';

import {
  basicTooltip,
  chartGridLinesStyle,
  yAxisTickStyle,
  xAxisTickStyle,
} from './styles';

import EmptyData from './empty-data';
import colors from '../../config/colors';
import { formatDate } from '../../utils/date';
import { decimalWithCommas, numberWithCommas } from '../../utils/numbers';

type Props = {
  chartPointsStyle: Array<string>,
  data: Array<string | number>,
  dataName: string,
  errorMessage: string,
  labels: Array<string>,
  symbol: string,
};

const AreaChart = ({
  chartPointsStyle,
  data,
  errorMessage,
  labels,
  dataName,
  symbol,
}: Props) => (
  <Fragment>
    <Line
      data={{
        labels,
        datasets: [
          {
            label: dataName,
            data,
          },
        ],
      }}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        legend: { display: false },
        tooltips: {
          ...basicTooltip,
          mode: 'x-axis',
          callbacks: {
            title: tooltipItem => formatDate(tooltipItem[0].xLabel),
            label: (tooltipItem, dataArr) => {
              const datasetLabel =
                dataArr.datasets[tooltipItem.datasetIndex].label;
              const tooltipLabel = `${datasetLabel}: ${symbol} ${decimalWithCommas(
                tooltipItem.yLabel,
              )}`;

              return tooltipLabel;
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        lineHeightAnnotation: {
          always: false,
          hover: true,
          color: colors.paleGreyDark,
          lineWeight: 1,
          shadow: {
            color: colors.paleGreyDark,
            blur: 6,
            offset: {
              x: 0,
              y: 0,
            },
          },
          noDash: true,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: { ...xAxisTickStyle, maxRotation: 20 },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                ...chartGridLinesStyle,
              },
              ticks: {
                ...yAxisTickStyle,
                suggestedMin: 0,
                callback: value => numberWithCommas(value),
              },
            },
          ],
        },
        elements: {
          line: { fill: true, borderColor: colors.primary, borderWidth: 2.5 },
          point: {
            pointStyle: 'circle',
            backgroundColor: chartPointsStyle,
            borderWidth: 0,
            hoverRadius: 3,
            radius: 1.5,
          },
        },
      }}
    />

      {isEmpty(data) && (
        <EmptyData size="large" style={{ top: '35%', left: '45%' }}>
          {errorMessage}
        </EmptyData>
      )}
  </Fragment>
);

export default AreaChart;
