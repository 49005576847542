// @flow

import React, { useState } from 'react';

import { paperPlane } from '../../assets/images';
import { InputText, BottomContainer } from './chat-styles';

import ProfilePic from '../profile-pic';
import ActionButton from '../shared/action-button';

import strings from '../../config/strings';

type Props = {
  loading: Boolean,
  containerStyle: Object,
  onSendMessage: Function,
};

const ConversationInput = ({ loading, onSendMessage, containerStyle }: Props) => {
  const [text, setText] = useState('');

  const onClickSend = () => {
    if (!text || text.trim() === '') {
      return;
    }

    onSendMessage(text);
    setText('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickSend();
    }
  }

  return (
    <BottomContainer style={containerStyle}>
      <ProfilePic containerStyle={{ minWidth: 43 }} />
      <InputText
        type="text"
        value={text}
        name="message"
        autoComplete="off"
        onKeyDown={handleKeyDown}
        placeholder={strings.SEND_MESSAGE_PLACEHOLDER}
        onChange={({ target: { value } }) => {
          setText(value);
        }}
      />
      <ActionButton
        disabled={loading}
        onClick={onClickSend}
        title={loading ? strings.SENDING_INBOX_MESSAGE : strings.SEND_BUTTON}
        iconStyle={{ top: 3 }}
        icon={paperPlane}
        size="50"
      />
    </BottomContainer>
  );
};

export default ConversationInput;
