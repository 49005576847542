// @flow

import React from 'react';
import StyledBurger from './styled-burger';

type Props = {
  animationState: boolean,
  onClick: Function,
};

const HamburgerNav = ({ animationState, onClick }: Props) => (
  <StyledBurger animationState={animationState} onClick={onClick}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default HamburgerNav;
