import {
  REQUEST_GET_FAQS,
  SUCCESS_GET_FAQS,
  FAILURE_GET_FAQS,
} from '../constants/types';

import getFAQsAPI from '../../api/faqs';

const requestGetFAQs = () => ({ type: REQUEST_GET_FAQS });

const successGetFAQs = (data) => ({
  type: SUCCESS_GET_FAQS,
  payload: { data },
});

const failureGetFAQs = (err) => ({
  type: FAILURE_GET_FAQS,
  payload: { err },
});

const getFAQs = () => async (dispatch) => {
  dispatch(requestGetFAQs());

  try {
    const { data: { sections } } = await getFAQsAPI();

    dispatch(successGetFAQs(sections));
  } catch (error) {
    dispatch(failureGetFAQs(error));
  }
};

export default getFAQs;
