// @flow

import React, { useRef, useEffect } from 'react';

import Message from './message';
import ConversationInput from './conversation-input';
import ConversationHeader from './conversation-header';

import scrollToBottom from '../../utils/scroll';

import {
  Container,
  TopContainer,
  BodyContainer,
} from './chat-styles';

type Props = {
  messages: any[],
  onClose: Function,
  onSendMessage: Function,
  currentConversation: any,
};

const ConversationDetails = ({ messages = [], onSendMessage, currentConversation, onClose }: Props) => {
  const chatBox = useRef(null);

  useEffect(() => {
    scrollToBottom(chatBox);
  }, [messages]);

  return (
    <Container>
      <TopContainer>
        <ConversationHeader
          onClose={onClose}
          currentConversation={currentConversation}
        />
        <BodyContainer ref={chatBox}>
          {messages.map(message =>
            <Message key={message.id} data={message} />
          )}
        </BodyContainer>
      </TopContainer>
      <ConversationInput onSendMessage={onSendMessage} />
    </Container>
  )
};

export default ConversationDetails;
