// @flow

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import strings from '../../config/strings';
import NotificationCard from './notification-card';
import postReadNotifications from '../../api/notifications/read-notifications';

import { INBOX } from '../../router/routes';
import { EndList } from '../shared/texts/title';
import { getNotifications } from '../../redux/actions';
import { notificationOn, notificationOff } from '../../assets/images';

import {
  NotificationContainer,
  ArrowUp,
  NotificationWrapper,
  ImageWrapper,
  EndListContainer,
} from './styles';

type Props = {
  data: Array<any>,
};
const NotificationHolder = ({ data }: Props) => {
  const [isNotification, setIsNotification] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleNotificationClick = () => {
    setIsClicked(!isClicked);
  };

  const handleCardClick = e => {
    const { id } = e.currentTarget;
    const url = `${INBOX}?conversation=${id}`;
    history.push(url);
    setIsClicked(!isClicked);

    postReadNotifications(id).then(() =>
      dispatch(getNotifications())
    );
  };

  useEffect(() => {
    const unreadMessages = data.filter(el => el.read === false);
    if (unreadMessages.length > 0) {
      setIsNotification(true);
    }
  }, [data]);

  return (
    <NotificationWrapper>
      <ImageWrapper onClick={handleNotificationClick}>
        <img
          src={isNotification ? notificationOn : notificationOff}
          alt="notification"
        />
      </ImageWrapper>
      {isClicked && (
        <>
          <ArrowUp />
          <NotificationContainer>
            {data.map(el => (
              <div
                onClick={handleCardClick}
                id={el.id}
                key={el.id}
                role="button"
                tabIndex={0}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleCardClick();
                  }
                }}
              >
                <NotificationCard key={el.id} data={el} />
              </div>
            ))}
            <EndListContainer>
              <EndList>{strings.YOU_REACHED_END_LIST}</EndList>
            </EndListContainer>
          </NotificationContainer>
        </>
      )}
    </NotificationWrapper>
  );
};

export default NotificationHolder;
