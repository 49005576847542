// @flow

import React from 'react';

import ChartsView from './charts';
import { Container } from './header';
import globals from '../../config/globals';
import strings from '../../config/strings';
import Switch from '../../containers/switch';
import AllocationsListView from './list-view';
import type { PolicyFund } from '../../models/policies';
import Tooltip from '../shared/toolpin/hint-toolpin';
import { SectionTitle2 } from '../shared/texts/title';
import { TooltipContainer, AllocationsTitleContainer } from './container';

type Props = {
  policyInfo: any,
  activeTabKey: string,
  currency: { id: number, name: string },
  funds: Array<PolicyFund>,
  handleTabSwitch: Function,
  policyId: number,
};

const PerformanceTabSwitch = ({
  activeTabKey,
  currency,
  funds,
  policyId,
  policyInfo,
  handleTabSwitch,
}: Props) => (
  <Container>
    <AllocationsTitleContainer>
      <SectionTitle2>
        {strings.SECTION_TITLE_PERFORMANCE_ALLOCATION}
      </SectionTitle2>
      <TooltipContainer>
        <Tooltip
          hint={strings.PORTFOLIO_ALLOCATIONS_HELP_TEXT}
          keyid="performancePortfolioAllocations"
        />
      </TooltipContainer>
    </AllocationsTitleContainer>

    <Switch handleTabSwitch={handleTabSwitch} activeTabKey={activeTabKey}>
      {
        {
          [`${globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.LIST_VIEW}`]: (
            <AllocationsListView funds={funds} policyInfo={policyInfo} />
          ),
          [`${globals.PORTFOLIO_ALLOCATIONS_TABS_KEYS.CHART_VIEW}`]: (
            <ChartsView
              funds={funds}
              currency={currency}
              policyCurrency={policyInfo.currency}
              policyId={policyId}
            />
          ),
        }[activeTabKey]
      }
    </Switch>
  </Container>
);

export default PerformanceTabSwitch;
