// @flow

import React from 'react';
import type Moment from 'moment';
import type { PolicyType } from '../models/policies';
import { ArticlesList, PoliciesList } from '../components';
import { OverviewScreenSectionDivider as SectionDivider } from '../components/shared/divider';

type Policy = {
  policyNumber: string,
  policyId: number,
  policyType: PolicyType,
  startDate: Moment,
  endDate: Moment,
  insuredName: string,
  insuredDob: Moment,
  totalContribution: number,
  totalReturnPerc: number,
  totalLoss: number,
  nextInstallmentDate: Moment,
  nextInstallmentAmount: number,
  currency: string,
  currentPolicyValue: number,
  policyAnniversaryDate: Moment,
};

type Props = {
  handlePolicyViewMoreClick: Function,
  userPolicies: Array<Policy>,
};

const Overview = ({ handlePolicyViewMoreClick, userPolicies }: Props) => (
  <div>
    <PoliciesList
      handlePolicyViewMoreClick={handlePolicyViewMoreClick}
      userPolicies={userPolicies}
    />
    <SectionDivider />
    <ArticlesList />
  </div>
);

export default Overview;
