// @flow

import React, { useState, useEffect } from 'react';
import type { Node } from 'react';

import Popup from '../components/shared/popup';

type Props = {
  children: Node,
  handleClosePopup: Function,
  containerStyle: any,
  isOpen: boolean,
};

const PopupOverlay = ({ containerStyle, isOpen, children, handleClosePopup }: Props) => {
  const [openState, setOpenState] = useState(isOpen);

  const handleCloseBtnClick = () => {
    setOpenState(false);
    handleClosePopup();
  };

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  return (
    <Popup
      isOpen={openState}
      setOpenState={setOpenState}
      handleCloseBtnClick={handleClosePopup ? handleCloseBtnClick : null}
      style={containerStyle}
    >
      {children}
    </Popup>
  );
};

export default PopupOverlay;
