// @flow

import React, { useState, useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';

import {
  DropdownBox,
  DropdownContainer,
  DropdownList,
  DropdownItem,
  SimpleTitle,
} from './container';

import Arrow from '../../arrow';

type Props = {
  title: String,
  options: any[],
  noMaxWidth: Boolean,
  onSelect: Function,
  width: Number,
  right: Number,
};

const SimpleDropdown = ({ title, options, onSelect, width, right, noMaxWidth }: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  // close dropdown if clicked outside
  useOutsideClick(ref, () => setActive(false));

  const toggleDropdown = () => {
    if (!options || !options.length) {
      return;
    }

    setActive(prevActiveState => !prevActiveState);
  };

  const onClickItem = option => {
    onSelect(option);
    toggleDropdown();
  };

  const renderOptions = () => {
    if (!options) {
      return null;
    }

    return options.map(option => (
      <DropdownItem
        simple
        visible
        key={option}
        noMaxWidth={noMaxWidth}
        onClick={() => onClickItem(option)}
      >
        {option}
      </DropdownItem>
    ));
  };

  return (
    <DropdownContainer style={{ margin: 0 }} ref={ref}>
      <DropdownBox width={width} simple onClick={toggleDropdown}>
        <SimpleTitle>{title}</SimpleTitle>
        <Arrow
          color="#001791"
          style={{ transform: 'rotate(90deg)', marginRight: 8 }}
        />
      </DropdownBox>
      <DropdownList width={width} simple active={active} right={right}>
        {renderOptions()}
      </DropdownList>
    </DropdownContainer>
  );
};

export default SimpleDropdown;
