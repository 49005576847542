import styled from 'styled-components';
import { size } from '../../config/device';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ConvertableRowToColContainer = styled(RowContainer)`
  flex-wrap: wrap;

  @media (max-width: ${size.tablet}) {
    flex-direction: column;
  }
`;

export const fullWidth = { width: '100%' };
