// @flow

import React, { Fragment } from 'react';

import ErrorLabel from './error';
import type FormUtils from '../../models/form';
import Textbox, { TextBoxWrapper } from './text-input';
import { MainContent4 } from '../shared/texts/content';

type Props = {
  errorMessage: string,
  formUtils: FormUtils,
  isDisabled: boolean,
  label: string,
  name: string,
  placeholder: string,
  type: 'text' | 'password',
  defaultValue: string,
};

const TextboxValidation = ({
  formUtils,
  isDisabled,
  name,
  type,
  placeholder,
  errorMessage,
  label,
  defaultValue,
}: Props) => (
  <Fragment>
    <MainContent4>{label}</MainContent4>

    <TextBoxWrapper>
      <Textbox
        name={name}
        type={type}
        placeholder={placeholder}
        ref={formUtils.register}
        hasError={formUtils.errors[name]}
        defaultValue={defaultValue}
        autoComplete="off"
        disabled={isDisabled}
      />
    </TextBoxWrapper>

    <ErrorLabel hasError={formUtils.errors[name]}>{errorMessage}</ErrorLabel>
  </Fragment>
);

export default TextboxValidation;
