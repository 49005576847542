// @flow

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LIBRARY, POLICY_DETAILS, INDEX } from '../../router/routes';
import { overview, library, policyDetails } from '../../assets/images/index';
import { NavigationLink } from '../shared/texts/navigation-links';
import { usePolicies } from '../../hooks/policies.hooks';

import strings from '../../config/strings';

import {
  NavigationDropDownContainer,
  NavigationTab,
  LogoContainer,
  NavigationSliderContainer,
  Selector,
} from './styles';

type Props = {
  animationState: boolean,
  onChangePath: () => void,
};

const NavigationDropDown = ({ animationState, onChangePath }: Props) => {
  const [onOverview, setOnOverview] = useState(false);
  const [onPolicyDetails, setOnPolicyDetails] = useState(false);
  const [onLibrary, setOnLibrary] = useState(false);

  const history = useHistory();
  const location = useLocation().pathname;
  const policies = usePolicies();

  function checkPath() {
    switch (location) {
      case POLICY_DETAILS:
        setOnPolicyDetails(true);
        break;

      case LIBRARY:
        setOnLibrary(true);
        break;

      default:
        setOnOverview(true);
        break;
    }
  }

  function resetNavigationState() {
    setOnOverview(false);
    setOnLibrary(false);
    setOnPolicyDetails(false);
  }

  function navigateTo(path) {
    history.push(path);
    onChangePath();
  }

  useEffect(() => {
    resetNavigationState();
    checkPath();
  }, [location]);

  return (
    <NavigationSliderContainer animationState={animationState}>
      <NavigationDropDownContainer>
        <NavigationTab
          onClick={() => navigateTo(INDEX)}
          showBackground={onOverview}
        >
          {onOverview && <Selector />}
          <LogoContainer>
            <img src={overview} alt="overview" width="17" />
          </LogoContainer>
          <NavigationLink>{strings.OVERVIEW}</NavigationLink>
        </NavigationTab>
        {!!policies.length && (
          <NavigationTab
            onClick={() => navigateTo(POLICY_DETAILS)}
            showBackground={onPolicyDetails}
          >
            {onPolicyDetails && <Selector />}
            <LogoContainer>
              <img src={policyDetails} alt="policy details" width="17" />
            </LogoContainer>
            <NavigationLink>
              {strings.POLICY_DETAILS}
            </NavigationLink>
          </NavigationTab>
        )}
        <NavigationTab
          onClick={() => navigateTo(LIBRARY)}
          showBackground={onLibrary}
        >
          {onLibrary && <Selector />}
          <LogoContainer>
            <img src={library} alt="library" width="17" />
            <NavigationLink>{strings.LIBRARY}</NavigationLink>
          </LogoContainer>
        </NavigationTab>
      </NavigationDropDownContainer>
    </NavigationSliderContainer>
  );
};

export default NavigationDropDown;
