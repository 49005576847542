const parseErrorId = (error) => {
  if (Array.isArray(error.message) && error.message.length) {
    return parseErrorId(error.message[0]);
  }

  if (Array.isArray(error.messages) && error.messages.length) {
    return parseErrorId(error.messages[0]);
  }

  if (typeof error === 'object' && error.id) {
    return error.id;
  }

  return '';
};

const isAuthBlockedError = (message) => (
  message === 'Auth.form.error.blocked'
);

const isAuthBlockedTempError = (message) => (
  message === 'Auth.form.error.blocked_temporary'
);

const isAuthBlockedResetPasswordRequired = (message) => (
  message === 'Auth.form.error.blocked.password.reset_required'
);

module.exports = {
  parseErrorId,
  isAuthBlockedError,
  isAuthBlockedResetPasswordRequired,
  isAuthBlockedTempError,
};
