// @flow

import React from 'react';
import ContributionPayments from '../../components/contribution/payments';
import ContributionChart from '../../components/contribution/chart';
import ContributionCards from '../../components/contribution/cards';
import { isInvestmentTypePolicy } from '../../utils/policy';

type Props = {
  payments: any[],
  currency: String,
  policyType: String,
  contributions: any[],
  contributionsInfo: any,
  contributionsPage: Number,
  onLoadMorePayments: Function,
  onChangeContributionType: Function,
};

const ContributionScreen = ({
  onLoadMorePayments,
  payments,
  policyType,
  contributionsPage,
  onChangeContributionType,
  contributions,
  contributionsInfo,
  currency,
}: Props) => (
  <>
    <ContributionCards
      info={contributionsInfo}
      isInvestmentType={isInvestmentTypePolicy(policyType)}
    />
    <ContributionChart
      currency={currency}
      contributions={payments}
      showDropdown={isInvestmentTypePolicy(policyType)}
      onChangeContributionType={onChangeContributionType}
    />
    <ContributionPayments
      onLoadMore={onLoadMorePayments}
      currentPage={contributionsPage}
      data={contributions}
      currency={currency}
    />
  </>
);

export default ContributionScreen;
