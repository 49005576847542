import styled from 'styled-components';
import { RowContainer } from '../../shared/container';

const PeriodPickerContainer = styled(RowContainer)`
  width: 100%;
  justify-content: space-evenly;
`;

export const performanceSectionTitle = { marginBottom: 30 };

export default PeriodPickerContainer;
