// @flow

import React, { useState } from 'react';

import {
  Container,
  ContentWrapper,
} from './styles';

import Dropdowns from './dropdowns';
import Actions from './actions';

type Props = {
  filters: any[];
  selectedFilters: any[];

  onClickClear: Function;
  onClickApply: (filter) => void;

  checkboxValue: Boolean;
  onChangeCheckboxValue: (value: boolean) => void;
};

const FilterPopup = ({
  checkboxValue, onChangeCheckboxValue,
  filters, selectedFilters, onClickClear, onClickApply,
}: Props) => {
  const [currentFilter, setCurrentFilter] = useState(new Map());

  const onChangeFilter = (filter) => {
    setCurrentFilter(new Map(filter));
  };

  const apply = () => {
    onClickApply(currentFilter);
  };

  return (
    <Container>
      <ContentWrapper>
        <Dropdowns
          data={filters}
          selected={selectedFilters}
          onChangeFilter={onChangeFilter}
        />
        <Actions
          onChangeCheckboxValue={onChangeCheckboxValue}
          checkboxValue={checkboxValue}
          onClickClear={onClickClear}
          onClickApply={apply}
        />
      </ContentWrapper>
    </Container>
  );
};

export default FilterPopup;
