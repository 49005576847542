// @flow

import React from 'react';
import ConversationItem from './conversation-item';
import { ConversationsContainer } from './styles';
import Button from '../shared/table/button';
import strings from '../../config/strings';

type Props = {
  shrink: Boolean,
  conversations: any[],
  currentConversation: any,
  onConversationClick: Function,
  onLoadMore: Function,
};

const Conversations = ({
  shrink,
  onLoadMore,
  conversations = [],
  currentConversation,
  onConversationClick,
}: Props) => (
  <ConversationsContainer shrink={shrink}>
    {conversations.map(el => (
      <ConversationItem
        onItemClick={() => onConversationClick(el.id)}
        active={currentConversation === el.id}
        shrink={shrink}
        key={el.id}
        data={el}
      />
    ))}
    {!!onLoadMore && <Button onClick={onLoadMore}>{strings.LOAD_MORE}</Button>}
  </ConversationsContainer>
);

export default Conversations;
