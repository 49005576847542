// @flow

import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import { formatDate } from '../../utils/date';
import strings from '../../config/strings';
import Spinner from '../shared/spinner';

import {
  downloadIcon,
  eye,
  GenericDocument,
  noGenericDocument,
  pdfShape,
} from '../../assets/images';

import {
  DateTitle,
  DocumentTitle,
  DownloadTitle,
  NoDocumentTitle,
  ViewTitle,
} from '../shared/texts/title';

import {
  CardContainer,
  LogoDiv,
  TitleDiv,
  RowContainer,
  ViewDiv,
  ViewLogo,
  DownloadLogo,
  DocumentDiv,
  DownloadDiv,
  GroupBlock,
} from './styles';

import type PolicyDocType from '../../models/documents';

type Props = {
  dateAdded: Date,
  docType?: PolicyDocType,
  documentType: string,
  documentName: string,
  documentPath: string,
  handleDownloadDocumentClick: Function,
  handleViewDocumentClick: Function,
  empty: boolean,
  emptyClause: string,
  centerClause: Boolean,
  noIcon: Boolean,
};

const DocumentCard = ({
  dateAdded,
  docType,
  documentType,
  documentName,
  documentPath,
  handleDownloadDocumentClick,
  handleViewDocumentClick,
  empty,
  noIcon,
  emptyClause,
  centerClause,
}: Props) => {
  const [loading, setLoading] = useState(false);

  let imageSrc = documentType === 'generic' ? GenericDocument : pdfShape;
  imageSrc = empty ? noGenericDocument : imageSrc;

  const date = dateAdded ? `Added on ${formatDate(dateAdded)}` : null;

  const renderDownloadContent = () => (
    <>
      <DownloadTitle
        onClick={async () => {
          setLoading(true);

          // eslint-disable-next-line no-unused-expressions
          isEmpty(docType)
            ? await handleDownloadDocumentClick(documentName, documentPath)
            : await handleDownloadDocumentClick(documentName, docType);

          setLoading(false);
        }}
      >
        {strings.DOWNLOAD}
      </DownloadTitle>

      <DownloadLogo>
        <img
          src={downloadIcon}
          alt="download"
          style={{ objectFit: 'contain' }}
        />
      </DownloadLogo>
    </>
  );

  return (
    <CardContainer empty={empty}>
      <RowContainer centerClause={empty && centerClause}>
        {!noIcon && (
          <LogoDiv>
            <DocumentDiv>
              <img src={imageSrc} alt="document" />
            </DocumentDiv>
          </LogoDiv>
        )}
        <TitleDiv centerClause={empty && centerClause}>
          {!empty ? (
            <DocumentTitle>{documentName}</DocumentTitle>
          ) : (
            <NoDocumentTitle>{emptyClause}</NoDocumentTitle>
          )}
        </TitleDiv>
      </RowContainer>

      {!empty && (
        <GroupBlock isSubtitle>
          <DateTitle>{date}</DateTitle>
        </GroupBlock>
      )}

      {!empty && (
        <GroupBlock>
          <ViewDiv>
            <ViewTitle
              target="_blank"
              onClick={() =>
                isEmpty(docType)
                  ? handleViewDocumentClick(documentName, documentPath)
                  : handleViewDocumentClick(documentName, docType)
              }
            >
              {strings.VIEW}
            </ViewTitle>
            <ViewLogo>
              <img src={eye} alt="view" />
            </ViewLogo>
          </ViewDiv>
          <DownloadDiv>
            {!loading ? (
              renderDownloadContent()
            ) : (
              <Spinner offset="0" style={{ transform: 'scale(0.5)' }} />
            )}
          </DownloadDiv>
        </GroupBlock>
      )}
    </CardContainer>
  );
};

DocumentCard.defaultProps = {
  docType: null,
};

export default DocumentCard;
