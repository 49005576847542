import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { getArticleDetails } from '../redux/actions/articles.action';
import ArticleDetailsComponent from '../components/articles/details';

const ArticleDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const details = useSelector(state => state.articles.currentArticle);

  useEffect(() => {
    dispatch(getArticleDetails(id));
  }, []);

  return <ArticleDetailsComponent data={details} />;
};

export default ArticleDetails;
