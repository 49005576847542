// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import groupby from '../utils/groupby';
import { formatDate } from '../utils/date';
import { getServiceForms } from '../redux/actions';
import Spinner from '../components/shared/spinner';
import ServiceFormsScreen from '../pages/service-forms';
import FileDownloader, { FileViewer } from '../utils/downloader';

const ServiceForms = () => {
  const dispatch = useDispatch();
  const { loading, data: forms } = useSelector(state => state.serviceForms);

  useEffect(() => {
    dispatch(getServiceForms());
  }, []);

  const formattedData = forms.map(item => {
    const info = { ...item };
    info.updatedDate = formatDate(item.updatedDate);

    return info;
  });
  const sections = groupby(formattedData, 'section');

  const groupedData = sections.map(el => ({
    section: el.section,
    data: groupby(el.data, 'topic'),
  }));

  const getWBDocumentEndpointAttributes = () => {
    const {
      REACT_APP_WB_BACKEND_URL,
      REACT_APP_ACCEPT_HEADER_WB,
    } = process.env;
    const url = `${REACT_APP_WB_BACKEND_URL || ''}/documents`;
    const headers = {
      'Content-Type': 'application/json',
      Accept: REACT_APP_ACCEPT_HEADER_WB,
    };

    return { url, headers };
  };

  const handleDownloadDocumentClick = async (name: string, path: string) => {
    const params = { name, path };
    const { url, headers } = getWBDocumentEndpointAttributes();

    return FileDownloader(url, headers, params);
  };

  const handleViewDocumentClick = (name, path) => {
    const params = { name, path };
    const { url, headers } = getWBDocumentEndpointAttributes();

    return FileViewer(url, headers, params);
  };

  function renderContent() {
    return (
      <ServiceFormsScreen
        forms={groupedData}
        handleDownloadDocumentClick={handleDownloadDocumentClick}
        handleViewDocumentClick={handleViewDocumentClick}
      />
    );
  }

  return loading ? <Spinner /> : renderContent();
};

export default ServiceForms;
