// @flow

import React, { useEffect, useState } from 'react';

import {
  Info,
  Image,
  Button,
  ButtonText,
  CardLayout,
  Content,
  Title,
} from './styles';
import Spinner from '../shared/spinner';

type Props = {
  id: Number,
  yt: String,
  documentName: string,
  documentPath: String,
  description: String,
  imagePath: string,
  imageName: string,
  buttonText: String,
  title: String,
  getDocumentImage: Function,
  handleViewArticleButtonClick: Function,
};

const generateSrcDoc = (title, link) => `<style>
    *{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
  </style>
  <a href=${link}>
    <img src='${link
      .replace('www.youtube.com/embed/', 'img.youtube.com/vi/')
      .replace(/\?.+/, '/hqdefault.jpg')}'alt='${title}'>
    <span>▶</span>
  </a>`;

const Card = ({
  id: cardId,
  documentName,
  documentPath,
  imagePath,
  imageName,
  yt,
  title,
  description,
  buttonText,
  getDocumentImage,
  handleViewArticleButtonClick,
}: Props) => {
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    let isSubscribed = true;

    async function fetchData() {
      const src = await getDocumentImage(
        imageName,
        imagePath,
        `img_${imageName}`,
      );

      if (isSubscribed) {
        setImageSrc(src);
      }
    }

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [imageName]);

  const renderYoutubeLink = (youtubeLink, height) => (
    <iframe
      width="100%"
      height={height}
      frameBorder="0"
      src={youtubeLink}
      title={`YT-${cardId}`}
      srcDoc={generateSrcDoc(`YT-${cardId}`, youtubeLink)}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  );

  const renderButton = (clickHandler?: Function) => {
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
      if (clickHandler) {
        setLoading(true);
        await clickHandler();
        setLoading(false);
      }
    };

    if (loading) {
      return <Spinner offset="0" style={{ transform: 'scale(0.5)' }} />;
    }

    return (
      <Button onClick={onClick}>
        <ButtonText>
          {buttonText}
        </ButtonText>
      </Button>
    );
  };

  const renderLink = () => {
    try {
      Boolean(new URL(documentPath));
      return (
        <a href={documentPath} target="_blank" rel="noopener noreferrer">
          {renderButton()}
        </a>
      );
    } catch (error) {
      return renderButton(() =>
        handleViewArticleButtonClick(documentName, documentPath),
      );
    }
  };

  return (
    <CardLayout>
      {yt ? (
        renderYoutubeLink(yt, '252')
      ) : (
        <Image id={`img_${imageName}`} src={imageSrc} height="252" lazy />
      )}
      <Info>
        <Title>{title}</Title>
        <Content style={{ WebkitBoxOrient: 'vertical' }}>{description}</Content>
        {renderLink()}
      </Info>
    </CardLayout>
  );
};

export default Card;
