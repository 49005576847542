// @flow

import React from 'react';
import { isEmpty } from 'lodash';

import Spinner from '../../shared/spinner';
import PeriodPickerContainer from './styles';
import Dropdown from '../../shared/dropdown';
import globals from '../../../config/globals';
import strings from '../../../config/strings';
import BarLineChart from '../../charts/bar-line';
import ActionButton from '../../shared/action-button';
import { ChartContainer, PaddedSectionDescriptionBlock } from '../container';

import type { DropDownOption } from '../../../models/form';

type Props = {
  accountValue: Array<number>,
  currency: { id: number, name: string },
  fundsOptions: Array<DropDownOption>,
  labels: Array<string>,
  handleFundsDropdownClick: Function,
  handlePeriodPickerClick: Function,
  profitLoss: Array<number>,
  selectedFundId: number | string,
  selectedPeriod: number,
  loading: boolean,
};

const AccountValueChart = ({
  accountValue,
  currency,
  fundsOptions,
  handleFundsDropdownClick,
  handlePeriodPickerClick,
  labels,
  profitLoss,
  selectedFundId,
  selectedPeriod,
  loading,
}: Props) => (
  <PaddedSectionDescriptionBlock isChart>
    {fundsOptions.length > 2 && (
      <Dropdown
        options={fundsOptions}
        title={fundsOptions.filter(opt => opt.id === selectedFundId)[0].name}
        selected={fundsOptions.filter(opt => opt.id === selectedFundId)[0]}
        onSelect={id => id !== -1 && handleFundsDropdownClick(id)}
        size="large"
      />
    )}

    <PeriodPickerContainer>
      {globals.CHART_ACCOUNT_VALUE_CONFIG.PERIOD_PICKERS.map((period, i) => (
        <ActionButton
          key={period}
          title={period}
          onClick={() => handlePeriodPickerClick(i + 1)}
          inverted={selectedPeriod !== i + 1}
          type="square"
          size="35"
        />
      ))}
    </PeriodPickerContainer>

    {!!loading && <Spinner />}

    {!loading && (
      <ChartContainer>
        <BarLineChart
          barData={accountValue}
          lineData={profitLoss}
          labels={labels}
          barDataName={
            globals.CHART_ACCOUNT_VALUE_CONFIG.ACCOUNT_VALUE.TOOLTIP_LABEL
          }
          lineDataName={
            globals.CHART_ACCOUNT_VALUE_CONFIG.PROFIT_LOSS.TOOLTIP_LABEL
          }
          symbol={
            !isEmpty(currency) &&
            globals.CURRENCIES[currency.name.toUpperCase()].value
          }
          errorMessage={strings.STRATEGY_ACCOUNT_VALUE_EMPTY_DATA_MESSAGE}
        />
      </ChartContainer>
    )}
  </PaddedSectionDescriptionBlock>
);

export default AccountValueChart;
